import { Api } from "../api";
import { Unit } from "../../models/Unit";
import { ListQuery } from "../../models/redux/ListQuery";

export const getAllUnit = (
	page: number,
	size: number,
	filter?: { [key: string]: any }
) => {
	return Api.get("/unit/getAll", { page, size, name: filter?.name || "" });
};

export const getAllUnitByUser = () => {
	return Api.get("/auth/getAllUnitByUser");
};

export const getUserNotifications = (page: number,
									 size: number) => {
	return Api.get("/user/notifications", { page, size });
};

export const addNewUnit = (data: Unit) => {
	return Api.post("/unit/createOrEdit", data);
};

export const getOneUnit = (id: number) => {
	return Api.get("/unit/createOrEdit", { id });
};

export const deleteUnit = (unitId: number) => {
	return Api.get("/unit/delete", { unitId });
};
