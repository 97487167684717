import { Action } from "../../../models/redux/action";
import { mergeMap, map, catchError, takeUntil } from "rxjs/operators";
import {
  FETCH_TERMNIAL_INIT,
  fetchTerminalSuccess,
  fetchTerminalFailed,
  FETCH_TERMNIAL_CANCELLED,
  ADD_TERMNIAL_INIT,
  addTerminalFailed,
  EDIT_TERMNIAL_INIT,
  editTerminalFailed,
  DELETE_TERMNIAL_INIT,
  fetchTerminalInit,
} from "./actions";
import { ActionsObservable, ofType } from "redux-observable";
import { of } from "rxjs";
import { CANCEL_ALL_REQUEST } from "../../default";
import { onEpicSuccess, onEpicFailed } from "../../../helpers/reduxEpic";
import {
  getAllTerminal,
  addTerminal,
  deleteOneTerminal,
  getAllTerminal1,
} from "../../../api/terminal";

export const fetchListTerminalEpic = (action$: ActionsObservable<Action>) => {
  return action$.pipe(
    ofType(FETCH_TERMNIAL_INIT),
    mergeMap((action) =>
      getAllTerminal1(
        action.payload.query?.filter?.name,
        action.payload.query.page,
        action.payload.query.per_page,
        action.payload.query?.filter?.status,
        action.payload.query?.filter?.portId
      ).pipe(
        map((response) =>
          onEpicSuccess(action, response, fetchTerminalSuccess(response))
        ),
        catchError((error) =>
          of(onEpicFailed(action, error, fetchTerminalFailed(error)))
        ),
        takeUntil(
          action$.pipe(ofType(FETCH_TERMNIAL_CANCELLED, CANCEL_ALL_REQUEST))
        )
      )
    )
  );
};

export const addTerminalEpic = (
  action$: ActionsObservable<Action>,
  state$: any
) => {
  return action$.pipe(
    ofType(ADD_TERMNIAL_INIT),
    mergeMap((action) =>
      addTerminal(action.payload).pipe(
        map((response) => {
          return onEpicSuccess(
            action,
            response,
            fetchTerminalInit({
              page: state$.value.terminal.query.page,
              per_page: state$.value.terminal.query.per_page,
            })
          );
        }),
        catchError((error) =>
          of(onEpicFailed(action, error, addTerminalFailed(error)))
        ),
        takeUntil(action$.pipe(ofType(CANCEL_ALL_REQUEST)))
      )
    )
  );
};

export const editTerminalEpic = (
  action$: ActionsObservable<Action>,
  state$: any
) => {
  return action$.pipe(
    ofType(EDIT_TERMNIAL_INIT),
    mergeMap((action) =>
      addTerminal(action.payload).pipe(
        map((response) => {
          return onEpicSuccess(
            action,
            response,
            fetchTerminalInit({
              page: state$.value.terminal.query.page,
              per_page: state$.value.terminal.query.per_page,
            })
          );
        }),
        catchError((error) =>
          of(onEpicFailed(action, error, editTerminalFailed(error)))
        ),
        takeUntil(action$.pipe(ofType(CANCEL_ALL_REQUEST)))
      )
    )
  );
};

export const deleteTerminalEpic = (
  action$: ActionsObservable<Action>,
  state$: any
) => {
  return action$.pipe(
    ofType(DELETE_TERMNIAL_INIT),
    mergeMap((action) =>
      deleteOneTerminal(action.payload).pipe(
        map((response) => {
          return onEpicSuccess(
            action,
            response,
            fetchTerminalInit({
              page: state$.value.terminal.query.page,
              per_page: state$.value.terminal.query.per_page,
            })
          );
        }),
        catchError((error) =>
          of(onEpicFailed(action, error, editTerminalFailed(error)))
        ),
        takeUntil(action$.pipe(ofType(CANCEL_ALL_REQUEST)))
      )
    )
  );
};
