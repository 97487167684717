import { ListState } from "../../../models/redux/listState";
import { FeeApprove } from "../../../models/feeApprove";
import {
  baseFetchListState,
  fetchListState,
  fetchListStateFailed,
  fetchListStateSuccess,
} from "../../../helpers/reduxAction";
import { Action } from "../../../models/redux/action";
import {
  FETCH_FEE_APPROVE,
  FETCH_FEE_APPROVE_FAIL,
  FETCH_FEE_APPROVE_SUCCESS,
} from "./action";

const initState: ListState<FeeApprove> = baseFetchListState();
export const feeApproveReducer = (
  state: ListState<FeeApprove> = initState,
  action: Action
) => {
  switch (action.type) {
    case FETCH_FEE_APPROVE:
      return fetchListState(action);
    case FETCH_FEE_APPROVE_SUCCESS:
      return fetchListStateSuccess(action, state);
    case FETCH_FEE_APPROVE_FAIL:
      return fetchListStateFailed(action, state);
    default:
      return state;
  }
};
