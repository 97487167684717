import { Api } from "../api";
import { TypeContainer } from "../../models/TypeContainer";
import { ListQuery } from "../../models/redux/ListQuery";

export const getAllTypeContainer = (
  name: string = "",
  page: number,
  size: number,
  status: string = ""
) => {
  return Api.get("/typecontainerlist/getAll", { name, page, size, status });
};
export const getAllTypeContainer1 = (query: ListQuery) =>
  Api.get("/typecontainerlist/getAll", {
    page: query.page,
    size: query.per_page,
    name: query.filter?.name || "",
    status: query.filter?.status || "",
  });

export const addTypeContainer = (data: TypeContainer) => {
  return Api.post("/typecontainerlist/createOrEdit", { ...data });
};

export const getOneTypeContainer = (id: number) => {
  return Api.get("/typecontainerlist/createOrEdit", { id });
};

export const deleteOneTypeContainer = (data: any) => {
  return Api.get("/typecontainerlist/delete", { ...data });
};
