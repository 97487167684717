import { ActionsObservable, ofType } from "redux-observable";
import { Action } from "../../../models/redux/action";
import {
	FETCH_ROLE,
	fetchRoleSuccess,
	fetchRoleFailed,
	FETCH_ROLE_CANCELLED,
	deleteRoleSuccess,
	deleteRoleFailed,
	DELETE_ROLE_CANCELLED,
	DELETE_ROLE,
	ADD_ROLE,
	addRoleFailed,
	addRoleSuccess,
	ADD_ROLE_CANCELLED,
} from "./actions";
import { mergeMap, map, catchError, takeUntil } from "rxjs/operators";
import { getAllRole, deleteRole, createOrEditRole } from "../../../api/role";
import { ReduxCallbacks } from "../../../models/redux/Callbacks";
import { ListQuery } from "../../../models/redux/ListQuery";
import { DEFAULT_PAGE_SIZE } from "../../../constants/pagination";
import { onEpicSuccess, onEpicFailed } from "../../../helpers/reduxEpic";
import { of } from "rxjs";
import { CANCEL_ALL_REQUEST } from "../../default";
import { CreateRole } from "../../../models/CreateRole";

export const fetchRoleEpic = (
	action$: ActionsObservable<
		Action<{ query: ListQuery; callbacks: ReduxCallbacks }>
	>
) =>
	action$.pipe(
		ofType(FETCH_ROLE),
		mergeMap((action) =>
			getAllRole(
				action.payload?.query.page || 0,
				action.payload?.query.per_page || DEFAULT_PAGE_SIZE,
				action.payload?.query.filter
			).pipe(
				map((response) =>
					onEpicSuccess(action, response, fetchRoleSuccess(response))
				),
				catchError((error) =>
					of(onEpicFailed(action, error, fetchRoleFailed(error)))
				),
				takeUntil(
					action$.pipe(ofType(FETCH_ROLE_CANCELLED, CANCEL_ALL_REQUEST))
				)
			)
		)
	);

export const addRoleEpic = (
	action$: ActionsObservable<
		Action<{ role: CreateRole; callbacks: ReduxCallbacks }>
	>
) =>
	action$.pipe(
		ofType(ADD_ROLE),
		mergeMap((action) => {
			if (action.payload) {
				return createOrEditRole(action.payload.role).pipe(
					map((response) =>
						onEpicSuccess(action, response, addRoleSuccess(response))
					),
					catchError((error) =>
						of(onEpicFailed(action, error, addRoleFailed(error)))
					),
					takeUntil(
						action$.pipe(ofType(ADD_ROLE_CANCELLED, CANCEL_ALL_REQUEST))
					)
				);
			}

			return of(
				onEpicFailed(
					action,
					{ message: "Thông tin không đủ" },
					addRoleFailed({ message: "Thông tin không đủ" })
				)
			);
		})
	);

export const deleteRoleEpic = (
	action$: ActionsObservable<
		Action<{ roleId: number; callbacks: ReduxCallbacks }>
	>
) =>
	action$.pipe(
		ofType(DELETE_ROLE),
		mergeMap((action) =>
			deleteRole(action.payload?.roleId || 0).pipe(
				map((response) =>
					onEpicSuccess(action, response.response, deleteRoleSuccess(response))
				),
				catchError((error) =>
					of(onEpicFailed(action, error, deleteRoleFailed(error)))
				),
				takeUntil(
					action$.pipe(ofType(DELETE_ROLE_CANCELLED, CANCEL_ALL_REQUEST))
				)
			)
		)
	);
