import { ActionsObservable, ofType } from "redux-observable";
import { Action } from "../../../models/redux/action";
import {
  FETCH_FEE_APPROVE,
  fetchFeeApproveSuccess,
  fetchFeeApproveFail,
  ADD_FEE_APPROVE,
  addFeeApproveSuccess,
  addFeeApproveFail,
  DELETE_FEE_APPROVE,
  deleteFeeApproveFail,
  deleteFeeApproveSuccess,
} from "./action";
import { mergeMap, map, catchError, takeUntil } from "rxjs/operators";
import {
  getAllFeeApproveService,
  createFeeApproveService,
  deleteFeeApproveService,
} from "../../../api/config_approve";
import { onEpicSuccess, onEpicFailed } from "../../../helpers/reduxEpic";
import { of } from "rxjs";
import { CANCEL_ALL_REQUEST } from "../../default";

export const fetchFeeApproveEpic = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    ofType(FETCH_FEE_APPROVE),
    mergeMap((action) => {
      return getAllFeeApproveService(action.payload.query).pipe(
        map((response) =>
          onEpicSuccess(action, response, fetchFeeApproveSuccess(response))
        ),
        catchError((error) =>
          of(onEpicFailed(action, error, fetchFeeApproveFail(error)))
        ),
        takeUntil(action$.pipe(ofType(CANCEL_ALL_REQUEST)))
      );
    })
  );

export const addFeeApproveEpic = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    ofType(ADD_FEE_APPROVE),
    mergeMap((action) =>
      createFeeApproveService(action.payload.data).pipe(
        map((response) => {
          return onEpicSuccess(
            action,
            response,
            addFeeApproveSuccess(response)
          );
        }),
        catchError((error) =>
          of(onEpicFailed(action, error, addFeeApproveFail(error)))
        ),
        takeUntil(action$.pipe(ofType(CANCEL_ALL_REQUEST)))
      )
    )
  );
export const deleteFeeApproveEpic = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    ofType(DELETE_FEE_APPROVE),
    mergeMap((action) =>
      deleteFeeApproveService(action.payload.id).pipe(
        map((response) => {
          return onEpicSuccess(
            action,
            response,
            deleteFeeApproveSuccess(response)
          );
        }),
        catchError((error) =>
          of(onEpicFailed(action, error, deleteFeeApproveFail(error)))
        ),
        takeUntil(action$.pipe(ofType(CANCEL_ALL_REQUEST)))
      )
    )
  );
