import React, {
  FunctionComponent,
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
  useContext,
} from "react";
import env from "../helpers/env";
import {
  NotificationsContext,
  NotificationsContextType,
} from "./NotificationsContext";
// import { messaging } from "../../firebaseInit";
import { notify } from "../helpers/notify";
import { getUserNotifications } from "../api/unit";
import { map } from "rxjs/operators";
import moment from "moment";

const { DEFAULT_PAGE_TITLE } = env;

export type PageTitleContextType = {
  pageTitle: string;
  setPageTitle: Dispatch<SetStateAction<string>>;
};

export const PageTitleContext = React.createContext<
  PageTitleContextType | undefined
>(undefined);
const useNotifications = () => {
  const context = useContext<NotificationsContextType | undefined>(
    NotificationsContext
  );
  if (context === undefined) {
    throw new Error("usePagTitle must be used in BookingProvider");
  }
  return context;
};
export const PageTitleProvider: FunctionComponent = ({ children }) => {
  const [pageTitle, setPageTitle] = useState<string>(DEFAULT_PAGE_TITLE);
  const { notifications, setNotifications } = useNotifications();

  // messaging.onMessage((payload) => {

  //     const {bookingNoteNo, bookingNoteStatus} = payload.data;
  //     notify("Cập nhật booking note: " + payload.data.bookingNoteNo);
  //     getUserNotifications(0, 10)
  //         .pipe(map(({response}) => response))
  //         .subscribe((response) => {

  //             setNotifications((prevState: any) => {

  //                 const newState = {...prevState}
  //                 newState.unseenCount = response.unseen;
  //                 newState.content = response.content;
  //                 return newState;
  //             });
  //         });
  // });

  useEffect(() => {
    getUserNotifications(0, 10)
      .pipe(map(({ response }) => response))
      .subscribe((response) => {
        console.log(response);
        response.content = response.content.map((value: any, index: number) => {
          let data = JSON.parse(value.data);
          value.creationTime = moment(new Date(value.creationTime)).format(
            "DD/MM/YYYY HH:mm"
          );

          switch (data.bookingNoteStatus) {
            case 1:
              data.status = "Đã xác nhận";
              break;
            case 2:
              data.status = "Đã từ chối";
          }
          return {
            ...value,
            data: data,
          };
        });
        setNotifications(response);
      });
    localStorage.setItem("firstRequest", "2");
  }, []);

  useEffect(() => {
    document.title = pageTitle;
  }, [pageTitle]);

  return (
    <PageTitleContext.Provider value={{ pageTitle, setPageTitle }}>
      {children}
    </PageTitleContext.Provider>
  );
};
