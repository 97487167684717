import { Api } from "../api";
import { Fee } from "../../models/Fee";
import { ListQuery } from "../../models/redux/ListQuery";

export const getAllFee = (query: ListQuery) => {
  return Api.get("/fee/getAll", {
    name: query?.filter?.name || "",
    page: query.page,
    size: query.per_page,
    status: query.filter?.status || undefined,
  });
};

export const addFee = (data: Fee) => {
  return Api.post("/fee/createOrEdit", { ...data });
};

export const getOneFee = (id: number) => {
  return Api.get("/fee/createOrEdit", { id });
};

export const deleteOneFee = (data: Fee) => {
  return Api.get("/fee/delete", { ...data });
};
