import { Action } from "../../../models/redux/action";
import { mergeMap, map, catchError, takeUntil } from "rxjs/operators";
import {
  FETCH_CHARGE_INIT,
  fetchChargeSuccess,
  fetchChargeFailed,
  FETCH_CHARGE_CANCELLED,
  ADD_CHARGE_INIT,
  addChargeFailed,
  EDIT_CHARGE_INIT,
  editChargeFailed,
  DELETE_CHARGE_INIT,
  fetchChargeInit,
} from "./actions";
import { ActionsObservable, ofType } from "redux-observable";
import { of } from "rxjs";
import { CANCEL_ALL_REQUEST } from "../../default";
import { onEpicSuccess, onEpicFailed } from "../../../helpers/reduxEpic";
import { getAllCharge, addCharge, deleteOneCharge } from "../../../api/charge";

export const fetchListChargeEpic = (action$: ActionsObservable<Action>) => {
  return action$.pipe(
    ofType(FETCH_CHARGE_INIT),
    mergeMap((action) =>
      getAllCharge(action.payload.query).pipe(
        map((response) =>
          onEpicSuccess(action, response, fetchChargeSuccess(response))
        ),
        catchError((error) =>
          of(onEpicFailed(action, error, fetchChargeFailed(error)))
        ),
        takeUntil(
          action$.pipe(ofType(FETCH_CHARGE_CANCELLED, CANCEL_ALL_REQUEST))
        )
      )
    )
  );
};

export const addChargeEpic = (
  action$: ActionsObservable<Action>,
  state$: any
) => {
  return action$.pipe(
    ofType(ADD_CHARGE_INIT),
    mergeMap((action) =>
      addCharge(action.payload).pipe(
        map((response) => {
          return onEpicSuccess(
            action,
            response,
            fetchChargeInit({
              page: state$.value.charge.query.page,
              per_page: state$.value.charge.query.per_page,
            })
          );
        }),
        catchError((error) =>
          of(onEpicFailed(action, error, addChargeFailed(error)))
        ),
        takeUntil(action$.pipe(ofType(CANCEL_ALL_REQUEST)))
      )
    )
  );
};

export const editChargeEpic = (
  action$: ActionsObservable<Action>,
  state$: any
) => {
  return action$.pipe(
    ofType(EDIT_CHARGE_INIT),
    mergeMap((action) =>
      addCharge(action.payload).pipe(
        map((response) => {
          console.log("bbb", response);
          console.log(state$);
          return onEpicSuccess(
            action,
            response,
            fetchChargeInit({
              page: state$.value.charge.query.page,
              per_page: state$.value.charge.query.per_page,
            })
          );
        }),
        catchError((error) =>
          of(onEpicFailed(action, error, editChargeFailed(error)))
        ),
        takeUntil(action$.pipe(ofType(CANCEL_ALL_REQUEST)))
      )
    )
  );
};

export const deleteChargeEpic = (
  action$: ActionsObservable<Action>,
  state$: any
) => {
  return action$.pipe(
    ofType(DELETE_CHARGE_INIT),
    mergeMap((action) =>
      deleteOneCharge(action.payload).pipe(
        map((response) => {
          return onEpicSuccess(
            action,
            response,
            fetchChargeInit({
              page: state$.value.charge.query.page,
              per_page: state$.value.charge.query.per_page,
            })
          );
        }),
        catchError((error) =>
          of(onEpicFailed(action, error, editChargeFailed(error)))
        ),
        takeUntil(action$.pipe(ofType(CANCEL_ALL_REQUEST)))
      )
    )
  );
};
