import { Action } from "../../../models/redux/action";
import { mergeMap, map, catchError, takeUntil } from "rxjs/operators";
import {
  FETCH_CHARGETYPE_INIT,
  fetchChargeTypeSuccess,
  fetchChargeTypeFailed,
  FETCH_CHARGETYPE_CANCELLED,
  ADD_CHARGETYPE_INIT,
  addChargeTypeFailed,
  EDIT_CHARGETYPE_INIT,
  editChargeTypeFailed,
  DELETE_CHARGETYPE_INIT,
  fetchChargeTypeInit,
} from "./actions";
import { ActionsObservable, ofType } from "redux-observable";
import { of } from "rxjs";
import { CANCEL_ALL_REQUEST } from "../../default";
import { onEpicSuccess, onEpicFailed } from "../../../helpers/reduxEpic";
import {
  getAllChargeType,
  addChargeType,
  deleteOneChargeType,
} from "../../../api/chargetypes";

export const fetchListChargeTypeEpic = (action$: ActionsObservable<Action>) => {
  return action$.pipe(
    ofType(FETCH_CHARGETYPE_INIT),
    mergeMap((action) =>
      getAllChargeType(action.payload.query).pipe(
        map((response) =>
          onEpicSuccess(action, response, fetchChargeTypeSuccess(response))
        ),
        catchError((error) =>
          of(onEpicFailed(action, error, fetchChargeTypeFailed(error)))
        ),
        takeUntil(
          action$.pipe(ofType(FETCH_CHARGETYPE_CANCELLED, CANCEL_ALL_REQUEST))
        )
      )
    )
  );
};

export const addChargeTypeEpic = (
  action$: ActionsObservable<Action>,
  state$: any
) => {
  return action$.pipe(
    ofType(ADD_CHARGETYPE_INIT),
    mergeMap((action) =>
      addChargeType(action.payload).pipe(
        map((response) => {
          return onEpicSuccess(
            action,
            response,
            fetchChargeTypeInit({
              page: state$.value.chargetype.query.page,
              per_page: state$.value.chargetype.query.per_page,
            })
          );
        }),
        catchError((error) =>
          of(onEpicFailed(action, error, addChargeTypeFailed(error)))
        ),
        takeUntil(action$.pipe(ofType(CANCEL_ALL_REQUEST)))
      )
    )
  );
};

export const editChargeTypeEpic = (
  action$: ActionsObservable<Action>,
  state$: any
) => {
  return action$.pipe(
    ofType(EDIT_CHARGETYPE_INIT),
    mergeMap((action) =>
      addChargeType(action.payload).pipe(
        map((response) => {
          console.log("bbb", response);
          console.log(state$);
          return onEpicSuccess(
            action,
            response,
            fetchChargeTypeInit({
              page: state$.value.chargetype.query.page,
              per_page: state$.value.chargetype.query.per_page,
            })
          );
        }),
        catchError((error) =>
          of(onEpicFailed(action, error, editChargeTypeFailed(error)))
        ),
        takeUntil(action$.pipe(ofType(CANCEL_ALL_REQUEST)))
      )
    )
  );
};

export const deleteChargeTypeEpic = (
  action$: ActionsObservable<Action>,
  state$: any
) => {
  return action$.pipe(
    ofType(DELETE_CHARGETYPE_INIT),
    mergeMap((action) =>
      deleteOneChargeType(action.payload).pipe(
        map((response) => {
          return onEpicSuccess(
            action,
            response,
            fetchChargeTypeInit({
              page: state$.value.chargetype.query.page,
              per_page: state$.value.chargetype.query.per_page,
            })
          );
        }),
        catchError((error) =>
          of(onEpicFailed(action, error, editChargeTypeFailed(error)))
        ),
        takeUntil(action$.pipe(ofType(CANCEL_ALL_REQUEST)))
      )
    )
  );
};
