import { BookingNoteRequest } from "../../models/BookingNoteRequest";
import { Api } from "../api";
import { ListQuery } from "../../models/redux/ListQuery";
import { InforProductBookingNote } from "../../models/inforProductBookingNote";
export const createOrUpdateBookingNote = (bookingNote: BookingNoteRequest) =>
  Api.post("/bookingnote/createOrEdit", bookingNote);
export const getOneBookingNote = (id: number) =>
  Api.get("/bookingnote/createOrEdit", { id });

export const updateBookingNoteStatus = (id: number, status: number) =>
  Api.post("/bookingnote/updateStatus", { id, status });

export const getAllBookingNote = (query: ListQuery) => {
  return Api.get("/bookingnote/getAll", {
    page: query.page,
    size: query.per_page,
    desPortId: query.filter?.desPortId,
    eta: query.filter?.eta,
    etd: query.filter?.etd,
    pointPortId: query.filter?.pointPortId,
    createdByCurrentUser: query.filter?.createdByCurrentUser,
    showDraftBooking: query.filter?.showDraftBooking || false,
    shipperId: query.filter?.shipperId,
    status: query.filter?.status,
  });
};

export const getPdf = (id: number | undefined) => {
    return Api.fetchPdf("/bookingnote/getPdf", {
        id
    }, {responseType: 'arraybuffer'});
};

export const getAllBookingNoteOfMe = (query: ListQuery) =>
  Api.get("/bookingnote/getAllForCustomer", {
    page: query.page,
    size: query.per_page,
    desPortId: query.filter?.desPortId,
    eta: query.filter?.eta,
    etd: query.filter?.etd,
    pointPortId: query.filter?.pointPortId,
  });

export const updateProductBookingNote = (data: InforProductBookingNote) =>
  Api.post("/bookingnotecommodity/createOrEdit", data);

export const updateNote = (data: BookingNoteRequest) =>
  Api.post("/bookingnote/updateNote", data);

export const getAllBookingNoteCommodity = (query: ListQuery) =>
  Api.get("/bookingnotecommodity/getAll", {
    page: query.page,
    size: query.per_page,
    bookingNoteId: query.filter?.bookingNoteId,
  });

export const fetchBookingNoteNo = (id: number) =>
  Api.get("/bookingnote/getBookingNo", { id });

export const deleteBookingNote = (bookingnoteId: number) =>
  Api.get("/bookingnote/delete", { bookingnoteId });

export const getAllBookingNoteBySender = (query: ListQuery) => {
  return Api.get("/bookingnote/getAllBySender", {
    bookingNo: query.filter?.bookingNo,
    page: query.page,
    size: query.per_page,
    receiveId: query.filter?.receiveId,
    senderId: query.filter?.senderId,
  });
};
