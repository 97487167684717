import { ListState } from "../../../models/redux/listState";
import { Action } from "../../../models/redux/action";
// import { BILLList } from "../../../models/BILLList";
// import { UserByType } from "../../../models/getUserByType";
// import { BILLNotifyAddress } from '../../../models/BILLNotifyAddress';
import {
  FETCH_BILL_LIST,
  FETCH_BILL_LIST_SUCCESS,
  FETCH_BILL_LIST_FAILED,
  GET_ALL_USER_BY_TYPE,
  GET_ALL_USER_BY_TYPE_SUCCESS,
  GET_ALL_USER_BY_TYPE_FAILED,
  GET_BILL_NOTIFY_ADDRESS,
  GET_BILL_NOTIFY_ADDRESS_SUCCESS,
  GET_BILL_NOTIFY_ADDRESS_FAILED,
  GET_VESSEL_REQUEST,
  GET_VESSEL_REQUEST_SUCCESS,
  GET_VESSEL_REQUEST_FAILED,
  DELETE_ROW_NOTIFY_REQUEST,
  DELETE_ROW_NOTIFY_SUCCESS,
  DELETE_ROW_NOTIFY_FAILED,
  BILL_SHIPPING_TABLE,
  BILL_SHIPPING_TABLE_SUCCESS,
  BILL_SHIPPING_TABLE_FAILED,
  DELETE_ROW_SHIPPING,
  DELETE_ROW_SHIPPING_SUCCESS,
  DELETE_ROW_SHIPPING_FAILED,
  BILL_COST_TABLE,
  BILL_COST_TABLE_SUCCESS,
  BILL_COST_TABLE_FAILED,
  DELETE_ROW_COST,
  DELETE_ROW_COST_SUCCESS,
  DELETE_ROW_COST_FAILED,
  BILL_CONTAINER_TABLE,
  BILL_CONTAINER_TABLE_SUCCESS,
  BILL_CONTAINER_TABLE_FAILED,
  DELETE_ROW_CONTAINER,
  DELETE_ROW_CONTAINER_SUCCESS,
  DELETE_ROW_CONTAINER_FAILED,
  BILL_CHARGES_TABLE,
  BILL_CHARGES_TABLE_SUCCESS,
  BILL_CHARGES_TABLE_FAILED,
  DELETE_ROW_CHARGES,
  DELETE_ROW_CHARGES_SUCCESS,
  DELETE_ROW_CHARGES_FAILED,
} from "./actions";
import {
  fetchListState,
  fetchListStateSuccess,
  fetchListStateFailed,
  baseFetchListState,
  deleteInit,
  deleteSuccess,
  deleteFailed,
} from "../../../helpers/reduxAction";
const initBillState: ListState<any> = baseFetchListState();
const initAllUserState: ListState<any> = baseFetchListState();
const initBillNotifyState: ListState<any> = baseFetchListState();
const initVesselState: ListState<any> = baseFetchListState();
const initShippingState: ListState<any> = baseFetchListState();
const initContainerState: ListState<any> = baseFetchListState();
const initChargesState: ListState<any> = baseFetchListState();
const initCostState: ListState<any> = baseFetchListState();

export const BillListReducer = (
  state: ListState<any> = initBillState,
  action: Action
) => {
  switch (action.type) {
    case FETCH_BILL_LIST:
      return fetchListState(action);
    case FETCH_BILL_LIST_SUCCESS:
      return fetchListStateSuccess(action, state);
    case FETCH_BILL_LIST_FAILED:
      return fetchListStateFailed(action, state);
    default:
      return state;
  }
};

export const userByTypeListReducerr = (
  state: ListState<any> = initAllUserState,
  action: Action
) => {
  switch (action.type) {
    case GET_ALL_USER_BY_TYPE:
      return fetchListState(action);
    case GET_ALL_USER_BY_TYPE_SUCCESS:
      return fetchListStateSuccess(action, state);
    case GET_ALL_USER_BY_TYPE_FAILED:
      return fetchListStateFailed(action, state);
    default:
      return state;
  }
};

export const BillNotifyAddressReducer = (
  state: ListState<any> = initBillNotifyState,
  action: Action
) => {
  switch (action.type) {
    case GET_BILL_NOTIFY_ADDRESS:
      return fetchListState(action);
    case GET_BILL_NOTIFY_ADDRESS_SUCCESS:
      return fetchListStateSuccess(action, state);
    case GET_BILL_NOTIFY_ADDRESS_FAILED:
      return fetchListStateFailed(action, state);
    case DELETE_ROW_NOTIFY_REQUEST:
      return deleteInit(action, state);
    case DELETE_ROW_NOTIFY_SUCCESS:
      return deleteSuccess(action, state);
    case DELETE_ROW_NOTIFY_FAILED:
      return deleteFailed(action, state);
    default:
      return state;
  }
};

export const getVesselReducerr = (
  state: ListState<any> = initVesselState,
  action: Action
) => {
  switch (action.type) {
    case GET_VESSEL_REQUEST:
      return fetchListState(action);
    case GET_VESSEL_REQUEST_SUCCESS:
      return fetchListStateSuccess(action, state);
    case GET_VESSEL_REQUEST_FAILED:
      return fetchListStateFailed(action, state);
    default:
      return state;
  }
};

export const BillShippingReducer = (
  state: ListState<any> = initShippingState,
  action: Action
) => {
  switch (action.type) {
    case BILL_SHIPPING_TABLE:
      return fetchListState(action);
    case BILL_SHIPPING_TABLE_SUCCESS:
      return fetchListStateSuccess(action, state);
    case BILL_SHIPPING_TABLE_FAILED:
      return fetchListStateFailed(action, state);
    case DELETE_ROW_SHIPPING:
      return deleteInit(action, state);
    case DELETE_ROW_SHIPPING_SUCCESS:
      return deleteSuccess(action, state);
    case DELETE_ROW_SHIPPING_FAILED:
      return deleteFailed(action, state);
    default:
      return state;
  }
};

export const BillContainerReducer = (
  state: ListState<any> = initContainerState,
  action: Action
) => {
  switch (action.type) {
    case BILL_CONTAINER_TABLE:
      return fetchListState(action);
    case BILL_CONTAINER_TABLE_SUCCESS:
      return fetchListStateSuccess(action, state);
    case BILL_CONTAINER_TABLE_FAILED:
      return fetchListStateFailed(action, state);
    case DELETE_ROW_CONTAINER:
      return deleteInit(action, state);
    case DELETE_ROW_CONTAINER_SUCCESS:
      return deleteSuccess(action, state);
    case DELETE_ROW_CONTAINER_FAILED:
      return deleteFailed(action, state);
    default:
      return state;
  }
};

export const BillChargesReducer = (
  state: ListState<any> = initChargesState,
  action: Action
) => {
  switch (action.type) {
    case BILL_CHARGES_TABLE:
      return fetchListState(action);
    case BILL_CHARGES_TABLE_SUCCESS:
      return fetchListStateSuccess(action, state);
    case BILL_CHARGES_TABLE_FAILED:
      return fetchListStateFailed(action, state);
    case DELETE_ROW_CHARGES:
      return deleteInit(action, state);
    case DELETE_ROW_CHARGES_SUCCESS:
      return deleteSuccess(action, state);
    case DELETE_ROW_CHARGES_FAILED:
      return deleteFailed(action, state);
    default:
      return state;
  }
};

export const BillCostReducer = (
  state: ListState<any> = initCostState,
  action: Action
) => {
  switch (action.type) {
    case BILL_COST_TABLE:
      return fetchListState(action);
    case BILL_COST_TABLE_SUCCESS:
      return fetchListStateSuccess(action, state);
    case BILL_COST_TABLE_FAILED:
      return fetchListStateFailed(action, state);
    case DELETE_ROW_COST:
      return deleteInit(action, state);
    case DELETE_ROW_COST_SUCCESS:
      return deleteSuccess(action, state);
    case DELETE_ROW_COST_FAILED:
      return deleteFailed(action, state);
    default:
      return state;
  }
};
