/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { connect } from "react-redux";
import { SystemMenuList } from "./SystemMenuList";
import { UserMenuList } from "./UserMenuList";

interface Props {
	userType: string;
}

const mapStateToProps = (state: any) => ({
	userType: state.auth.user_type,
});

export const AsideMenuList = connect(mapStateToProps)(({ userType }: Props) => {
	return <>{userType === "system" ? <SystemMenuList /> : <UserMenuList />}</>;
});
