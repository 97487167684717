import React from "react";
import { Topbar } from "./Topbar";
import AnimateLoading from "../../components/AnimateLoading";

export function Header() {
	return (
		<>
			{/*begin::Header*/}
			<div className={`header header-fixed`} id="kt_header">
				{/*begin::Container*/}
				<div
					className={`container-fluid d-flex align-items-stretch justify-content-end`}
				>
					<AnimateLoading />

					{/*begin::Topbar*/}
					<Topbar />
					{/*end::Topbar*/}
				</div>
				{/*end::Container*/}
			</div>
			{/*end::Header*/}
		</>
	);
}
