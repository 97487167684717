import { Api } from "../api";
import { ListQuery } from "../../models/redux/ListQuery";
import { ShipSchedule } from "../../models/ShipSchedule";
import {SendMailRequest} from "../../models/SendMailRequest";

export const getAllShipSchedule = (query: ListQuery) =>
	Api.get("/trainSchedule/getAll", {
		page: query.page,
		size: query.per_page,
		desPortId: query.filter?.desPortId,
		eta: query.filter?.eta,
		etd: query.filter?.etd,
		pointPortId: query.filter?.pointPortId,
		vesselId: query.filter?.vesselId,
	});

export const getAllShipScheduleSystem = (query: ListQuery) =>
	Api.get("/trainSchedule/getAllForSystem", {
		page: query.page,
		size: query.per_page,
		desPortId: query.filter?.desPortId,
		eta: query.filter?.eta,
		etd: query.filter?.etd,
		pointPortId: query.filter?.pointPortId,
		vesselId: query.filter?.vesselId,
	});

export const getShipSchedulePDF = (query: ListQuery) => {
	return Api.fetchPdf("/trainSchedule/getPdf", {
		page: query.page,
		size: query.per_page,
		desPortId: query.filter?.desPortId,
		eta: query.filter?.eta,
		etd: query.filter?.etd,
		pointPortId: query.filter?.pointPortId,
		vesselId: query.filter?.vesselId,
	}, {responseType: 'arraybuffer'});
}

export const sendMailToCustomer = (query: SendMailRequest) => {
	return Api.post("/trainSchedule/sendMail", {
		desPortId: query.desPortId,
		eta: query.eta,
		etd: query.etd,
		pointPortId: query.pointPortId,
		vesselId: query.vesselId,
		merchantId: query.customerId
	});
}

export const getAllShipScheduleCustomer = (query: ListQuery) =>
	Api.get("/trainSchedule/getAllForCustomer", {
		page: query.page,
		size: query.per_page,
		desPortId: query.filter?.desPortId,
		eta: query.filter?.eta,
		etd: query.filter?.etd,
		pointPortId: query.filter?.pointPortId,
	});

export const createOrEditShipSchedule = (ship_schedule: ShipSchedule) =>
	Api.post("/trainSchedule/createOrEdit", ship_schedule);

export const getOneShipSchedule = (id: number) =>
	Api.get("/trainSchedule/createOrEdit", { id });

export const getOneShipScheduleDetail = (id: number) =>
	Api.get("/trainSchedule/getDetailById", { id });

export const deleteShipSchedule = (trainScheduleId: number) =>
	Api.get("/trainSchedule/delete", { trainScheduleId });
