/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useMemo, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Nav,
  Tab,
  Dropdown,
  OverlayTrigger,
  Tooltip,
  Button,
} from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import SVG from "react-inlinesvg";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import {
  NotificationsContext,
  NotificationsContextType,
} from "../../../../../app/contexts/NotificationsContext";
// import {messaging} from "../../../../../firebaseInit";
import { notify } from "../../../../../app/helpers/notify";
import {
  getAllUnitByUser,
  getUserNotifications,
} from "../../../../../app/api/unit";
import { map } from "rxjs/operators";
import { getRoutePathWithParam } from "../../../../../app/helpers";
import { RoutePaths } from "../../../../../app/constants";
import { FaIcon } from "../../../../../app/components/FaIcon";
import { Link } from "react-router-dom";
import { markNotificationAsRead } from "../../../../../app/api/user";
import moment from "moment";
import {
  disableLoading,
  enableLoading,
} from "../../../../../app/redux/modules/loading";

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

const useNotifications = () => {
  const context = useContext<NotificationsContextType | undefined>(
    NotificationsContext
  );
  if (context === undefined) {
    throw new Error("usePagTitle must be used in BookingProvider");
  }
  return context;
};

export function UserNotificationsDropdown() {
  const [key, setKey] = useState("Alerts");
  const bgImage = toAbsoluteUrl("/media/misc/bg-1.jpg");
  const { notifications, setNotifications } = useNotifications();
  const history = useHistory();
  useEffect(() => {}, [notifications]);

  const goToBookingNote = async (notificationId: number, bookingId: any) => {
    enableLoading();
    await markNotificationAsRead(notificationId).toPromise();
    getUserNotifications(0, 10)
      .pipe(map(({ response }) => response))
      .subscribe((response) => {
        response.content = response.content.map((value: any, index: number) => {
          let data = JSON.parse(value.data);
          value.creationTime = moment(new Date(value.creationTime)).format(
            "DD/MM/YYYY HH:mm"
          );

          switch (data.bookingNoteStatus) {
            case 1:
              data.status = "Đã xác nhận";
              break;
            case 2:
              data.status = "Đã từ chối";
          }
          return {
            ...value,
            data: data,
          };
        });
        setNotifications(response);
      });
    history.push(
      getRoutePathWithParam(RoutePaths.Action.BookingNoteDetail, {
        bookingNoteId: bookingId,
      })
    );
    disableLoading();
  };

  return (
    <>
      <Dropdown drop="down" alignRight>
        <Dropdown.Toggle
          as={DropdownTopbarItemToggler}
          id="kt_quick_notifications_toggle"
        >
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id="user-notification-tooltip">
                User Notifications
              </Tooltip>
            }
          >
            <div
              className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary"
              id="kt_quick_notifications_toggle"
            >
              <span className="svg-icon svg-icon-xl svg-icon-primary">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")}
                />
                {notifications != null && notifications > 0 && (
                  <span className="unseen-count">
                    {notifications.unseenCount}
                  </span>
                )}
              </span>
            </div>
          </OverlayTrigger>
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
          <form>
            {/** Head */}
            <div
              className="d-flex flex-column pt-12 bgi-size-cover bgi-no-repeat rounded-top"
              style={{ backgroundImage: `url(${bgImage})` }}
            >
              <PerfectScrollbar
                options={perfectScrollbarOptions}
                className="navi navi-hover scroll my-4"
                style={{ maxHeight: "300px", position: "relative" }}
              >
                {notifications != null && notifications.content.length > 0 ? (
                  <div>
                    {notifications.content.map((value: any, index: number) => {
                      return (
                        <a
                          onClick={() =>
                            goToBookingNote(value.id, value.data.bookingId)
                          }
                          className="navi-item"
                          style={{ cursor: "pointer" }}
                        >
                          <div className="navi-link">
                            <div className="navi-icon mr-2">
                              <i className="flaticon2-line-chart text-success"></i>
                            </div>
                            <div className="navi-text">
                              <div className="font-weight-bold">
                                {"Booking note " +
                                  value.data.bookingNoteNo +
                                  ": cập nhật trạng thái: " +
                                  value.data.status}
                              </div>
                              <div className="text-muted">
                                {value.creationTime}
                              </div>
                            </div>
                            <div
                              className="navi-icon mr-2"
                              style={{ textAlign: "right" }}
                            >
                              {value.read ? (
                                <span className="notification-read"></span>
                              ) : (
                                <span className="notification-unread"></span>
                              )}
                            </div>
                          </div>
                        </a>
                      );
                    })}
                  </div>
                ) : (
                  <div className="notification-empty">
                    <span> Không có thông báo </span>
                  </div>
                )}
              </PerfectScrollbar>
              {notifications != null && notifications.content.length > 0 && (
                <Link
                  to={getRoutePathWithParam(RoutePaths.Notifications)}
                  className="navi-item"
                >
                  <div className="view-all-notification">
                    <span>Xem tất cả thông báo</span>
                  </div>
                </Link>
              )}
            </div>
          </form>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}
