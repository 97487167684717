import { Action } from "../../../models/redux/action";
import { CHANGE_PASSWORD, CHANGE_PASSWORD_FAILED, CHANGE_PASSWORD_SUCCESS } from "./actions";

const defaultState = {
	loading: false
}

export const accountReducer = (state: any = defaultState, action: Action) => {
	switch (action.type) {
		case CHANGE_PASSWORD:
			return {
				loading: true
			};
		case CHANGE_PASSWORD_FAILED:
		case CHANGE_PASSWORD_SUCCESS:
			return {
				loading: false
			}

		default:
			return state
	}
}