import { Action } from "../../../models/redux/action";
import { mergeMap, map, catchError, takeUntil } from "rxjs/operators";
import {
  FETCH_BILL_LIST,
  fetchBillListSuccess,
  fetchBillListFailed,
  FETCH_BILL_LIST_CANCELLED,
  GET_ALL_USER_BY_TYPE,
  getAllUserByTypeSuccess,
  getAllUserByTypeFailed,
  GET_ALL_USER_BY_TYPE_CANCELLED,
  // GENERAL INFORMATION
  GET_BILL_NOTIFY_ADDRESS,
  getBillNotifyAddressSuccess,
  getBillNotifyAddressFailed,
  GET_BILL_NOTIFY_ADDRESS_CANCELLED,
  GET_VESSEL_REQUEST,
  getVesselRequestSuccess,
  getVesselRequestFailed,
  GET_VESSEL_REQUEST_CANCELLED,
  DELETE_ROW_NOTIFY_REQUEST,
  deleteRowNotifySuccess,
  deleteRowNotifyFailed,
  DELETE_ROW_NOTIFY_CANCELLED,
  // SHIPPING
  BILL_SHIPPING_TABLE,
  getBillShippingSuccess,
  getBillShippingFailed,
  BILL_SHIPPING_TABLE_CANCELLED,
  DELETE_ROW_SHIPPING,
  deleteRowShippingSuccess,
  deleteRowShippingFailed,
  DELETE_ROW_SHIPPING_CANCELLED,
  // COST
  BILL_COST_TABLE,
  getBillCostSuccess,
  getBillCostFailed,
  BILL_COST_TABLE_CANCELLED,
  DELETE_ROW_COST,
  deleteRowCostSuccess,
  deleteRowCostFailed,
  DELETE_ROW_COST_CANCELLED,
  // CONTAINER
  BILL_CONTAINER_TABLE,
  getBillContainerSuccess,
  getBillContainerFailed,
  BILL_CONTAINER_TABLE_CANCELLED,
  DELETE_ROW_CONTAINER,
  deleteRowContainerSuccess,
  deleteRowContainerFailed,
  DELETE_ROW_CONTAINER_CANCELLED,
  // CHARGES
  BILL_CHARGES_TABLE,
  getBillChargesSuccess,
  getBillChargesFailed,
  BILL_CHARGES_TABLE_CANCELLED,
  DELETE_ROW_CHARGES,
  deleteRowChargesSuccess,
  deleteRowChargesFailed,
  DELETE_ROW_CHARGES_CANCELLED,
} from "./actions";
import { ActionsObservable, ofType } from "redux-observable";
import { of } from "rxjs";
import { CANCEL_ALL_REQUEST } from "../../default";
import { onEpicSuccess, onEpicFailed } from "../../../helpers/reduxEpic";
import {
  getAllBillList,
  getAllUserByType,
  getBillNotifyAddress,
  getAllVessel,
  deleteRowNotifyThirdAddress,
  getBillShippingTable,
  deleteRowShippingTable,
  getBillContainerTable,
  deleteRowContainerTable,
  getBillChargesTable,
  deleteRowChargesTable,
  getBillCostTable,
  deleteRowCostTable,
} from "../../../api/billBooking";

export const fetchListBillEpic = (action$: ActionsObservable<Action>) => {
  return action$.pipe(
    ofType(FETCH_BILL_LIST),
    mergeMap((action) =>
      getAllBillList(action.payload.query).pipe(
        map((response) =>
          onEpicSuccess(action, response, fetchBillListSuccess(response))
        ),
        catchError((error) =>
          of(onEpicFailed(action, error, fetchBillListFailed(error)))
        ),
        takeUntil(
          action$.pipe(ofType(FETCH_BILL_LIST_CANCELLED, CANCEL_ALL_REQUEST))
        )
      )
    )
  );
};

export const getAllListUserByTypeEpicc = (
  action$: ActionsObservable<Action>
) => {
  return action$.pipe(
    ofType(GET_ALL_USER_BY_TYPE),
    mergeMap((action) =>
      getAllUserByType(action.payload.query).pipe(
        map((response) =>
          onEpicSuccess(action, response, getAllUserByTypeSuccess(response))
        ),
        catchError((error) =>
          of(onEpicFailed(action, error, getAllUserByTypeFailed(error)))
        ),
        takeUntil(
          action$.pipe(
            ofType(GET_ALL_USER_BY_TYPE_CANCELLED, CANCEL_ALL_REQUEST)
          )
        )
      )
    )
  );
};

export const getBillNotifyAddressEpic = (
  action$: ActionsObservable<Action>
) => {
  return action$.pipe(
    ofType(GET_BILL_NOTIFY_ADDRESS),
    mergeMap((action) =>
      getBillNotifyAddress(action.payload.query).pipe(
        map((response) =>
          onEpicSuccess(action, response, getBillNotifyAddressSuccess(response))
        ),
        catchError((error) =>
          of(onEpicFailed(action, error, getBillNotifyAddressFailed(error)))
        ),
        takeUntil(
          action$.pipe(
            ofType(GET_BILL_NOTIFY_ADDRESS_CANCELLED, CANCEL_ALL_REQUEST)
          )
        )
      )
    )
  );
};

export const getVesselEpicc = (action$: ActionsObservable<Action>) => {
  return action$.pipe(
    ofType(GET_VESSEL_REQUEST),
    mergeMap((action) =>
      getAllVessel(action.payload.query).pipe(
        map((response) =>
          onEpicSuccess(action, response, getVesselRequestSuccess(response))
        ),
        catchError((error) =>
          of(onEpicFailed(action, error, getVesselRequestFailed(error)))
        ),
        takeUntil(
          action$.pipe(ofType(GET_VESSEL_REQUEST_CANCELLED, CANCEL_ALL_REQUEST))
        )
      )
    )
  );
};

export const deleteNotifyAddressEpicc = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    ofType(DELETE_ROW_NOTIFY_REQUEST),
    mergeMap((action) =>
      deleteRowNotifyThirdAddress(action.payload.addressId).pipe(
        map((response) =>
          onEpicSuccess(action, response, deleteRowNotifySuccess())
        ),
        catchError((error) =>
          of(onEpicFailed(action, error, deleteRowNotifyFailed(error)))
        ),
        takeUntil(
          action$.pipe(ofType(DELETE_ROW_NOTIFY_CANCELLED, CANCEL_ALL_REQUEST))
        )
      )
    )
  );

export const getBillShippingEpic = (action$: ActionsObservable<Action>) => {
  return action$.pipe(
    ofType(BILL_SHIPPING_TABLE),
    mergeMap((action) =>
      getBillShippingTable(action.payload.query).pipe(
        map((response) =>
          onEpicSuccess(action, response, getBillShippingSuccess(response))
        ),
        catchError((error) =>
          of(onEpicFailed(action, error, getBillShippingFailed(error)))
        ),
        takeUntil(
          action$.pipe(
            ofType(BILL_SHIPPING_TABLE_CANCELLED, CANCEL_ALL_REQUEST)
          )
        )
      )
    )
  );
};

export const deleteRowShippingEpicc = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    ofType(DELETE_ROW_SHIPPING),
    mergeMap((action) =>
      deleteRowShippingTable(action.payload.addressId).pipe(
        map((response) =>
          onEpicSuccess(action, response, deleteRowShippingSuccess())
        ),
        catchError((error) =>
          of(onEpicFailed(action, error, deleteRowShippingFailed(error)))
        ),
        takeUntil(
          action$.pipe(
            ofType(DELETE_ROW_SHIPPING_CANCELLED, CANCEL_ALL_REQUEST)
          )
        )
      )
    )
  );

// CONTAINER
export const getBillContainerEpic = (action$: ActionsObservable<Action>) => {
  return action$.pipe(
    ofType(BILL_CONTAINER_TABLE),
    mergeMap((action) =>
      getBillContainerTable(action.payload.query).pipe(
        map((response) =>
          onEpicSuccess(action, response, getBillContainerSuccess(response))
        ),
        catchError((error) =>
          of(onEpicFailed(action, error, getBillContainerFailed(error)))
        ),
        takeUntil(
          action$.pipe(
            ofType(BILL_CONTAINER_TABLE_CANCELLED, CANCEL_ALL_REQUEST)
          )
        )
      )
    )
  );
};

export const deleteRowContainerEpicc = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    ofType(DELETE_ROW_CONTAINER),
    mergeMap((action) =>
      deleteRowContainerTable(action.payload.Id).pipe(
        map((response) =>
          onEpicSuccess(action, response, deleteRowContainerSuccess())
        ),
        catchError((error) =>
          of(onEpicFailed(action, error, deleteRowContainerFailed(error)))
        ),
        takeUntil(
          action$.pipe(
            ofType(DELETE_ROW_CONTAINER_CANCELLED, CANCEL_ALL_REQUEST)
          )
        )
      )
    )
  );

// CHARGES
export const getBillChargesEpic = (action$: ActionsObservable<Action>) => {
  return action$.pipe(
    ofType(BILL_CHARGES_TABLE),
    mergeMap((action) =>
      getBillChargesTable(action.payload.query).pipe(
        map((response) =>
          onEpicSuccess(action, response, getBillChargesSuccess(response))
        ),
        catchError((error) =>
          of(onEpicFailed(action, error, getBillChargesFailed(error)))
        ),
        takeUntil(
          action$.pipe(ofType(BILL_CHARGES_TABLE_CANCELLED, CANCEL_ALL_REQUEST))
        )
      )
    )
  );
};

export const deleteRowChargesEpicc = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    ofType(DELETE_ROW_CHARGES),
    mergeMap((action) =>
      deleteRowChargesTable(action.payload.chargeId).pipe(
        map((response) =>
          onEpicSuccess(action, response, deleteRowChargesSuccess())
        ),
        catchError((error) =>
          of(onEpicFailed(action, error, deleteRowChargesFailed(error)))
        ),
        takeUntil(
          action$.pipe(ofType(DELETE_ROW_CHARGES_CANCELLED, CANCEL_ALL_REQUEST))
        )
      )
    )
  );

// COST
export const getBillCostEpic = (action$: ActionsObservable<Action>) => {
  return action$.pipe(
    ofType(BILL_COST_TABLE),
    mergeMap((action) =>
      getBillCostTable(action.payload.query).pipe(
        map((response) =>
          onEpicSuccess(action, response, getBillCostSuccess(response))
        ),
        catchError((error) =>
          of(onEpicFailed(action, error, getBillCostFailed(error)))
        ),
        takeUntil(
          action$.pipe(ofType(BILL_COST_TABLE_CANCELLED, CANCEL_ALL_REQUEST))
        )
      )
    )
  );
};

export const deleteRowCostEpicc = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    ofType(DELETE_ROW_COST),
    mergeMap((action) =>
      deleteRowCostTable(action.payload.addressId).pipe(
        map((response) =>
          onEpicSuccess(action, response, deleteRowCostSuccess())
        ),
        catchError((error) =>
          of(onEpicFailed(action, error, deleteRowCostFailed(error)))
        ),
        takeUntil(
          action$.pipe(ofType(DELETE_ROW_COST_CANCELLED, CANCEL_ALL_REQUEST))
        )
      )
    )
  );
