import { ListQuery } from "../../../models/redux/ListQuery";
import { ReduxCallbacks } from "../../../models/redux/Callbacks";
import { Vessel } from "../../../models/Vessel";

export const FETCH_VESSEL_INIT = "FETCH_VESSEL_INIT";
export const FETCH_VESSEL_SUCCESS = "FETCH_VESSEL_SUCCESS";
export const FETCH_VESSEL_FAILED = "FETCH_VESSEL_FAILED";
export const FETCH_VESSEL_CANCELLED = "FETCH_VESSEL_CANCELLED";

export const ADD_VESSEL_INIT = "ADD_VESSEL_INIT";
export const ADD_VESSEL_SUCCESS = "ADD_VESSEL_SUCCESS";
export const ADD_VESSEL_FAILED = "ADD_VESSEL_FAILED";

export const EDIT_VESSEL_INIT = "EDIT_VESSEL_INIT";
export const EDIT_VESSEL_SUCCESS = "EDIT_VESSEL_SUCCESS";
export const EDIT_VESSEL_FAILED = "EDIT_VESSEL_FAILED";

export const DELETE_VESSEL_INIT = "DELETE_VESSEL_INIT";
export const DELETE_VESSEL_SUCCESS = "DELETE_VESSEL_SUCCESS";
export const DELETE_VESSEL_FAILED = "DELETE_VESSEL_FAILED";

export const fetchVesselInit = (
	query: ListQuery,
	callbacks?: ReduxCallbacks
) => ({
	type: FETCH_VESSEL_INIT,
	payload: { query, callbacks },
});

// FETCH

export const fetchVesselSuccess = (response: any) => ({
	type: FETCH_VESSEL_SUCCESS,
	payload: response,
});

export const fetchVesselFailed = (error: any) => ({
	type: FETCH_VESSEL_FAILED,
	payload: error,
});

export const fetchVesselCancelled = () => ({
	type: FETCH_VESSEL_CANCELLED,
});

// ADD

export const addVesselInit = (vessel: Vessel, callbacks?: ReduxCallbacks) => ({
	type: ADD_VESSEL_INIT,
	payload: { vessel, callbacks },
});

export const addVesselSuccess = () => ({
	type: ADD_VESSEL_SUCCESS,
});

export const addVesselFailed = (error: any) => ({
	type: ADD_VESSEL_FAILED,
	payload: error,
});

// EDIT

export const editVesselInit = (vessel: Vessel, callbacks?: ReduxCallbacks) => ({
	type: EDIT_VESSEL_INIT,
	payload: { vessel, callbacks },
});

export const editVesselSuccess = () => ({
	type: EDIT_VESSEL_SUCCESS,
});

export const editVesselFailed = (error: any) => ({
	type: EDIT_VESSEL_FAILED,
	payload: error,
});

// DELETE

export const deleteVesselInit = (
	content: Vessel,
	callbacks?: ReduxCallbacks
) => {
	return {
		type: DELETE_VESSEL_INIT,
		payload: { ...content, callbacks },
	};
};

export const deleteVesselSuccess = () => ({
	type: DELETE_VESSEL_SUCCESS,
});

export const deleteVesselFailed = (error: any) => ({
	type: DELETE_VESSEL_FAILED,
	payload: error,
});
