export const Notifications: { [key: string]: any } = {
	Role: {
		Errors: {
			Delete: "Không thể xóa vai trò này!",
			IsInUse: "Vai trò này đang được sử dụng!",
		},
	},
	Unit: {
		Errors: {
			Delete: "Không thể xóa đơn vị này!",
			IsInUse: "Đơn vị này đang được sử dụng!",
		},
	},
	Department: {
		Errors: {
			Delete: "Không thể xóa văn phòng này!",
			IsInUse: "Văn phòng này này đang được sử dụng!",
		},
	},
};
