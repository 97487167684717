import { Action } from "../../../models/redux/action";
import { mergeMap, map, catchError, takeUntil } from "rxjs/operators";
import {
	FETCH_SHIP_SCHEDULE_INIT,
	fetchShipScheduleSuccess,
	fetchShipScheduleFailed,
	FETCH_SHIP_SCHEDULE_CANCELLED,
	ADD_SHIP_SCHEDULE_INIT,
	EDIT_SHIP_SCHEDULE_INIT,
	DELETE_SHIP_SCHEDULE_INIT,
	addShipScheduleSuccess,
	ADD_SHIP_SCHEDULE_CANCELLED,
	addShipScheduleFailed,
	deleteShipScheduleSuccess,
	deleteShipScheduleFailed,
	DELETE_SHIP_SCHEDULE_CANCELLED,
	FETCH_SHIP_SCHEDULE_SYSTEM_INIT,
	FETCH_SHIP_SCHEDULE_SYSTEM_CANCELLED,
	FETCH_SHIP_SCHEDULE_CUSTOMER_INIT,
	FETCH_SHIP_SCHEDULE_CUSTOMER_CANCELLED,
	fetchShipScheduleSystemSuccess,
	fetchShipScheduleSystemFailed,
	fetchShipScheduleCustomerSuccess,
	fetchShipScheduleCustomerFailed,
} from "./actions";
import { ActionsObservable, ofType } from "redux-observable";
import { of } from "rxjs";
import { CANCEL_ALL_REQUEST } from "../../default";
import { onEpicSuccess, onEpicFailed } from "../../../helpers/reduxEpic";
import {
	getAllShipSchedule,
	createOrEditShipSchedule,
	deleteShipSchedule,
	getAllShipScheduleCustomer,
	getAllShipScheduleSystem,
} from "../../../api/ship";

export const fetchListShipScheduleEpic = (
	action$: ActionsObservable<Action>
) => {
	return action$.pipe(
		ofType(FETCH_SHIP_SCHEDULE_INIT),
		mergeMap((action) =>
			getAllShipSchedule(action.payload.query).pipe(
				map((response) =>
					onEpicSuccess(action, response, fetchShipScheduleSuccess(response))
				),
				catchError((error) =>
					of(onEpicFailed(action, error, fetchShipScheduleFailed(error)))
				),
				takeUntil(
					action$.pipe(
						ofType(FETCH_SHIP_SCHEDULE_CANCELLED, CANCEL_ALL_REQUEST)
					)
				)
			)
		)
	);
};

export const fetchListShipScheduleSystemEpic = (
	action$: ActionsObservable<Action>
) => {
	return action$.pipe(
		ofType(FETCH_SHIP_SCHEDULE_SYSTEM_INIT),
		mergeMap((action) =>
			getAllShipScheduleSystem(action.payload.query).pipe(
				map((response) =>
					onEpicSuccess(
						action,
						response,
						fetchShipScheduleSystemSuccess(response)
					)
				),
				catchError((error) =>
					of(onEpicFailed(action, error, fetchShipScheduleSystemFailed(error)))
				),
				takeUntil(
					action$.pipe(
						ofType(FETCH_SHIP_SCHEDULE_SYSTEM_CANCELLED, CANCEL_ALL_REQUEST)
					)
				)
			)
		)
	);
};

export const fetchListShipScheduleCustomerEpic = (
	action$: ActionsObservable<Action>
) => {
	return action$.pipe(
		ofType(FETCH_SHIP_SCHEDULE_CUSTOMER_INIT),
		mergeMap((action) =>
			getAllShipScheduleCustomer(action.payload.query).pipe(
				map((response) =>
					onEpicSuccess(
						action,
						response,
						fetchShipScheduleCustomerSuccess(response)
					)
				),
				catchError((error) =>
					of(
						onEpicFailed(action, error, fetchShipScheduleCustomerFailed(error))
					)
				),
				takeUntil(
					action$.pipe(
						ofType(FETCH_SHIP_SCHEDULE_CUSTOMER_CANCELLED, CANCEL_ALL_REQUEST)
					)
				)
			)
		)
	);
};

export const addShipScheduleEpic = (
	action$: ActionsObservable<Action>,
	state$: any
) => {
	return action$.pipe(
		ofType(ADD_SHIP_SCHEDULE_INIT),
		mergeMap((action) =>
			createOrEditShipSchedule(action.payload.ship_schedule).pipe(
				map((response) =>
					onEpicSuccess(action, response, addShipScheduleSuccess())
				),
				catchError((error) =>
					of(onEpicFailed(action, error, addShipScheduleFailed(error)))
				),
				takeUntil(
					action$.pipe(ofType(ADD_SHIP_SCHEDULE_CANCELLED, CANCEL_ALL_REQUEST))
				)
			)
		)
	);
};

export const editShipScheduleEpic = (
	action$: ActionsObservable<Action>,
	state$: any
) => {
	return action$.pipe(ofType(EDIT_SHIP_SCHEDULE_INIT));
};

export const deleteShipScheduleEpic = (
	action$: ActionsObservable<Action>,
	state$: any
) => {
	return action$.pipe(
		ofType(DELETE_SHIP_SCHEDULE_INIT),
		mergeMap((action) =>
			deleteShipSchedule(action.payload.id).pipe(
				map((response) =>
					onEpicSuccess(action, response, deleteShipScheduleSuccess())
				),
				catchError((error) =>
					of(onEpicFailed(action, error, deleteShipScheduleFailed(error)))
				),
				takeUntil(
					action$.pipe(
						ofType(DELETE_SHIP_SCHEDULE_CANCELLED, CANCEL_ALL_REQUEST)
					)
				)
			)
		)
	);
};
