import { ListState } from "../../../models/redux/listState";
import { Role } from "../../../models/Role";
import { Action } from "../../../models/redux/action";
import { FETCH_ROLE_SUCCESS, FETCH_ROLE_FAILED } from "./actions";
import {
	fetchListStateSuccess,
	fetchListStateFailed,
	baseFetchListState,
} from "../../../helpers/reduxAction";

const initState: ListState<Role> = baseFetchListState();

export const roleReducer = (
	state: ListState<Role> = initState,
	action: Action
) => {
	switch (action.type) {
		case FETCH_ROLE_SUCCESS:
			return fetchListStateSuccess(action, state);
		case FETCH_ROLE_FAILED:
			return fetchListStateFailed(action, state);
		default:
			return state;
	}
};
