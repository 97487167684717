import { Api } from "../api";
import { CurrencyConversions } from "../../models/CurrencyConversions";
import { ListQuery } from "../../models/redux/ListQuery";

export const getAllCurrencyConversions = (query: ListQuery) => {
  return Api.get("/currencyconversion/getAll", {
    name: query?.filter?.name || "",
    page: query.page,
    size: query.per_page,
    status: query.filter?.status || "",
  });
};

export const addCurrencyConversions = (data: CurrencyConversions) => {
  return Api.post("/currencyconversion/createOrEdit", { ...data });
};

export const getOneCurrencyConversion = (id: number) => {
  return Api.get("/currencyconversion/createOrEdit", { id });
};

export const deleteCurrencyConversions = (data: any) => {
  return Api.get("/currencyconversion/delete", { ...data });
};
