import { Api } from "../api";
import { ListQuery } from "../../models/redux/ListQuery";
import { CreateOrEditBooking } from "../../models/createOrEditBooking";
import { CreateOrEditShipping } from "../../models/createOrEditShipping";
import { CreateOrEditNotifyAddress } from "../../models/createOrEditNotifyAddress";
import { CreateOrEditContainerModel } from "../../models/createOrEditContainer";
import { CreateOrEditCostModel } from "../../models/createOrEditCost";
import _ from "lodash";

// General information
export const getAllBillList = (query: ListQuery) => {
  if (_.isEmpty(query.filter)) {
    return Api.get(
      `/bill/getAll?page=${query.page}&size=${query.per_page}&status=1`,
      {},
      {},
      true
    );
  }
  return Api.get("/bill/getAll", {
    page: query.page,
    size: query.per_page,
    billDateFrom: query.filter?.billDateFrom,
    billDateTo: query.filter?.billDateTo,
    billNo: query.filter?.billNo,
    receiveId: query.filter?.receiveId,
    senderId: query.filter?.senderId,
    status: query.filter?.status,
  });
};

export const getAllUserByType = (query: ListQuery) => {
  return Api.get("/user/getAllUserByType", {
    page: query.page,
    size: query.per_page,
    type: "customer",
  });
};

export const createBillNotify = (data: any) => {
  return Api.post("/bill/createOrEdit", { ...data });
};

export const getOneBill = (id: number) => Api.get("/bill/createOrEdit", { id });

export const getBillNotifyAddress = (query: ListQuery) => {
  return Api.get("/billNotifyAddress/getAll", {
    page: query.page,
    size: query.per_page,
    billId: query.filter?.billId,
  });
};

export const getAllVessel = (query: ListQuery) =>
  Api.get("/vessellist/getAll", {
    page: query.page,
    size: query.per_page,
    name: query.filter?.name || "",
  });

export const createOrEditNotifyAddress = (data: any) =>
  Api.post("/billNotifyAddress/addNotifyAddress", { ...data });

export const deleteRowNotifyThirdAddress = (addressId: number) =>
  Api.delete("/billNotifyAddress/delete", { addressId });

// Shipping
export const getBillShippingTable = (query: ListQuery) => {
  return Api.get("/billShipping/getAll", {
    page: query.page,
    size: query.per_page,
    billId: query.filter?.billId,
  });
};

export const deleteRowShippingTable = (addressId: number) =>
  Api.get("/billShipping/delete", { addressId });

export const createBillShipping = (data: any) => {
  return Api.post("/billShipping/createOrEdit", { ...data });
};

// Container
export const getBillContainerTable = (query: ListQuery) => {
  return Api.get("/billContainer/getAll", {
    page: query.page,
    size: query.per_page,
    billId: query.filter?.billId,
  });
};

export const deleteRowContainerTable = (billcontainerId: number) => {
  return Api.delete("/billContainer/delete", { billcontainerId });
};

export const createOrEditContainer = (data: any) => {
  return Api.post("/billContainer/createOrEdit", { ...data });
};

export const getListContainerNeedUpdate = (billId: number) => {
  return Api.get("/billContainer/getBillContainerForUpdateContainerNo", {
    billId,
  });
};

export const updateBillContainerNo = (
  data: Array<{
    shippingId: number;
    typeContainerId: number;
    containerNo: number;
  }>
) => {
  return Api.post("/billContainer/updateBillContainerNo", data);
};

// Charges
export const getBillChargesTable = (query: ListQuery) => {
  return Api.get("/billCharge/getAll", {
    page: query.page,
    size: query.per_page,
    billId: query.filter?.billId,
  });
};

export const deleteRowChargesTable = (chargeId: number) => {
  return Api.delete("/billCharge/delete", { chargeId });
};

export const createChargeFromQuotation = (
  quotationId: number,
  billId: number
) => {
  return Api.post("/billCharge/createChargeFromQuotation", {
    quotationId,
    billId,
  });
};

export const createOrEditCharges = (data: any) => {
  return Api.post("/billCharge/createOrEdit", { ...data });
};

// Cost
export const getBillCostTable = (query: ListQuery) => {
  return Api.get("/billcost/getAll", {
    page: query.page,
    size: query.per_page,
    billId: query.filter?.bjllId,
  });
};

export const deleteRowCostTable = (billCostId: number) =>
  Api.get("/billcost/delete", { billCostId });

export const createOrEditCost = (data: any) => {
  return Api.post("/billcost/createOrEdit", {
    billId: data.billId,
    feeAtPortId: data.feeAtPortId,
    departmentId: data.departmentId,
    typeOfContainerId: data.typeOfContainerId,
    commodityId: data.commodityId,
    price: data.price,
    currencyId: data.currencyId,
    chargeListId: data.chargeListId,
    quantity: data.quantity,
  });
};
