import { ListQuery } from "../../../models/redux/ListQuery";
import { ReduxCallbacks } from "../../../models/redux/Callbacks";
import { User } from "../../../models/User";

export const FETCH_USER_INIT = "FETCH_USER_INIT";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAILED = "FETCH_USER_FAILED";
export const FETCH_USER_CANCELLED = "FETCH_USER_CANCELLED";

export const fetchUserInit = (
	query: ListQuery,
	callbacks?: ReduxCallbacks
) => ({
	type: FETCH_USER_INIT,
	payload: {
		query,
		callbacks,
	},
});

export const fetchUserSuccess = (response: any) => ({
	type: FETCH_USER_SUCCESS,
	payload: response,
});

export const fetchUserFailed = (error: any) => ({
	type: FETCH_USER_SUCCESS,
	payload: error,
});

export const fetchUserCancelled = () => ({
	type: FETCH_USER_SUCCESS,
});

// ADD USER
export const ADD_USER = "ADD_USER";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_FAILED = "ADD_USER_FAILED";
export const ADD_USER_CANCELLED = "ADD_USER_CANCELLED";

export const addUserInit = (user: any, callbacks?: ReduxCallbacks) => ({
	type: ADD_USER,
	payload: {
		user,
		callbacks,
	},
});

export const addUserSuccess = (response: any) => ({
	type: ADD_USER_SUCCESS,
	payload: response,
});

export const addUserFailed = (error: any) => ({
	type: ADD_USER_FAILED,
	payload: error,
});

export const addUserCancelled = () => ({
	type: ADD_USER_CANCELLED,
});

// UPDATE USER
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILED = "UPDATE_USER_FAILED";
export const UPDATE_USER_CANCELLED = "UPDATE_USER_CANCELLED";

export const updateUserInit = (user: User, callbacks?: ReduxCallbacks) => ({
	type: UPDATE_USER,
	payload: {
		user,
		callbacks,
	},
});

export const updateUserSuccess = (response: any) => ({
	type: UPDATE_USER_SUCCESS,
	payload: response,
});

export const updateUserFailed = (error: any) => ({
	type: UPDATE_USER_FAILED,
	payload: error,
});

export const updateUserCancelled = () => ({
	type: UPDATE_USER_CANCELLED,
});

// DELETE USER
export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILED = "DELETE_USER_FAILED";
export const DELETE_USER_CANCELLED = "DELETE_USER_CANCELLED";

export const deleteUserInit = (userId: number, callbacks?: ReduxCallbacks) => ({
	type: DELETE_USER,
	payload: {
		userId,
		callbacks,
	},
});

export const deleteUserSuccess = (response: any) => ({
	type: DELETE_USER_SUCCESS,
	payload: response,
});

export const deleteUserFailed = (error: any) => ({
	type: DELETE_USER_FAILED,
	payload: error,
});

export const deleteUserCancelled = () => ({
	type: DELETE_USER_CANCELLED,
});

export const FETCH_NOTIFICATIONS_INIT = "FETCH_NOTIFICATIONS_INIT";
export const FETCH_NOTIFICATIONS_SUCCESS = "FETCH_NOTIFICATIONS_SUCCESS";
export const FETCH_NOTIFICATIONS_FAILED = "FETCH_NOTIFICATIONS_FAILED";
export const FETCH_NOTIFICATION_CANCELLED = "FETCH_NOTIFICATION_CANCELLED";

export const fetchNotificationsInit = (
	query: ListQuery,
	callbacks?: ReduxCallbacks
) => ({
	type: FETCH_NOTIFICATIONS_INIT,
	payload: {
		query,
		callbacks,
	},
});

export const fetchNotificationsSuccess = (response: any) => ({
	type: FETCH_NOTIFICATIONS_SUCCESS,
	payload: response,
});

export const fetchNotificationsFailed = (error: any) => ({
	type: FETCH_NOTIFICATIONS_FAILED,
	payload: error,
});

export const fetchNotificationsCancelled = () => ({
	type: FETCH_NOTIFICATION_CANCELLED,
});
