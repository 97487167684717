import { ActionsObservable, ofType } from "redux-observable";
import { Action } from "../../../models/redux/action";
import { ListQuery } from "../../../models/redux/ListQuery";
import { mergeMap, map, catchError, takeUntil } from "rxjs/operators";
import { ReduxCallbacks } from "../../../models/redux/Callbacks";
import {
  FETCH_QUOTATION,
  fetchQuotation,
  fetchQuotationSuccess,
  fetchQuotationFail,
  FETCH_QUOTATION_CANCELLED,
  ADD_QUOTATION,
  addQuotationSuccess,
  addQuotationFail,
  ADD_QUOTATION_CANCELLED,
  ADD_QUOTATION_COMMODITY,
  addQuotationCommoditySuccess,
  addQuotationCommodityFail,
  ADD_QUOTATION_COMMODITY_CANCELLED,
  FETCH_QUOTATION_COMMODITY,
  fetchQuotationCommoditySuccess,
  fetchQuotationCommodityFail,
  FETCH_QUOTATION_COMMODITY_CANCELLED,
} from "./actions";
import {
  getAllQuotation,
  createOrEditQuotation,
  quotationCommodityCreateOrEdit,
  quotationCommodityGetAll,
} from "../../../api/quotation";
import { onEpicSuccess, onEpicFailed } from "../../../helpers/reduxEpic";
import { of } from "rxjs";
import { CANCEL_ALL_REQUEST } from "../../default";

export const fetchQuotationEpic = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    ofType(FETCH_QUOTATION),
    mergeMap((action) => {
      return getAllQuotation(action.payload.query).pipe(
        map((response) =>
          onEpicSuccess(action, response, fetchQuotationSuccess(response))
        ),
        catchError((error) =>
          of(onEpicFailed(action, error, fetchQuotationFail(error)))
        ),
        takeUntil(
          action$.pipe(ofType(FETCH_QUOTATION_CANCELLED, CANCEL_ALL_REQUEST))
        )
      );
    })
  );

export const addQuotationEpic = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    ofType(ADD_QUOTATION),
    mergeMap((action) => {
      return createOrEditQuotation(action.payload.quotation).pipe(
        map((response) =>
          onEpicSuccess(action, response, addQuotationSuccess(response))
        ),
        catchError((error) =>
          of(onEpicFailed(action, error, addQuotationFail(error)))
        ),
        takeUntil(
          action$.pipe(ofType(ADD_QUOTATION_CANCELLED, CANCEL_ALL_REQUEST))
        )
      );
    })
  );

export const addQuotationCommodityEpic = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    ofType(ADD_QUOTATION_COMMODITY),
    mergeMap((action) => {
      return quotationCommodityCreateOrEdit(action.payload.quotation).pipe(
        map((response) =>
          onEpicSuccess(action, response, addQuotationCommoditySuccess(response))
        ),
        catchError((error) =>
          of(onEpicFailed(action, error, addQuotationCommodityFail(error)))
        ),
        takeUntil(
          action$.pipe(
            ofType(ADD_QUOTATION_COMMODITY_CANCELLED, CANCEL_ALL_REQUEST)
          )
        )
      );
    })
  );

export const fetchQuotationCommodityEic = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    ofType(FETCH_QUOTATION_COMMODITY),
    mergeMap((action) => {
      return quotationCommodityGetAll(action.payload.query).pipe(
        map((response) =>
          onEpicSuccess(
            action,
            response,
            fetchQuotationCommoditySuccess(response)
          )
        ),
        catchError((error) =>
          of(onEpicFailed(action, error, fetchQuotationCommodityFail(error)))
        ),
        takeUntil(
          action$.pipe(
            ofType(FETCH_QUOTATION_COMMODITY_CANCELLED, CANCEL_ALL_REQUEST)
          )
        )
      );
    })
  );
