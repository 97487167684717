import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { CircularProgress } from "@material-ui/core";
import { connect } from "react-redux";

interface Props {
	isLoading: boolean;
	opacity?: number;
}

const mapStateToProps = ({ loading }: any) => {
	return {
		isLoading: loading.isLoading,
		opacity: loading.opacity,
	};
};

const LoadingOverlay: FunctionComponent<Props> = ({ isLoading, opacity }) => {
	return (
		<Overlay loading={isLoading ? 1 : 0} opacity={opacity}>
			<CircularProgress />
		</Overlay>
	);
};

export default connect(mapStateToProps)(LoadingOverlay);

const Overlay = styled.div<{
	readonly loading: boolean | number;
	opacity?: number;
}>`
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 10000;
	display: ${({ loading }) => (loading ? "flex" : "none")};
	justify-content: center;
	align-items: center;
	background-color: ${({ opacity }) => {
		if (!opacity) {
			opacity = 0.5;
		}

		return "rgba(255, 255, 255, " + opacity + ")";
	}};
`;
