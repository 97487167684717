import { Action } from "../../../models/redux/action";
import { mergeMap, map, catchError, takeUntil } from "rxjs/operators";
import {
    FETCH_BOOKING_LIST,
    fetchBookingListSuccess,
    fetchBookingListFailed,
    FETCH_BOOKING_LIST_CANCELLED,
    GET_ALL_USER_BY_TYPE,
    getAllUserByTypeSuccess,
    getAllUserByTypeFailed,
    GET_ALL_USER_BY_TYPE_CANCELLED,
    // GENERAL INFORMATION
    GET_BOOKING_NOTIFY_ADDRESS,
    getBookingNotifyAddressSuccess,
    getBookingNotifyAddressFailed,
    GET_BOOKING_NOTIFY_ADDRESS_CANCELLED,
    GET_VESSEL_REQUEST,
    getVesselRequestSuccess,
    getVesselRequestFailed,
    GET_VESSEL_REQUEST_CANCELLED,
    DELETE_ROW_NOTIFY_REQUEST,
    deleteRowNotifySuccess,
    deleteRowNotifyFailed,
    DELETE_ROW_NOTIFY_CANCELLED,
    // SHIPPING
    BOOKING_SHIPPING_TABLE,
    getBookingShippingSuccess,
    getBookingShippingFailed,
    BOOKING_SHIPPING_TABLE_CANCELLED,
    DELETE_ROW_SHIPPING,
    deleteRowShippingSuccess,
    deleteRowShippingFailed,
    DELETE_ROW_SHIPPING_CANCELLED,
    // COST
    BOOKING_COST_TABLE,
    getBookingCostSuccess,
    getBookingCostFailed,
    BOOKING_COST_TABLE_CANCELLED,
    DELETE_ROW_COST,
    deleteRowCostSuccess,
    deleteRowCostFailed,
    DELETE_ROW_COST_CANCELLED,
    // CONTAINER
    BOOKING_CONTAINER_TABLE,
    getBookingContainerSuccess,
    getBookingContainerFailed,
    BOOKING_CONTAINER_TABLE_CANCELLED,
    DELETE_ROW_CONTAINER,
    deleteRowContainerSuccess,
    deleteRowContainerFailed,
    DELETE_ROW_CONTAINER_CANCELLED,
    // CHARGES
    BOOKING_CHARGES_TABLE,
    getBookingChargesSuccess,
    getBookingChargesFailed,
    BOOKING_CHARGES_TABLE_CANCELLED,
    DELETE_ROW_CHARGES,
    deleteRowChargesSuccess,
    deleteRowChargesFailed,
    DELETE_ROW_CHARGES_CANCELLED
} from "./actions";
import { ActionsObservable, ofType } from "redux-observable";
import { of } from "rxjs";
import { CANCEL_ALL_REQUEST } from "../../default";
import { onEpicSuccess, onEpicFailed } from "../../../helpers/reduxEpic";
import {
    getAllBookingList,
    getAllUserByType,
    getBookingNotifyAddress,
    getAllVessel,
    deleteRowNotifyThirdAddress,
    getBookingShippingTable,
    deleteRowShippingTable,
    getBookingContainerTable,
    deleteRowContainerTable,
    getBookingChargesTable,
    deleteRowChargesTable,
    getBookingCostTable,
    deleteRowCostTable
} from '../../../api/booking';

export const fetchListBookingEpic = (action$: ActionsObservable<Action>) => {
    return action$.pipe(
        ofType(FETCH_BOOKING_LIST),
        mergeMap((action) =>
            getAllBookingList(action.payload.query).pipe(
                map((response) =>
                    onEpicSuccess(action, response, fetchBookingListSuccess(response))
                ),
                catchError((error) =>
                    of(onEpicFailed(action, error, fetchBookingListFailed(error)))
                ),
                takeUntil(
                    action$.pipe(
                        ofType(FETCH_BOOKING_LIST_CANCELLED, CANCEL_ALL_REQUEST)
                    )
                )
            )
        )
    );
};

export const getAllListUserByTypeEpic = (action$: ActionsObservable<Action>) => {
    return action$.pipe(
        ofType(GET_ALL_USER_BY_TYPE),
        mergeMap((action) =>
            getAllUserByType(action.payload.query).pipe(
                map((response) =>
                    onEpicSuccess(action, response, getAllUserByTypeSuccess(response))
                ),
                catchError((error) =>
                    of(onEpicFailed(action, error, getAllUserByTypeFailed(error)))
                ),
                takeUntil(
                    action$.pipe(
                        ofType(GET_ALL_USER_BY_TYPE_CANCELLED, CANCEL_ALL_REQUEST)
                    )
                )
            )
        )
    );
};

export const getBookingNotifyAddressEpic = (action$: ActionsObservable<Action>) => {
    return action$.pipe(
        ofType(GET_BOOKING_NOTIFY_ADDRESS),
        mergeMap((action) =>
            getBookingNotifyAddress(action.payload.query).pipe(
                map((response) =>
                    onEpicSuccess(action, response, getBookingNotifyAddressSuccess(response))
                ),
                catchError((error) =>
                    of(onEpicFailed(action, error, getBookingNotifyAddressFailed(error)))
                ),
                takeUntil(
                    action$.pipe(
                        ofType(GET_BOOKING_NOTIFY_ADDRESS_CANCELLED, CANCEL_ALL_REQUEST)
                    )
                )
            )
        )
    );
};

export const getVesselEpic = (action$: ActionsObservable<Action>) => {
    return action$.pipe(
        ofType(GET_VESSEL_REQUEST),
        mergeMap((action) =>
            getAllVessel(action.payload.query).pipe(
                map((response) =>
                    onEpicSuccess(action, response, getVesselRequestSuccess(response))
                ),
                catchError((error) =>
                    of(onEpicFailed(action, error, getVesselRequestFailed(error)))
                ),
                takeUntil(
                    action$.pipe(
                        ofType(GET_VESSEL_REQUEST_CANCELLED, CANCEL_ALL_REQUEST)
                    )
                )
            )
        )
    );
};

export const deleteNotifyAddressEpic = (action$: ActionsObservable<Action>) =>
    action$.pipe(
        ofType(DELETE_ROW_NOTIFY_REQUEST),
        mergeMap((action) =>
            deleteRowNotifyThirdAddress(action.payload.addressId).pipe(
                map((response) =>
                    onEpicSuccess(action, response, deleteRowNotifySuccess())
                ),
                catchError((error) =>
                    of(onEpicFailed(action, error, deleteRowNotifyFailed(error)))
                ),
                takeUntil(
                    action$.pipe(ofType(DELETE_ROW_NOTIFY_CANCELLED, CANCEL_ALL_REQUEST))
                )
            )
        )
    );

export const getBookingShippingEpic = (action$: ActionsObservable<Action>) => {
    return action$.pipe(
        ofType(BOOKING_SHIPPING_TABLE),
        mergeMap((action) =>
            getBookingShippingTable(action.payload.query).pipe(
                map((response) =>
                    onEpicSuccess(action, response, getBookingShippingSuccess(response))
                ),
                catchError((error) =>
                    of(onEpicFailed(action, error, getBookingShippingFailed(error)))
                ),
                takeUntil(
                    action$.pipe(
                        ofType(BOOKING_SHIPPING_TABLE_CANCELLED, CANCEL_ALL_REQUEST)
                    )
                )
            )
        )
    );
};

export const deleteRowShippingEpic = (action$: ActionsObservable<Action>) =>
    action$.pipe(
        ofType(DELETE_ROW_SHIPPING),
        mergeMap((action) =>
            deleteRowShippingTable(action.payload.addressId).pipe(
                map((response) =>
                    onEpicSuccess(action, response, deleteRowShippingSuccess())
                ),
                catchError((error) =>
                    of(onEpicFailed(action, error, deleteRowShippingFailed(error)))
                ),
                takeUntil(
                    action$.pipe(ofType(DELETE_ROW_SHIPPING_CANCELLED, CANCEL_ALL_REQUEST))
                )
            )
        )
    );

// CONTAINER
export const getBookingContainerEpic = (action$: ActionsObservable<Action>) => {
    return action$.pipe(
        ofType(BOOKING_CONTAINER_TABLE),
        mergeMap((action) =>
            getBookingContainerTable(action.payload.query).pipe(
                map((response) =>
                    onEpicSuccess(action, response, getBookingContainerSuccess(response))
                ),
                catchError((error) =>
                    of(onEpicFailed(action, error, getBookingContainerFailed(error)))
                ),
                takeUntil(
                    action$.pipe(
                        ofType(BOOKING_CONTAINER_TABLE_CANCELLED, CANCEL_ALL_REQUEST)
                    )
                )
            )
        )
    );
};

export const deleteRowContainerEpic = (action$: ActionsObservable<Action>) =>
    action$.pipe(
        ofType(DELETE_ROW_CONTAINER),
        mergeMap((action) =>
            deleteRowContainerTable(action.payload.Id).pipe(
                map((response) =>
                    onEpicSuccess(action, response, deleteRowContainerSuccess())
                ),
                catchError((error) =>
                    of(onEpicFailed(action, error, deleteRowContainerFailed(error)))
                ),
                takeUntil(
                    action$.pipe(ofType(DELETE_ROW_CONTAINER_CANCELLED, CANCEL_ALL_REQUEST))
                )
            )
        )
    );

// CHARGES
export const getBookingChargesEpic = (action$: ActionsObservable<Action>) => {
    return action$.pipe(
        ofType(BOOKING_CHARGES_TABLE),
        mergeMap((action) =>
            getBookingChargesTable(action.payload.query).pipe(
                map((response) =>
                    onEpicSuccess(action, response, getBookingChargesSuccess(response))
                ),
                catchError((error) =>
                    of(onEpicFailed(action, error, getBookingChargesFailed(error)))
                ),
                takeUntil(
                    action$.pipe(
                        ofType(BOOKING_CHARGES_TABLE_CANCELLED, CANCEL_ALL_REQUEST)
                    )
                )
            )
        )
    );
};

export const deleteRowChargesEpic = (action$: ActionsObservable<Action>) =>
    action$.pipe(
        ofType(DELETE_ROW_CHARGES),
        mergeMap((action) =>
            deleteRowChargesTable(action.payload.chargeId).pipe(
                map((response) =>
                    onEpicSuccess(action, response, deleteRowChargesSuccess())
                ),
                catchError((error) =>
                    of(onEpicFailed(action, error, deleteRowChargesFailed(error)))
                ),
                takeUntil(
                    action$.pipe(ofType(DELETE_ROW_CHARGES_CANCELLED, CANCEL_ALL_REQUEST))
                )
            )
        )
    );

// COST
export const getBookingCostEpic = (action$: ActionsObservable<Action>) => {
    return action$.pipe(
        ofType(BOOKING_COST_TABLE),
        mergeMap((action) =>
            getBookingCostTable(action.payload.query).pipe(
                map((response) =>
                    onEpicSuccess(action, response, getBookingCostSuccess(response))
                ),
                catchError((error) =>
                    of(onEpicFailed(action, error, getBookingCostFailed(error)))
                ),
                takeUntil(
                    action$.pipe(
                        ofType(BOOKING_COST_TABLE_CANCELLED, CANCEL_ALL_REQUEST)
                    )
                )
            )
        )
    );
};

export const deleteRowCostEpic = (action$: ActionsObservable<Action>) =>
    action$.pipe(
        ofType(DELETE_ROW_COST),
        mergeMap((action) =>
            deleteRowCostTable(action.payload.addressId).pipe(
                map((response) =>
                    onEpicSuccess(action, response, deleteRowCostSuccess())
                ),
                catchError((error) =>
                    of(onEpicFailed(action, error, deleteRowCostFailed(error)))
                ),
                takeUntil(
                    action$.pipe(ofType(DELETE_ROW_COST_CANCELLED, CANCEL_ALL_REQUEST))
                )
            )
        )
    );