import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../helpers";
import styled from "styled-components";
import {notify} from "../helpers/notify";

interface AuthLayoutProps {}

export const AuthLayout: FunctionComponent<AuthLayoutProps> = ({
	children,
}) => {
	return (
		<>
			<Container>
				<Content>
					<div className="container-fluid">
						<div className="row">
							<ColLeft className="col-12 col-lg-8">
								{/* <img
									src={toAbsoluteUrl("/login_logo.png")}
									style={{ maxWidth: "50%" }}
									alt=""
								/> */}
							</ColLeft>
							<ColRight className="col-12 col-lg-4">
								<div className="w-100">{children}</div>
							</ColRight>
						</div>
					</div>
				</Content>
			</Container>
		</>
	);
};

const Container = styled.div`
	max-width: 100vw;
	height: 100vh;
	background: rgba(0, 0, 0, 0.3) url(${toAbsoluteUrl("/auth_bg.jpg")}) no-repeat;
	background-size: cover;
`;

const Content = styled.div`
	max-width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const ColLeft = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;
const ColRight = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(0, 0, 0, 0.3);
	padding: 7rem 1rem;
`;
