import React from "react";
import { Link } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../helpers";
import styled from "styled-components";

export function HeaderMobile() {
	return (
		<>
			{/*begin::Header Mobile*/}
			<div
				id="kt_header_mobile"
				className={`header-mobile align-items-center header-mobile-fixed`}
			>
				{/*begin::Logo*/}
				<Link to="/">
					<Logo alt="logo" src={toAbsoluteUrl("/logo.png")} />
				</Link>
				{/*end::Logo*/}

				{/*begin::Toolbar*/}
				<div className="d-flex align-items-center">
					{/*begin::Aside Mobile Toggle*/}
					<button
						className="btn p-0 burger-icon burger-icon-left"
						id="kt_aside_mobile_toggle"
					>
						<span />
					</button>
					{/*end::Aside Mobile Toggle*/}

					{/*begin::Topbar Mobile Toggle*/}
					<button
						className="btn btn-hover-text-primary p-0 ml-2"
						id="kt_header_mobile_topbar_toggle"
					>
						<span className="svg-icon svg-icon-xl">
							<SVG src="/media/svg/icons/General/User.svg" />
						</span>
					</button>
					{/*end::Topbar Mobile Toggle*/}
				</div>
				{/*end::Toolbar*/}
			</div>
			{/*end::Header Mobile*/}
		</>
	);
}

const Logo = styled.img`
	height: 45px;
	object-fit: cover;
`;
