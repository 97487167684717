import { Action } from "../../../models/redux/action";
import { mergeMap, map, catchError, takeUntil } from "rxjs/operators";
import {
	FETCH_PORT_INIT,
	fetchPortSuccess,
	fetchPortFailed,
	FETCH_PORT_CANCELLED,
	ADD_PORT_INIT,
	addPortFailed,
	EDIT_PORT_INIT,
	editPortFailed,
	DELETE_PORT_INIT,
	fetchListPort,
} from "./actions";
import { ActionsObservable, ofType } from "redux-observable";
import { of } from "rxjs";
import { CANCEL_ALL_REQUEST } from "../../default";
import { onEpicSuccess, onEpicFailed } from "../../../helpers/reduxEpic";
import { getAllPorts, addPort, deleteOnePort } from "../../../api/port";

export const fetchListPortEpic = (action$: ActionsObservable<Action>) => {
	return action$.pipe(
		ofType(FETCH_PORT_INIT),
		mergeMap((action) =>
			getAllPorts(
				action.payload.query?.filter?.name,
				action.payload.query.page,
				action.payload.query.per_page,
				action.payload.query.filter?.status
			).pipe(
				map((response) =>
					onEpicSuccess(action, response, fetchPortSuccess(response))
				),
				catchError((error) =>
					of(onEpicFailed(action, error, fetchPortFailed(error)))
				),
				takeUntil(
					action$.pipe(ofType(FETCH_PORT_CANCELLED, CANCEL_ALL_REQUEST))
				)
			)
		)
	);
};

export const addPortEpic = (
	action$: ActionsObservable<Action>,
	state$: any
) => {
	return action$.pipe(
		ofType(ADD_PORT_INIT),
		mergeMap((action) =>
			addPort(action.payload).pipe(
				map((response) => {
					return onEpicSuccess(
						action,
						response,
						fetchListPort({
							page: state$.value.port.query.page,
							per_page: state$.value.port.query.per_page,
						})
					);
				}),
				catchError((error) =>
					of(onEpicFailed(action, error, addPortFailed(error)))
				),
				takeUntil(action$.pipe(ofType(CANCEL_ALL_REQUEST)))
			)
		)
	);
};

export const editPortEpic = (
	action$: ActionsObservable<Action>,
	state$: any
) => {
	return action$.pipe(
		ofType(EDIT_PORT_INIT),
		mergeMap((action) =>
			addPort(action.payload).pipe(
				map((response) => {
					console.log(state$);
					return onEpicSuccess(
						action,
						response,
						fetchListPort({
							page: state$.value.port.query.page,
							per_page: state$.value.port.query.per_page,
						})
					);
				}),
				catchError((error) =>
					of(onEpicFailed(action, error, editPortFailed(error)))
				),
				takeUntil(action$.pipe(ofType(CANCEL_ALL_REQUEST)))
			)
		)
	);
};

export const deletePortEpic = (
	action$: ActionsObservable<Action>,
	state$: any
) => {
	return action$.pipe(
		ofType(DELETE_PORT_INIT),
		mergeMap((action) =>
			deleteOnePort(action.payload).pipe(
				map((response) => {
					return onEpicSuccess(
						action,
						response,
						fetchListPort({
							page: state$.value.port.query.page,
							per_page: state$.value.port.query.per_page,
						})
					);
				}),
				catchError((error) =>
					of(onEpicFailed(action, error, editPortFailed(error)))
				),
				takeUntil(action$.pipe(ofType(CANCEL_ALL_REQUEST)))
			)
		)
	);
};
