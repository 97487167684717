import { ActionsObservable, ofType } from "redux-observable";
import { Action } from "../../../models/redux/action";
import { getAllTypeContainer1 } from "../../../api/typeContainer";
import {
  fetchContainerSuccess,
  FETCH_CONTAINER,
  fetchContainerFailed,
  FETCH_CONTAINER_CANCELLED,
} from "./actions";
import { map, mergeMap, catchError, takeUntil } from "rxjs/operators";
import { onEpicSuccess, onEpicFailed } from "../../../helpers/reduxEpic";
import { of } from "rxjs";
import { CANCEL_ALL_REQUEST } from "../../default";

export const fetchContainerEpic = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    ofType(FETCH_CONTAINER),
    mergeMap((action) => {
      return getAllTypeContainer1(action.payload.query).pipe(
        map((response) =>
          onEpicSuccess(action, response, fetchContainerSuccess(response))
        ),
        catchError((error) =>
          of(onEpicFailed(action, error, fetchContainerFailed()))
        ),
        takeUntil(
          action$.pipe(ofType(FETCH_CONTAINER_CANCELLED, CANCEL_ALL_REQUEST))
        )
      );
    })
  );
