import { Action } from "../../../models/redux/action";
import { mergeMap, map, catchError, takeUntil } from "rxjs/operators";

import { ActionsObservable, ofType } from "redux-observable";
import { of } from "rxjs";
import { CANCEL_ALL_REQUEST } from "../../default";
import { onEpicSuccess, onEpicFailed } from "../../../helpers/reduxEpic";
import {
	FETCH_USER_INIT,
	fetchUserSuccess,
	fetchUserFailed,
	FETCH_USER_CANCELLED,
	ADD_USER,
	addUserSuccess,
	fetchUserInit,
	addUserFailed,
	ADD_USER_CANCELLED,
	UPDATE_USER,
	UPDATE_USER_CANCELLED,
	updateUserFailed,
	updateUserSuccess,
	DELETE_USER,
	DELETE_USER_CANCELLED,
	deleteUserFailed,
	deleteUserSuccess,
	FETCH_NOTIFICATIONS_INIT,
	fetchNotificationsSuccess,
	fetchNotificationsFailed,
	FETCH_NOTIFICATION_CANCELLED,
} from "./actions";
import {Api, getUserNotifications} from "../../../api";
import {
	updateUser,
	deleteUser,
	addUserService,
	getAllUserService,
} from "../../../api/user";

export const fetchListUserInit = (action$: ActionsObservable<Action>) => {
	return action$.pipe(
		ofType(FETCH_USER_INIT),
		mergeMap((action) => {
			return getAllUserService(
				action.payload.query.page,
				action.payload.query.size,
				action.payload.query?.filter?.name
			).pipe(
				map((response) =>
					onEpicSuccess(action, response, fetchUserSuccess(response))
				),
				catchError((error) =>
					of(onEpicFailed(action, error, fetchUserFailed(error)))
				),
				takeUntil(
					action$.pipe(ofType(FETCH_USER_CANCELLED, CANCEL_ALL_REQUEST))
				)
			);
		})
	);
};

export const addUserEpic = (
	action$: ActionsObservable<Action>,
	state$: any
) => {
	return action$.pipe(
		ofType(ADD_USER),
		mergeMap((action) => {
			return addUserService(action.payload.user).pipe(
				mergeMap(({ response }) =>
					onEpicSuccess(
						action,
						response,
						of(
							addUserSuccess(response),
							fetchUserInit({
								page: state$.value.user.query.page,
								size: state$.value.user.query.size,
							})
						)
					)
				),
				catchError((error) =>
					of(onEpicFailed(action, error, addUserFailed(error)))
				),
				takeUntil(action$.pipe(ofType(ADD_USER_CANCELLED, CANCEL_ALL_REQUEST)))
			);
		})
	);
};

export const updateUserEpic = (
	action$: ActionsObservable<Action>,
	state$: any
) => {
	return action$.pipe(
		ofType(UPDATE_USER),
		mergeMap((action) => {
			return updateUser(action.payload.user).pipe(
				mergeMap(({ response }) =>
					onEpicSuccess(
						action,
						response,
						of(
							updateUserSuccess(response),
							fetchUserInit({
								page: state$.value.user.query.page,
								size: state$.value.user.query.size,
							})
						)
					)
				),
				catchError((error) =>
					of(onEpicFailed(action, error, updateUserFailed(error)))
				),
				takeUntil(
					action$.pipe(ofType(UPDATE_USER_CANCELLED, CANCEL_ALL_REQUEST))
				)
			);
		})
	);
};

export const deleteUserEpic = (
	action$: ActionsObservable<Action>,
	state$: any
) => {
	return action$.pipe(
		ofType(DELETE_USER),
		mergeMap((action) => {
			return deleteUser(action.payload.userId).pipe(
				mergeMap(({ response }) =>
					onEpicSuccess(
						action,
						response,
						of(
							deleteUserSuccess(response),
							fetchUserInit({
								page: state$.value.user.query.page,
								size: state$.value.user.query.size,
							})
						)
					)
				),
				catchError((error) =>
					of(onEpicFailed(action, error, deleteUserFailed(error)))
				),
				takeUntil(
					action$.pipe(ofType(DELETE_USER_CANCELLED, CANCEL_ALL_REQUEST))
				)
			);
		})
	);
};

export const fetchListNotificationEpic = (action$: ActionsObservable<Action>) => {
	return action$.pipe(
		ofType(FETCH_NOTIFICATIONS_INIT),
		mergeMap((action) => {
			return getUserNotifications(
				action.payload.query.page,
				action.payload.query.size,
			).pipe(
				map((response) =>
					onEpicSuccess(action, response, fetchNotificationsSuccess(response))
				),
				catchError((error) =>
					of(onEpicFailed(action, error, fetchNotificationsFailed(error)))
				),
				takeUntil(
					action$.pipe(ofType(FETCH_NOTIFICATION_CANCELLED, CANCEL_ALL_REQUEST))
				)
			);
		})
	);
};
