import { combineEpics } from "redux-observable";
import { combineReducers } from "redux";
import { loadingReducer } from "./loading";
import {
  fetchListOfficeEpic,
  officeReducer,
  addOfficeEpic,
  editOfficeEpic,
  deleteOfficeEpic,
} from "./office";
import {
  loginEpic,
  fetchUserEpic,
  authReducer,
  fetchPermissionsEpic,
  logoutEpic,
  onFetchUserSuccessEpic,
  registerDeviceEpic, registerDeviceReducer
} from "./auth";
import {
  fetchListUnitEpic,
  unitReducer,
  addUnitEpic,
  deleteUnitEpic,
} from "./unit";
import { currentUnitReducer, currentUnitEpic } from "./current-unit";
import {
  fetchRoleEpic,
  deleteRoleEpic,
  roleReducer,
  addRoleEpic,
} from "./role";

import {
  fetchListUserInit,
  userReducer,
  addUserEpic,
  updateUserEpic,
  deleteUserEpic, notificationReducer, fetchListNotificationEpic,
} from "./user";

import {
  fetchListShipScheduleEpic,
  addShipScheduleEpic,
  editShipScheduleEpic,
  deleteShipScheduleEpic,
  shipScheduleReducer,
  fetchListShipScheduleSystemEpic,
  fetchListShipScheduleCustomerEpic,
  customerShipScheduleReducer,
  systemShipScheduleReducer,
} from "./ship_schedule";

import {
  fetchMerchantsEpic,
  merchantReducer,
  addMerchantEpic,
  deleteMerchantEpic,
} from "./merchants";

import {
  fetchAddressesEpic,
  addAddressEpic,
  deleteAddressEpic,
  addressReducer,
} from "./address";
import {
  fetchListChargeTypeEpic,
  addChargeTypeEpic,
  deleteChargeTypeEpic,
  editChargeTypeEpic,
  chargeTypeReducer,
} from "./chargetype";

import {
  fetchListChargeGroupEpic,
  addChargeGroupEpic,
  deleteChargeGroupEpic,
  editChargeGroupEpic,
  chargegroupReducer,
} from "./chargegroup";

import {
  fetchListChargeEpic,
  addChargeEpic,
  deleteChargeEpic,
  editChargeEpic,
  chargeReducer,
} from "./charge";

import {
  fetchListTermEpic,
  addTermEpic,
  deleteTermEpic,
  editTermEpic,
  termReducer,
} from "./term";

import {
  addPortOfCallingEpic,
  deletePortOfCallingEpic,
  portOfCallingReducer,
  fetchPortOfCallingsEpic,
} from "./portOfCalling";

import {
  addFeeEpic,
  deleteFeeEpic,
  editFeeEpic,
  fetchListFeeEpic,
  feeReducer,
} from "./fee";
import { bookingNoteReducer, allBookingNoteReducer } from "./booking-not";

import {
  createBookingNoteEpic,
  updateInforProductEpic,
  updateBookingNoteEpic,
  updateNoteEpic,
  getBookingNoteNoEpic,
  // cancelBookingNoteEpic,
  fetchAllBookingNoteEpic,
  fetchAllBookingNoteBySenderIdEpic,
  cancelBookingNoteEpic,
} from "./booking-not/epic";

import {
  fetchCurrencysEpic,
  deleteCurrencyEpic,
  addCurrencyEpic,
  currencyReducer,
  editCurrencyEpic,
} from "./currency";

import {
  fetchListVesselEpic,
  addVesselEpic,
  deleteVesselEpic,
  editVesselEpic,
  vesselReducer,
} from "./vessel";

import {
  fetchListPortEpic,
  addPortEpic,
  deletePortEpic,
  editPortEpic,
  portReducer,
} from "./port";

import {
  fetchListTerminalEpic,
  addTerminalEpic,
  deleteTerminalEpic,
  editTerminalEpic,
  terminalReducer,
} from "./terminal";

import {
  fetchListTypeContainerEpic,
  addTypeContainerEpic,
  deleteTypeContainerEpic,
  editTypeContainerEpic,
  typeContainerReducer,
} from "./typeContainer";

import {
  fetchListSizeContainerEpic,
  addSizeContainerEpic,
  deleteSizeContainerEpic,
  editSizeContainerEpic,
  sizeContainerReducer,
} from "./sizeContainer";

import {
  fetchCommoditysEpic,
  addCommodityEpic,
  deleteCommodityEpic,
  editCommodityEpic,
  commodityReducer,
} from "./commodity";

import {
  bookingListReducer,
  userByTypeListReducer,
  bookingNotifyAddressReducer,
  getVesselReducer,
  bookingShippingReducer,
  bookingContainerReducer,
  bookingChargesReducer,
  bookingCostReducer,
} from "./booking";

import {
  fetchListBookingEpic,
  getAllListUserByTypeEpic,
  getBookingNotifyAddressEpic,
  getVesselEpic,
  deleteNotifyAddressEpic,
  getBookingShippingEpic,
  deleteRowShippingEpic,
  getBookingContainerEpic,
  deleteRowContainerEpic,
  getBookingChargesEpic,
  deleteRowChargesEpic,
  getBookingCostEpic,
  deleteRowCostEpic,
} from "./booking/epic";

import {
  BillListReducer,
  userByTypeListReducerr,
  BillNotifyAddressReducer,
  getVesselReducerr,
  BillShippingReducer,
  BillContainerReducer,
  BillChargesReducer,
  BillCostReducer,
  fetchListBillEpic,
  getAllListUserByTypeEpicc,
  getBillNotifyAddressEpic,
  getVesselEpicc,
  deleteNotifyAddressEpicc,
  getBillShippingEpic,
  deleteRowShippingEpicc,
  getBillContainerEpic,
  deleteRowContainerEpicc,
  getBillChargesEpic,
  deleteRowChargesEpicc,
  getBillCostEpic,
  deleteRowCostEpicc,
} from "./billManage";
import {
  fetchQuotationEpic,
  quotationReducer,
  quotationCommodityReducer,
  addQuotationEpic,
  addQuotationCommodityEpic,
  fetchQuotationCommodityEic,
} from "./quotation";

import {
  feeApproveReducer,
  fetchFeeApproveEpic,
  addFeeApproveEpic,
  deleteFeeApproveEpic,
} from "./configApprove";

import {
  fetchCurrencyConversionEpic,
  addCurrencyConversionEpic,
  editCurrencyConversionEpic,
  deleteCurrencyConversionEpic,
  currencyConversionReducer,
} from "./currencyConversion";

import {
  fetchConfigApproverEpic,
  addConfigApproverEpic,
  editConfigApproverEpic,
  deleteConfigApproverEpic,
  configApproverReducer,
} from "./configApprover";

import accountEpics from './account/epics'

import { fetchContainerEpic, containerReducer } from "./container";
import {localizeReducer} from "react-localize-redux";
import { accountReducer } from "./account/reducer";

export const rootEpic = combineEpics(
  loginEpic,
  fetchUserEpic,
  fetchPermissionsEpic,
  logoutEpic,
  onFetchUserSuccessEpic,
  fetchListNotificationEpic,
  registerDeviceEpic,
  fetchListUnitEpic,
  addUnitEpic,
  deleteUnitEpic,
  currentUnitEpic,
  ...accountEpics,

  fetchListOfficeEpic,
  addOfficeEpic,
  editOfficeEpic,
  deleteOfficeEpic,

  fetchListShipScheduleEpic,
  fetchListShipScheduleSystemEpic,
  fetchListShipScheduleCustomerEpic,
  addShipScheduleEpic,
  editShipScheduleEpic,
  deleteShipScheduleEpic,

  fetchRoleEpic,
  deleteRoleEpic,
  addRoleEpic,

  fetchListUserInit,
  addUserEpic,
  updateUserEpic,
  deleteUserEpic,

  fetchMerchantsEpic,
  addMerchantEpic,
  deleteMerchantEpic,

  fetchListChargeTypeEpic,
  addChargeTypeEpic,
  editChargeTypeEpic,
  deleteChargeTypeEpic,

  fetchListChargeGroupEpic,
  addChargeGroupEpic,
  editChargeGroupEpic,
  deleteChargeGroupEpic,

  fetchListChargeEpic,
  addChargeEpic,
  editChargeEpic,
  deleteChargeEpic,

  fetchListTermEpic,
  addTermEpic,
  deleteTermEpic,
  editTermEpic,

  fetchAddressesEpic,
  addAddressEpic,
  deleteAddressEpic,

  fetchListVesselEpic,
  addVesselEpic,
  deleteVesselEpic,
  editVesselEpic,

  fetchListPortEpic,
  addPortEpic,
  deletePortEpic,
  editPortEpic,

  fetchCurrencysEpic,
  addCurrencyEpic,
  deleteCurrencyEpic,
  editCurrencyEpic,

  createBookingNoteEpic,
  fetchAllBookingNoteEpic,
  updateInforProductEpic,
  updateBookingNoteEpic,
  updateNoteEpic,
  getBookingNoteNoEpic,
  cancelBookingNoteEpic,
  fetchAllBookingNoteBySenderIdEpic,

  fetchPortOfCallingsEpic,
  addPortOfCallingEpic,
  deletePortOfCallingEpic,

  fetchListTerminalEpic,
  addTerminalEpic,
  deleteTerminalEpic,
  editTerminalEpic,

  fetchListTypeContainerEpic,
  addTypeContainerEpic,
  deleteTypeContainerEpic,
  editTypeContainerEpic,

  fetchListSizeContainerEpic,
  addSizeContainerEpic,
  deleteSizeContainerEpic,
  editSizeContainerEpic,

  fetchCommoditysEpic,
  addCommodityEpic,
  deleteCommodityEpic,
  editCommodityEpic,

  fetchListBookingEpic,
  getAllListUserByTypeEpic,
  getBookingNotifyAddressEpic,
  getVesselEpic,
  deleteNotifyAddressEpic,
  getBookingShippingEpic,
  deleteRowShippingEpic,
  getBookingContainerEpic,
  deleteRowContainerEpic,
  getBookingChargesEpic,
  deleteRowChargesEpic,
  getBookingCostEpic,
  deleteRowCostEpic,

  fetchListBillEpic,
  getAllListUserByTypeEpicc,
  getBillNotifyAddressEpic,
  getVesselEpicc,
  deleteNotifyAddressEpicc,
  getBillShippingEpic,
  deleteRowShippingEpicc,
  getBillContainerEpic,
  deleteRowContainerEpicc,
  getBillChargesEpic,
  deleteRowChargesEpicc,
  getBillCostEpic,
  deleteRowCostEpicc,

  fetchQuotationEpic,
  addQuotationEpic,
  addQuotationCommodityEpic,
  fetchQuotationCommodityEic,

  fetchContainerEpic,

  fetchFeeApproveEpic,
  addFeeApproveEpic,
  deleteFeeApproveEpic,

  addFeeEpic,
  deleteFeeEpic,
  editFeeEpic,
  fetchListFeeEpic,

  fetchCurrencyConversionEpic,
  addCurrencyConversionEpic,
  editCurrencyConversionEpic,
  deleteCurrencyConversionEpic,

  fetchConfigApproverEpic,
  addConfigApproverEpic,
  editConfigApproverEpic,
  deleteConfigApproverEpic
);

export const rootReducer = combineReducers({
  loading: loadingReducer,
  office: officeReducer,
  auth: authReducer,
  registerDevice: registerDeviceReducer,
  unit: unitReducer,
  currentUnit: currentUnitReducer,
  role: roleReducer,
  user: userReducer,
  notifications: notificationReducer,
  shipSchedule: shipScheduleReducer,
  customerShipSchedule: customerShipScheduleReducer,
  systemShipSchedule: systemShipScheduleReducer,
  portOfCalling: portOfCallingReducer,
  bookingNote: bookingNoteReducer,
  allBookingNote: allBookingNoteReducer,
  merchant: merchantReducer,
  address: addressReducer,
  vessel: vesselReducer,
  port: portReducer,
  currency: currencyReducer,
  fee: feeReducer,
  terminal: terminalReducer,
  typeContainer: typeContainerReducer,
  sizeContainer: sizeContainerReducer,
  commodity: commodityReducer,
  bookingList: bookingListReducer,
  userByType: userByTypeListReducer,
  bookingNotify: bookingNotifyAddressReducer,
  getVessel: getVesselReducer,
  chargetype: chargeTypeReducer,
  chargegroup: chargegroupReducer,
  charge: chargeReducer,
  term: termReducer,
  quotation: quotationReducer,
  container: containerReducer,
  quotationCommodity: quotationCommodityReducer,
  feeApprove: feeApproveReducer,
  bookingShipping: bookingShippingReducer,
  bookingContainer: bookingContainerReducer,
  bookingCharges: bookingChargesReducer,
  bookingCost: bookingCostReducer,
  currencyConversion: currencyConversionReducer,
  configApprover: configApproverReducer,
  billList: BillListReducer,
  userByTypes: userByTypeListReducerr,
  billNotify: BillNotifyAddressReducer,
  getVessels: getVesselReducerr,
  billShipping: BillShippingReducer,
  billContainer: BillContainerReducer,
  billCharge: BillChargesReducer,
  billCost: BillCostReducer,
  account: accountReducer,
});

export type RootStoreType = ReturnType<typeof rootReducer>;
