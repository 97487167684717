import { ListState } from "../../../models/redux/listState";
import { ConfigApprove } from "../../../models/configApprover";
import { Action } from "../../../models/redux/action";
import {
  FETCH_LIST_CONFIGAPPROVER,
  FETCH_LIST_CONFIGAPPROVER_SUCCESS,
  FETCH_LIST_CONFIGAPPROVER_FAILED,
  ADD_CONFIGAPPROVER,
  ADD_CONFIGAPPROVER_SUCCESS,
  ADD_CONFIGAPPROVER_FAILED,
  EDIT_CONFIGAPPROVER,
  EDIT_CONFIGAPPROVER_SUCCESS,
  EDIT_CONFIGAPPROVER_FAILED,
  DELETE_CONFIGAPPROVER,
  DELETE_CONFIGAPPROVER_SUCCESS,
  DELETE_CONFIGAPPROVER_FAILED,
} from "./actions";
import {
  fetchListState,
  fetchListStateSuccess,
  fetchListStateFailed,
  baseFetchListState,
  addInit,
  addFailed,
  addSuccess,
  deleteInit,
  deleteSuccess,
  deleteFailed,
} from "../../../helpers/reduxAction";

const initState: ListState<ConfigApprove> = baseFetchListState();

export const configApproverReducer = (
  state: ListState<ConfigApprove> = initState,
  action: Action
) => {
  switch (action.type) {
    case FETCH_LIST_CONFIGAPPROVER:
      return fetchListState(action);
    case FETCH_LIST_CONFIGAPPROVER_SUCCESS:
      return fetchListStateSuccess(action, state);
    case FETCH_LIST_CONFIGAPPROVER_FAILED:
      return fetchListStateFailed(action, state);

    case ADD_CONFIGAPPROVER:
      return addInit(action, state);
    case ADD_CONFIGAPPROVER_SUCCESS:
      return addSuccess(action, state);
    case ADD_CONFIGAPPROVER_FAILED:
      return addFailed(action, state);

    case DELETE_CONFIGAPPROVER:
      return deleteInit(action, state);
    case DELETE_CONFIGAPPROVER_SUCCESS:
      return deleteSuccess(action, state);
    case DELETE_CONFIGAPPROVER_FAILED:
      return deleteFailed(action, state);
    default:
      return state;
  }
};
