import {LoginUser, RegisterDevice} from "../models/LoginUser";
import { Api } from "../api";
import store from "../redux";
import { Permission } from "../models/Permission";
import { fetchUserSuccess } from "../redux/modules/auth";
import { CurrentUnit } from "../models/CurrentUnit";

export class Auth {
	static login(user: LoginUser) {
		return Api.post("/auth/signin", user);
	}

	static registerDevice(registerDevice: RegisterDevice){
		return Api.post("/auth/registerDevice", registerDevice);
	}



	static fetchUser() {
		return Api.get("/user/me");
	}

	static chooseUnitDepartment(unit: CurrentUnit) {
		return Api.post("/auth/choseUnitDepartment", unit);
	}

	static fetchPermissions(unitId?: number) {
		return Api.get("/permission/getAllCurrentUserPermission", {
			unitId: Auth.isSystemUser() ? unitId : undefined,
		});
	}

	static hasPermission(permission: string) {

		const state = store.getState();
		const permissions: Permission[] = state.auth.permissions;
		if (!permissions) {
			return false;
		}

		return (
			permissions.findIndex(
				(perm) => perm.isUse && perm.permission === permission
			) > -1
		);
	}

	static async isLoggedIn() {
		try {
			const { response } = await Auth.fetchUser().toPromise();
			store.dispatch(fetchUserSuccess(response));
			return true;
		} catch (error) {
			return false;
		}
	}

	static isSystemUser() {
		const state = store.getState();
		return state.auth.user_type === "system";
	}

	static getProfile() {
		return Api.get("/user/profile");
	}
}
