/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../helpers";
import { RoutePaths } from "../../../constants";
import ItemMenu from "./ItemMenu";
import { FaIcon } from "../../../components/FaIcon";
import { ParentMenu } from "./ParentMenu";
import { Permissions } from "../../../constants/permissions";

export function SystemMenuList() {
	return (
		<>
			{/* begin::Menu Nav */}
			<ul className={`menu-nav`}>
				<ParentMenu
					icon={
						<span className="menu-icon">
							<SVG src={toAbsoluteUrl("/media/svg/ship.svg")} />
						</span>
					}
					name="Tàu"
					to={RoutePaths.Ship.Index}
					permission={Permissions.Pages.ShipSchedule.Main}
					showWithUser
				>
					<ItemMenu
						to={RoutePaths.Ship.Schedule}
						icon={
							<span className="menu-icon">
								<FaIcon icon="calendar-alt" />
							</span>
						}
						name="Lịch tàu"
						// permission={Permissions.Pages.ShipSchedule.Main}
						showWithUser
					/>
					{/*end::2 Level*/}

					{/*begin::2 Level*/}
					<ItemMenu
						to={RoutePaths.Ship.ListSchedule}
						icon={
							<span className="menu-icon">
								<FaIcon icon="calendar-plus" />
							</span>
						}
						name="Cập nhật lịch tàu"
						permission={Permissions.Pages.ShipSchedule.Create}
					/>
				</ParentMenu>

				{/*begin::1 Level*/}

				{/* Booking */}
				<ParentMenu
					icon={
						<span className="menu-icon">
							<FaIcon icon="anchor" />
						</span>
					}
					to={RoutePaths.Action.Index}
					name="Hoạt động"
					showWithUser
				>
					<ItemMenu
						to={RoutePaths.Action.BookingNote}
						// icon={
						//   <span className="menu-icon">
						//     <FaIcon icon="book" />
						//   </span>
						// }
						name="Booking Note"
						permission={Permissions.Pages.BookingNote.SaleConfirm}
						exact
						showWithUser
					/>
					{/*end::2 Level*/}

					{/*begin::2 Level*/}
					<ItemMenu
						to={RoutePaths.Action.Booking}
						// icon={
						//   <span className="menu-icon">
						//     <FaIcon icon="bolt" />
						//   </span>
						// }
						name="Booking"
						permission={Permissions.Pages.Booking.Main}
						exact
					/>
					<ItemMenu
						to={RoutePaths.Action.Quotation}
						// icon={
						//   <span className="menu-icon">
						//     <FaIcon icon="book" />
						//   </span>
						// }
						name="Báo giá"
						permission={Permissions.Pages.Quotations.Main}
					/>
					<ItemMenu
						to={RoutePaths.Action.PriceApproval}
						// icon={
						//   <span className="menu-icon">
						//     <FaIcon group="fab" icon="codiepie" />
						//   </span>
						// }
						name="Duyệt giá đặc biệt"
						permission={Permissions.Pages.Quotations.Main}
					/>

					<ItemMenu
						to={RoutePaths.Action.ManageBill}
						// icon={
						//   <span className="menu-icon">
						//     <FaIcon icon="book" />
						//   </span>
						// }
						name="Quản lý bill"
						permission={Permissions.Pages.BiiLadings.Main}
					/>
					<ItemMenu
						to={RoutePaths.Action.DebitNoteAndCheckout}
						// icon={
						//   <span className="menu-icon">
						//     <FaIcon icon="book" />
						//   </span>
						// }
						name="Debit note & thanh toán"
						permission={Permissions.Pages.BiiLadings.Main}
					/>

					<ItemMenu
						to={RoutePaths.Action.CreateBillFromBooking}
						// icon={
						//   <span className="menu-icon">
						//     <FaIcon icon="bolt" />
						//   </span>
						// }
						name="Tạo bill từ booking"
						permission={Permissions.Pages.BiiLadings.Main}
					/>
				</ParentMenu>

				{/* Danh Mục */}
				<ParentMenu
					icon={
						<span className="svg-icon menu-icon">
							<SVG
								src={toAbsoluteUrl("/media/svg/icons/Layout/Layout-grid.svg")}
							/>
						</span>
					}
					to={RoutePaths.Common.Categories.Index}
					name="Danh mục"
					permission={Permissions.Pages.Common.Categories.Main}
				>
					{/*begin::2 Level*/}
					<ItemMenu
						to={RoutePaths.Common.Categories.Currencys}
						name="Danh mục tiền tệ"
						permission={Permissions.Pages.Common.Categories.Currencys.Main}
					/>
					{/*end::2 Level*/}
					{/*begin::2 Level*/}
					<ItemMenu
						to={RoutePaths.Common.Categories.CurrencyConversion}
						name="Danh mục quy đổi tiền tệ"
						permission={
							Permissions.Pages.Common.Categories.CurrencyConVersions.Main
						}
					/>
					{/*end::2 Level*/}
					{/*begin::2 Level*/}
					<ItemMenu
						to={RoutePaths.Common.Categories.Vessels}
						name="Danh mục tàu"
						permission={Permissions.Pages.Common.Categories.Vessels.Main}
					/>
					{/*end::2 Level*/}
					{/*begin::2 Level*/}
					<ItemMenu
						to={RoutePaths.Common.Categories.Ports}
						name="Danh mục cảng"
						permission={Permissions.Pages.Common.Categories.Ports.Main}
					/>
					{/*end::2 Level*/}
					{/*begin::2 Level*/}
					<ItemMenu
						to={RoutePaths.Common.Categories.Terminals}
						name="Danh mục đầu cảng"
						permission={Permissions.Pages.Common.Categories.Terminals.Main}
					/>
					{/*end::2 Level*/}
					{/*begin::2 Level*/}
					<ItemMenu
						to={RoutePaths.Common.Categories.ChargeTypes}
						name="Danh mục kiểu phí"
						permission={Permissions.Pages.Common.Categories.ChargeTypes.Main}
					/>
					{/*end::2 Level*/}
					{/*begin::2 Level*/}
					<ItemMenu
						to={RoutePaths.Common.Categories.ChargeGroups}
						name="Danh mục nhóm phí"
						permission={Permissions.Pages.Common.Categories.ChargeGroups.Main}
					/>
					{/*end::2 Level*/}
					{/*begin::2 Level*/}
					<ItemMenu
						to={RoutePaths.Common.Categories.Charges}
						name="Danh mục phí"
						permission={Permissions.Pages.Common.Categories.Charges.Main}
					/>
					{/*end::2 Level*/}
					{/*begin::2 Level*/}
					<ItemMenu
						to={RoutePaths.Common.Categories.SizeContainers}
						name="Danh mục kích cỡ Container"
						permission={Permissions.Pages.Common.Categories.SizeContainers.Main}
					/>
					{/*end::2 Level*/}
					{/*begin::2 Level*/}
					<ItemMenu
						to={RoutePaths.Common.Categories.TypeOfContainers}
						name="Danh mục kiểu Container"
						permission={
							Permissions.Pages.Common.Categories.TypeOfContainers.Main
						}
					/>
					{/*end::2 Level*/}
					{/*begin::2 Level*/}
					<ItemMenu
						to={RoutePaths.Common.Categories.Terms}
						name="Danh mục hình thức vận chuyển"
						permission={Permissions.Pages.Common.Categories.Terms.Main}
					/>
					{/*end::2 Level*/}
					{/*begin::2 Level*/}
					<ItemMenu
						to={RoutePaths.Common.Categories.Merchants}
						name="Danh mục đối tượng"
						permission={Permissions.Pages.Common.Categories.Merchants.Main}
					/>
					{/*end::2 Level*/}
					{/*begin::2 Level*/}
					<ItemMenu
						to={RoutePaths.Common.Categories.Commoditys}
						name="Danh mục loại hàng"
						permission={Permissions.Pages.Common.Categories.Commoditys.Main}
					/>
					{/*end::2 Level*/}
					{/*begin::2 Level*/}
					<ItemMenu
						to={RoutePaths.Common.Categories.Fee}
						name="Danh mục bảng giá"
						permission={Permissions.Pages.Common.Categories.Fee.Main}
					/>
					{/*end::2 Level*/}
					{/*begin::2 Level*/}

					{/*end::2 Level*/}
				</ParentMenu>
				{/*end::1 Level*/}

				{/* Quản Trị */}
				<ParentMenu
					icon={
						<span className="svg-icon menu-icon">
							<SVG
								src={toAbsoluteUrl(
									"/media/svg/icons/General/Shield-protected.svg"
								)}
							/>
						</span>
					}
					to={RoutePaths.Administration.Index}
					name="Quản trị"
					permission={Permissions.Pages.Administration.Main}
				>
					<ItemMenu
						to={RoutePaths.Administration.Unit}
						name="Danh mục đơn vị cơ sở"
						permission={Permissions.Pages.Administration.Units.Main}
					/>
					<ItemMenu
						to={RoutePaths.Administration.Office}
						name="Danh mục văn phòng"
						permission={Permissions.Pages.Administration.Deparments.Main}
					/>
					{/* <ItemMenu to={undefined} name="Danh mục đối tượng" /> */}
					<ItemMenu
						to={RoutePaths.Administration.User}
						name="Danh sách người dùng"
						permission={Permissions.Pages.Administration.Users.Main}
					/>
					<ItemMenu
						to={RoutePaths.Administration.Role}
						name="Danh mục vai trò"
						permission={Permissions.Pages.Administration.Roles.Main}
					/>
					<ItemMenu
						to={RoutePaths.Administration.ConfigApprove}
						name="Cấu hình tham số báo giá"
						permission={Permissions.Pages.Common.Categories.Commoditys.Main}
					/>
					<ItemMenu
						to={undefined}
						name="Danh mục tham số"
						permission={Permissions.Pages.Administration.Parameters.Main}
					/>
					<ItemMenu
						to={undefined}
						name="Lịch sử"
						permission={Permissions.Pages.Administration.Hitories.Main}
					/>
					<ItemMenu
						to={undefined}
						name="Danh mục mẫu Email"
						permission={Permissions.Pages.Administration.EmailTempates.Main}
					/>
				</ParentMenu>

				{/*end::1 Level*/}
			</ul>
			{/* end::Menu Nav */}
		</>
	);
}
