/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { checkIsActive } from "../../../helpers";
import { notify } from "../../../helpers/notify";
import { Auth } from "../../../services/auth";
import { connect } from "react-redux";

export default connect((state: any) => ({
	permissions: state.auth.permissions,
}))(function (props: {
	name: string;
	to?: string;
	icon?: JSX.Element;
	permission?: string;
	permissions: any[];
	exact?: boolean;
	showWithUser?: boolean;
}) {
	const { name, to, icon, permission, exact, showWithUser } = props;
	const [hasPermission, setHasPermission] = useState<boolean>(false);
	const location = useLocation();
	const getMenuItemActive = (url?: any, hasSubmenu = false) => {
		return checkIsActive(location, url, exact)
			? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
			: "";
	};

	useEffect(() => {
		if (showWithUser && !Auth.isSystemUser()) {
			setHasPermission(true);
			return;
		}

		if (permission) {
			setHasPermission(Auth.hasPermission(permission));
			return;
		}
		setHasPermission(true);
	}, [props.permissions, permission, showWithUser]);

	const alertCommingSoon = (event: any) => {
		event.preventDefault();
		notify("Comming soon!", {
			position: "top-left",
		});
	};

	return (
		<>
			{hasPermission && (
				<li
					className={`menu-item ${getMenuItemActive(to)}`}
					aria-haspopup="true"
				>
					{to ? (
						<NavLink className="menu-link" to={to}>
							{icon || (
								<i className="menu-bullet menu-bullet-dot">
									<span />
								</i>
							)}
							<span className="menu-text">{name}</span>
						</NavLink>
					) : (
						<a onClick={alertCommingSoon} href="/#" className="menu-link">
							{icon || (
								<i className="menu-bullet menu-bullet-dot">
									<span />
								</i>
							)}
							<span className="menu-text">{name}</span>
						</a>
					)}
				</li>
			)}
		</>
	);
});
