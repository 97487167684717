import { Api } from "../api";
import { SizeContainer } from "../../models/SizeContainer";

export const getAllSizeContainer = (
  name: string = "",
  page: number,
  size: number,
  status: string = ""
) => {
  return Api.get("/sizecontainerlist/getAll", { name, page, size, status });
};

export const addSizeContainer = (data: SizeContainer) => {
  return Api.post("/sizecontainerlist/createOrEdit", { ...data });
};

export const getOneSizeContainer = (id: number) => {
  return Api.get("/sizecontainerlist/createOrEdit", { id });
};

export const deleteOneSizeContainer = (data: any) => {
  return Api.get("/sizecontainerlist/delete", { ...data });
};
