import React, { lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { DashboardLayout } from "../layouts/DashboardLayout";
import { RoutePaths, DefaultRoute } from "../constants";
import { AuthorizationRoute } from "../components/Route/AuthorizationRoute";
import { Permissions } from "../constants/permissions";
import { connect } from "react-redux";
import { Auth } from "../services/auth";

const ChooseUnit = lazy(() => import("../pages/auth/ChooseUnit"));

const DashboardIndex = lazy(() => import("../pages/DashboardIndex"));

// Ship pages
const Ship = lazy(() => import("../pages/ship"));
const ListShip = lazy(() => import("../pages/ship/ListShipSchedule"));

// Notifications
const Notifications = lazy(
	() => import("../pages/notifications/index")
);

// Action pages
const MyBookingNote = lazy(
	() => import("../pages/action/BookingNote/Customer/MyBookingNote/index")
);
const BookingNote = lazy(
	() => import("../pages/action/BookingNote/System/ListBookingNote")
);
const CreateBookingNote = lazy(
	() => import("../pages/action/BookingNote/Customer/CreateBookingNote/index")
);
const BookingNoteDetail = lazy(
	() => import("../pages/action/BookingNote/components/BookingNoteDetail")
);
const Booking = lazy(() => import("../pages/action/Booking/index"));
const Quotation = lazy(() => import("../pages/action/Quotation/index"));
const QuotationSpecail = lazy(() => import("../pages/action/QuotationSpecial"));
const ManageBill = lazy(() => import("../pages/action/ManageBill"));
const CreateBillFromBooking = lazy(
	() => import("../pages/action/CreateBillFromBooking")
);

// Category pages
const Merchant = lazy(() => import("../pages/categories/merchants"));
const ChargeTypes = lazy(() => import("../pages/categories/chargeTypes"));
const ChargeGroups = lazy(() => import("../pages/categories/chargeGroups"));
const Charges = lazy(() => import("../pages/categories/charges"));
const Terms = lazy(() => import("../pages/categories/terms"));
const Commoditys = lazy(() => import("../pages/categories/commoditys"));
const Fee = lazy(() => import("../pages/categories/fee"));
const CurrencyConversions = lazy(
	() => import("../pages/categories/currencyConversions")
);
const Vessel = lazy(() => import("../pages/categories/vessels"));
const PortList = lazy(() => import("../pages/categories/ports"));
const Currency = lazy(() => import("../pages/categories/currencys"));
const TerminalList = lazy(() => import("../pages/categories/terminal"));
const SizeContainer = lazy(() => import("../pages/categories/sizeContainers"));
const TypeContainer = lazy(
	() => import("../pages/categories/typeOfContainers")
);

// Admin pages
const CategoryOffice = lazy(() => import("../pages/administration/Office"));
const CategoryUnit = lazy(() => import("../pages/administration/Unit"));
const Role = lazy(() => import("../pages/administration/Role"));
const User = lazy(() => import("../pages/administration/User"));
const ConfigApprove = lazy(
	() => import("../pages/administration/ConfigApprove")
);

interface Props {
	currentUnit: any;
	userType?: string;
}

const mapStateToProps = (state: any) => ({
	currentUnit: state.currentUnit,
});

export const BaseRoutes = connect(mapStateToProps)(({ currentUnit }: Props) => {
	return (
		<>
			{!currentUnit && Auth.isSystemUser() ? (
				<Switch>
					<Route
						path={RoutePaths.Auth.ChooseUnit}
						exact
						component={ChooseUnit}
					/>
					<Redirect to={DefaultRoute} />
				</Switch>
			) : null}

			{!currentUnit && Auth.isSystemUser() ? (
				<Redirect to={RoutePaths.Auth.ChooseUnit} />
			) : (
				<DashboardLayout>
					<Switch>
						<Route
							path={RoutePaths.Dashboard.Index}
							exact
							component={DashboardIndex}
						/>
						<Route
							path={RoutePaths.Notifications}
							exact
							component={Notifications}
						/>
						<AuthorizationRoute
							permission={Permissions.Pages.Administration.Deparments.Main}
							path={RoutePaths.Administration.Office}
							component={CategoryOffice}
						/>
						<AuthorizationRoute
							permission={Permissions.Pages.Administration.Units.Main}
							path={RoutePaths.Administration.Unit}
							component={CategoryUnit}
						/>
						<AuthorizationRoute
							permission={Permissions.Pages.Administration.Roles.Main}
							path={RoutePaths.Administration.Role}
							component={Role}
						/>
						<AuthorizationRoute
							permission={Permissions.Pages.Administration.Roles.Main}
							path={RoutePaths.Administration.ConfigApprove}
							component={ConfigApprove}
						/>
						<AuthorizationRoute
							permission={Permissions.Pages.Administration.Users.Main}
							path={RoutePaths.Administration.User}
							component={User}
						/>
						<AuthorizationRoute
							path={RoutePaths.Ship.Schedule}
							component={Ship}
						/>
						<AuthorizationRoute
							permission={Permissions.Pages.ShipSchedule.Main}
							path={RoutePaths.Ship.ListSchedule}
							component={ListShip}
						/>
						<AuthorizationRoute
							permission={Permissions.Pages.Common.Categories.Vessels.Main}
							path={RoutePaths.Common.Categories.Vessels}
							component={Vessel}
						/>
						<AuthorizationRoute
							permission={Permissions.Pages.Common.Categories.Ports.Main}
							path={RoutePaths.Common.Categories.Ports}
							component={PortList}
						/>
						<AuthorizationRoute
							permission={Permissions.Pages.Common.Categories.Currencys.Main}
							path={RoutePaths.Common.Categories.Currencys}
							component={Currency}
						/>
						<AuthorizationRoute
							permission={Permissions.Pages.Common.Categories.Terminals.Main}
							path={RoutePaths.Common.Categories.Terminals}
							component={TerminalList}
						/>
						<AuthorizationRoute
							permission={
								Permissions.Pages.Common.Categories.SizeContainers.Main
							}
							path={RoutePaths.Common.Categories.SizeContainers}
							component={SizeContainer}
						/>
						<AuthorizationRoute
							permission={
								Permissions.Pages.Common.Categories.TypeOfContainers.Main
							}
							path={RoutePaths.Common.Categories.TypeOfContainers}
							component={TypeContainer}
						/>

						<AuthorizationRoute
							permission={Permissions.Pages.Common.Categories.Merchants.Main}
							path={RoutePaths.Common.Categories.Merchants}
							component={Merchant}
						/>
						<AuthorizationRoute
							permission={Permissions.Pages.Common.Categories.ChargeTypes.Main}
							path={RoutePaths.Common.Categories.ChargeTypes}
							component={ChargeTypes}
						/>
						<AuthorizationRoute
							permission={Permissions.Pages.Common.Categories.ChargeGroups.Main}
							path={RoutePaths.Common.Categories.ChargeGroups}
							component={ChargeGroups}
						/>
						<AuthorizationRoute
							permission={Permissions.Pages.Common.Categories.Charges.Main}
							path={RoutePaths.Common.Categories.Charges}
							component={Charges}
						/>
						<AuthorizationRoute
							permission={Permissions.Pages.Common.Categories.Terms.Main}
							path={RoutePaths.Common.Categories.Terms}
							component={Terms}
						/>
						<AuthorizationRoute
							permission={Permissions.Pages.Common.Categories.Commoditys.Main}
							path={RoutePaths.Common.Categories.Commoditys}
							component={Commoditys}
						/>
						<AuthorizationRoute
							permission={Permissions.Pages.Common.Categories.Fee.Main}
							path={RoutePaths.Common.Categories.Fee}
							component={Fee}
						/>
						<AuthorizationRoute
							path={RoutePaths.Action.CreateBookingNote}
							permission={Permissions.Pages.BookingNote.Main}
							component={CreateBookingNote}
							showWithUser
						/>
						<AuthorizationRoute
							path={RoutePaths.Action.BookingNoteDetail}
							permission={Permissions.Pages.BookingNote.Main}
							component={BookingNoteDetail}
							showWithUser
						/>
						<AuthorizationRoute
							path={RoutePaths.Action.MyBookingNote}
							permission={Permissions.Pages.BookingNote.Main}
							component={MyBookingNote}
							showWithUser
						/>
						<AuthorizationRoute
							path={RoutePaths.Action.BookingNote}
							permission={Permissions.Pages.BookingNote.Main}
							component={BookingNote}
							showWithUser
						/>
						<AuthorizationRoute
							path={RoutePaths.Action.Booking}
							permission={Permissions.Pages.Booking.Main}
							component={Booking}
						/>
						<AuthorizationRoute
							path={RoutePaths.Action.Quotation}
							permission={Permissions.Pages.Quotations.Main}
							component={Quotation}
						/>
						<AuthorizationRoute
							path={RoutePaths.Action.PriceApproval}
							permission={Permissions.Pages.PriceApproval.Main}
							component={QuotationSpecail}
						/>
						<AuthorizationRoute
							path={RoutePaths.Action.ManageBill}
							permission={Permissions.Pages.BiiLadings.Main}
							component={ManageBill}
						/>
						<AuthorizationRoute
							path={RoutePaths.Action.CreateBillFromBooking}
							permission={Permissions.Pages.BiiLadings.Create}
							component={CreateBillFromBooking}
						/>
						<AuthorizationRoute
							path={RoutePaths.Common.Categories.CurrencyConversion}
							permission={
								Permissions.Pages.Common.Categories.CurrencyConVersions.Main
							}
							component={CurrencyConversions}
						/>
						<Redirect
							from={RoutePaths.Auth.ChooseUnit}
							exact
							to={DefaultRoute}
						/>
						<Redirect to={RoutePaths.NotFound} />
					</Switch>
				</DashboardLayout>
			)}
		</>
	);
});
