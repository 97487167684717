import { Action } from "../../../models/redux/action";
import { CurrentUnit } from "../../../models/CurrentUnit";
import { ReduxCallbacks } from "../../../models/redux/Callbacks";

export const SET_CURRENT_UNIT = "SET_CURRENT_UNIT";
export const SET_CURRENT_UNIT_SUCCESS = "SET_CURRENT_UNIT_SUCCESS";
export const SET_CURRENT_UNIT_FAILED = "SET_CURRENT_UNIT_FAILED";
export const REMOVE_CURRENT_UNIT = "REMOVE_CURRENT_UNIT";

export const setCurrentUnit = (
	unit: CurrentUnit,
	callbacks?: ReduxCallbacks
): Action => ({
	type: SET_CURRENT_UNIT,
	payload: { unit, callbacks },
});

export const setCurrentUnitSuccess = (unit: CurrentUnit): Action => ({
	type: SET_CURRENT_UNIT_SUCCESS,
	payload: unit,
});

export const setCurrentUnitFailed = (error: any): Action => ({
	type: SET_CURRENT_UNIT_FAILED,
	payload: error,
});

export const removeCurrentUnit = (unit: CurrentUnit): Action => ({
	type: REMOVE_CURRENT_UNIT,
	payload: unit,
});
