import { Api } from "../api";
import { deleteOffice } from "../../models/deleteOffice";
import { addOffice } from "../../models/addOffice";

export const getAllOfficeService = (page: number, size: number, filter?: any) => {
	return Api.get("/department/getAll", { page, size, name: filter?.name });
};

export const addOfficeSerive = (data: addOffice) => {
	return Api.post("/department/createOrEdit", { ...data });
};

export const getOneOffice = (id: number) => {
	return Api.get("/department/createOrEdit", { id });
};
export const getDepartmentsByUnitId = (unitId: number) => {
	return Api.get("/department/getDepartmentsByUnitId", { unitId });
};

export const deleteOfficeService = (data: deleteOffice) => {
	return Api.get("/department/delete", { ...data });
};
