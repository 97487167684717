import { Action } from "../../../models/redux/action";
import { ReduxCallbacks } from "../../../models/redux/Callbacks";
import { Commodity } from "../../../models/Commodity";
import { ListQuery } from "../../../models/redux/ListQuery";
import { AjaxResponse } from "rxjs/ajax";

export const FETCH_LIST_COMMODITY = "FETCH_LIST_COMMODITY";
export const FETCH_LIST_COMMODITY_SUCCESS = "FETCH_LIST_COMMODITY_SUCCESS";
export const FETCH_LIST_COMMODITY_FAILED = "FETCH_LIST_COMMODITY_FAILED";
export const FETCH_LIST_COMMODITY_CANCELLED = "FETCH_LIST_COMMODITY_CANCELLED";

export const fetchListCommodity = (
  query: ListQuery,
  callbacks?: ReduxCallbacks
): Action => ({
  type: FETCH_LIST_COMMODITY,
  payload: { query, callbacks },
});

export const fetchListCommoditySuccess = ({
  response,
}: AjaxResponse): Action => ({
  type: FETCH_LIST_COMMODITY_SUCCESS,
  payload: { response },
});

export const fetchListCommodityFailed = (error: any): Action => ({
  type: FETCH_LIST_COMMODITY_FAILED,
  payload: error,
});

export const fetchListCommodityCancelled = (): Action => ({
  type: FETCH_LIST_COMMODITY_CANCELLED,
});

// ADD COMMODITY
export const ADD_COMMODITY = "ADD_COMMODITY";
export const ADD_COMMODITY_SUCCESS = "ADD_COMMODITY_SUCCESS";
export const ADD_COMMODITY_FAILED = "ADD_COMMODITY_FAILED";
export const ADD_COMMODITY_CANCELLED = "ADD_COMMODITY_CANCELLED";

export const addCommodity = (
  commodity: Commodity,
  callbacks?: ReduxCallbacks
): Action => ({
  type: ADD_COMMODITY,
  payload: { commodity, callbacks },
});

export const addCommoditySuccess = (): Action => ({
  type: ADD_COMMODITY_SUCCESS,
});

export const addCommodityFailed = (): Action => ({
  type: ADD_COMMODITY_FAILED,
});

export const addCommodityCancelled = (): Action => ({
  type: ADD_COMMODITY_CANCELLED,
});

//EDIT COMMODITY
export const EDIT_COMMODITY = "EDIT_COMMODITY";
export const EDIT_COMMODITY_SUCCESS = "EDIT_COMMODITY_SUCCESS";
export const EDIT_COMMODITY_FAILED = "EDIT_COMMODITY_FAILED";
export const EDIT_COMMODITY_CANCELLED = "EDIT_COMMODITY_CANCELLED";

export const editCommodity = (
  content: Commodity,
  callbacks?: ReduxCallbacks
) => ({
  type: EDIT_COMMODITY,
  payload: { ...content, callbacks },
});

export const editCommoditySuccess = () => ({
  type: EDIT_COMMODITY_SUCCESS,
});

export const editCommodityFailed = (error: any) => ({
  type: EDIT_COMMODITY_FAILED,
  payload: error,
});
// DELETE COMMODITY
export const DELETE_COMMODITY = "DELETE_COMMODITY";
export const DELETE_COMMODITY_SUCCESS = "DELETE_COMMODITY_SUCCESS";
export const DELETE_COMMODITY_FAILED = "DELETE_COMMODITY_FAILED";
export const DELETE_COMMODITY_CANCELLED = "DELETE_COMMODITY_CANCELLED";

export const deleteCommodity = (
  content: Commodity,
  callbacks?: ReduxCallbacks
): Action => ({
  type: DELETE_COMMODITY,
  payload: { ...content, callbacks },
});

export const deleteCommoditySuccess = (): Action => ({
  type: DELETE_COMMODITY_SUCCESS,
});

export const deleteCommodityFailed = (): Action => ({
  type: DELETE_COMMODITY_FAILED,
});

export const deleteCommodityCancelled = (): Action => ({
  type: DELETE_COMMODITY_CANCELLED,
});
