import { ListQuery } from "../../../models/redux/ListQuery";
import { ReduxCallbacks } from "../../../models/redux/Callbacks";
import { SizeContainer } from "../../../models/SizeContainer";

export const FETCH_SIZECONTAINER_INIT = "FETCH_SIZECONTAINER_INIT";
export const FETCH_SIZECONTAINER_SUCCESS = "FETCH_SIZECONTAINER_SUCCESS";
export const FETCH_SIZECONTAINER_FAILED = "FETCH_SIZECONTAINER_FAILED";
export const FETCH_SIZECONTAINER_CANCELLED = "FETCH_SIZECONTAINER_CANCELLED";

export const ADD_SIZECONTAINER_INIT = "ADD_SIZECONTAINER_INIT";
export const ADD_SIZECONTAINER_SUCCESS = "ADD_SIZECONTAINER_SUCCESS";
export const ADD_SIZECONTAINER_FAILED = "ADD_SIZECONTAINER_FAILED";

export const EDIT_SIZECONTAINER_INIT = "EDIT_SIZECONTAINER_INIT";
export const EDIT_SIZECONTAINER_SUCCESS = "EDIT_SIZECONTAINER_SUCCESS";
export const EDIT_SIZECONTAINER_FAILED = "EDIT_SIZECONTAINER_FAILED";

export const DELETE_SIZECONTAINER_INIT = "DELETE_SIZECONTAINER_INIT";
export const DELETE_SIZECONTAINER_SUCCESS = "DELETE_SIZECONTAINER_SUCCESS";
export const DELETE_SIZECONTAINER_FAILED = "DELETE_SIZECONTAINER_FAILED";

export const fetchSizeContainerInit = (
  query: ListQuery,
  callbacks?: ReduxCallbacks
) => ({
  type: FETCH_SIZECONTAINER_INIT,
  payload: { query, callbacks },
});

// FETCH

export const fetchSizeContainerSuccess = (response: any) => ({
  type: FETCH_SIZECONTAINER_SUCCESS,
  payload: response,
});

export const fetchSizeContainerFailed = (error: any) => ({
  type: FETCH_SIZECONTAINER_FAILED,
  payload: error,
});

export const fetchSizeContainerCancelled = () => ({
  type: FETCH_SIZECONTAINER_CANCELLED,
});

// ADD

export const addSizeContainerInit = (
  content: SizeContainer,
  callbacks?: ReduxCallbacks
) => ({
  type: ADD_SIZECONTAINER_INIT,
  payload: { ...content, callbacks },
});

export const addSizeContainerSuccess = () => ({
  type: ADD_SIZECONTAINER_SUCCESS,
});

export const addSizeContainerFailed = (error: any) => ({
  type: ADD_SIZECONTAINER_FAILED,
  payload: error,
});

// EDIT

export const editSizeContainerInit = (
  content: SizeContainer,
  callbacks?: ReduxCallbacks
) => ({
  type: EDIT_SIZECONTAINER_INIT,
  payload: { ...content, callbacks },
});

export const editSizeContainerSuccess = () => ({
  type: EDIT_SIZECONTAINER_SUCCESS,
});

export const editSizeContainerFailed = (error: any) => ({
  type: EDIT_SIZECONTAINER_FAILED,
  payload: error,
});

// DELETE

export const deleteSizeContainerInit = (
  content: SizeContainer,
  callbacks?: ReduxCallbacks
) => {
  return {
    type: DELETE_SIZECONTAINER_INIT,
    payload: { ...content, callbacks },
  };
};

export const deleteSizeContainerSuccess = () => ({
  type: DELETE_SIZECONTAINER_SUCCESS,
});

export const deleteSizeContainerFailed = (error: any) => ({
  type: DELETE_SIZECONTAINER_FAILED,
  payload: error,
});
