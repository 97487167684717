import { ListQuery } from "../../models/redux/ListQuery";
import { Api } from "../api";
import { Address } from "../../models/Address";

export const getAllAddress = (query: ListQuery) =>
	Api.get("/address/getAll", {
		page: query.page,
		size: query.per_page,
		merchantId: query.filter?.merchantId,
	});

export const createOrEditAddress = (address: Address) =>
	Api.post("/address/createOrEdit", address);

export const getOneAddress = (id: number) =>
	Api.get("/address/createOrEdit", { id });

export const deleteAddress = (addressId: number) =>
	Api.get("/address/delete", { addressId });
