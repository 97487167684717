import { ListQuery } from "../../../models/redux/ListQuery";
import { ReduxCallbacks } from "../../../models/redux/Callbacks";
import { Fee } from "../../../models/Fee";

export const FETCH_FEE_INIT = "FETCH_FEE_INIT";
export const FETCH_FEE_SUCCESS = "FETCH_FEE_SUCCESS";
export const FETCH_FEE_FAILED = "FETCH_FEE_FAILED";
export const FETCH_FEE_CANCELLED = "FETCH_FEE_CANCELLED";

export const ADD_FEE_INIT = "ADD_FEE_INIT";
export const ADD_FEE_SUCCESS = "ADD_FEE_SUCCESS";
export const ADD_FEE_FAILED = "ADD_FEE_FAILED";

export const EDIT_FEE_INIT = "EDIT_FEE_INIT";
export const EDIT_FEE_SUCCESS = "EDIT_FEE_SUCCESS";
export const EDIT_FEE_FAILED = "EDIT_FEE_FAILED";

export const DELETE_FEE_INIT = "DELETE_FEE_INIT";
export const DELETE_FEE_SUCCESS = "DELETE_FEE_SUCCESS";
export const DELETE_FEE_FAILED = "DELETE_FEE_FAILED";

export const fetchFeeInit = (query: ListQuery, callbacks?: ReduxCallbacks) => ({
  type: FETCH_FEE_INIT,
  payload: { query, callbacks },
});

// FETCH

export const fetchFeeSuccess = (response: any) => ({
  type: FETCH_FEE_SUCCESS,
  payload: response,
});

export const fetchFeeFailed = (error: any) => ({
  type: FETCH_FEE_FAILED,
  payload: error,
});

export const fetchFeeCancelled = () => ({
  type: FETCH_FEE_CANCELLED,
});

// ADD

export const addFeeInit = (fee: Fee, callbacks?: ReduxCallbacks) => ({
  type: ADD_FEE_INIT,
  payload: { fee, callbacks },
});

export const addFeeSuccess = () => ({
  type: ADD_FEE_SUCCESS,
});

export const addFeeFailed = (error: any) => ({
  type: ADD_FEE_FAILED,
  payload: error,
});

// EDIT

export const editFeeInit = (fee: Fee, callbacks?: ReduxCallbacks) => ({
  type: EDIT_FEE_INIT,
  payload: { fee, callbacks },
});

export const editFeeSuccess = () => ({
  type: EDIT_FEE_SUCCESS,
});

export const editFeeFailed = (error: any) => ({
  type: EDIT_FEE_FAILED,
  payload: error,
});

// DELETE

export const deleteFeeInit = (content: Fee, callbacks?: ReduxCallbacks) => {
  return {
    type: DELETE_FEE_INIT,
    payload: { ...content, callbacks },
  };
};

export const deleteFeeSuccess = () => ({
  type: DELETE_FEE_SUCCESS,
});

export const deleteFeeFailed = (error: any) => ({
  type: DELETE_FEE_FAILED,
  payload: error,
});
