import { Action } from "../../../models/redux/action";
import { mergeMap, map, catchError, takeUntil } from "rxjs/operators";
// import { Api } from "../../../api";
import {
	FETCH_UNIT_INIT,
	fetchUnitSuccess,
	fetchUnitFailed,
	FETCH_UNIT_CANCELLED,
	ADD_UNIT,
	addUnitSuccess,
	addUnitFailed,
	ADD_UNIT_CANCELLED,
	fetchUnitInit,
	DELETE_UNIT,
	deleteUnitSuccess,
	DELETE_UNIT_CANCELLED,
	deleteUnitFailed,
} from "./actions";
import { ActionsObservable, ofType } from "redux-observable";
import { of } from "rxjs";
import { CANCEL_ALL_REQUEST } from "../../default";
import { getAllUnit, addNewUnit, deleteUnit } from "../../../api/unit";
import { onEpicSuccess, onEpicFailed } from "../../../helpers/reduxEpic";

export const fetchListUnitEpic = (action$: ActionsObservable<Action>) => {
	return action$.pipe(
		ofType(FETCH_UNIT_INIT),
		mergeMap((action) =>
			getAllUnit(action.payload.query.page, action.payload.query.per_page, action.payload.query.filter).pipe(
				map((response) =>
					onEpicSuccess(action, response, fetchUnitSuccess(response))
				),
				catchError((error) =>
					of(onEpicFailed(action, error, fetchUnitFailed(error)))
				),
				takeUntil(
					action$.pipe(ofType(FETCH_UNIT_CANCELLED, CANCEL_ALL_REQUEST))
				)
			)
		)
	);
};

export const addUnitEpic = (action$: ActionsObservable<Action>, state$: any) =>
	action$.pipe(
		ofType(ADD_UNIT),
		mergeMap((action) =>
			addNewUnit(action.payload.unit).pipe(
				mergeMap(({ response }) =>
					onEpicSuccess(
						action,
						response,
						of(
							addUnitSuccess(response),
							fetchUnitInit({
								page: state$.value.unit.query.page,
								per_page: state$.value.unit.query.per_page,
							})
						)
					)
				),
				catchError((error) => of(addUnitFailed(error))),
				takeUntil(action$.pipe(ofType(ADD_UNIT_CANCELLED, CANCEL_ALL_REQUEST)))
			)
		)
	);

export const deleteUnitEpic = (
	action$: ActionsObservable<Action>,
	state$: any
) =>
	action$.pipe(
		ofType(DELETE_UNIT),
		mergeMap((action) =>
			deleteUnit(action.payload.unitId).pipe(
				mergeMap(({ response }) =>
					onEpicSuccess(
						action,
						response,
						of(
							deleteUnitSuccess(response),
							fetchUnitInit({
								page: state$.value.unit.query.page,
								per_page: state$.value.unit.query.per_page,
							})
						)
					)
				),
				catchError((error) =>
					of(onEpicFailed(action, error, deleteUnitFailed(error)))
				),
				takeUntil(
					action$.pipe(ofType(DELETE_UNIT_CANCELLED, CANCEL_ALL_REQUEST))
				)
			)
		)
	);
