import { ListState } from "../../../models/redux/listState";
import { Commodity } from "../../../models/Commodity";
import { Action } from "../../../models/redux/action";
import {
	FETCH_LIST_COMMODITY_SUCCESS,
	FETCH_LIST_COMMODITY_FAILED,
	FETCH_LIST_COMMODITY,
} from "./actions";
import {
	fetchListStateSuccess,
	fetchListStateFailed,
	baseFetchListState,
	fetchListState,
} from "../../../helpers/reduxAction";

const initState: ListState<Commodity> = baseFetchListState();

export const commodityReducer = (
	state: ListState<Commodity> = initState,
	action: Action
) => {
	switch (action.type) {
		case FETCH_LIST_COMMODITY:
			return fetchListState(action);
		case FETCH_LIST_COMMODITY_SUCCESS:
			return fetchListStateSuccess(action, state);
		case FETCH_LIST_COMMODITY_FAILED:
			return fetchListStateFailed(action, state);
		default:
			return state;
	}
};
