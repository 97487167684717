import React from "react";
import { AsideMenuList } from "./MenuList";

export const AsideMenu = ({ disableScroll }: any) => {
	return (
		<>
			{/* begin::Menu Container */}
			<div
				id="kt_aside_menu"
				data-menu-vertical="1"
				className={`aside-menu my-4 scroll ps ps--active-y`}
				data-menu-scroll="1"
			>
				<AsideMenuList />
			</div>
			{/* end::Menu Container */}
		</>
	);
};
