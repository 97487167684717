import { Action } from "../../../models/redux/action";
import { mergeMap, map, catchError, takeUntil } from "rxjs/operators";
import {
	FETCH_VESSEL_INIT,
	fetchVesselSuccess,
	fetchVesselFailed,
	FETCH_VESSEL_CANCELLED,
	ADD_VESSEL_INIT,
	addVesselFailed,
	EDIT_VESSEL_INIT,
	editVesselFailed,
	DELETE_VESSEL_INIT,
	fetchVesselInit,
} from "./actions";
import { ActionsObservable, ofType } from "redux-observable";
import { of } from "rxjs";
import { CANCEL_ALL_REQUEST } from "../../default";
import { onEpicSuccess, onEpicFailed } from "../../../helpers/reduxEpic";
import { getAllVessel, addVessel, deleteOneVessel } from "../../../api/vessel";

export const fetchListVesselEpic = (action$: ActionsObservable<Action>) => {
	return action$.pipe(
		ofType(FETCH_VESSEL_INIT),
		mergeMap((action) =>
			getAllVessel(action.payload.query).pipe(
				map((response) =>
					onEpicSuccess(action, response, fetchVesselSuccess(response))
				),
				catchError((error) =>
					of(onEpicFailed(action, error, fetchVesselFailed(error)))
				),
				takeUntil(
					action$.pipe(ofType(FETCH_VESSEL_CANCELLED, CANCEL_ALL_REQUEST))
				)
			)
		)
	);
};

export const addVesselEpic = (
	action$: ActionsObservable<Action>,
	state$: any
) => {
	return action$.pipe(
		ofType(ADD_VESSEL_INIT),
		mergeMap((action) =>
			addVessel(action.payload.vessel).pipe(
				map((response) => {
					return onEpicSuccess(
						action,
						response,
						fetchVesselInit({
							page: state$.value.vessel.query.page,
							per_page: state$.value.vessel.query.per_page,
						})
					);
				}),
				catchError((error) =>
					of(onEpicFailed(action, error, addVesselFailed(error)))
				),
				takeUntil(action$.pipe(ofType(CANCEL_ALL_REQUEST)))
			)
		)
	);
};

export const editVesselEpic = (
	action$: ActionsObservable<Action>,
	state$: any
) => {
	return action$.pipe(
		ofType(EDIT_VESSEL_INIT),
		mergeMap((action) =>
			addVessel(action.payload.vessel).pipe(
				map((response) => {
					return onEpicSuccess(
						action,
						response,
						fetchVesselInit({
							page: state$.value.vessel.query.page,
							per_page: state$.value.vessel.query.per_page,
						})
					);
				}),
				catchError((error) =>
					of(onEpicFailed(action, error, editVesselFailed(error)))
				),
				takeUntil(action$.pipe(ofType(CANCEL_ALL_REQUEST)))
			)
		)
	);
};

export const deleteVesselEpic = (
	action$: ActionsObservable<Action>,
	state$: any
) => {
	return action$.pipe(
		ofType(DELETE_VESSEL_INIT),
		mergeMap((action) =>
			deleteOneVessel(action.payload).pipe(
				map((response) => {
					return onEpicSuccess(
						action,
						response,
						fetchVesselInit({
							page: state$.value.vessel.query.page,
							per_page: state$.value.vessel.query.per_page,
						})
					);
				}),
				catchError((error) =>
					of(onEpicFailed(action, error, editVesselFailed(error)))
				),
				takeUntil(action$.pipe(ofType(CANCEL_ALL_REQUEST)))
			)
		)
	);
};
