import { ListState } from "../../../models/redux/listState";
import { User } from "../../../models/User";
import { Action } from "../../../models/redux/action";
import {
	FETCH_USER_INIT,
	FETCH_USER_SUCCESS,
	FETCH_USER_FAILED, FETCH_NOTIFICATIONS_INIT, FETCH_NOTIFICATIONS_SUCCESS, FETCH_NOTIFICATIONS_FAILED,
} from "./actions";
import {
	fetchListState,
	fetchListStateSuccess,
	fetchListStateFailed,
	baseFetchListState,
} from "../../../helpers/reduxAction";

const initState: ListState<User> = baseFetchListState();
const notificationState: ListState<Notification> = baseFetchListState();

export const userReducer = (
	state: ListState<User> = initState,
	action: Action
) => {
	switch (action.type) {
		case FETCH_USER_INIT:
			return fetchListState(action);

		case FETCH_USER_SUCCESS:
			return fetchListStateSuccess(action, state);

		case FETCH_USER_FAILED:
			return fetchListStateFailed(action, state);

		default:
			return state;
	}
};

export const notificationReducer = (
	state: ListState<Notification> = notificationState,
	action: Action
) => {
	switch (action.type) {
		case FETCH_NOTIFICATIONS_INIT:
			return fetchListState(action);

		case FETCH_NOTIFICATIONS_SUCCESS:
			return fetchListStateSuccess(action, state);

		case FETCH_NOTIFICATIONS_FAILED:
			return fetchListStateFailed(action, state);

		default:
			return state;
	}
};
