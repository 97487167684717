import React, { FunctionComponent, useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { checkIsActive } from "../../../helpers";
import { Auth } from "../../../services/auth";
import { connect } from "react-redux";

interface Props {
	to?: string;
	name: string;
	icon?: JSX.Element;
	permission?: string;
	children: any;
	permissions: any[];
	showWithUser?: boolean;
}

export const ParentMenu = connect((state: any) => ({
	permissions: state.auth.permissions,
}))(
	({
		to,
		icon,
		children,
		name,
		permission,
		permissions,
		showWithUser,
	}: Props) => {
		const [hasPermission, setHasPermission] = useState<boolean>(false);
		const location = useLocation();
		const getMenuItemActive = (url: any, hasSubmenu = false) => {
			return checkIsActive(location, url)
				? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
				: "";
		};

		useEffect(() => {
			if (showWithUser && !Auth.isSystemUser()) {
				setHasPermission(true);
				return;
			}

			if (permission) {
				setHasPermission(Auth.hasPermission(permission));
				return;
			}
			setHasPermission(true);
		}, [permissions, permission]);

		return (
			<>
				{hasPermission && (
					<li
						className={`menu-item menu-item-submenu ${getMenuItemActive(
							to,
							true
						)}`}
						aria-haspopup="true"
						data-menu-toggle="hover"
					>
						<NavLink className="menu-link menu-toggle" to={to || "#"}>
							{icon}
							<span className="menu-text">{name}</span>
							<i className="menu-arrow" />
						</NavLink>

						<div className="menu-submenu">
							<i className="menu-arrow" />
							<ul className="menu-subnav">{children}</ul>
						</div>
					</li>
				)}
			</>
		);
	}
);
