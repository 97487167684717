import { ListQuery } from "../../../models/redux/ListQuery";
import { ReduxCallbacks } from "../../../models/redux/Callbacks";
import { Port } from "../../../models/Port";

export const FETCH_PORT_INIT = "FETCH_PORT_INIT";
export const FETCH_PORT_SUCCESS = "FETCH_PORT_SUCCESS";
export const FETCH_PORT_FAILED = "FETCH_PORT_FAILED";
export const FETCH_PORT_CANCELLED = "FETCH_PORT_CANCELLED";

export const ADD_PORT_INIT = "ADD_PORT_INIT";
export const ADD_PORT_SUCCESS = "ADD_PORT_SUCCESS";
export const ADD_PORT_FAILED = "ADD_PORT_FAILED";

export const EDIT_PORT_INIT = "EDIT_PORT_INIT";
export const EDIT_PORT_SUCCESS = "EDIT_PORT_SUCCESS";
export const EDIT_PORT_FAILED = "EDIT_PORT_FAILED";

export const DELETE_PORT_INIT = "DELETE_PORT_INIT";
export const DELETE_PORT_SUCCESS = "DELETE_PORT_SUCCESS";
export const DELETE_PORT_FAILED = "DELETE_PORT_FAILED";

export const fetchListPort = (
  query: ListQuery,
  callbacks?: ReduxCallbacks
) => ({
  type: FETCH_PORT_INIT,
  payload: { query, callbacks },
});

// FETCH

export const fetchPortSuccess = (response: any) => ({
  type: FETCH_PORT_SUCCESS,
  payload: response,
});

export const fetchPortFailed = (error: any) => ({
  type: FETCH_PORT_FAILED,
  payload: error,
});

export const fetchPortCancelled = () => ({
  type: FETCH_PORT_CANCELLED,
});

// ADD

export const addPortInit = (content: Port, callbacks?: ReduxCallbacks) => ({
  type: ADD_PORT_INIT,
  payload: { ...content, callbacks },
});

export const addPortSuccess = () => ({
  type: ADD_PORT_SUCCESS,
});

export const addPortFailed = (error: any) => ({
  type: ADD_PORT_FAILED,
  payload: error,
});

// EDIT

export const editPortInit = (content: Port, callbacks?: ReduxCallbacks) => ({
  type: EDIT_PORT_INIT,
  payload: { ...content, callbacks },
});

export const editPortSuccess = () => ({
  type: EDIT_PORT_SUCCESS,
});

export const editPortFailed = (error: any) => ({
  type: EDIT_PORT_FAILED,
  payload: error,
});

// DELETE

export const deletePortInit = (content: Port, callbacks?: ReduxCallbacks) => {
  return {
    type: DELETE_PORT_INIT,
    payload: { ...content, callbacks },
  };
};

export const deletePortSuccess = () => ({
  type: DELETE_PORT_SUCCESS,
});

export const deletePortFailed = (error: any) => ({
  type: DELETE_PORT_FAILED,
  payload: error,
});
