import { ListState } from "../../../models/redux/listState";
import { TypeContainer } from "../../../models/TypeContainer";
import { Action } from "../../../models/redux/action";
import {
  FETCH_TYPECONTAINER_INIT,
  FETCH_TYPECONTAINER_SUCCESS,
  FETCH_TYPECONTAINER_FAILED,
  ADD_TYPECONTAINER_INIT,
  ADD_TYPECONTAINER_SUCCESS,
  ADD_TYPECONTAINER_FAILED,
  EDIT_TYPECONTAINER_INIT,
  EDIT_TYPECONTAINER_SUCCESS,
  EDIT_TYPECONTAINER_FAILED,
  DELETE_TYPECONTAINER_INIT,
  DELETE_TYPECONTAINER_SUCCESS,
  DELETE_TYPECONTAINER_FAILED,
} from "./actions";
import {
  fetchListState,
  fetchListStateSuccess,
  fetchListStateFailed,
  baseFetchListState,
  addInit,
  addFailed,
  addSuccess,
  deleteInit,
  deleteSuccess,
  deleteFailed,
} from "../../../helpers/reduxAction";

const initState: ListState<TypeContainer> = baseFetchListState();

export const typeContainerReducer = (
  state: ListState<TypeContainer> = initState,
  action: Action
) => {
  switch (action.type) {
    case FETCH_TYPECONTAINER_INIT:
      return fetchListState(action);
    case FETCH_TYPECONTAINER_SUCCESS:
      return fetchListStateSuccess(action, state);
    case FETCH_TYPECONTAINER_FAILED:
      return fetchListStateFailed(action, state);

    case ADD_TYPECONTAINER_INIT:
      return addInit(action, state);
    case ADD_TYPECONTAINER_SUCCESS:
      return addSuccess(action, state);
    case ADD_TYPECONTAINER_FAILED:
      return addFailed(action, state);

    case DELETE_TYPECONTAINER_INIT:
      return deleteInit(action, state);
    case DELETE_TYPECONTAINER_SUCCESS:
      return deleteSuccess(action, state);
    case DELETE_TYPECONTAINER_FAILED:
      return deleteFailed(action, state);
    default:
      return state;
  }
};
