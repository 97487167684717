import { Action } from "../../../models/redux/action";
import { ReduxCallbacks } from "../../../models/redux/Callbacks";
import { Currency } from "../../../models/Currency";
import { ListQuery } from "../../../models/redux/ListQuery";
import { AjaxResponse } from "rxjs/ajax";

export const FETCH_LIST_CURRENCY = "FETCH_LIST_CURRENCY";
export const FETCH_LIST_CURRENCY_SUCCESS = "FETCH_LIST_CURRENCY_SUCCESS";
export const FETCH_LIST_CURRENCY_FAILED = "FETCH_LIST_CURRENCY_FAILED";
export const FETCH_LIST_CURRENCY_CANCELLED = "FETCH_LIST_CURRENCY_CANCELLED";

export const fetchListCurrency = (
  query: ListQuery,
  callbacks?: ReduxCallbacks
): Action => ({
  type: FETCH_LIST_CURRENCY,
  payload: { query, callbacks },
});

export const fetchListCurrencySuccess = ({
  response,
}: AjaxResponse): Action => ({
  type: FETCH_LIST_CURRENCY_SUCCESS,
  payload: { response },
});

export const fetchListCurrencyFailed = (error: any): Action => ({
  type: FETCH_LIST_CURRENCY_FAILED,
  payload: error,
});

export const fetchListCurrencyCancelled = (): Action => ({
  type: FETCH_LIST_CURRENCY_CANCELLED,
});

// ADD CURRENCY
export const ADD_CURRENCY = "ADD_CURRENCY";
export const ADD_CURRENCY_SUCCESS = "ADD_CURRENCY_SUCCESS";
export const ADD_CURRENCY_FAILED = "ADD_CURRENCY_FAILED";
export const ADD_CURRENCY_CANCELLED = "ADD_CURRENCY_CANCELLED";

export const addCurrency = (
  content: Currency,
  callbacks?: ReduxCallbacks
): Action => ({
  type: ADD_CURRENCY,
  payload: { ...content, callbacks },
});

export const addCurrencySuccess = (): Action => ({
  type: ADD_CURRENCY_SUCCESS,
});

export const addCurrencyFailed = (error: any): Action => ({
  type: ADD_CURRENCY_FAILED,
  payload: error,
});

export const addCurrencyCancelled = (): Action => ({
  type: ADD_CURRENCY_CANCELLED,
});

// DELETE CURRENCY
export const DELETE_CURRENCY = "DELETE_CURRENCY";
export const DELETE_CURRENCY_SUCCESS = "DELETE_CURRENCY_SUCCESS";
export const DELETE_CURRENCY_FAILED = "DELETE_CURRENCY_FAILED";
export const DELETE_CURRENCY_CANCELLED = "DELETE_CURRENCY_CANCELLED";

export const deleteCurrencyInit = (
  content: Currency,
  callbacks?: ReduxCallbacks
): Action => ({
  type: DELETE_CURRENCY,
  payload: { ...content, callbacks },
});

export const deleteCurrencySuccess = (): Action => ({
  type: DELETE_CURRENCY_SUCCESS,
});

export const deleteCurrencyFailed = (error: any): Action => ({
  type: DELETE_CURRENCY_FAILED,
  payload: error,
});

export const deleteCurrencyCancelled = (): Action => ({
  type: DELETE_CURRENCY_CANCELLED,
});

//EDIT CURRENCY
export const EDIT_CURRENCY = "EDIT CURRENCY";
export const EDIT_CURRENCY_SUCCESS = "EDIT_CURRENCY_SUCCESS";
export const EDIT_CURRENCY_FAILED = "EDIT_CURRENCY_FAILED";
export const EDIT_CURRENCY_CANCELLED = "EDIT_CURRENCY_CANCELLED";

export const editCurrency = (
  content: Currency,
  callbacks?: ReduxCallbacks
): Action => ({
  type: EDIT_CURRENCY,
  payload: { ...content, callbacks },
});
export const editCurrencySuccess = (): Action => ({
  type: EDIT_CURRENCY_SUCCESS,
});
export const editCurrencyFailed = (error: any): Action => ({
  type: EDIT_CURRENCY_FAILED,
  payload: error,
});
export const editCurrencyCancelled = (): Action => ({
  type: EDIT_CURRENCY_CANCELLED,
});
