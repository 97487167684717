import React, { FunctionComponent } from "react";
import styled from "styled-components";

interface Props {
	title: string;
}

export const MenuSection: FunctionComponent<Props> = ({ title }: Props) => (
	<li className="menu-section mt-0">
		<Title className="menu-text">{title}</Title>
		<i className="menu-icon flaticon-more-v2"></i>
	</li>
);

const Title = styled.h4`
	font-weight: normal !important;
	text-transform: uppercase !important;
	color: #d0d0d0 !important;
`;
