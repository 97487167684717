import { Auth } from "../services/auth";
import { Permissions } from "../constants/permissions";

export const getBookingNoteStatusText = (status: number) => {
	switch (status) {
		case 0:
			return "Tạo lập";
		case 1:
			return "Chờ xác nhận";
		case 2:
			return "Đã xác nhận";
		case 5:
			return "Đang báo giá";
		case 6:
			return "Đã có giá";
		case 7:
			return "Đã ra bill";
		case 9:
			return "Đã hủy";
		default:
			return "Tạo lập";
	}
};

export const getBookingNoteStatusColor = (status: number) => {
	switch (status) {
		case 0:
			return "#9AE9C8";
		case 1:
			return "#4CAF50";
		case 2:
			return "#03A9F4";
		case 5:
			return "#4DD0E1";
		case 6:
			return "#7E57C2";
		case 7:
			return "#FDD835";
		case 9:
			return "#F44336";
		default:
			return "#9AE9C8";
	}
};

export const isBookingNoteWaitingConfirm = (status: number) => {
	return status === 1;
};

export const isBookingNoteCreated = (status: number) => {
	return status === 0;
};

export const isBookingNoteDraftOrCreated = (status?: number | null) => {
	console.log(status);

	return status === 0 || (status !== 0 && !status);
};

export const isUserCanUpdateBookingNote = (status: number) => {
	return !Auth.isSystemUser() && isBookingNoteDraftOrCreated(status);
};

export const isSystemUserCanCancelBookingNote = (status: number) => {
	return status === 1 || status === 2 || status === 5 || status === 6;
};

export const disableUpdateBookingNote = (status: number) => {
	return (
		Auth.hasPermission(Permissions.Pages.BookingNote.SaleConfirm) ||
		!isBookingNoteDraftOrCreated(status)
	);
};
