import { Api } from "../api";
import { ListQuery } from "../../models/redux/ListQuery";
export const getAllFeeApproveService = (query: ListQuery) => {
  return Api.get("/feeapprover/getAll", {
    page: query.page,
    size: query.per_page,
    name: query.filter?.name || "",
  });
};

export const createFeeApproveService = (feeApprove: any) => {
  return Api.post("/feeapprover/createOrEdit", feeApprove);
};

export const deleteFeeApproveService = (feeApproveId: number) => {
  return Api.get("/feeapprover/delete", { id: feeApproveId });
};

export const getAllUserByType = (query: ListQuery) => {
  return Api.get("/user/getAllUserByType", {
    page: query.page,
    size: query.per_page,
    type: "system",
  });
};
