import { ListQuery } from "../../../models/redux/ListQuery";
import { ReduxCallbacks } from "../../../models/redux/Callbacks";
import { AjaxResponse } from "rxjs/ajax";
import { FeeApprove } from "../../../models/feeApprove";

export const FETCH_FEE_APPROVE = "FETCH_FEE_APPROVE";
export const FETCH_FEE_APPROVE_SUCCESS = "FETCH_FEE_APPROVE_SUCCESS";
export const FETCH_FEE_APPROVE_FAIL = "FETCH_FEE_APPROVE_FAIL";

export const fetchFeeApprove = (
  query: ListQuery,
  callbacks?: ReduxCallbacks
) => {
  return {
    type: FETCH_FEE_APPROVE,
    payload: { query, callbacks },
  };
};

export const fetchFeeApproveSuccess = (response: AjaxResponse) => {
  return {
    type: FETCH_FEE_APPROVE_SUCCESS,
    payload: response,
  };
};

export const fetchFeeApproveFail = (error: any) => {
  return {
    type: FETCH_FEE_APPROVE_FAIL,
    payload: error,
  };
};

// ===== ADD

export const ADD_FEE_APPROVE = "ADD_FEE_APPROVE";
export const ADD_FEE_APPROVE_SUCCESS = "ADD_FEE_APPROVE_SUCCESS";
export const ADD_FEE_APPROVE_FAIL = "ADD_FEE_APPROVE_FAIL";

export const addFeeApprove = (
  feeApprove: FeeApprove,
  callbacks?: ReduxCallbacks
) => {
  return {
    type: ADD_FEE_APPROVE,
    payload: { data: feeApprove, callbacks },
  };
};

export const addFeeApproveSuccess = (response: AjaxResponse) => {
  return {
    type: ADD_FEE_APPROVE_SUCCESS,
    payload: response,
  };
};
export const addFeeApproveFail = (error: any) => {
  return {
    type: ADD_FEE_APPROVE_FAIL,
    payload: { error },
  };
};
// ===== DELETE

export const DELETE_FEE_APPROVE = "DELETE_FEE_APPROVE";
export const DELETE_FEE_APPROVE_SUCCESS = "DELETE_FEE_APPROVE_SUCCESS";
export const DELETE_FEE_APPROVE_FAIL = "DELETE_FEE_APPROVE_FAIL";

export const deleteFeeApprove = (id: number, callbacks?: ReduxCallbacks) => {
  return {
    type: DELETE_FEE_APPROVE,
    payload: { id, callbacks },
  };
};

export const deleteFeeApproveSuccess = (response: AjaxResponse) => {
  return {
    type: DELETE_FEE_APPROVE_SUCCESS,
    payload: response,
  };
};
export const deleteFeeApproveFail = (error: any) => {
  return {
    type: DELETE_FEE_APPROVE_FAIL,
    payload: { error },
  };
};
