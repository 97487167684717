import { Api } from "../api";
import { Port } from "../../models/Port";

export const getAllPorts = (
  name: string = "",
  page: number,
  size: number,
  status?: boolean
) => {
  return Api.get("/portlist/getAll", { name, page, size, status });
};

export const addPort = (data: Port) => {
  return Api.post("/portlist/createOrEdit", { ...data });
};

export const getOnePort = (id: number) => {
  return Api.get("/portlist/createOrEdit", { id });
};

export const deleteOnePort = (data: any) => {
  return Api.get("/portlist/delete", { ...data });
};
