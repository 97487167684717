export const Permissions = {
  Pages: {
    Administration: {
      Main: "Pages.Administration",
      Units: {
        Main: "Pages.Administration.Units",
        Create: "Pages.Administration.Units.Create",
        Edit: "Pages.Administration.Units.Edit",
        Delete: "Pages.Administration.Units.Delete",
      },
      Deparments: {
        Main: "Pages.Administration.Departments",
        Create: "Pages.Administration.Departments.Create",
        Edit: "Pages.Administration.Departments.Edit",
        Delete: "Pages.Administration.Departments.Delete",
      },
      Roles: {
        Main: "Pages.Administration.Roles",
        Create: "Pages.Administration.Roles.Create",
        Edit: "Pages.Administration.Roles.Edit",
        Delete: "Pages.Administration.Roles.Delete",
      },
      Users: {
        Main: "Pages.Administration.Users",
        Create: "Pages.Administration.Users.Create",
        Edit: "Pages.Administration.Users.Edit",
        Delete: "Pages.Administration.Users.Delete",
      },
      Parameters: {
        Main: "Pages.Administration.Parameters",
        Create: "Pages.Administration.Parameters.Create",
        Edit: "Pages.Administration.Parameters.Edit",
        Delete: "Pages.Administration.Parameters.Delete",
      },
      Hitories: {
        Main: "Pages.Administration.Hitories",
        Create: "Pages.Administration.Hitories.Create",
        Edit: "Pages.Administration.Hitories.Edit",
        Delete: "Pages.Administration.Hitories.Delete",
      },
      EmailTempates: {
        Main: "Pages.Administration.EmailTempates ",
        Create: "Pages.Administration.EmailTempates .Create",
        Edit: "Pages.Administration.EmailTempates .Edit",
        Delete: "Pages.Administration.EmailTempates .Delete",
      },
    },
    ShipSchedule: {
      Main: "Pages.ShipSchedule",
      Create: "Pages.ShipSchedule .Create",
      Edit: "Pages.ShipSchedule.Edit",
      Delete: "Pages.ShipSchedule.Delete",
    },
    Common: {
      Categories: {
        Main: "Pages.Common.Categories",
        Merchants: {
          Main: "Pages.Common.Categories.Merchants",
          Create: "Pages.Common.Categories.Merchants Create",
          Edit: "Pages.Common.Categories.Merchants.Edit",
          Delete: "Pages.Common.Categories.Merchants.Delete",
        },
        Currencys: {
          Main: "Pages.Common.Categories.Currencys",
          Create: "Pages.Common.Categories.Currencys.Create",
          Edit: "Pages.Common.Categories.Currencys.Edit",
          Delete: "Pages.Common.Categories.Currencys.Delete",
        },
        CurrencyConVersions: {
          Main: "Pages.Common.Categories.CurrencyConversions",
          Create: "Pages.Common.Categories.CurrencyConversions.Create",
          Edit: "Pages.Common.Categories.CurrencyConversions.Edit",
          Delete: "Pages.Common.Categories.CurrencyConversions.Delete",
        },
        Fee: {
          Main: "Pages.Common.Categories.Fee",
          Create: "Pages.Common.Categories.Fee.Create",
          Edit: "Pages.Common.Categories.Fee.Edit",
          Delete: "Pages.Common.Categories.Fee.Delete",
        },
        Vessels: {
          Main: "Pages.Common.Categories.Vessels",
          Create: "Pages.Common.Categories.Vessels.Create",
          Edit: "Pages.Common.Categories.Vessels.Edit",
          Delete: "Pages.Common.Categories.Vessels.Delete",
        },
        Ports: {
          Main: "Pages.Common.Categories.Ports",
          Create: "Pages.Common.Categories.Ports.Create",
          Edit: "Pages.Common.Categories.Ports.Edit",
          Delete: "Pages.Common.Categories.Ports.Delete",
        },
        Terminals: {
          Main: "Pages.Common.Categories.Terminals",
          Create: "Pages.Common.Categories.Terminals.Create",
          Edit: "Pages.Common.Categories.Terminals.Edit",
          Delete: "Pages.Common.Categories.Terminals.Delete",
        },
        ChargeTypes: {
          Main: "Pages.Common.Categories.ChargeTypes",
          Create: "Pages.Common.Categories.ChargeTypes.Create",
          Edit: "Pages.Common.Categories.ChargeTypes.Edit",
          Delete: "Pages.Common.Categories.ChargeTypes.Delete",
        },
        ChargeGroups: {
          Main: "Pages.Common.Categories.ChargeGroups",
          Create: "Pages.Common.Categories.ChargeGroups.Create",
          Edit: "Pages.Common.Categories.ChargeGroups.Edit",
          Delete: "Pages.Common.Categories.ChargeGroups.Delete",
        },
        Charges: {
          Main: "Pages.Common.Categories.Charges",
          Create: "Pages.Common.Categories.Charges.Create",
          Edit: "Pages.Common.Categories.Charges.Edit",
          Delete: "Pages.Common.Categories.Charges.Delete",
        },
        SizeContainers: {
          Main: "Pages.Common.Categories.SizeContainers",
          Create: "Pages.Common.Categories.SizeContainers.Create",
          Edit: "Pages.Common.Categories.SizeContainers.Edit",
          Delete: "Pages.Common.Categories.SizeContainers.Delete",
        },
        TypeOfContainers: {
          Main: "Pages.Common.Categories.TypeOfContainers",
          Create: "Pages.Common.Categories.TypeOfContainers.Create",
          Edit: "Pages.Common.Categories.TypeOfContainers.Edit",
          Delete: "Pages.Common.Categories.TypeOfContainers.Delete",
        },
        Terms: {
          Main: "Pages.Common.Categories.Terms",
          Create: "Pages.Common.Categories.Terms.Create",
          Edit: "Pages.Common.Categories.Terms.Edit",
          Delete: "Pages.Common.Categories.Terms.Delete",
        },
        Commoditys: {
          Main: "Pages.Common.Categories.Commoditys",
          Create: "Pages.Common.Categories.Commoditys.Create",
          Edit: "Pages.Common.Categories.Commoditys.Edit",
          Delete: "Pages.Common.Categories.Commoditys.Delete",
        },
        Tariffs: {
          Main: "Pages.Common.Categories.Tariffs",
          Create: "Pages.Common.Categories.Tariffs.Create",
          Edit: "Pages.Common.Categories.Tariffs.Edit",
          Delete: "Pages.Common.Categories.Tariffs.Delete",
        },
      },
    },
    Booking: {
      Main: "Pages.Booking",
      Create: "Pages_Booking.Create",
      Edit: "Pages_Booking.Edit",
      Delete: "Pages_Booking.Delete",
    },
    BookingNote: {
      Main: "Pages.BookingNote",
      SaleConfirm: "Pages.BookingNote.SaleConfirm",
      Create: "Pages.BookingNote.Create",
      Edit: "Pages.BookingNote.Edit",
      Delete: "Pages.BookingNote.Delete",
    },
    Quotations: {
      Main: "Pages.Quotations",
      Create: "Pages.Quotations.Create",
      Edit: "Pages.Quotations.Edit",
      Delete: "Pages.Quotations.Delete",
    },
    PriceApproval: {
      Main: "Pages.Quotations",
      Create: "Pages.Quotations.Create",
      Edit: "Pages.Quotations.Edit",
      Delete: "Pages.Quotations.Delete",
    },
    BiiLadings: {
      Main: "Pages.Booking.BiiLadings",
      Create: "Pages.BiiLadings.Create",
      Edit: "Pages.BiiLadings.Edit",
      Delete: "Pages.BiiLadings.Delete",
    },
  },
};
