import { ListState } from "../../../models/redux/listState";
import { Action } from "../../../models/redux/action";

import {
  fetchListStateSuccess,
  fetchListStateFailed,
  baseFetchListState,
  fetchListState,
} from "../../../helpers/reduxAction";
import {
  FETCH_CONTAINER,
  FETCH_CONTAINER_FAILED,
  FETCH_CONTAINER_SUCCESS,
} from "./actions";

const initState: ListState<any> = baseFetchListState();

export const containerReducer = (
  state: ListState<any> = initState,
  action: Action
) => {
  switch (action.type) {
    case FETCH_CONTAINER:
      return fetchListState(action);
    case FETCH_CONTAINER_SUCCESS:
      return fetchListStateSuccess(action, state);
    case FETCH_CONTAINER_FAILED:
      return fetchListStateFailed(action, state);
    default:
      return state;
  }
};
