import { ActionsObservable, ofType } from "redux-observable";
import { Action } from "../../../models/redux/action";
import { changePasswordFailed, changePasswordSuccess, CHANGE_PASSWORD, CHANGE_PASSWORD_CANCELLED } from "./actions";
import * as accountApi from '../../../api/account';
import { catchError, map, mergeMap, takeUntil } from "rxjs/operators";
import { of } from "rxjs";
import { onEpicFailed, onEpicSuccess } from "../../../helpers/reduxEpic";

const changePasswordEpic = (action$: ActionsObservable<Action>) => action$.pipe(
	ofType(CHANGE_PASSWORD),
	mergeMap(action => accountApi.changePassword(action.payload.request).pipe(
		map(response => onEpicSuccess(action, response, changePasswordSuccess())),
		catchError(error => of(onEpicFailed(action, error, changePasswordFailed(error)))),
		takeUntil(action$.pipe(
			ofType(CHANGE_PASSWORD_CANCELLED)
		))
	))
);

export default [
	changePasswordEpic
]