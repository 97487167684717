import { ChangePasswordRequest } from "../../../models/ChangePasswordRequest";
import { Action } from "../../../models/redux/action";
import { ReduxCallbacks } from "../../../models/redux/Callbacks";

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED';
export const CHANGE_PASSWORD_CANCELLED = 'CHANGE_PASSWORD_CANCELLED';

export const changePassword = (request: ChangePasswordRequest, callbacks?: ReduxCallbacks): Action => ({
	type: CHANGE_PASSWORD,
	payload: {
		request,
		callbacks
	}
});

export const changePasswordSuccess = (): Action => ({
	type: CHANGE_PASSWORD_SUCCESS,
});

export const changePasswordFailed = (error: any): Action => ({
	type: CHANGE_PASSWORD_FAILED,
	payload: error
});

export const changePasswordCancelled = (error: any): Action => ({
	type: CHANGE_PASSWORD_CANCELLED,
});