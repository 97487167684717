import { ActionsObservable, ofType } from "redux-observable";
import { Action } from "../../../models/redux/action";
import {
  ADD_CONFIGAPPROVER,
  DELETE_CONFIGAPPROVER,
  FETCH_LIST_CONFIGAPPROVER,
  fetchListConfigApproverSuccess,
  fetchListConfigApproverFailed,
  FETCH_LIST_CONFIGAPPROVER_CANCELLED,
  addConfigApproverSuccess,
  addConfigApproverFailed,
  ADD_CONFIGAPPROVER_CANCELLED,
  deleteConfigApproverSuccess,
  deleteConfigApproverFailed,
  DELETE_CONFIGAPPROVER_CANCELLED,
  EDIT_CONFIGAPPROVER,
  fetchListConfigApprover,
  editConfigApproverFailed,
} from "./actions";
import { mergeMap, map, catchError, takeUntil } from "rxjs/operators";
import { of } from "rxjs";
import { onEpicSuccess, onEpicFailed } from "../../../helpers/reduxEpic";
import { CANCEL_ALL_REQUEST } from "../../default";
import {
  getAllConfigApprover,
  addConfigApprover,
  deleteConfigApprover,
} from "../../../api/configApprover";

export const fetchConfigApproverEpic = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    ofType(FETCH_LIST_CONFIGAPPROVER),
    mergeMap((action) =>
      getAllConfigApprover(action.payload.query).pipe(
        map((response) =>
          onEpicSuccess(
            action,
            response,
            fetchListConfigApproverSuccess(response)
          )
        ),
        catchError((error) =>
          of(onEpicFailed(action, error, fetchListConfigApproverFailed(error)))
        ),
        takeUntil(
          action$.pipe(
            ofType(FETCH_LIST_CONFIGAPPROVER_CANCELLED, CANCEL_ALL_REQUEST)
          )
        )
      )
    )
  );

export const addConfigApproverEpic = (
  action$: ActionsObservable<Action>,
  state$: any
) => {
  return action$.pipe(
    ofType(ADD_CONFIGAPPROVER),
    mergeMap((action) =>
      addConfigApprover(action.payload).pipe(
        map((response) => {
          return onEpicSuccess(
            action,
            response,
            fetchListConfigApprover({
              page: state$.value.configApprover.query.page,
              per_page: state$.value.configApprover.query.per_page,
            })
          );
        }),
        catchError((error) =>
          of(onEpicFailed(action, error, addConfigApproverFailed(error)))
        ),
        takeUntil(action$.pipe(ofType(CANCEL_ALL_REQUEST)))
      )
    )
  );
};

export const editConfigApproverEpic = (
  action$: ActionsObservable<Action>,
  state$: any
) => {
  return action$.pipe(
    ofType(EDIT_CONFIGAPPROVER),
    mergeMap((action) =>
      addConfigApprover(action.payload).pipe(
        map((response) => {
          return onEpicSuccess(
            action,
            response,
            fetchListConfigApprover({
              page: state$.value.configApprover.query.page,
              per_page: state$.value.configApprover.query.per_page,
            })
          );
        }),
        catchError((error) =>
          of(onEpicFailed(action, error, editConfigApproverFailed(error)))
        ),
        takeUntil(action$.pipe(ofType(CANCEL_ALL_REQUEST)))
      )
    )
  );
};

export const deleteConfigApproverEpic = (
  action$: ActionsObservable<Action>,
  state$: any
) => {
  return action$.pipe(
    ofType(DELETE_CONFIGAPPROVER),
    mergeMap((action) =>
      deleteConfigApprover(action.payload).pipe(
        map((response) => {
          return onEpicSuccess(
            action,
            response,
            fetchListConfigApprover({
              page: state$.value.configApprover.query.page,
              per_page: state$.value.configApprover.query.per_page,
            })
          );
        }),
        catchError((error) =>
          of(onEpicFailed(action, error, deleteConfigApproverFailed(error)))
        ),
        takeUntil(action$.pipe(ofType(CANCEL_ALL_REQUEST)))
      )
    )
  );
};
