import { Api } from "../api";
import { ListQuery } from "../../models/redux/ListQuery";

export const createOrEditQuotation = (quotation: any) => {
  return Api.post("/quotation/createOrEdit", quotation);
};

export const getOneQuotation = (id: number) => {
  return Api.get("/quotation/createOrEdit", { id });
};

export const deleteQuotation = (id: any) => {
  return Api.get("/quotation/delete", { id: id });
};

export const getAllQuotation = (query: ListQuery) => {
  return Api.get("/quotation/getAllQuotations", {
    page: query.page,
    size: query.per_page,
    receiveId: query.filter?.receiveId,
    senderId: query.filter?.senderId,
    status: query.filter?.status,
    from: query.filter?.form,
    to: query.filter?.to,
  });
};

export const quotationCommodityCreateOrEdit = (quotation: any) => {
  return Api.post("/quotationCommodity/createOrEdit", quotation);
};

export const quotationCommodityGetAll = (query: ListQuery) => {
  return Api.get("/quotationCommodity/getAll", {
    page: query.page,
    size: query.size,
    quotationId: query.filter?.quotationId,
  });
};

export const quotationCommodityDelete = (id: any) => {
  return Api.get("/quotationCommodity/delete", { QuotationCommodityId: id });
};

// =================================== API APRROVE ==================================================

export const approveQuotation = (data: any) => {
  return Api.put("/quotationCommodity/updateStatus", data);
};
