import { ListQuery } from "../../../models/redux/ListQuery";
import { ReduxCallbacks } from "../../../models/redux/Callbacks";
import { Terminal } from "../../../models/Terminal";

export const FETCH_TERMNIAL_INIT = "FETCH_TERMNIAL_INIT";
export const FETCH_TERMNIAL_SUCCESS = "FETCH_TERMNIAL_SUCCESS";
export const FETCH_TERMNIAL_FAILED = "FETCH_TERMNIAL_FAILED";
export const FETCH_TERMNIAL_CANCELLED = "FETCH_TERMNIAL_CANCELLED";

export const ADD_TERMNIAL_INIT = "ADD_TERMNIAL_INIT";
export const ADD_TERMNIAL_SUCCESS = "ADD_TERMNIAL_SUCCESS";
export const ADD_TERMNIAL_FAILED = "ADD_TERMNIAL_FAILED";

export const EDIT_TERMNIAL_INIT = "EDIT_TERMNIAL_INIT";
export const EDIT_TERMNIAL_SUCCESS = "EDIT_TERMNIAL_SUCCESS";
export const EDIT_TERMNIAL_FAILED = "EDIT_TERMNIAL_FAILED";

export const DELETE_TERMNIAL_INIT = "DELETE_TERMNIAL_INIT";
export const DELETE_TERMNIAL_SUCCESS = "DELETE_TERMNIAL_SUCCESS";
export const DELETE_TERMNIAL_FAILED = "DELETE_TERMNIAL_FAILED";

export const fetchTerminalInit = (
  query: ListQuery,
  callbacks?: ReduxCallbacks
) => ({
  type: FETCH_TERMNIAL_INIT,
  payload: { query, callbacks },
});

// FETCH

export const fetchTerminalSuccess = (response: any) => ({
  type: FETCH_TERMNIAL_SUCCESS,
  payload: response,
});

export const fetchTerminalFailed = (error: any) => ({
  type: FETCH_TERMNIAL_FAILED,
  payload: error,
});

export const fetchTerminalCancelled = () => ({
  type: FETCH_TERMNIAL_CANCELLED,
});

// ADD

export const addTerminalInit = (
  content: Terminal,
  callbacks?: ReduxCallbacks
) => ({
  type: ADD_TERMNIAL_INIT,
  payload: { ...content, callbacks },
});

export const addTerminalSuccess = () => ({
  type: ADD_TERMNIAL_SUCCESS,
});

export const addTerminalFailed = (error: any) => ({
  type: ADD_TERMNIAL_FAILED,
  payload: error,
});

// EDIT

export const editTerminalInit = (
  content: Terminal,
  callbacks?: ReduxCallbacks
) => ({
  type: EDIT_TERMNIAL_INIT,
  payload: { ...content, callbacks },
});

export const editTerminalSuccess = () => ({
  type: EDIT_TERMNIAL_SUCCESS,
});

export const editTerminalFailed = (error: any) => ({
  type: EDIT_TERMNIAL_FAILED,
  payload: error,
});

// DELETE

export const deleteTerminalInit = (
  content: Terminal,
  callbacks?: ReduxCallbacks
) => {
  return {
    type: DELETE_TERMNIAL_INIT,
    payload: { ...content, callbacks },
  };
};

export const deleteTerminalSuccess = () => ({
  type: DELETE_TERMNIAL_SUCCESS,
});

export const deleteTerminalFailed = (error: any) => ({
  type: DELETE_TERMNIAL_FAILED,
  payload: error,
});
