import React, {
  FunctionComponent,
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
} from "react";
import env from "../helpers/env";
//import {messaging} from "../../firebaseInit";
import { notify } from "../helpers/notify";

export type NotificationsContextType = {
  notifications: any;
  setNotifications: Dispatch<SetStateAction<any>>;
};

export const NotificationsContext = React.createContext<
  NotificationsContextType | undefined
>(undefined);

export const NotificationsProvider: FunctionComponent = ({ children }) => {
  const [notifications, setNotifications] = useState<any>(null);

  useEffect(() => {}, []);
  return (
    <NotificationsContext.Provider value={{ notifications, setNotifications }}>
      {children}
    </NotificationsContext.Provider>
  );
};
