import { ListState } from "../../../models/redux/listState";
import { Office } from "../../../models/Office";
import { Action } from "../../../models/redux/action";
import {
	FETCH_OFFICE_INIT,
	FETCH_OFFICE_SUCCESS,
	FETCH_OFFICE_FAILED,
	ADD_OFFICE_INIT,
	ADD_OFFICE_SUCCESS,
	ADD_OFFICE_FAILED,
	EDIT_OFFICE_INIT,
	EDIT_OFFICE_SUCCESS,
	EDIT_OFFICE_FAILED,
	DELETE_OFFICE_INIT,
	DELETE_OFFICE_SUCCESS,
	DELETE_OFFICE_FAILED,
} from "./actions";
import {
	fetchListState,
	fetchListStateSuccess,
	fetchListStateFailed,
	baseFetchListState,
	addInit,
	addFailed,
	addSuccess,
	deleteInit,
	deleteSuccess,
	deleteFailed,
} from "../../../helpers/reduxAction";

const initState: ListState<Office> = baseFetchListState();

export const officeReducer = (
	state: ListState<Office> = initState,
	action: Action
) => {
	switch (action.type) {
		case FETCH_OFFICE_INIT:
			return fetchListState(action);
		case FETCH_OFFICE_SUCCESS:
			return fetchListStateSuccess(action, state);
		case FETCH_OFFICE_FAILED:
			return fetchListStateFailed(action, state);

		case ADD_OFFICE_INIT:
			return addInit(action, state);
		case ADD_OFFICE_SUCCESS:
			return addSuccess(action, state);
		case ADD_OFFICE_FAILED:
			return addFailed(action, state);

		case DELETE_OFFICE_INIT:
			return deleteInit(action, state);
		case DELETE_OFFICE_SUCCESS:
			return deleteSuccess(action, state);
		case DELETE_OFFICE_FAILED:
			return deleteFailed(action, state);
		default:
			return state;
	}
};
