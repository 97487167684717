import { Action } from "../../../models/redux/action";
import { ReduxCallbacks } from "../../../models/redux/Callbacks";
import { CurrencyConversions } from "../../../models/CurrencyConversions";
import { ListQuery } from "../../../models/redux/ListQuery";
import { AjaxResponse } from "rxjs/ajax";

export const FETCH_LIST_CURRENCYCONVERSION = "FETCH_LIST_CURRENCYCONVERSION";
export const FETCH_LIST_CURRENCYCONVERSION_SUCCESS =
  "FETCH_LIST_CURRENCYCONVERSION_SUCCESS";
export const FETCH_LIST_CURRENCYCONVERSION_FAILED =
  "FETCH_LIST_CURRENCYCONVERSION_FAILED";
export const FETCH_LIST_CURRENCYCONVERSION_CANCELLED =
  "FETCH_LIST_CURRENCYCONVERSION_CANCELLED";

export const fetchListCurrencyConversion = (
  query: ListQuery,
  callbacks?: ReduxCallbacks
): Action => ({
  type: FETCH_LIST_CURRENCYCONVERSION,
  payload: { query, callbacks },
});

export const fetchListCurrencyConversionSuccess = ({
  response,
}: AjaxResponse): Action => ({
  type: FETCH_LIST_CURRENCYCONVERSION_SUCCESS,
  payload: { response },
});

export const fetchListCurrencyConversionFailed = (error: any): Action => ({
  type: FETCH_LIST_CURRENCYCONVERSION_FAILED,
  payload: error,
});

export const fetchListCurrencyConversionCancelled = (): Action => ({
  type: FETCH_LIST_CURRENCYCONVERSION_CANCELLED,
});

// ADD CURRENCY
export const ADD_CURRENCYCONVERSION = "ADD_CURRENCYCONVERSION";
export const ADD_CURRENCYCONVERSION_SUCCESS = "ADD_CURRENCYCONVERSION_SUCCESS";
export const ADD_CURRENCYCONVERSION_FAILED = "ADD_CURRENCYCONVERSION_FAILED";
export const ADD_CURRENCYCONVERSION_CANCELLED =
  "ADD_CURRENCYCONVERSION_CANCELLED";

export const addCurrencyConversion = (
  content: CurrencyConversions,
  callbacks?: ReduxCallbacks
): Action => ({
  type: ADD_CURRENCYCONVERSION,
  payload: { ...content, callbacks },
});

export const addCurrencyConversionSuccess = (): Action => ({
  type: ADD_CURRENCYCONVERSION_SUCCESS,
});

export const addCurrencyConversionFailed = (error: any): Action => ({
  type: ADD_CURRENCYCONVERSION_FAILED,
  payload: error,
});

export const addCurrencyConversionCancelled = (): Action => ({
  type: ADD_CURRENCYCONVERSION_CANCELLED,
});

// DELETE CURRENCY
export const DELETE_CURRENCYCONVERSION = "DELETE_CURRENCYCONVERSION";
export const DELETE_CURRENCYCONVERSION_SUCCESS =
  "DELETE_CURRENCYCONVERSION_SUCCESS";
export const DELETE_CURRENCYCONVERSION_FAILED =
  "DELETE_CURRENCYCONVERSION_FAILED";
export const DELETE_CURRENCYCONVERSION_CANCELLED =
  "DELETE_CURRENCYCONVERSION_CANCELLED";

export const deleteCurrencyConversionInit = (
  content: CurrencyConversions,
  callbacks?: ReduxCallbacks
): Action => ({
  type: DELETE_CURRENCYCONVERSION,
  payload: { ...content, callbacks },
});

export const deleteCurrencyConversionSuccess = (): Action => ({
  type: DELETE_CURRENCYCONVERSION_SUCCESS,
});

export const deleteCurrencyConversionFailed = (error: any): Action => ({
  type: DELETE_CURRENCYCONVERSION_FAILED,
  payload: error,
});

export const deleteCurrencyConversionCancelled = (): Action => ({
  type: DELETE_CURRENCYCONVERSION_CANCELLED,
});

//EDIT CURRENCY
export const EDIT_CURRENCYCONVERSION = "EDIT CURRENCYCONVERSION";
export const EDIT_CURRENCYCONVERSION_SUCCESS =
  "EDIT_CURRENCYCONVERSION_SUCCESS";
export const EDIT_CURRENCYCONVERSION_FAILED = "EDIT_CURRENCYCONVERSION_FAILED";
export const EDIT_CURRENCYCONVERSION_CANCELLED =
  "EDIT_CURRENCYCONVERSION_CANCELLED";

export const editCurrencyConversion = (
  content: CurrencyConversions,
  callbacks?: ReduxCallbacks
): Action => ({
  type: EDIT_CURRENCYCONVERSION,
  payload: { ...content, callbacks },
});
export const editCurrencyConversionSuccess = (): Action => ({
  type: EDIT_CURRENCYCONVERSION_SUCCESS,
});
export const editCurrencyConversionFailed = (error: any): Action => ({
  type: EDIT_CURRENCYCONVERSION_FAILED,
  payload: error,
});
export const editCurrencyConversionCancelled = (): Action => ({
  type: EDIT_CURRENCYCONVERSION_CANCELLED,
});
