import React from "react";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";

const getFieldCSSClasses = (touched: Boolean, errors: any) => {
	const classes = ["form-control input_bootstrap override-p-v"];
	if (touched && errors) {
		classes.push("is-invalid");
	}

	if (touched && !errors) {
		classes.push("was-validated_override");
	}

	return classes.join(" ");
};

export function Select({
	field, // { name, value, onChange, onBlur }
	form: { touched, errors },
	label,
	withFeedbackLabel = true,
	type = "text",
	customFeedbackLabel,
	children,
	size = 3,
	screen = "lg",
	...props
}: any) {
	return (
		<>
			<div className="row">
				<div className={`col-${screen}-${size}`}>
					{label && <label className={`mt-${screen}-2`}> {label}</label>}
				</div>
				<div className="col">
					<select
						{...field}
						{...props}
						className={getFieldCSSClasses(
							touched[field.name],
							errors[field.name]
						)}
						onChange={(event: any) => {
							field.onChange(event);
							if (props.onChange) {
								props.onChange(event);
							}
						}}
					>
						{children}
					</select>
					{withFeedbackLabel && (
						<FieldFeedbackLabel
							error={errors[field.name]}
							touched={touched[field.name]}
							label={label}
							customFeedbackLabel={customFeedbackLabel}
						/>
					)}
				</div>
			</div>
		</>
	);
}
