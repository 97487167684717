import { ListState } from "../../../models/redux/listState";
import { PortOfCalling } from "../../../models/PortOfCalling";
import { Action } from "../../../models/redux/action";
import {
	FETCH_LIST_POST_OF_CALLING_SUCCESS,
	FETCH_LIST_POST_OF_CALLING_FAILED,
	FETCH_LIST_POST_OF_CALLING,
} from "./actions";
import {
	fetchListStateSuccess,
	fetchListStateFailed,
	baseFetchListState,
	fetchListState,
} from "../../../helpers/reduxAction";

const initState: ListState<PortOfCalling> = baseFetchListState();

export const portOfCallingReducer = (
	state: ListState<PortOfCalling> = initState,
	action: Action
) => {
	switch (action.type) {
		case FETCH_LIST_POST_OF_CALLING:
			return fetchListState(action);
		case FETCH_LIST_POST_OF_CALLING_SUCCESS:
			return fetchListStateSuccess(action, state);
		case FETCH_LIST_POST_OF_CALLING_FAILED:
			return fetchListStateFailed(action, state);
		default:
			return state;
	}
};
