import { ListState } from "../../../models/redux/listState";
import { Vessel } from "../../../models/Vessel";
import { Action } from "../../../models/redux/action";
import {
  FETCH_VESSEL_INIT,
  FETCH_VESSEL_SUCCESS,
  FETCH_VESSEL_FAILED,
  ADD_VESSEL_INIT,
  ADD_VESSEL_SUCCESS,
  ADD_VESSEL_FAILED,
  EDIT_VESSEL_INIT,
  EDIT_VESSEL_SUCCESS,
  EDIT_VESSEL_FAILED,
  DELETE_VESSEL_INIT,
  DELETE_VESSEL_SUCCESS,
  DELETE_VESSEL_FAILED,
} from "./actions";
import {
  fetchListState,
  fetchListStateSuccess,
  fetchListStateFailed,
  baseFetchListState,
  addInit,
  addFailed,
  addSuccess,
  deleteInit,
  deleteSuccess,
  deleteFailed,
} from "../../../helpers/reduxAction";

const initState: ListState<Vessel> = baseFetchListState();

export const vesselReducer = (
  state: ListState<Vessel> = initState,
  action: Action
) => {
  switch (action.type) {
    case FETCH_VESSEL_INIT:
      return fetchListState(action);
    case FETCH_VESSEL_SUCCESS:
      return fetchListStateSuccess(action, state);
    case FETCH_VESSEL_FAILED:
      return fetchListStateFailed(action, state);

    case ADD_VESSEL_INIT:
      return addInit(action, state);
    case ADD_VESSEL_SUCCESS:
      return addSuccess(action, state);
    case ADD_VESSEL_FAILED:
      return addFailed(action, state);

    case DELETE_VESSEL_INIT:
      return deleteInit(action, state);
    case DELETE_VESSEL_SUCCESS:
      return deleteSuccess(action, state);
    case DELETE_VESSEL_FAILED:
      return deleteFailed(action, state);
    default:
      return state;
  }
};
