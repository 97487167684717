import { Action } from "../../../models/redux/action";
import { mergeMap, map, catchError, takeUntil } from "rxjs/operators";
import {
  FETCH_SIZECONTAINER_INIT,
  fetchSizeContainerSuccess,
  fetchSizeContainerFailed,
  FETCH_SIZECONTAINER_CANCELLED,
  ADD_SIZECONTAINER_INIT,
  addSizeContainerFailed,
  EDIT_SIZECONTAINER_INIT,
  editSizeContainerFailed,
  DELETE_SIZECONTAINER_INIT,
  fetchSizeContainerInit,
} from "./actions";
import { ActionsObservable, ofType } from "redux-observable";
import { of } from "rxjs";
import { CANCEL_ALL_REQUEST } from "../../default";
import { onEpicSuccess, onEpicFailed } from "../../../helpers/reduxEpic";
import {
  getAllSizeContainer,
  addSizeContainer,
  deleteOneSizeContainer,
} from "../../../api/sizeContainer";

export const fetchListSizeContainerEpic = (
  action$: ActionsObservable<Action>
) => {
  return action$.pipe(
    ofType(FETCH_SIZECONTAINER_INIT),
    mergeMap((action) =>
      getAllSizeContainer(
        action.payload.query?.filter?.name,
        action.payload.query.page,
        action.payload.query.per_page,
        action.payload.query?.filter.status
      ).pipe(
        map((response) =>
          onEpicSuccess(action, response, fetchSizeContainerSuccess(response))
        ),
        catchError((error) =>
          of(onEpicFailed(action, error, fetchSizeContainerFailed(error)))
        ),
        takeUntil(
          action$.pipe(
            ofType(FETCH_SIZECONTAINER_CANCELLED, CANCEL_ALL_REQUEST)
          )
        )
      )
    )
  );
};

export const addSizeContainerEpic = (
  action$: ActionsObservable<Action>,
  state$: any
) => {
  return action$.pipe(
    ofType(ADD_SIZECONTAINER_INIT),
    mergeMap((action) =>
      addSizeContainer(action.payload).pipe(
        map((response) => {
          return onEpicSuccess(
            action,
            response,
            fetchSizeContainerInit({
              page: state$.value.sizeContainer.query.page,
              per_page: state$.value.sizeContainer.query.per_page,
            })
          );
        }),
        catchError((error) =>
          of(onEpicFailed(action, error, addSizeContainerFailed(error)))
        ),
        takeUntil(action$.pipe(ofType(CANCEL_ALL_REQUEST)))
      )
    )
  );
};

export const editSizeContainerEpic = (
  action$: ActionsObservable<Action>,
  state$: any
) => {
  return action$.pipe(
    ofType(EDIT_SIZECONTAINER_INIT),
    mergeMap((action) =>
      addSizeContainer(action.payload).pipe(
        map((response) => {
          return onEpicSuccess(
            action,
            response,
            fetchSizeContainerInit({
              page: state$.value.sizeContainer.query.page,
              per_page: state$.value.sizeContainer.query.per_page,
            })
          );
        }),
        catchError((error) =>
          of(onEpicFailed(action, error, editSizeContainerFailed(error)))
        ),
        takeUntil(action$.pipe(ofType(CANCEL_ALL_REQUEST)))
      )
    )
  );
};

export const deleteSizeContainerEpic = (
  action$: ActionsObservable<Action>,
  state$: any
) => {
  return action$.pipe(
    ofType(DELETE_SIZECONTAINER_INIT),
    mergeMap((action) =>
      deleteOneSizeContainer(action.payload).pipe(
        map((response) => {
          return onEpicSuccess(
            action,
            response,
            fetchSizeContainerInit({
              page: state$.value.sizeContainer.query.page,
              per_page: state$.value.sizeContainer.query.per_page,
            })
          );
        }),
        catchError((error) =>
          of(onEpicFailed(action, error, editSizeContainerFailed(error)))
        ),
        takeUntil(action$.pipe(ofType(CANCEL_ALL_REQUEST)))
      )
    )
  );
};
