import { Api } from "../api";
import { deleteChargeType } from "../../models/deleteChargeType";
import { ChargeType } from "../../models/ChargeType";
import { ListQuery } from "../../models/redux/ListQuery";

export const getAllChargeType = (query: ListQuery) => {
  return Api.get("/chargetypelist/getAll", {
    page: query.page,
    size: query.per_page,
    name: query.filter?.name || "",
    status: query.filter?.status || "",
  });
};
export const getMainType = (query: ListQuery) => {
  console.log(query);
  return Api.get("/chargetypelist/getAllActiveMainType", {
    page: query.page,
    size: query.per_page,
    name: query?.filter?.name || "",
  });
};
export const addChargeType = (data: ChargeType) => {
  return Api.post("/chargetypelist/createOrEdit", { ...data });
};

export const getOneChargeType = (id: number) => {
  return Api.get("/chargetypelist/createOrEdit", { id });
};

export const deleteOneChargeType = (data: deleteChargeType) => {
  return Api.delete("/chargetypelist/delete", { ...data });
};
