import React from "react";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";
import { InputGroup, FormControl } from "react-bootstrap";
import { FaIcon } from "../FaIcon";
import { upperCase } from "lodash";

const getFieldCSSClasses = (touched: Boolean, errors: any) => {
  const classes = ["form-control input_bootstrap"];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

export function Input({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  withFeedbackLabel = true,
  customFeedbackLabel,
  type = "text",
  screen = "lg",
  size = 3,
  hasLabel = true,
  special = false,
  prependIcon,
  uppercase = false,
  ...props
}: any) {
  return (
    <>
      <div className="row">
        {hasLabel ? (
          <div className={`col-${screen}-${size}`}>
            {label && (
              <label className={`text-nowrap mt-${screen}-2`}>
                {" "}
                {label}{" "}
                {special ? (
                  <span style={{ color: "red", fontSize: "1rem" }}>*</span>
                ) : null}
              </label>
            )}
          </div>
        ) : null}
        <div className="col">
          <InputGroup className="mb-3">
            {prependIcon && (
              <InputGroup.Prepend className="input_bootstrap_group_prepend">
                <InputGroup.Text>
                  <FaIcon icon={prependIcon} />
                </InputGroup.Text>
              </InputGroup.Prepend>
            )}
            <FormControl
              type={type}
              className={getFieldCSSClasses(
                touched[field.name],
                errors[field.name]
              )}
              {...field}
              {...props}
              onChange={(event) => {
                if (props.onChange) {
                  props.onChange(event);
                }

                let { value } = event.target;
                if (uppercase) {
                  value = value.toUpperCase();
                }
                setFieldValue(field.name, value);
              }}
            />
          </InputGroup>
          {withFeedbackLabel && (
            <FieldFeedbackLabel
              error={errors[field.name]}
              touched={touched[field.name]}
              label={label}
              type={type}
              customFeedbackLabel={customFeedbackLabel}
            />
          )}
        </div>
      </div>
    </>
  );
}
