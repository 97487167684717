import React from "react";

interface Props {
	group?: string;
	icon: string;
	className?: string;
}

export const FaIcon = ({ group, icon, className }: Props) => {
	const getGroupClass = () => {
		return group ? group : "fas";
	};

	return <span className={`${getGroupClass()} fa-${icon} ${className}`}></span>;
};
