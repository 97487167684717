import React from "react";
import { Brand } from "./Brand";
import { AsideMenu } from "./menu";

export const Aside = () => {
	return (
		<>
			{/* begin::Aside */}
			<div
				id="kt_aside"
				className={`aside aside-left aside-fixed d-flex flex-column flex-row-auto`}
			>
				<Brand />

				{/* begin::Aside Menu */}
				<div
					id="kt_aside_menu_wrapper"
					className="aside-menu-wrapper flex-column-fluid"
				>
					<AsideMenu disableScroll={false} />
				</div>
				{/* end::Aside Menu */}
			</div>
			{/* end::Aside */}
		</>
	);
};
