import { ActionsObservable, ofType } from "redux-observable";
import { Action } from "../../../models/redux/action";
import {
  ADD_CURRENCYCONVERSION,
  DELETE_CURRENCYCONVERSION,
  FETCH_LIST_CURRENCYCONVERSION,
  fetchListCurrencyConversionSuccess,
  fetchListCurrencyConversionFailed,
  FETCH_LIST_CURRENCYCONVERSION_CANCELLED,
  addCurrencyConversionSuccess,
  addCurrencyConversionFailed,
  ADD_CURRENCYCONVERSION_CANCELLED,
  deleteCurrencyConversionSuccess,
  deleteCurrencyConversionFailed,
  DELETE_CURRENCYCONVERSION_CANCELLED,
  EDIT_CURRENCYCONVERSION,
  fetchListCurrencyConversion,
  editCurrencyConversionFailed,
  addCurrencyConversion,
} from "./actions";
import { mergeMap, map, catchError, takeUntil } from "rxjs/operators";
import { of } from "rxjs";
import { onEpicSuccess, onEpicFailed } from "../../../helpers/reduxEpic";
import { CANCEL_ALL_REQUEST } from "../../default";
import {
  getAllCurrencyConversions,
  addCurrencyConversions,
  deleteCurrencyConversions,
} from "../../../api/currencyConversions";

export const fetchCurrencyConversionEpic = (
  action$: ActionsObservable<Action>
) =>
  action$.pipe(
    ofType(FETCH_LIST_CURRENCYCONVERSION),
    mergeMap((action) =>
      getAllCurrencyConversions(action.payload.query).pipe(
        map((response) =>
          onEpicSuccess(
            action,
            response,
            fetchListCurrencyConversionSuccess(response)
          )
        ),
        catchError((error) =>
          of(
            onEpicFailed(
              action,
              error,
              fetchListCurrencyConversionFailed(error)
            )
          )
        ),
        takeUntil(
          action$.pipe(
            ofType(FETCH_LIST_CURRENCYCONVERSION_CANCELLED, CANCEL_ALL_REQUEST)
          )
        )
      )
    )
  );

export const addCurrencyConversionEpic = (
  action$: ActionsObservable<Action>,
  state$: any
) => {
  return action$.pipe(
    ofType(ADD_CURRENCYCONVERSION),
    mergeMap((action) =>
      addCurrencyConversions(action.payload).pipe(
        map((response) => {
          return onEpicSuccess(
            action,
            response,
            fetchListCurrencyConversion({
              page: state$.value.currencyConversion.query.page,
              per_page: state$.value.currencyConversion.query.per_page,
            })
          );
        }),
        catchError((error) =>
          of(onEpicFailed(action, error, addCurrencyConversionFailed(error)))
        ),
        takeUntil(action$.pipe(ofType(CANCEL_ALL_REQUEST)))
      )
    )
  );
};

export const editCurrencyConversionEpic = (
  action$: ActionsObservable<Action>,
  state$: any
) => {
  return action$.pipe(
    ofType(EDIT_CURRENCYCONVERSION),
    mergeMap((action) =>
      addCurrencyConversions(action.payload).pipe(
        map((response) => {
          return onEpicSuccess(
            action,
            response,
            fetchListCurrencyConversion({
              page: state$.value.currencyConversion.query.page,
              per_page: state$.value.currencyConversion.query.per_page,
            })
          );
        }),
        catchError((error) =>
          of(onEpicFailed(action, error, editCurrencyConversionFailed(error)))
        ),
        takeUntil(action$.pipe(ofType(CANCEL_ALL_REQUEST)))
      )
    )
  );
};

export const deleteCurrencyConversionEpic = (
  action$: ActionsObservable<Action>,
  state$: any
) => {
  return action$.pipe(
    ofType(DELETE_CURRENCYCONVERSION),
    mergeMap((action) =>
      deleteCurrencyConversions(action.payload).pipe(
        map((response) => {
          return onEpicSuccess(
            action,
            response,
            fetchListCurrencyConversion({
              page: state$.value.currencyConversion.query.page,
              per_page: state$.value.currencyConversion.query.per_page,
            })
          );
        }),
        catchError((error) =>
          of(onEpicFailed(action, error, deleteCurrencyConversionFailed(error)))
        ),
        takeUntil(action$.pipe(ofType(CANCEL_ALL_REQUEST)))
      )
    )
  );
};
