import {
  SEARCH_BOOKING,
  BOOKINGNOTE_COMMON_INFO,
  UPDATE_BOOKING,
  UPDATE_BOOKING1,
  MY_BOOKING,
  FETCH_ALL_BOOKING_NOTE,
  FETCH_ALL_BOOKING_NOTE_SUCCESS,
  FETCH_ALL_BOOKING_NOTE_FAILED,
} from "./actions";
import { ListState } from "../../../models/redux/listState";
import { MyBookingNote } from "../../../models/MyBookingNote";
import {
  baseFetchListState,
  fetchListState,
  fetchListStateSuccess,
  fetchListStateFailed,
} from "../../../helpers/reduxAction";
import { Action } from "../../../models/redux/action";

export const bookingNoteReducer = (state: any = {}, action: any) => {
  switch (action.type) {
    case SEARCH_BOOKING:
      return {
        ...state,
        searchBooking: true,
        initBooking: false,
        updateBooking: false,
        updateBooking1: false,
        ourBooking: false,
      };
    case BOOKINGNOTE_COMMON_INFO:
      // 
      return {
        ...state,
        searchBooking: false,
        initBooking: true,
        updateBooking: false,
        updateBooking1: false,
        ourBooking: false,
        shipSchedule: action.payload.shipSchedule,
        bookingNote: action.payload.bookingNote,
        isEdit: action.payload.isEdit,
      };
    case UPDATE_BOOKING:
      return {
        ...state,
        searchBooking: false,
        initBooking: false,
        updateBooking: true,
        updateBooking1: false,
        ourBooking: false,
        shipSchedule: state.shipSchedule,
        bookingNote: action.payload.bookingNote,
      };
    case UPDATE_BOOKING1:
      return {
        ...state,
        searchBooking: false,
        initBooking: false,
        updateBooking: false,
        updateBooking1: true,
        ourBooking: false,
        shipSchedule: state.shipSchedule,
        bookingNote: state.bookingNote,
      };
    case MY_BOOKING:
      return {
        ...state,
        searchBooking: false,
        initBooking: false,
        updateBooking: false,
        updateBooking1: false,
        ourBooking: true,
        shipSchedule: state.shipSchedule,
        bookingNote: state.bookingNote,
      };
    default:
      return {
        ...state,
      };
  }
};

const allBookingNoteState: ListState<MyBookingNote> = baseFetchListState();
export const allBookingNoteReducer = (
  state: ListState<MyBookingNote> = allBookingNoteState,
  action: Action
) => {
  switch (action.type) {
    case FETCH_ALL_BOOKING_NOTE:
      return fetchListState(action);
    case FETCH_ALL_BOOKING_NOTE_SUCCESS:
      return fetchListStateSuccess(action, state);
    case FETCH_ALL_BOOKING_NOTE_FAILED:
      return fetchListStateFailed(action, state);
    default:
      return state;
  }
};
