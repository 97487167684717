import { ListQuery } from "../../../models/redux/ListQuery";
import { ReduxCallbacks } from "../../../models/redux/Callbacks";
import { Charge } from "../../../models/Charge";

export const FETCH_CHARGE_INIT = "FETCH_CHARGE_INIT";
export const FETCH_CHARGE_SUCCESS = "FETCH_CHARGE_SUCCESS";
export const FETCH_CHARGE_FAILED = "FETCH_CHARGE_FAILED";
export const FETCH_CHARGE_CANCELLED = "FETCH_CHARGE_CANCELLED";

export const ADD_CHARGE_INIT = "ADD_CHARGE_INIT";
export const ADD_CHARGE_SUCCESS = "ADD_CHARGE_SUCCESS";
export const ADD_CHARGE_FAILED = "ADD_CHARGE_FAILED";

export const EDIT_CHARGE_INIT = "EDIT_CHARGE_INIT";
export const EDIT_CHARGE_SUCCESS = "EDIT_CHARGE_SUCCESS";
export const EDIT_CHARGE_FAILED = "EDIT_CHARGE_FAILED";

export const DELETE_CHARGE_INIT = "DELETE_CHARGE_INIT";
export const DELETE_CHARGE_SUCCESS = "DELETE_CHARGE_SUCCESS";
export const DELETE_CHARGE_FAILED = "DELETE_CHARGE_FAILED";

export const fetchChargeInit = (
  query: ListQuery,
  callbacks?: ReduxCallbacks
) => ({
  type: FETCH_CHARGE_INIT,
  payload: { query, callbacks },
});

// FETCH

export const fetchChargeSuccess = (response: any) => ({
  type: FETCH_CHARGE_SUCCESS,
  payload: response,
});

export const fetchChargeFailed = (error: any) => ({
  type: FETCH_CHARGE_FAILED,
  payload: error,
});

export const fetchChargeCancelled = () => ({
  type: FETCH_CHARGE_CANCELLED,
});

// ADD

export const addChargeInit = (content: Charge, callbacks?: ReduxCallbacks) => ({
  type: ADD_CHARGE_INIT,
  payload: { ...content, callbacks },
});

export const addChargeSuccess = () => ({
  type: ADD_CHARGE_SUCCESS,
});

export const addChargeFailed = (error: any) => ({
  type: ADD_CHARGE_SUCCESS,
  payload: error,
});

// EDIT

export const editChargeInit = (
  content: Charge,
  callbacks?: ReduxCallbacks
) => ({
  type: EDIT_CHARGE_INIT,
  payload: { ...content, callbacks },
});

export const editChargeSuccess = () => ({
  type: EDIT_CHARGE_SUCCESS,
});

export const editChargeFailed = (error: any) => ({
  type: EDIT_CHARGE_FAILED,
  payload: error,
});

// DELETE

export const deleteChargeInit = (
  content: Charge,
  callbacks?: ReduxCallbacks
) => {
  return {
    type: DELETE_CHARGE_INIT,
    payload: { ...content, callbacks },
  };
};

export const deleteChargeSuccess = () => ({
  type: DELETE_CHARGE_SUCCESS,
});

export const deleteChargeFailed = (error: any) => ({
  type: DELETE_CHARGE_FAILED,
  payload: error,
});
