import { Action } from "../../../models/redux/action";
import { mergeMap, map, catchError, takeUntil } from "rxjs/operators";
import {
  FETCH_TYPECONTAINER_INIT,
  fetchTypeContainerSuccess,
  fetchTypeContainerFailed,
  FETCH_TYPECONTAINER_CANCELLED,
  ADD_TYPECONTAINER_INIT,
  addTypeContainerFailed,
  EDIT_TYPECONTAINER_INIT,
  editTypeContainerFailed,
  DELETE_TYPECONTAINER_INIT,
  fetchTypeContainerInit,
} from "./actions";
import { ActionsObservable, ofType } from "redux-observable";
import { of } from "rxjs";
import { CANCEL_ALL_REQUEST } from "../../default";
import { onEpicSuccess, onEpicFailed } from "../../../helpers/reduxEpic";
import {
  getAllTypeContainer,
  addTypeContainer,
  deleteOneTypeContainer,
} from "../../../api/typeContainer";

export const fetchListTypeContainerEpic = (
  action$: ActionsObservable<Action>
) => {
  return action$.pipe(
    ofType(FETCH_TYPECONTAINER_INIT),
    mergeMap((action) =>
      getAllTypeContainer(
        action.payload.query?.filter?.name,
        action.payload.query.page,
        action.payload.query.per_page,
        action.payload.query?.filter?.status
      ).pipe(
        map((response) =>
          onEpicSuccess(action, response, fetchTypeContainerSuccess(response))
        ),
        catchError((error) =>
          of(onEpicFailed(action, error, fetchTypeContainerFailed(error)))
        ),
        takeUntil(
          action$.pipe(
            ofType(FETCH_TYPECONTAINER_CANCELLED, CANCEL_ALL_REQUEST)
          )
        )
      )
    )
  );
};

export const addTypeContainerEpic = (
  action$: ActionsObservable<Action>,
  state$: any
) => {
  return action$.pipe(
    ofType(ADD_TYPECONTAINER_INIT),
    mergeMap((action) =>
      addTypeContainer(action.payload).pipe(
        map((response) => {
          return onEpicSuccess(
            action,
            response,
            fetchTypeContainerInit({
              page: state$.value.typeContainer.query.page,
              per_page: state$.value.typeContainer.query.per_page,
            })
          );
        }),
        catchError((error) =>
          of(onEpicFailed(action, error, addTypeContainerFailed(error)))
        ),
        takeUntil(action$.pipe(ofType(CANCEL_ALL_REQUEST)))
      )
    )
  );
};

export const editTypeContainerEpic = (
  action$: ActionsObservable<Action>,
  state$: any
) => {
  return action$.pipe(
    ofType(EDIT_TYPECONTAINER_INIT),
    mergeMap((action) =>
      addTypeContainer(action.payload).pipe(
        map((response) => {
          return onEpicSuccess(
            action,
            response,
            fetchTypeContainerInit({
              page: state$.value.typeContainer.query.page,
              per_page: state$.value.typeContainer.query.per_page,
            })
          );
        }),
        catchError((error) =>
          of(onEpicFailed(action, error, editTypeContainerFailed(error)))
        ),
        takeUntil(action$.pipe(ofType(CANCEL_ALL_REQUEST)))
      )
    )
  );
};

export const deleteTypeContainerEpic = (
  action$: ActionsObservable<Action>,
  state$: any
) => {
  return action$.pipe(
    ofType(DELETE_TYPECONTAINER_INIT),
    mergeMap((action) =>
      deleteOneTypeContainer(action.payload).pipe(
        map((response) => {
          return onEpicSuccess(
            action,
            response,
            fetchTypeContainerInit({
              page: state$.value.typeContainer.query.page,
              per_page: state$.value.typeContainer.query.per_page,
            })
          );
        }),
        catchError((error) =>
          of(onEpicFailed(action, error, editTypeContainerFailed(error)))
        ),
        takeUntil(action$.pipe(ofType(CANCEL_ALL_REQUEST)))
      )
    )
  );
};
