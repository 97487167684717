import { Action } from "../../../models/redux/action";
import { ActionsObservable, ofType } from "redux-observable";
import {
	SET_CURRENT_UNIT,
	setCurrentUnitSuccess,
	setCurrentUnitFailed,
} from "./actions";
import { Auth } from "../../../services/auth";
import { loginSuccess, fetchPermissions } from "../auth";
import { catchError, mergeMap } from "rxjs/operators";
import { of } from "rxjs";
import { onEpicSuccess, onEpicFailed } from "../../../helpers/reduxEpic";

export const currentUnitEpic = (action$: ActionsObservable<Action>) =>
	action$.pipe(
		ofType(SET_CURRENT_UNIT),
		mergeMap((action: Action) => {
			return Auth.chooseUnitDepartment(action.payload.unit).pipe(
				mergeMap(({ response }) =>
					onEpicSuccess(
						action,
						response,
						of(
							setCurrentUnitSuccess(action.payload.unit),
							loginSuccess({ token: response.accessToken }),
							fetchPermissions(action.payload.unit.unitId)
						)
					)
				),
				catchError((error) =>
					onEpicFailed(action, error, of(setCurrentUnitFailed(error)))
				)
			);
		})
	);
