import { Action } from "../../../models/redux/action";
import { mergeMap, map, catchError, takeUntil } from "rxjs/operators";
import {
  FETCH_MERCHANT_INIT,
  fetchMerchantSuccess,
  fetchMerchantFailed,
  FETCH_MERCHANT_CANCELLED,
  ADD_MERCHANT_INIT,
  addMerchantFailed,
  EDIT_MERCHANT_INIT,
  editMerchantFailed,
  DELETE_MERCHANT_INIT,
  fetchListMerchant,
} from "./actions";
import { ActionsObservable, ofType } from "redux-observable";
import { of } from "rxjs";
import { CANCEL_ALL_REQUEST } from "../../default";
import { onEpicSuccess, onEpicFailed } from "../../../helpers/reduxEpic";
import {
  getAllMerchants,
  addMerchant,
  deleteMerchant,
} from "../../../api/merchant";

export const fetchMerchantsEpic = (action$: ActionsObservable<Action>) => {
  return action$.pipe(
    ofType(FETCH_MERCHANT_INIT),
    mergeMap((action) =>
      getAllMerchants(action.payload.query).pipe(
        map((response) =>
          onEpicSuccess(action, response, fetchMerchantSuccess(response))
        ),
        catchError((error) =>
          of(onEpicFailed(action, error, fetchMerchantFailed(error)))
        ),
        takeUntil(
          action$.pipe(ofType(FETCH_MERCHANT_CANCELLED, CANCEL_ALL_REQUEST))
        )
      )
    )
  );
};

export const addMerchantEpic = (
  action$: ActionsObservable<Action>,
  state$: any
) => {
  return action$.pipe(
    ofType(ADD_MERCHANT_INIT),
    mergeMap((action) =>
      addMerchant(action.payload).pipe(
        map((response) => {
          return onEpicSuccess(
            action,
            response,
            fetchListMerchant({
              page: state$.value.merchant.query.page,
              per_page: state$.value.merchant.query.per_page,
            })
          );
        }),
        catchError((error) =>
          of(onEpicFailed(action, error, addMerchantFailed(error)))
        ),
        takeUntil(action$.pipe(ofType(CANCEL_ALL_REQUEST)))
      )
    )
  );
};

export const editMerchantEpic = (
  action$: ActionsObservable<Action>,
  state$: any
) => {
  return action$.pipe(
    ofType(EDIT_MERCHANT_INIT),
    mergeMap((action) =>
      addMerchant(action.payload).pipe(
        map((response) => {
          return onEpicSuccess(
            action,
            response,
            fetchListMerchant({
              page: state$.value.merchant.query.page,
              per_page: state$.value.merchant.query.per_page,
            })
          );
        }),
        catchError((error) =>
          of(onEpicFailed(action, error, editMerchantFailed(error)))
        ),
        takeUntil(action$.pipe(ofType(CANCEL_ALL_REQUEST)))
      )
    )
  );
};

export const deleteMerchantEpic = (
  action$: ActionsObservable<Action>,
  state$: any
) => {
  return action$.pipe(
    ofType(DELETE_MERCHANT_INIT),
    mergeMap((action) =>
      deleteMerchant(action.payload.merchantId).pipe(
        map((response) => {
          return onEpicSuccess(
            action,
            response,
            fetchListMerchant({
              page: state$.value.merchant.query.page,
              per_page: state$.value.merchant.query.per_page,
            })
          );
        }),
        catchError((error) =>
          of(onEpicFailed(action, error, editMerchantFailed(error)))
        ),
        takeUntil(action$.pipe(ofType(CANCEL_ALL_REQUEST)))
      )
    )
  );
};
