import { ListState } from "../../../models/redux/listState";
import { Office } from "../../../models/Office";
import { Action } from "../../../models/redux/action";
import {
	FETCH_UNIT_INIT,
	FETCH_UNIT_SUCCESS,
	FETCH_UNIT_FAILED,
} from "./actions";
import {
	fetchListState,
	fetchListStateSuccess,
	fetchListStateFailed,
	baseFetchListState,
} from "../../../helpers/reduxAction";

const initState: ListState<Office> = baseFetchListState();

export const unitReducer = (
	state: ListState<Office> = initState,
	action: Action
) => {
	switch (action.type) {
		case FETCH_UNIT_INIT:
			return fetchListState(action);

		case FETCH_UNIT_SUCCESS:
			return fetchListStateSuccess(action, state);

		case FETCH_UNIT_FAILED:
			return fetchListStateFailed(action, state);

		default:
			return state;
	}
};
