import { isObject } from "lodash";
import { Notifications } from "../constants/notifications";

export const getNotification = (
	code: string,
	notifications = Notifications
): string => {
	const parts = code.split(".");
	const name = parts.splice(0, 1)[0];
	if (isObject(notifications[name])) {
		return getNotification(parts.join("."), notifications[name]);
	}

	return notifications[name];
};
