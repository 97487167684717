import { Action } from "../../../models/redux/action";
import { mergeMap, map, catchError, takeUntil } from "rxjs/operators";
import {
  FETCH_FEE_INIT,
  fetchFeeSuccess,
  fetchFeeFailed,
  FETCH_FEE_CANCELLED,
  ADD_FEE_INIT,
  addFeeFailed,
  EDIT_FEE_INIT,
  editFeeFailed,
  deleteFeeFailed,
  DELETE_FEE_INIT,
  fetchFeeInit,
} from "./actions";
import { ActionsObservable, ofType } from "redux-observable";
import { of } from "rxjs";
import { CANCEL_ALL_REQUEST } from "../../default";
import { onEpicSuccess, onEpicFailed } from "../../../helpers/reduxEpic";
import { getAllFee, addFee, deleteOneFee } from "../../../api/fees";

export const fetchListFeeEpic = (action$: ActionsObservable<Action>) => {
  return action$.pipe(
    ofType(FETCH_FEE_INIT),
    mergeMap((action) =>
      getAllFee(action.payload.query).pipe(
        map((response) =>
          onEpicSuccess(action, response, fetchFeeSuccess(response))
        ),
        catchError((error) =>
          of(onEpicFailed(action, error, fetchFeeFailed(error)))
        ),
        takeUntil(action$.pipe(ofType(FETCH_FEE_CANCELLED, CANCEL_ALL_REQUEST)))
      )
    )
  );
};

export const addFeeEpic = (action$: ActionsObservable<Action>, state$: any) => {
  return action$.pipe(
    ofType(ADD_FEE_INIT),
    mergeMap((action) =>
      addFee(action.payload.fee).pipe(
        map((response) => {
          return onEpicSuccess(
            action,
            response,
            fetchFeeInit({
              page: state$.value.fee.query.page,
              per_page: state$.value.fee.query.per_page,
            })
          );
        }),
        catchError((error) =>
          of(onEpicFailed(action, error, addFeeFailed(error)))
        ),
        takeUntil(action$.pipe(ofType(CANCEL_ALL_REQUEST)))
      )
    )
  );
};

export const editFeeEpic = (
  action$: ActionsObservable<Action>,
  state$: any
) => {
  return action$.pipe(
    ofType(EDIT_FEE_INIT),
    mergeMap((action) =>
      addFee(action.payload.fee).pipe(
        map((response) => {
          return onEpicSuccess(
            action,
            response,
            fetchFeeInit({
              page: state$.value.fee.query.page,
              per_page: state$.value.fee.query.per_page,
            })
          );
        }),
        catchError((error) =>
          of(onEpicFailed(action, error, editFeeFailed(error)))
        ),
        takeUntil(action$.pipe(ofType(CANCEL_ALL_REQUEST)))
      )
    )
  );
};

export const deleteFeeEpic = (
  action$: ActionsObservable<Action>,
  state$: any
) => {
  return action$.pipe(
    ofType(DELETE_FEE_INIT),
    mergeMap((action) =>
      deleteOneFee(action.payload).pipe(
        map((response) => {
          return onEpicSuccess(
            action,
            response,
            fetchFeeInit({
              page: state$.value.fee.query.page,
              per_page: state$.value.fee.query.per_page,
            })
          );
        }),
        catchError((error) =>
          of(onEpicFailed(action, error, deleteFeeFailed(error)))
        ),
        takeUntil(action$.pipe(ofType(CANCEL_ALL_REQUEST)))
      )
    )
  );
};
