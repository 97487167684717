import { ListQuery } from "../../../models/redux/ListQuery";
import { ReduxCallbacks } from "../../../models/redux/Callbacks";
import { Action } from "../../../models/redux/action";
import { AjaxResponse } from "rxjs/ajax";

export const FETCH_CONTAINER = "FETCH_CONTAINER";
export const FETCH_CONTAINER_CANCELLED = "FETCH_CONTAINER_CANCELLED";
export const FETCH_CONTAINER_SUCCESS = "FETCH_CONTAINER_SUCCESS";
export const FETCH_CONTAINER_FAILED = "FETCH_CONTAINER_FAILED";

export const fetchContainer = (
  query: ListQuery,
  callbacks?: ReduxCallbacks
): Action => {
  return {
    type: FETCH_CONTAINER,
    payload: { query, callbacks },
  };
};

export const fetchContainerSuccess = ({ response }: AjaxResponse): Action => {
  return {
    type: FETCH_CONTAINER_SUCCESS,
    payload: response,
  };
};
export const fetchContainerFailed = () => {
  return {
    type: FETCH_CONTAINER_FAILED,
  };
};

export const fetchContainerCancelled = () => ({
  type: FETCH_CONTAINER_CANCELLED,
});
