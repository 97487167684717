import { Api } from "../api";
import { deleteChargeGroup } from "../../models/deleteChargeGroup";
import { ChargeGroup } from "../../models/ChargeGroup";
import { ListQuery } from "../../models/redux/ListQuery";

export const getAllChargeGroup = (query: ListQuery) => {
  return Api.get("/chargegrouplist/getAll", {
    page: query.page,
    size: query.per_page,
    name: query.filter?.name || "",
    status: query.filter?.status || "",
  });
};

export const addChargeGroup = (data: ChargeGroup) => {
  return Api.post("/chargegrouplist/createOrEdit", { ...data });
};

export const getOneChargeGroup = (id: number) => {
  return Api.get("/chargegrouplist/createOrEdit", { id });
};

export const deleteOneChargeGroup = (data: deleteChargeGroup) => {
  return Api.get("/chargegrouplist/delete", { ...data });
};
