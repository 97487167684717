import { Action } from "../../../models/redux/action";
import { mergeMap, map, catchError, takeUntil } from "rxjs/operators";
import { Api } from "../../../api";
import {
  FETCH_OFFICE_INIT,
  fetchOfficeSuccess,
  fetchOfficeFailed,
  FETCH_OFFICE_CANCELLED,
  ADD_OFFICE_INIT,
  addOfficeFailed,
  EDIT_OFFICE_INIT,
  editOfficeFailed,
  DELETE_OFFICE_INIT,
  fetchOfficeInit,
} from "./actions";
import { ActionsObservable, ofType } from "redux-observable";
import { of } from "rxjs";
import { CANCEL_ALL_REQUEST } from "../../default";
import { onEpicSuccess, onEpicFailed } from "../../../helpers/reduxEpic";
import {
  getAllOfficeService,
  addOfficeSerive,
  deleteOfficeService,
} from "../../../api/office";

export const fetchListOfficeEpic = (action$: ActionsObservable<Action>) => {
  return action$.pipe(
    ofType(FETCH_OFFICE_INIT),
    mergeMap((action) =>
      getAllOfficeService(
        action.payload.query.page,
        action.payload.query.per_page,
        action.payload.query.filter
      ).pipe(
        map((response) =>
          onEpicSuccess(action, response, fetchOfficeSuccess(response))
        ),
        catchError((error) =>
          of(onEpicFailed(action, error, fetchOfficeFailed(error)))
        ),
        takeUntil(
          action$.pipe(ofType(FETCH_OFFICE_CANCELLED, CANCEL_ALL_REQUEST))
        )
      )
    )
  );
};

export const addOfficeEpic = (
  action$: ActionsObservable<Action>,
  state$: any
) => {
  return action$.pipe(
    ofType(ADD_OFFICE_INIT),
    mergeMap((action) =>
      addOfficeSerive(action.payload).pipe(
        map((response) => {
          return onEpicSuccess(
            action,
            response,
            fetchOfficeInit({
              page: state$.value.office.query.page,
              per_page: state$.value.office.query.per_page,
            })
          );
        }),
        catchError((error) =>
          of(onEpicFailed(action, error, addOfficeFailed(error)))
        ),
        takeUntil(action$.pipe(ofType(CANCEL_ALL_REQUEST)))
      )
    )
  );
};

export const editOfficeEpic = (
  action$: ActionsObservable<Action>,
  state$: any
) => {
  return action$.pipe(
    ofType(EDIT_OFFICE_INIT),
    mergeMap((action) =>
      addOfficeSerive(action.payload).pipe(
        map((response) => {
          console.log("aaa", response);
          console.log(state$);
          return onEpicSuccess(
            action,
            response,
            fetchOfficeInit({
              page: state$.value.office.query.page,
              per_page: state$.value.office.query.per_page,
            })
          );
        }),
        catchError((error) =>
          of(onEpicFailed(action, error, editOfficeFailed(error)))
        ),
        takeUntil(action$.pipe(ofType(CANCEL_ALL_REQUEST)))
      )
    )
  );
};

export const deleteOfficeEpic = (
  action$: ActionsObservable<Action>,
  state$: any
) => {
  return action$.pipe(
    ofType(DELETE_OFFICE_INIT),
    mergeMap((action) =>
      deleteOfficeService(action.payload).pipe(
        map((response) => {
          return onEpicSuccess(
            action,
            response,
            fetchOfficeInit({
              page: state$.value.office.query.page,
              per_page: state$.value.office.query.per_page,
            })
          );
        }),
        catchError((error) =>
          of(onEpicFailed(action, error, editOfficeFailed(error)))
        ),
        takeUntil(action$.pipe(ofType(CANCEL_ALL_REQUEST)))
      )
    )
  );
};
