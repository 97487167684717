export const toAbsoluteUrl = (pathname: string) =>
	process.env.PUBLIC_URL + pathname;

export const getRoutePathWithParam = (
	path: string,
	data?: { [key: string]: string }
) => {
	let result = path;

	for (const k in data) {
		result = result.replace(`:${k}`, data[k]);
	}

	return result;
};

export function getCurrentUrl(location: any) {
	return location.pathname.split(/[?#]/)[0];
}

export function checkIsActive(
	location: any,
	url: string,
	exact: boolean = false
) {
	return isCurrentUrl(location, url, exact);
}

export function isCurrentUrl(
	location: any,
	url: string,
	exact: boolean = false
) {
	const current = getCurrentUrl(location);
	if (!current || !url) {
		return false;
	}

	if (current === url) {
		return true;
	}

	if (!exact && current.indexOf(url) > -1) {
		return true;
	}

	return false;
}
