import { ActionsObservable, ofType } from "redux-observable";
import { Action } from "../../../models/redux/action";
import {
	ADD_POST_OF_CALLING,
	DELETE_POST_OF_CALLING,
	FETCH_LIST_POST_OF_CALLING,
	fetchListPortOfCallingSuccess,
	fetchListPortOfCallingFailed,
	FETCH_LIST_POST_OF_CALLING_CANCELLED,
	addPortOfCallingSuccess,
	addPortOfCallingFailed,
	ADD_POST_OF_CALLING_CANCELLED,
	deletePortOfCallingSuccess,
	deletePortOfCallingFailed,
	DELETE_POST_OF_CALLING_CANCELLED,
} from "./actions";
import { mergeMap, map, catchError, takeUntil } from "rxjs/operators";
import { of } from "rxjs";
import { onEpicSuccess, onEpicFailed } from "../../../helpers/reduxEpic";
import { CANCEL_ALL_REQUEST } from "../../default";
import {
	getAllPortOfCallings,
	createOrEditPortOfCalling,
	deletePortOfCalling,
} from "../../../api/portOfCalling";

export const fetchPortOfCallingsEpic = (action$: ActionsObservable<Action>) =>
	action$.pipe(
		ofType(FETCH_LIST_POST_OF_CALLING),
		mergeMap((action) =>
			getAllPortOfCallings(action.payload.query).pipe(
				map((response) =>
					onEpicSuccess(
						action,
						response,
						fetchListPortOfCallingSuccess(response)
					)
				),
				catchError((error) =>
					of(onEpicFailed(action, error, fetchListPortOfCallingFailed(error)))
				),
				takeUntil(
					action$.pipe(
						ofType(FETCH_LIST_POST_OF_CALLING_CANCELLED, CANCEL_ALL_REQUEST)
					)
				)
			)
		)
	);

export const addPortOfCallingEpic = (action$: ActionsObservable<Action>) =>
	action$.pipe(
		ofType(ADD_POST_OF_CALLING),
		mergeMap((action) =>
			createOrEditPortOfCalling(action.payload.portOfCalling).pipe(
				map((response) =>
					onEpicSuccess(action, response, addPortOfCallingSuccess())
				),
				catchError((error) =>
					of(onEpicFailed(action, error, addPortOfCallingFailed()))
				),
				takeUntil(
					action$.pipe(
						ofType(ADD_POST_OF_CALLING_CANCELLED, CANCEL_ALL_REQUEST)
					)
				)
			)
		)
	);

export const deletePortOfCallingEpic = (action$: ActionsObservable<Action>) =>
	action$.pipe(
		ofType(DELETE_POST_OF_CALLING),
		mergeMap((action) =>
			deletePortOfCalling(action.payload.portOfCallingId).pipe(
				map((response) =>
					onEpicSuccess(action, response, deletePortOfCallingSuccess())
				),
				catchError((error) =>
					of(onEpicFailed(action, error, deletePortOfCallingFailed()))
				),
				takeUntil(
					action$.pipe(
						ofType(DELETE_POST_OF_CALLING_CANCELLED, CANCEL_ALL_REQUEST)
					)
				)
			)
		)
	);
