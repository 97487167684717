import { Action } from "../../../models/redux/action";
import { ReduxCallbacks } from "../../../models/redux/Callbacks";
import { ListQuery } from "../../../models/redux/ListQuery";
import { AjaxResponse } from "rxjs/ajax";
import { Address } from "../../../models/Address";

export const FETCH_LIST_ADDRESS = "FETCH_LIST_ADDRESS";
export const FETCH_LIST_ADDRESS_SUCCESS = "FETCH_LIST_ADDRESS_SUCCESS";
export const FETCH_LIST_ADDRESS_FAILED = "FETCH_LIST_ADDRESS_FAILED";
export const FETCH_LIST_ADDRESS_CANCELLED = "FETCH_LIST_ADDRESS_CANCELLED";

export const fetchListAddress = (
	query: ListQuery,
	callbacks?: ReduxCallbacks
): Action => ({
	type: FETCH_LIST_ADDRESS,
	payload: { query, callbacks },
});

export const fetchListAddressSuccess = ({
	response,
}: AjaxResponse): Action => ({
	type: FETCH_LIST_ADDRESS_SUCCESS,
	payload: { response },
});

export const fetchListAddressFailed = (error: any): Action => ({
	type: FETCH_LIST_ADDRESS_FAILED,
	payload: error,
});

export const fetchListAddressCancelled = (): Action => ({
	type: FETCH_LIST_ADDRESS_CANCELLED,
});

// ADD ADDRESS
export const ADD_ADDRESS = "ADD_ADDRESS";
export const ADD_ADDRESS_SUCCESS = "ADD_ADDRESS_SUCCESS";
export const ADD_ADDRESS_FAILED = "ADD_ADDRESS_FAILED";
export const ADD_ADDRESS_CANCELLED = "ADD_ADDRESS_CANCELLED";

export const addAddress = (
	address: Address,
	callbacks?: ReduxCallbacks
): Action => ({
	type: ADD_ADDRESS,
	payload: { address, callbacks },
});

export const addAddressSuccess = (): Action => ({
	type: ADD_ADDRESS_SUCCESS,
});

export const addAddressFailed = (): Action => ({
	type: ADD_ADDRESS_FAILED,
});

export const addAddressCancelled = (): Action => ({
	type: ADD_ADDRESS_CANCELLED,
});

// DELETE ADDRESS
export const DELETE_ADDRESS = "DELETE_ADDRESS";
export const DELETE_ADDRESS_SUCCESS = "DELETE_ADDRESS_SUCCESS";
export const DELETE_ADDRESS_FAILED = "DELETE_ADDRESS_FAILED";
export const DELETE_ADDRESS_CANCELLED = "DELETE_ADDRESS_CANCELLED";

export const deleteAddress = (
	addressId: number,
	callbacks?: ReduxCallbacks
): Action => ({
	type: DELETE_ADDRESS,
	payload: { addressId, callbacks },
});

export const deleteAddressSuccess = (): Action => ({
	type: DELETE_ADDRESS_SUCCESS,
});

export const deleteAddressFailed = (): Action => ({
	type: DELETE_ADDRESS_FAILED,
});

export const deleteAddressCancelled = (): Action => ({
	type: DELETE_ADDRESS_CANCELLED,
});
