import { ListQuery } from "../../../models/redux/ListQuery";
import { ReduxCallbacks } from "../../../models/redux/Callbacks";
import { ShipSchedule } from "../../../models/ShipSchedule";

export const FETCH_SHIP_SCHEDULE_INIT = "FETCH_SHIP_SCHEDULE_INIT";
export const FETCH_SHIP_SCHEDULE_SUCCESS = "FETCH_SHIP_SCHEDULE_SUCCESS";
export const FETCH_SHIP_SCHEDULE_FAILED = "FETCH_SHIP_SCHEDULE_FAILED";
export const FETCH_SHIP_SCHEDULE_CANCELLED = "FETCH_SHIP_SCHEDULE_CANCELLED";

export const FETCH_SHIP_SCHEDULE_SYSTEM_INIT =
	"FETCH_SHIP_SCHEDULE_SYSTEM_INIT";
export const FETCH_SHIP_SCHEDULE_SYSTEM_SUCCESS =
	"FETCH_SHIP_SCHEDULE_SYSTEM_SUCCESS";
export const FETCH_SHIP_SCHEDULE_SYSTEM_FAILED =
	"FETCH_SHIP_SCHEDULE_SYSTEM_FAILED";
export const FETCH_SHIP_SCHEDULE_SYSTEM_CANCELLED =
	"FETCH_SHIP_SCHEDULE_SYSTEM_CANCELLED";

export const RESET_LIST_SHIP_SCHEDULE_CUSTOMER =
	"RESET_LIST_SHIP_SCHEDULE_CUSTOMER";
export const FETCH_SHIP_SCHEDULE_CUSTOMER_INIT =
	"FETCH_SHIP_SCHEDULE_CUSTOMER_INIT";
export const FETCH_SHIP_SCHEDULE_CUSTOMER_SUCCESS =
	"FETCH_SHIP_SCHEDULE_CUSTOMER_SUCCESS";
export const FETCH_SHIP_SCHEDULE_CUSTOMER_FAILED =
	"FETCH_SHIP_SCHEDULE_CUSTOMER_FAILED";
export const FETCH_SHIP_SCHEDULE_CUSTOMER_CANCELLED =
	"FETCH_SHIP_SCHEDULE_CUSTOMER_CANCELLED";

export const ADD_SHIP_SCHEDULE_INIT = "ADD_SHIP_SCHEDULE_INIT";
export const ADD_SHIP_SCHEDULE_SUCCESS = "ADD_SHIP_SCHEDULE_SUCCESS";
export const ADD_SHIP_SCHEDULE_FAILED = "ADD_SHIP_SCHEDULE_FAILED";
export const ADD_SHIP_SCHEDULE_CANCELLED = "ADD_SHIP_SCHEDULE_CANCELLED";

export const EDIT_SHIP_SCHEDULE_INIT = "EDIT_SHIP_SCHEDULE_INIT";
export const EDIT_SHIP_SCHEDULE_SUCCESS = "EDIT_SHIP_SCHEDULE_SUCCESS";
export const EDIT_SHIP_SCHEDULE_FAILED = "EDIT_SHIP_SCHEDULE_FAILED";
export const EDIT_SHIP_SCHEDULE_CANCELLED = "EDIT_SHIP_SCHEDULE_CANCELLED";

export const DELETE_SHIP_SCHEDULE_INIT = "DELETE_SHIP_SCHEDULE_INIT";
export const DELETE_SHIP_SCHEDULE_SUCCESS = "DELETE_SHIP_SCHEDULE_SUCCESS";
export const DELETE_SHIP_SCHEDULE_FAILED = "DELETE_SHIP_SCHEDULE_FAILED";
export const DELETE_SHIP_SCHEDULE_CANCELLED = "DELETE_SHIP_SCHEDULE_CANCELLED";

export const fetchShipScheduleInit = (
	query: ListQuery,
	callbacks?: ReduxCallbacks
) => ({
	type: FETCH_SHIP_SCHEDULE_INIT,
	payload: { query, callbacks },
});

// FETCH

export const fetchShipScheduleSuccess = (response: any) => ({
	type: FETCH_SHIP_SCHEDULE_SUCCESS,
	payload: response,
});

export const fetchShipScheduleFailed = (error: any) => ({
	type: FETCH_SHIP_SCHEDULE_FAILED,
	payload: error,
});

export const fetchShipScheduleCancelled = () => ({
	type: FETCH_SHIP_SCHEDULE_CANCELLED,
});

// FETCH SYSTEM
export const fetchShipScheduleSystemInit = (
	query: ListQuery,
	callbacks?: ReduxCallbacks
) => ({
	type: FETCH_SHIP_SCHEDULE_SYSTEM_INIT,
	payload: { query, callbacks },
});

export const fetchShipScheduleSystemSuccess = (response: any) => ({
	type: FETCH_SHIP_SCHEDULE_SYSTEM_SUCCESS,
	payload: response,
});

export const fetchShipScheduleSystemFailed = (error: any) => ({
	type: FETCH_SHIP_SCHEDULE_SYSTEM_FAILED,
	payload: error,
});

export const fetchShipScheduleSystemCancelled = () => ({
	type: FETCH_SHIP_SCHEDULE_SYSTEM_CANCELLED,
});

// FETCH CUSTOMER
export const resetListShipScheduleCustomer = () => ({
	type: RESET_LIST_SHIP_SCHEDULE_CUSTOMER,
});

export const fetchShipScheduleCustomerInit = (
	query: ListQuery,
	callbacks?: ReduxCallbacks
) => ({
	type: FETCH_SHIP_SCHEDULE_CUSTOMER_INIT,
	payload: { query, callbacks },
});

export const fetchShipScheduleCustomerSuccess = (response: any) => ({
	type: FETCH_SHIP_SCHEDULE_CUSTOMER_SUCCESS,
	payload: response,
});

export const fetchShipScheduleCustomerFailed = (error: any) => ({
	type: FETCH_SHIP_SCHEDULE_CUSTOMER_FAILED,
	payload: error,
});

export const fetchShipScheduleCustomerCancelled = () => ({
	type: FETCH_SHIP_SCHEDULE_CUSTOMER_CANCELLED,
});

// ADD

export const addShipScheduleInit = (
	ship_schedule: ShipSchedule,
	callbacks?: ReduxCallbacks
) => ({
	type: ADD_SHIP_SCHEDULE_INIT,
	payload: { ship_schedule, callbacks },
});

export const addShipScheduleSuccess = () => ({
	type: ADD_SHIP_SCHEDULE_SUCCESS,
});

export const addShipScheduleFailed = (error: any) => ({
	type: ADD_SHIP_SCHEDULE_SUCCESS,
	payload: error,
});

// EDIT

export const editShipScheduleInit = (
	content: ShipSchedule,
	callbacks?: ReduxCallbacks
) => ({
	type: EDIT_SHIP_SCHEDULE_INIT,
	payload: { ...content, callbacks },
});

export const editShipScheduleSuccess = () => ({
	type: EDIT_SHIP_SCHEDULE_SUCCESS,
});

export const editShipScheduleFailed = (error: any) => ({
	type: EDIT_SHIP_SCHEDULE_FAILED,
	payload: error,
});

// DELETE

export const deleteShipScheduleInit = (
	id: number,
	callbacks?: ReduxCallbacks
) => {
	return {
		type: DELETE_SHIP_SCHEDULE_INIT,
		payload: { id, callbacks },
	};
};

export const deleteShipScheduleSuccess = () => ({
	type: DELETE_SHIP_SCHEDULE_SUCCESS,
});

export const deleteShipScheduleFailed = (error: any) => ({
	type: DELETE_SHIP_SCHEDULE_FAILED,
	payload: error,
});
