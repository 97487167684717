import {
  baseFetchListState,
  fetchListState,
  fetchListStateSuccess,
  fetchListStateFailed,
} from "../../../helpers/reduxAction";
import { ListState } from "../../../models/redux/listState";
import { Role } from "../../../models/Role";
import { Action } from "../../../models/redux/action";
import {
  FETCH_QUOTATION,
  FETCH_QUOTATION_SUCCESS,
  FETCH_QUOTATION_FAIL,
  FETCH_QUOTATION_COMMODITY,
  FETCH_QUOTATION_COMMODITY_SUCCESS,
  FETCH_QUOTATION_COMMODITY_FAIL,
} from "./actions";
import { QuotationCommodity } from "../../../models/quotation";

const initState: ListState<Role> = baseFetchListState();

export const quotationReducer = (
  state: ListState<any> = initState,
  action: Action
) => {
  switch (action.type) {
    case FETCH_QUOTATION:
      return fetchListState(action);
    case FETCH_QUOTATION_SUCCESS:
      return fetchListStateSuccess(action, state);
    case FETCH_QUOTATION_FAIL:
      return fetchListStateFailed(action, state);
    default:
      return state;
  }
};
export const quotationCommodityReducer = (
  state: ListState<QuotationCommodity> = initState,
  action: Action
) => {
  switch (action.type) {
    case FETCH_QUOTATION_COMMODITY:
      return fetchListState(action);
    case FETCH_QUOTATION_COMMODITY_SUCCESS:
      return fetchListStateSuccess(action, state);
    case FETCH_QUOTATION_COMMODITY_FAIL:
      return fetchListStateFailed(action, state);
    default:
      return state;
  }
};
