import { Action } from "../../../models/redux/action";
import { CurrentUnit } from "../../../models/CurrentUnit";
import { REMOVE_CURRENT_UNIT, SET_CURRENT_UNIT_SUCCESS } from "./actions";
import { LOGOUT_SUCCESS } from "../auth";

const initState: CurrentUnit | null = null;

export const currentUnitReducer = (
	state: CurrentUnit | null = initState,
	action: Action
) => {
	switch (action.type) {
		case SET_CURRENT_UNIT_SUCCESS:
			return {
				...action.payload,
			};
		case REMOVE_CURRENT_UNIT:
		case LOGOUT_SUCCESS:
			return null;
		default:
			return state;
	}
};
