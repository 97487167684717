import { ListState } from "../../../models/redux/listState";
import { Currency } from "../../../models/Currency";
import { Action } from "../../../models/redux/action";
import {
  FETCH_LIST_CURRENCY,
  FETCH_LIST_CURRENCY_SUCCESS,
  FETCH_LIST_CURRENCY_FAILED,
  ADD_CURRENCY,
  ADD_CURRENCY_SUCCESS,
  ADD_CURRENCY_FAILED,
  EDIT_CURRENCY,
  EDIT_CURRENCY_SUCCESS,
  EDIT_CURRENCY_FAILED,
  DELETE_CURRENCY,
  DELETE_CURRENCY_SUCCESS,
  DELETE_CURRENCY_FAILED,
} from "./actions";
import {
  fetchListState,
  fetchListStateSuccess,
  fetchListStateFailed,
  baseFetchListState,
  addInit,
  addFailed,
  addSuccess,
  deleteInit,
  deleteSuccess,
  deleteFailed,
} from "../../../helpers/reduxAction";

const initState: ListState<Currency> = baseFetchListState();

export const currencyReducer = (
  state: ListState<Currency> = initState,
  action: Action
) => {
  switch (action.type) {
    case FETCH_LIST_CURRENCY:
      return fetchListState(action);
    case FETCH_LIST_CURRENCY_SUCCESS:
      return fetchListStateSuccess(action, state);
    case FETCH_LIST_CURRENCY_FAILED:
      return fetchListStateFailed(action, state);

    case ADD_CURRENCY:
      return addInit(action, state);
    case ADD_CURRENCY_SUCCESS:
      return addSuccess(action, state);
    case ADD_CURRENCY_FAILED:
      return addFailed(action, state);

    case DELETE_CURRENCY:
      return deleteInit(action, state);
    case DELETE_CURRENCY_SUCCESS:
      return deleteSuccess(action, state);
    case DELETE_CURRENCY_FAILED:
      return deleteFailed(action, state);
    default:
      return state;
  }
};
