import { ListQuery } from "../../../models/redux/ListQuery";
import { ReduxCallbacks } from "../../../models/redux/Callbacks";
import { AjaxResponse } from "rxjs/ajax";
import { Action } from "../../../models/redux/action";

export const FETCH_QUOTATION = "FETCH_QUOTATION";
export const FETCH_QUOTATION_SUCCESS = "FETCH_QUOTATION_SUCCESS";
export const FETCH_QUOTATION_FAIL = "FETCH_QUOTATION_FAIL";
export const FETCH_QUOTATION_CANCELLED = "FETCH_QUOTATION_CANCELLED";

export const fetchQuotation = (query: ListQuery, callbacks?: ReduxCallbacks) => ({
  type: FETCH_QUOTATION,
  payload: { query, callbacks },
});

export const fetchQuotationSuccess = (response: AjaxResponse): Action => ({
  type: FETCH_QUOTATION_SUCCESS,
  payload: response,
});

export const fetchQuotationFail = (err: any): Action => ({
  type: FETCH_QUOTATION_FAIL,
  payload: { err },
});

export const fetchQuotationCancelled = (): Action => ({
  type: FETCH_QUOTATION_CANCELLED,
});

export const ADD_QUOTATION = "ADD_QUOTATION";
export const ADD_QUOTATION_SUCCESS = "ADD_QUOTATION_SUCCESS";
export const ADD_QUOTATION_FAIL = "ADD_QUOTATION_FAIL";
export const ADD_QUOTATION_CANCELLED = "ADD_QUOTATION_CANCELLED";

export const addQuotation = (quotation: any, callbacks?: ReduxCallbacks) => ({
  type: ADD_QUOTATION,
  payload: {
    quotation,
    callbacks,
  },
});

export const addQuotationSuccess = (response: AjaxResponse): Action => ({
  type: ADD_QUOTATION_SUCCESS,
  payload: response,
});

export const addQuotationFail = (err: any): Action => ({
  type: ADD_QUOTATION_FAIL,
  payload: { err },
});

export const addQuotationCancelled = (): Action => ({
  type: ADD_QUOTATION_CANCELLED,
});
export const FETCH_QUOTATION_COMMODITY = "FETCH_QUOTATION_COMMODITY";
export const FETCH_QUOTATION_COMMODITY_SUCCESS =
  "FETCH_QUOTATION_COMMODITY_SUCCESS";
export const FETCH_QUOTATION_COMMODITY_FAIL = "FETCH_QUOTATION_COMMODITY_FAIL";
export const FETCH_QUOTATION_COMMODITY_CANCELLED =
  "FETCH_QUOTATION_COMMODITY_CANCELLED";

export const fetchQuotationCommodity = (
  query: ListQuery,
  callbacks?: ReduxCallbacks
) => ({
  type: FETCH_QUOTATION_COMMODITY,
  payload: {
    query,
    callbacks,
  },
});

export const fetchQuotationCommoditySuccess = (
  response: AjaxResponse
): Action => ({
  type: FETCH_QUOTATION_COMMODITY_SUCCESS,
  payload: response,
});

export const fetchQuotationCommodityFail = (err: any): Action => ({
  type: FETCH_QUOTATION_COMMODITY_FAIL,
  payload: { err },
});
export const ADD_QUOTATION_COMMODITY = "ADD_QUOTATION_COMMODITY";
export const ADD_QUOTATION_COMMODITY_SUCCESS = "ADD_QUOTATION_COMMODITY_SUCCESS";
export const ADD_QUOTATION_COMMODITY_FAIL = "ADD_QUOTATION_COMMODITY_FAIL";
export const ADD_QUOTATION_COMMODITY_CANCELLED =
  "ADD_QUOTATION_COMMODITY_CANCELLED";

export const addQuotationCommodity = (
  quotation: any,
  callbacks?: ReduxCallbacks
) => ({
  type: ADD_QUOTATION_COMMODITY,
  payload: {
    quotation,
    callbacks,
  },
});

export const addQuotationCommoditySuccess = (response: AjaxResponse): Action => ({
  type: ADD_QUOTATION_COMMODITY_SUCCESS,
  payload: response,
});

export const addQuotationCommodityFail = (err: any): Action => ({
  type: ADD_QUOTATION_COMMODITY_FAIL,
  payload: { err },
});

export const addQuotationCommodityCancelled = (): Action => ({
  type: ADD_QUOTATION_COMMODITY_CANCELLED,
});

export const DELETE_QUOTATION = "DELETE_QUOTATION";
export const DELETE_QUOTATION_SUCCESS = "DELETE_QUOTATION_SUCCESS";
export const DELETE_QUOTATION_FAIL = "DELETE_QUOTATION_FAIL";
export const DELETE_QUOTATION_CANCELLED = "DELETE_QUOTATION_CANCELLED";
