import React, { FunctionComponent, useEffect } from "react";
import { Route, RouteProps, Redirect } from "react-router-dom";
import { Auth } from "../../services/auth";
import { RoutePaths } from "../../constants";
import { connect } from "react-redux";
import { enableLoading, disableLoading } from "../../redux/modules/loading";
import { ComponentWithLoading } from "../../models/ComponentWithLoading";
import { User } from "../../models/User";

interface Props extends RouteProps, ComponentWithLoading {
	permission?: string;
	showWithUser?: boolean;
	user: User | null;
}

const mapStateToProps = (state: any) => ({
	user: state.auth.user,
});

const mapDispatchToProps = {
	enableLoading,
	disableLoading,
};

export const AuthorizationRoute = connect(
	mapStateToProps,
	mapDispatchToProps
)((props: Props) => {

	return (
		<>
			{props.user ? (
				(props.showWithUser && !Auth.isSystemUser()) ||
				!props?.permission ||
				Auth.hasPermission(props?.permission) ? (
					<Route {...props} />
				) : (
					<Redirect to={RoutePaths.AccessDenied} />
				)
			) : (
				<Redirect to={RoutePaths.Auth.Login} />
			)}
		</>
	);
});
