import { ListQuery } from "../../../models/redux/ListQuery";
import { ReduxCallbacks } from "../../../models/redux/Callbacks";
import { Unit } from "../../../models/Unit";

export const FETCH_UNIT_INIT = "FETCH_UNIT_INIT";
export const FETCH_UNIT_SUCCESS = "FETCH_UNIT_SUCCESS";
export const FETCH_UNIT_FAILED = "FETCH_UNIT_FAILED";
export const FETCH_UNIT_CANCELLED = "FETCH_UNIT_CANCELLED";

export const fetchUnitInit = (
  query: ListQuery,
  callbacks?: ReduxCallbacks
) => ({
  type: FETCH_UNIT_INIT,
  payload: {
    query,
    callbacks,
  },
});

export const fetchUnitSuccess = (response: any) => ({
  type: FETCH_UNIT_SUCCESS,
  payload: response,
});

export const fetchUnitFailed = (error: any) => ({
  type: FETCH_UNIT_FAILED,
  payload: error,
});

export const fetchUnitCancelled = () => ({
  type: FETCH_UNIT_CANCELLED,
});

// ADD UNIT
export const ADD_UNIT = "ADD_UNIT";
export const ADD_UNIT_SUCCESS = "ADD_UNIT_SUCCESS";
export const ADD_UNIT_FAILED = "ADD_UNIT_FAILED";
export const ADD_UNIT_CANCELLED = "ADD_UNIT_CANCELLED";

export const addUnitInit = (unit: Unit, callbacks?: ReduxCallbacks) => ({
  type: ADD_UNIT,
  payload: {
    unit,
    callbacks,
  },
});

export const addUnitSuccess = (response: any) => ({
  type: ADD_UNIT_SUCCESS,
  payload: response,
});

export const addUnitFailed = (error: any) => ({
  type: ADD_UNIT_FAILED,
  payload: error,
});

export const addUnitCancelled = () => ({
  type: ADD_UNIT_CANCELLED,
});

// DELETE UNIT
export const DELETE_UNIT = "DELETE_UNIT";
export const DELETE_UNIT_SUCCESS = "DELETE_UNIT_SUCCESS";
export const DELETE_UNIT_FAILED = "DELETE_UNIT_FAILED";
export const DELETE_UNIT_CANCELLED = "DELETE_UNIT_CANCELLED";

export const deleteUnitInit = (unitId: number, callbacks?: ReduxCallbacks) => ({
  type: DELETE_UNIT,
  payload: {
    unitId,
    callbacks,
  },
});

export const deleteUnitSuccess = (response: any) => ({
  type: DELETE_UNIT_SUCCESS,
  payload: response,
});

export const deleteUnitFailed = (error: any) => ({
  type: DELETE_UNIT_FAILED,
  payload: error,
});

export const deleteUnitCancelled = () => ({
  type: DELETE_UNIT_CANCELLED,
});
