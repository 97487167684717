import { ListQuery } from "../../../models/redux/ListQuery";
import { ReduxCallbacks } from "../../../models/redux/Callbacks";
import { ChargeGroup } from "../../../models/ChargeGroup";

export const FETCH_CHARGEGROUP_INIT = "FETCH_CHARGEGROUP_INIT";
export const FETCH_CHARGEGROUP_SUCCESS = "FETCH_CHARGEGROUP_SUCCESS";
export const FETCH_CHARGEGROUP_FAILED = "FETCH_CHARGEGROUP_FAILED";
export const FETCH_CHARGEGROUP_CANCELLED = "FETCH_CHARGEGROUP_CANCELLED";

export const ADD_CHARGEGROUP_INIT = "ADD_CHARGEGROUP_INIT";
export const ADD_CHARGEGROUP_SUCCESS = "ADD_CHARGEGROUP_SUCCESS";
export const ADD_CHARGEGROUP_FAILED = "ADD_CHARGEGROUP_FAILED";

export const EDIT_CHARGEGROUP_INIT = "EDIT_CHARGEGROUP_INIT";
export const EDIT_CHARGEGROUP_SUCCESS = "EDIT_CHARGEGROUP_SUCCESS";
export const EDIT_CHARGEGROUP_FAILED = "EDIT_CHARGEGROUP_FAILED";

export const DELETE_CHARGEGROUP_INIT = "DELETE_CHARGEGROUP_INIT";
export const DELETE_CHARGEGROUP_SUCCESS = "DELETE_CHARGEGROUP_SUCCESS";
export const DELETE_CHARGEGROUP_FAILED = "DELETE_CHARGEGROUP_FAILED";

export const fetchChargeGroupInit = (
  query: ListQuery,
  callbacks?: ReduxCallbacks
) => ({
  type: FETCH_CHARGEGROUP_INIT,
  payload: { query, callbacks },
});

// FETCH

export const fetchChargeGroupSuccess = (response: any) => ({
  type: FETCH_CHARGEGROUP_SUCCESS,
  payload: response,
});

export const fetchChargeGroupFailed = (error: any) => ({
  type: FETCH_CHARGEGROUP_FAILED,
  payload: error,
});

export const fetchChargeGroupCancelled = () => ({
  type: FETCH_CHARGEGROUP_CANCELLED,
});

// ADD

export const addChargeGroupInit = (
  content: ChargeGroup,
  callbacks?: ReduxCallbacks
) => ({
  type: ADD_CHARGEGROUP_INIT,
  payload: { ...content, callbacks },
});

export const addChargeGroupSuccess = () => ({
  type: ADD_CHARGEGROUP_SUCCESS,
});

export const addChargeGroupFailed = (error: any) => ({
  type: ADD_CHARGEGROUP_SUCCESS,
  payload: error,
});

// EDIT

export const editChargeGroupInit = (
  content: ChargeGroup,
  callbacks?: ReduxCallbacks
) => ({
  type: EDIT_CHARGEGROUP_INIT,
  payload: { ...content, callbacks },
});

export const editChargeGroupSuccess = () => ({
  type: EDIT_CHARGEGROUP_SUCCESS,
});

export const editChargeGroupFailed = (error: any) => ({
  type: EDIT_CHARGEGROUP_FAILED,
  payload: error,
});

// DELETE

export const deleteChargeGroupInit = (
  content: ChargeGroup,
  callbacks?: ReduxCallbacks
) => {
  return {
    type: DELETE_CHARGEGROUP_INIT,
    payload: { ...content, callbacks },
  };
};

export const deleteChargeGroupSuccess = () => ({
  type: DELETE_CHARGEGROUP_SUCCESS,
});

export const deleteChargeGroupFailed = (error: any) => ({
  type: DELETE_CHARGEGROUP_FAILED,
  payload: error,
});
