import { ListState } from "../../../models/redux/listState";
import { Action } from "../../../models/redux/action";
import { BookingList } from "../../../models/bookingList";
import { UserByType } from "../../../models/getUserByType";
import { BookingNotifyAddress } from '../../../models/BookingNotifyAddress';
import {
    FETCH_BOOKING_LIST,
    FETCH_BOOKING_LIST_SUCCESS,
    FETCH_BOOKING_LIST_FAILED,
    GET_ALL_USER_BY_TYPE,
    GET_ALL_USER_BY_TYPE_SUCCESS,
    GET_ALL_USER_BY_TYPE_FAILED,
    GET_BOOKING_NOTIFY_ADDRESS,
    GET_BOOKING_NOTIFY_ADDRESS_SUCCESS,
    GET_BOOKING_NOTIFY_ADDRESS_FAILED,
    GET_VESSEL_REQUEST,
    GET_VESSEL_REQUEST_SUCCESS,
    GET_VESSEL_REQUEST_FAILED,
    DELETE_ROW_NOTIFY_REQUEST,
    DELETE_ROW_NOTIFY_SUCCESS,
    DELETE_ROW_NOTIFY_FAILED,
    BOOKING_SHIPPING_TABLE,
    BOOKING_SHIPPING_TABLE_SUCCESS,
    BOOKING_SHIPPING_TABLE_FAILED,
    DELETE_ROW_SHIPPING,
    DELETE_ROW_SHIPPING_SUCCESS,
    DELETE_ROW_SHIPPING_FAILED,
    BOOKING_COST_TABLE,
    BOOKING_COST_TABLE_SUCCESS,
    BOOKING_COST_TABLE_FAILED,
    DELETE_ROW_COST,
    DELETE_ROW_COST_SUCCESS,
    DELETE_ROW_COST_FAILED,
    BOOKING_CONTAINER_TABLE,
    BOOKING_CONTAINER_TABLE_SUCCESS,
    BOOKING_CONTAINER_TABLE_FAILED,
    DELETE_ROW_CONTAINER,
    DELETE_ROW_CONTAINER_SUCCESS,
    DELETE_ROW_CONTAINER_FAILED,
    BOOKING_CHARGES_TABLE,
    BOOKING_CHARGES_TABLE_SUCCESS,
    BOOKING_CHARGES_TABLE_FAILED,
    DELETE_ROW_CHARGES,
    DELETE_ROW_CHARGES_SUCCESS,
    DELETE_ROW_CHARGES_FAILED
} from './actions';
import {
    fetchListState,
    fetchListStateSuccess,
    fetchListStateFailed,
    baseFetchListState,
    deleteInit,
    deleteSuccess,
    deleteFailed,
} from "../../../helpers/reduxAction";
const initBookingState: ListState<BookingList> = baseFetchListState();
const initAllUserState: ListState<UserByType> = baseFetchListState();
const initBookingNotifyState: ListState<BookingNotifyAddress> = baseFetchListState();
const initVesselState: ListState<any> = baseFetchListState();
const initShippingState: ListState<any> = baseFetchListState();
const initContainerState: ListState<any> = baseFetchListState();
const initChargesState: ListState<any> = baseFetchListState();
const initCostState: ListState<any> = baseFetchListState();

export const bookingListReducer = (
    state: ListState<BookingList> = initBookingState,
    action: Action
) => {
    switch (action.type) {
        case FETCH_BOOKING_LIST:
            return fetchListState(action);
        case FETCH_BOOKING_LIST_SUCCESS:
            return fetchListStateSuccess(action, state);
        case FETCH_BOOKING_LIST_FAILED:
            return fetchListStateFailed(action, state);
        default:
            return state;
    }
};

export const userByTypeListReducer = (
    state: ListState<UserByType> = initAllUserState,
    action: Action
) => {
    switch (action.type) {
        case GET_ALL_USER_BY_TYPE:
            return fetchListState(action);
        case GET_ALL_USER_BY_TYPE_SUCCESS:
            return fetchListStateSuccess(action, state);
        case GET_ALL_USER_BY_TYPE_FAILED:
            return fetchListStateFailed(action, state);
        default:
            return state;
    }
};

export const bookingNotifyAddressReducer = (
    state: ListState<BookingNotifyAddress> = initBookingNotifyState,
    action: Action
) => {
    switch (action.type) {
        case GET_BOOKING_NOTIFY_ADDRESS:
            return fetchListState(action);
        case GET_BOOKING_NOTIFY_ADDRESS_SUCCESS:
            return fetchListStateSuccess(action, state);
        case GET_BOOKING_NOTIFY_ADDRESS_FAILED:
            return fetchListStateFailed(action, state);
        case DELETE_ROW_NOTIFY_REQUEST:
            return deleteInit(action, state);
        case DELETE_ROW_NOTIFY_SUCCESS:
            return deleteSuccess(action, state);
        case DELETE_ROW_NOTIFY_FAILED:
            return deleteFailed(action, state);
        default:
            return state;
    }
};

export const getVesselReducer = (
    state: ListState<any> = initVesselState,
    action: Action
) => {
    switch (action.type) {
        case GET_VESSEL_REQUEST:
            return fetchListState(action);
        case GET_VESSEL_REQUEST_SUCCESS:
            return fetchListStateSuccess(action, state);
        case GET_VESSEL_REQUEST_FAILED:
            return fetchListStateFailed(action, state);
        default:
            return state;
    }
};

export const bookingShippingReducer = (
    state: ListState<any> = initShippingState,
    action: Action
) => {
    switch (action.type) {
        case BOOKING_SHIPPING_TABLE:
            return fetchListState(action);
        case BOOKING_SHIPPING_TABLE_SUCCESS:
            return fetchListStateSuccess(action, state);
        case BOOKING_SHIPPING_TABLE_FAILED:
            return fetchListStateFailed(action, state);
        case DELETE_ROW_SHIPPING:
            return deleteInit(action, state);
        case DELETE_ROW_SHIPPING_SUCCESS:
            return deleteSuccess(action, state);
        case DELETE_ROW_SHIPPING_FAILED:
            return deleteFailed(action, state);
        default:
            return state;
    }
};

export const bookingContainerReducer = (
    state: ListState<any> = initContainerState,
    action: Action
) => {
    switch (action.type) {
        case BOOKING_CONTAINER_TABLE:
            return fetchListState(action);
        case BOOKING_CONTAINER_TABLE_SUCCESS:
            return fetchListStateSuccess(action, state);
        case BOOKING_CONTAINER_TABLE_FAILED:
            return fetchListStateFailed(action, state);
        case DELETE_ROW_CONTAINER:
            return deleteInit(action, state);
        case DELETE_ROW_CONTAINER_SUCCESS:
            return deleteSuccess(action, state);
        case DELETE_ROW_CONTAINER_FAILED:
            return deleteFailed(action, state);
        default:
            return state;
    }
};

export const bookingChargesReducer = (
    state: ListState<any> = initChargesState,
    action: Action
) => {
    switch (action.type) {
        case BOOKING_CHARGES_TABLE:
            return fetchListState(action);
        case BOOKING_CHARGES_TABLE_SUCCESS:
            return fetchListStateSuccess(action, state);
        case BOOKING_CHARGES_TABLE_FAILED:
            return fetchListStateFailed(action, state);
        case DELETE_ROW_CHARGES:
            return deleteInit(action, state);
        case DELETE_ROW_CHARGES_SUCCESS:
            return deleteSuccess(action, state);
        case DELETE_ROW_CHARGES_FAILED:
            return deleteFailed(action, state);
        default:
            return state;
    }
};

export const bookingCostReducer = (
    state: ListState<any> = initCostState,
    action: Action
) => {
    switch (action.type) {
        case BOOKING_COST_TABLE:
            return fetchListState(action);
        case BOOKING_COST_TABLE_SUCCESS:
            return fetchListStateSuccess(action, state);
        case BOOKING_COST_TABLE_FAILED:
            return fetchListStateFailed(action, state);
        case DELETE_ROW_COST:
            return deleteInit(action, state);
        case DELETE_ROW_COST_SUCCESS:
            return deleteSuccess(action, state);
        case DELETE_ROW_COST_FAILED:
            return deleteFailed(action, state);
        default:
            return state;
    }
};