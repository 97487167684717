import { Api } from "../api";
import { CreateRole } from "../../models/CreateRole";
import { Permission } from "../../models/Permission";

export const getAllRole = (page: number, size: number, filter?: any) => {
	return Api.get("/role/getAll", { page, size, name: filter?.name });
};

export const getOneRole = (id: number) => {
	return Api.get("/role/createOrEdit", { id });
};

export const createOrEditRole = (role: CreateRole) => {
	return Api.post("/role/createOrEdit", role);
};

export const deleteRole = (roleId: number) =>
	Api.get("/role/delete", { roleId });

export const getAllPermissions = () =>
	Api.get("/permission/getALlPermissionOffSystem");

export const setPermissionForUser = (
	userId: number,
	unitId: number,
	permissionResponseList: Permission[]
) =>
	Api.post("/permission/setPermissionForUser", {
		userId,
		unitId,
		permissionResponseList,
	});
