import { Action } from "../../../models/redux/action";
import { ReduxCallbacks } from "../../../models/redux/Callbacks";
import { ConfigApprove } from "../../../models/configApprover";
import { ListQuery } from "../../../models/redux/ListQuery";
import { AjaxResponse } from "rxjs/ajax";

export const FETCH_LIST_CONFIGAPPROVER = "FETCH_LIST_CONFIGAPPROVER";
export const FETCH_LIST_CONFIGAPPROVER_SUCCESS =
  "FETCH_LIST_CONFIGAPPROVER_SUCCESS";
export const FETCH_LIST_CONFIGAPPROVER_FAILED =
  "FETCH_LIST_CONFIGAPPROVER_FAILED";
export const FETCH_LIST_CONFIGAPPROVER_CANCELLED =
  "FETCH_LIST_CONFIGAPPROVER_CANCELLED";

export const fetchListConfigApprover = (
  query: ListQuery,
  callbacks?: ReduxCallbacks
): Action => ({
  type: FETCH_LIST_CONFIGAPPROVER,
  payload: { query, callbacks },
});

export const fetchListConfigApproverSuccess = ({
  response,
}: AjaxResponse): Action => ({
  type: FETCH_LIST_CONFIGAPPROVER_SUCCESS,
  payload: { response },
});

export const fetchListConfigApproverFailed = (error: any): Action => ({
  type: FETCH_LIST_CONFIGAPPROVER_FAILED,
  payload: error,
});

export const fetchListConfigApproverCancelled = (): Action => ({
  type: FETCH_LIST_CONFIGAPPROVER_CANCELLED,
});

// ADD CURRENCY
export const ADD_CONFIGAPPROVER = "ADD_CONFIGAPPROVER";
export const ADD_CONFIGAPPROVER_SUCCESS = "ADD_CCONFIGAPPROVER_SUCCESS";
export const ADD_CONFIGAPPROVER_FAILED = "ADD_CONFIGAPPROVER_FAILED";
export const ADD_CONFIGAPPROVER_CANCELLED = "ADD_CONFIGAPPROVER_CANCELLED";

export const addConfigApprover = (
  content: ConfigApprove,
  callbacks?: ReduxCallbacks
): Action => ({
  type: ADD_CONFIGAPPROVER,
  payload: { ...content, callbacks },
});

export const addConfigApproverSuccess = (): Action => ({
  type: ADD_CONFIGAPPROVER_SUCCESS,
});

export const addConfigApproverFailed = (error: any): Action => ({
  type: ADD_CONFIGAPPROVER_FAILED,
  payload: error,
});

export const addConfigApproverCancelled = (): Action => ({
  type: ADD_CONFIGAPPROVER_CANCELLED,
});

// DELETE CURRENCY
export const DELETE_CONFIGAPPROVER = "DELETE_CONFIGAPPROVER";
export const DELETE_CONFIGAPPROVER_SUCCESS = "DELETE_CONFIGAPPROVER_SUCCESS";
export const DELETE_CONFIGAPPROVER_FAILED = "DELETE_CONFIGAPPROVER_FAILED";
export const DELETE_CONFIGAPPROVER_CANCELLED =
  "DELETE_CONFIGAPPROVER_CANCELLED";

export const deleteConfigApproverInit = (
  content: ConfigApprove,
  callbacks?: ReduxCallbacks
): Action => ({
  type: DELETE_CONFIGAPPROVER,
  payload: { ...content, callbacks },
});

export const deleteConfigApproverSuccess = (): Action => ({
  type: DELETE_CONFIGAPPROVER_SUCCESS,
});

export const deleteConfigApproverFailed = (error: any): Action => ({
  type: DELETE_CONFIGAPPROVER_FAILED,
  payload: error,
});

export const deleteConfigApproverCancelled = (): Action => ({
  type: DELETE_CONFIGAPPROVER_CANCELLED,
});

//EDIT CURRENCY
export const EDIT_CONFIGAPPROVER = "EDIT CONFIGAPPROVER";
export const EDIT_CONFIGAPPROVER_SUCCESS = "EDIT_CONFIGAPPROVER_SUCCESS";
export const EDIT_CONFIGAPPROVER_FAILED = "EDIT_CONFIGAPPROVER_FAILED";
export const EDIT_CONFIGAPPROVER_CANCELLED = "EDIT_CONFIGAPPROVER_CANCELLED";

export const editConfigApprover = (
  content: ConfigApprove,
  callbacks?: ReduxCallbacks
): Action => ({
  type: EDIT_CONFIGAPPROVER,
  payload: { ...content, callbacks },
});
export const editConfigApproverSuccess = (): Action => ({
  type: EDIT_CONFIGAPPROVER_SUCCESS,
});
export const editConfigApproverFailed = (error: any): Action => ({
  type: EDIT_CONFIGAPPROVER_FAILED,
  payload: error,
});
export const editConfigApproverCancelled = (): Action => ({
  type: EDIT_CONFIGAPPROVER_CANCELLED,
});
