const env: any = {};

export const getRealValue = (value: any) => {
	switch (value) {
		case "false":
		case "False":
		case "FALSE":
			return false;
		case "true":
		case "True":
		case "TRUE":
			return true;
		default:
			if (!Number.isNaN(parseFloat(value))) {
				return parseFloat(value);
			}
			return value;
	}
};

for (let key in process.env) {
	if (key.indexOf("REACT_APP_") === 0) {
		let newKey = key.replace("REACT_APP_", "");
		if (!process.env[newKey]) {
			env[newKey] = getRealValue(process.env[key]);
		}
		continue;
	}

	env[key] = process.env[key];
}

export default env;
