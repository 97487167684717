export const RoutePaths = {
	Auth: {
		Index: "/auth",
		Login: "/auth/login",
		ChooseUnit: "/choose-unit",
	},
	Dashboard: {
		Index: "/",
	},

	NotFound: "/404",
	AccessDenied: "/403",
	Notifications: "/notifications",
	Administration: {
		Index: "/administration",
		Office: "/administration/office",
		Unit: "/administration/unit",
		Role: "/administration/role",
		User: "/administration/user",
		ConfigApprove: "/administration/ConfigApprove",
	},
	Ship: {
		Index: "/ship",
		Schedule: "/ship/schedule",
		ListSchedule: "/ship/list-schedule",
	},
	Common: {
		Categories: {
			Index: "/categories",
			Vessels: "/categories/vessels",
			Ports: "/categories/ports",
			Currencys: "/categories/currencys",
			Terminals: "/categories/terminalList",
			Merchants: "/categories/merchants",
			ChargeTypes: "/categories/chargeTypes",
			ChargeGroups: "/categories/chargeGroups",
			Charges: "/categories/charges",
			SizeContainers: "/categories/sizeContainers",
			TypeOfContainers: "/categories/typeOfContainers",
			Terms: "/categories/terms",
			Commoditys: "/categories/commoditys",
			Fee: "/categories/fee",
			CurrencyConversion: "/categories/currencyConversions",
		},
	},
	Action: {
		Index: "/action",
		BookingNote: "/action/booking-note",
		MyBookingNote: "/action/my-booking-note",
		CreateBookingNote: "/action/create-booking-note",
		BookingNoteDetail: "/action/booking-note-detail/:bookingNoteId",
		Booking: "/action/booking",
		Quotation: "/action/quotation",
		PriceApproval: "/action/price-approval",
		ManageBill: "/action/manage-bill",
		DebitNoteAndCheckout: "/action/debit-not-checkout",
		CreateBillFromBooking: "/action/create-bill-from-booking",
	},
};

export const DefaultRoute = RoutePaths.Ship.Schedule;
