import { Api } from "../api";
import { Vessel } from "../../models/Vessel";
import { ListQuery } from "../../models/redux/ListQuery";

export const getAllVessel = (query: ListQuery) => {
	return Api.get("/vessellist/getAll", {
		name: query?.filter?.name || "",
		page: query.page,
		size: query.per_page,
		status: query.filter?.status || undefined,
	});
};

export const addVessel = (data: Vessel) => {
	return Api.post("/vessellist/createOrEdit", { ...data });
};

export const getOneVessel = (id: number) => {
	return Api.get("/vessellist/createOrEdit", { id });
};

export const deleteOneVessel = (data: any) => {
	return Api.get("/vessellist/delete", { ...data });
};
