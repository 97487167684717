import { Api } from "../api";
import { deleteTerm } from "../../models/deleteTerm";
import { Term } from "../../models/Term";
import { ListQuery } from "../../models/redux/ListQuery";

export const getAllTerm = (query: ListQuery) => {
  return Api.get("/termlist/getAll", {
    page: query.page,
    size: query.per_page,
    name: query.filter?.name || "",
    status: query.filter?.status || "",
  });
};

export const addTerm = (data: Term) => {
  return Api.post("/termlist/createOrEdit", { ...data });
};

export const getOneTerm = (id: number) => {
  return Api.get("/termlist/createOrEdit", { id });
};

export const deleteOneTerm = (data: deleteTerm) => {
  return Api.get("/termlist/delete", { ...data });
};
