import { ListQuery } from "../../../models/redux/ListQuery";
import { ReduxCallbacks } from "../../../models/redux/Callbacks";
import { Action } from "../../../models/redux/action";
import { CreateOrEditCostModel } from "../../../models/createOrEditCost";
import { CreateOrEditContainerModel } from "../../../models/createOrEditContainer";
import { AjaxResponse } from "rxjs/ajax";

// BILL LIST
export const FETCH_BILL_LIST = "FETCH_BILL_LIST";
export const FETCH_BILL_LIST_SUCCESS = "FETCH_BILL_LIST_SUCCESS";
export const FETCH_BILL_LIST_FAILED = "FETCH_BILL_LIST_FAILED";
export const FETCH_BILL_LIST_CANCELLED = "FETCH_BILL_LIST_CANCELLED";

export const GET_ALL_USER_BY_TYPE = "GET_ALL_USER_BY_TYPE";
export const GET_ALL_USER_BY_TYPE_SUCCESS = "GET_ALL_USER_BY_TYPE_SUCCESS";
export const GET_ALL_USER_BY_TYPE_FAILED = "GET_ALL_USER_BY_TYPE_FAILED";
export const GET_ALL_USER_BY_TYPE_CANCELLED = "GET_ALL_USER_BY_TYPE_CANCELLED";

// GENERAL INFORMATION
export const GET_BILL_NOTIFY_ADDRESS = "GET_BILL_NOTIFY_ADDRESS";
export const GET_BILL_NOTIFY_ADDRESS_SUCCESS =
  "GET_BILL_NOTIFY_ADDRESS_SUCCESS";
export const GET_BILL_NOTIFY_ADDRESS_FAILED = "GET_BILL_NOTIFY_ADDRESS_FAILED";
export const GET_BILL_NOTIFY_ADDRESS_CANCELLED =
  "GET_BILL_NOTIFY_ADDRESS_CANCELLED";

export const GET_VESSEL_REQUEST = "GET_VESSEL_REQUEST";
export const GET_VESSEL_REQUEST_SUCCESS = "GET_VESSEL_REQUEST_SUCCESS";
export const GET_VESSEL_REQUEST_FAILED = "GET_VESSEL_REQUEST_FAILED";
export const GET_VESSEL_REQUEST_CANCELLED = "GET_VESSEL_REQUEST_CANCELLED";

export const DELETE_ROW_NOTIFY_REQUEST = "DELETE_ROW_NOTIFY_REQUEST";
export const DELETE_ROW_NOTIFY_SUCCESS = "DELETE_ROW_NOTIFY_SUCCESS";
export const DELETE_ROW_NOTIFY_FAILED = "DELETE_ROW_NOTIFY_FAILED";
export const DELETE_ROW_NOTIFY_CANCELLED = "DELETE_ROW_NOTIFY_CANCELLED";

// SHIPPING
export const BILL_SHIPPING_TABLE = "BILL_SHIPPING_TABLE";
export const BILL_SHIPPING_TABLE_SUCCESS = "BILL_SHIPPING_TABLE_SUCCESS";
export const BILL_SHIPPING_TABLE_FAILED = "BILL_SHIPPING_TABLE_FAILED";
export const BILL_SHIPPING_TABLE_CANCELLED = "BILL_SHIPPING_TABLE_CANCELLED";

export const DELETE_ROW_SHIPPING = "DELETE_ROW_SHIPPING";
export const DELETE_ROW_SHIPPING_SUCCESS = "DELETE_ROW_SHIPPING_SUCCESS";
export const DELETE_ROW_SHIPPING_FAILED = "DELETE_ROW_SHIPPING_FAILED";
export const DELETE_ROW_SHIPPING_CANCELLED = "DELETE_ROW_SHIPPING_CANCELLED";

export const BILL_CONTAINER_TABLE = "BILL_CONTAINER_TABLE";
export const BILL_CONTAINER_TABLE_SUCCESS = "BILL_CONTAINER_TABLE_SUCCESS";
export const BILL_CONTAINER_TABLE_FAILED = "BILL_CONTAINER_TABLE_FAILED";
export const BILL_CONTAINER_TABLE_CANCELLED = "BILL_CONTAINER_TABLE_CANCELLED";

export const DELETE_ROW_CONTAINER = "DELETE_ROW_CONTAINER";
export const DELETE_ROW_CONTAINER_SUCCESS = "DELETE_ROW_CONTAINER_SUCCESS";
export const DELETE_ROW_CONTAINER_FAILED = "DELETE_ROW_CONTAINER_FAILED";
export const DELETE_ROW_CONTAINER_CANCELLED = "DELETE_ROW_CONTAINER_CANCELLED";

// CHARGES
export const BILL_CHARGES_TABLE = "BILL_CHARGES_TABLE";
export const BILL_CHARGES_TABLE_SUCCESS = "BILL_CHARGES_TABLE_SUCCESS";
export const BILL_CHARGES_TABLE_FAILED = "BILL_CHARGES_TABLE_FAILED";
export const BILL_CHARGES_TABLE_CANCELLED = "BILL_CHARGES_TABLE_CANCELLED";

export const DELETE_ROW_CHARGES = "DELETE_ROW_CHARGES";
export const DELETE_ROW_CHARGES_SUCCESS = "DELETE_ROW_CHARGES_SUCCESS";
export const DELETE_ROW_CHARGES_FAILED = "DELETE_ROW_CHARGES_FAILED";
export const DELETE_ROW_CHARGES_CANCELLED = "DELETE_ROW_CHARGES_CANCELLED";

export const BILL_COST_TABLE = "BILL_COST_TABLE";
export const BILL_COST_TABLE_SUCCESS = "BILL_COST_TABLE_SUCCESS";
export const BILL_COST_TABLE_FAILED = "BILL_COST_TABLE_FAILED";
export const BILL_COST_TABLE_CANCELLED = "BILL_COST_TABLE_CANCELLED";

export const DELETE_ROW_COST = "DELETE_ROW_COST";
export const DELETE_ROW_COST_SUCCESS = "DELETE_ROW_COST_SUCCESS";
export const DELETE_ROW_COST_FAILED = "DELETE_ROW_COST_FAILED";
export const DELETE_ROW_COST_CANCELLED = "DELETE_ROW_COST_CANCELLED";

// GET BILL LIST
export const fetchBillList = (query: ListQuery) => ({
  type: FETCH_BILL_LIST,
  payload: { query },
});

export const fetchBillListSuccess = (response: any) => ({
  type: FETCH_BILL_LIST_SUCCESS,
  payload: response,
});

export const fetchBillListFailed = (error: any) => ({
  type: FETCH_BILL_LIST_FAILED,
  payload: error,
});

export const fetchBillListCancelled = () => ({
  type: FETCH_BILL_LIST_CANCELLED,
});

// GET USER BY TYPE
export const getAllUserByType = (
  query: ListQuery,
  callbacks?: ReduxCallbacks
) => ({
  type: GET_ALL_USER_BY_TYPE,
  payload: { query, callbacks },
});

export const getAllUserByTypeSuccess = (response: any) => ({
  type: GET_ALL_USER_BY_TYPE_SUCCESS,
  payload: response,
});

export const getAllUserByTypeFailed = (error: any) => ({
  type: GET_ALL_USER_BY_TYPE_FAILED,
  payload: error,
});

export const getAllUserByTypeCancelled = () => ({
  type: GET_ALL_USER_BY_TYPE_CANCELLED,
});

// GET BILL NOTIFY ADDRESS
export const getBillNotifyAddress = (
  query: ListQuery,
  callbacks?: ReduxCallbacks
) => ({
  type: GET_BILL_NOTIFY_ADDRESS,
  payload: { query, callbacks },
});

export const getBillNotifyAddressSuccess = (response: any) => ({
  type: GET_BILL_NOTIFY_ADDRESS_SUCCESS,
  payload: response,
});

export const getBillNotifyAddressFailed = (error: any) => ({
  type: GET_BILL_NOTIFY_ADDRESS_FAILED,
  payload: error,
});

export const getBillNotifyAddressCancelled = () => ({
  type: GET_BILL_NOTIFY_ADDRESS_CANCELLED,
});

// GET VESSEL REQUEST
export const getVesselRequest = (
  query: ListQuery,
  callbacks?: ReduxCallbacks
): Action => ({
  type: GET_VESSEL_REQUEST,
  payload: { query, callbacks },
});

export const getVesselRequestSuccess = ({
  response,
}: AjaxResponse): Action => ({
  type: GET_VESSEL_REQUEST_SUCCESS,
  payload: { response },
});

export const getVesselRequestFailed = (error: any): Action => ({
  type: GET_VESSEL_REQUEST_FAILED,
  payload: error,
});

export const getVesselRequestCancelled = (): Action => ({
  type: GET_VESSEL_REQUEST_CANCELLED,
});

// DELETE AN ROW NOTIFY ADDRESS ITEM
export const deleteRowNotifyRequest = (
  addressId: number,
  callbacks?: ReduxCallbacks
) => {
  return {
    type: DELETE_ROW_NOTIFY_REQUEST,
    payload: { addressId, callbacks },
  };
};

export const deleteRowNotifySuccess = () => ({
  type: DELETE_ROW_NOTIFY_SUCCESS,
});

export const deleteRowNotifyFailed = (error: any) => ({
  type: DELETE_ROW_NOTIFY_FAILED,
  payload: error,
});

export const deleteAddressCancelled = (): Action => ({
  type: DELETE_ROW_NOTIFY_CANCELLED,
});

// GET BILL SHIPPING
export const getBillShipping = (
  query: ListQuery,
  callbacks?: ReduxCallbacks
) => ({
  type: BILL_SHIPPING_TABLE,
  payload: { query, callbacks },
});

export const getBillShippingSuccess = (response: any) => ({
  type: BILL_SHIPPING_TABLE_SUCCESS,
  payload: response,
});

export const getBillShippingFailed = (error: any) => ({
  type: BILL_SHIPPING_TABLE_FAILED,
  payload: error,
});

export const getBillShippingCancelled = () => ({
  type: BILL_SHIPPING_TABLE_CANCELLED,
});

// DELETE AN ROW NOTIFY ADDRESS ITEM
export const deleteRowShipping = (
  addressId: number,
  callbacks?: ReduxCallbacks
) => {
  return {
    type: DELETE_ROW_SHIPPING,
    payload: { addressId, callbacks },
  };
};

export const deleteRowShippingSuccess = () => ({
  type: DELETE_ROW_SHIPPING_SUCCESS,
});

export const deleteRowShippingFailed = (error: any) => ({
  type: DELETE_ROW_SHIPPING_FAILED,
  payload: error,
});

export const deleteRowShippingCancelled = (): Action => ({
  type: DELETE_ROW_SHIPPING_CANCELLED,
});

// GET BILL COST
export const getBillCost = (query: ListQuery, callbacks?: ReduxCallbacks) => ({
  type: BILL_COST_TABLE,
  payload: { query, callbacks },
});

export const getBillCostSuccess = (response: any) => ({
  type: BILL_COST_TABLE_SUCCESS,
  payload: response,
});

export const getBillCostFailed = (error: any) => ({
  type: BILL_COST_TABLE_FAILED,
  payload: error,
});

export const getBillCostCancelled = () => ({
  type: BILL_COST_TABLE_CANCELLED,
});

// DELETE AN ROW COST
export const deleteRowCost = (
  addressId: number,
  callbacks?: ReduxCallbacks
) => {
  return {
    type: DELETE_ROW_COST,
    payload: { addressId, callbacks },
  };
};

export const deleteRowCostSuccess = () => ({
  type: DELETE_ROW_COST_SUCCESS,
});

export const deleteRowCostFailed = (error: any) => ({
  type: DELETE_ROW_COST_FAILED,
  payload: error,
});

export const deleteRowCostCancelled = (): Action => ({
  type: DELETE_ROW_COST_CANCELLED,
});

// GET BILL CONTAINER
export const getBillContainer = (
  query: ListQuery,
  callbacks?: ReduxCallbacks
) => ({
  type: BILL_CONTAINER_TABLE,
  payload: { query, callbacks },
});

export const getBillContainerSuccess = (response: any) => ({
  type: BILL_CONTAINER_TABLE_SUCCESS,
  payload: response,
});

export const getBillContainerFailed = (error: any) => ({
  type: BILL_CONTAINER_TABLE_FAILED,
  payload: error,
});

export const getBillContainerCancelled = () => ({
  type: BILL_CONTAINER_TABLE_CANCELLED,
});

// DELETE AN ROW CONTAINER
export const deleteRowContainer = (Id: number, callbacks?: ReduxCallbacks) => {
  return {
    type: DELETE_ROW_CONTAINER,
    payload: { Id, callbacks },
  };
};

export const deleteRowContainerSuccess = () => ({
  type: DELETE_ROW_CONTAINER_SUCCESS,
});

export const deleteRowContainerFailed = (error: any) => ({
  type: DELETE_ROW_CONTAINER_FAILED,
  payload: error,
});

export const deleteRowContainerCancelled = (): Action => ({
  type: DELETE_ROW_CONTAINER_CANCELLED,
});

// GET BILL CHARGES
export const getBillCharges = (
  query: ListQuery,
  callbacks?: ReduxCallbacks
) => ({
  type: BILL_CHARGES_TABLE,
  payload: { query, callbacks },
});

export const getBillChargesSuccess = (response: any) => ({
  type: BILL_CHARGES_TABLE_SUCCESS,
  payload: response,
});

export const getBillChargesFailed = (error: any) => ({
  type: BILL_CHARGES_TABLE_FAILED,
  payload: error,
});

export const getBillChargesCancelled = () => ({
  type: BILL_CHARGES_TABLE_CANCELLED,
});

// DELETE AN ROW CHARGES
export const deleteRowCharges = (
  chargeId: number,
  callbacks?: ReduxCallbacks
) => {
  return {
    type: DELETE_ROW_CHARGES,
    payload: { chargeId, callbacks },
  };
};

export const deleteRowChargesSuccess = () => ({
  type: DELETE_ROW_CHARGES_SUCCESS,
});

export const deleteRowChargesFailed = (error: any) => ({
  type: DELETE_ROW_CHARGES_FAILED,
  payload: error,
});

export const deleteRowChargesCancelled = (): Action => ({
  type: DELETE_ROW_CHARGES_CANCELLED,
});
