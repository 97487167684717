import { ListQuery } from "../../../models/redux/ListQuery";
import { ReduxCallbacks } from "../../../models/redux/Callbacks";
import { Merchant } from "../../../models/Merchant";

export const FETCH_MERCHANT_INIT = "FETCH_MERCHANT_INIT";
export const FETCH_MERCHANT_SUCCESS = "FETCH_MERCHANT_SUCCESS";
export const FETCH_MERCHANT_FAILED = "FETCH_MERCHANT_FAILED";
export const FETCH_MERCHANT_CANCELLED = "FETCH_MERCHANT_CANCELLED";

export const ADD_MERCHANT_INIT = "ADD_MERCHANT_INIT";
export const ADD_MERCHANT_SUCCESS = "ADD_MERCHANT_SUCCESS";
export const ADD_MERCHANT_FAILED = "ADD_MERCHANT_FAILED";

export const EDIT_MERCHANT_INIT = "EDIT_MERCHANT_INIT";
export const EDIT_MERCHANT_SUCCESS = "EDIT_MERCHANT_SUCCESS";
export const EDIT_MERCHANT_FAILED = "EDIT_MERCHANT_FAILED";

export const DELETE_MERCHANT_INIT = "DELETE_MERCHANT_INIT";
export const DELETE_MERCHANT_SUCCESS = "DELETE_MERCHANT_SUCCESS";
export const DELETE_MERCHANT_FAILED = "DELETE_MERCHANT_FAILED";

export const fetchListMerchant = (
  query: ListQuery,
  callbacks?: ReduxCallbacks
) => ({
  type: FETCH_MERCHANT_INIT,
  payload: { query, callbacks },
});

// FETCH

export const fetchMerchantSuccess = (response: any) => ({
  type: FETCH_MERCHANT_SUCCESS,
  payload: response,
});

export const fetchMerchantFailed = (error: any) => ({
  type: FETCH_MERCHANT_FAILED,
  payload: error,
});

export const fetcHMerchantCancelled = () => ({
  type: FETCH_MERCHANT_CANCELLED,
});

// ADD

export const addMerchant = (content: Merchant, callbacks?: ReduxCallbacks) => ({
  type: ADD_MERCHANT_INIT,
  payload: { ...content, callbacks },
});

export const addMerchantSuccess = () => ({
  type: ADD_MERCHANT_SUCCESS,
});

export const addMerchantFailed = (error: any) => ({
  type: ADD_MERCHANT_FAILED,
  payload: error,
});

// EDIT

export const editMerchantInit = (
  content: Merchant,
  callbacks?: ReduxCallbacks
) => ({
  type: EDIT_MERCHANT_INIT,
  payload: { ...content, callbacks },
});

export const editMerchantSuccess = () => ({
  type: EDIT_MERCHANT_SUCCESS,
});

export const editMerchantFailed = (error: any) => ({
  type: EDIT_MERCHANT_FAILED,
  payload: error,
});

// DELETE

export const deleteMerchant = (
  merchantId: number,
  callbacks?: ReduxCallbacks
) => {
  return {
    type: DELETE_MERCHANT_INIT,
    payload: { merchantId, callbacks },
  };
};

export const deleteMerchantSuccess = () => ({
  type: DELETE_MERCHANT_SUCCESS,
});

export const deleteMerchantFailed = (error: any) => ({
  type: DELETE_MERCHANT_FAILED,
  payload: error,
});
