import React from "react";
import { Link } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../helpers";
import styled from "styled-components";

export const Brand = () => {
	return (
		<>
			{/* begin::Brand */}
			<div className={`brand flex-column-auto`} id="kt_brand">
				{/* begin::Logo */}
				<Link to="" className="brand-logo">
					<Logo alt="logo" src={toAbsoluteUrl("/white_logo.png")} />
				</Link>
				{/* end::Logo */}
				{/* begin::Toggle */}
				<button className="brand-toggle btn btn-sm px-0" id="kt_aside_toggle">
					<span className="svg-icon svg-icon-xl">
						<SVG
							src={toAbsoluteUrl(
								"/media/svg/icons/Navigation/Angle-double-left.svg"
							)}
						/>
					</span>
				</button>

				{/* end::Toolbar */}
			</div>
			{/* end::Brand */}
		</>
	);
};

const Logo = styled.img`
	width: 100%;
	max-height: 45px;
	object-fit: contain;
`;
