import { Api } from "../api";
import { User } from "../../models/User";

export const getUserByMerchantId = (id: number) => {
	return Api.get("/user/getProfileByMerchantId", { merchantId: id });
};

export const deleteUser = (userId: number) =>
	Api.get("/user/updateStatus", { userId });

export const checkUsernameExists = (username: string) =>
	Api.get("/user/checkUsernameAvailability", { username });

export const checkEmailExists = (email: string) =>
	Api.get("/user/checkEmailAvailability", { email });

export const updateUser = (user: User) => Api.post("/user/update", user);

export const getOneUser = (id: number) => Api.get("/user/createOrEdit", { id });

// EPIC

export const getAllUserService = (
	page: number,
	size: number,
	name: string = ""
) => {
	return Api.get("/user/getAll", { page, size, name });
};

export const addUserService = (data: any) => {
	return Api.post("/auth/signup", { ...data });
};

export const getUserPermissions = (userId: number, unitId: number) =>
	Api.get("/permission/getAllPermissionByUser", { userId, unitId });

export const markNotificationAsRead = (notificationId: number) =>
	Api.post("/notification/markAsRead", {notificationId: notificationId});

export const markAllNotificationsAsRead = () =>
	Api.post("/notification/markAllAsRead");
