import React from "react";
import ReactDOM from "react-dom";
import App from "./app/App";
import * as serviceWorker from "./serviceWorker";
import { MetronicLayoutProvider } from "./_metronic/layout";
import "./index.scss";
import "./_metronic/_assets/plugins/keenthemes-icons/font/ki.css";
import "socicon/css/socicon.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./_metronic/_assets/plugins/flaticon/flaticon.css";
import "./_metronic/_assets/plugins/flaticon2/flaticon.css";
import './translations/i18n';
// Datepicker
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";

serviceWorker.register();

ReactDOM.render(
  <MetronicLayoutProvider>
    <App />
  </MetronicLayoutProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
