import { ListQuery } from "../../../models/redux/ListQuery";
import { ReduxCallbacks } from "../../../models/redux/Callbacks";
import { TypeContainer } from "../../../models/TypeContainer";

export const FETCH_TYPECONTAINER_INIT = "FETCH_TYPECONTAINER_INIT";
export const FETCH_TYPECONTAINER_SUCCESS = "FETCH_TYPECONTAINER_SUCCESS";
export const FETCH_TYPECONTAINER_FAILED = "FETCH_TYPECONTAINER_FAILED";
export const FETCH_TYPECONTAINER_CANCELLED = "FETCH_TYPECONTAINER_CANCELLED";

export const ADD_TYPECONTAINER_INIT = "ADD_TYPECONTAINER_INIT";
export const ADD_TYPECONTAINER_SUCCESS = "ADD_TYPECONTAINER_SUCCESS";
export const ADD_TYPECONTAINER_FAILED = "ADD_TYPECONTAINER_FAILED";

export const EDIT_TYPECONTAINER_INIT = "EDIT_TYPECONTAINER_INIT";
export const EDIT_TYPECONTAINER_SUCCESS = "EDIT_TYPECONTAINER_SUCCESS";
export const EDIT_TYPECONTAINER_FAILED = "EDIT_TYPECONTAINER_FAILED";

export const DELETE_TYPECONTAINER_INIT = "DELETE_TYPECONTAINER_INIT";
export const DELETE_TYPECONTAINER_SUCCESS = "DELETE_TYPECONTAINER_SUCCESS";
export const DELETE_TYPECONTAINER_FAILED = "DELETE_TYPECONTAINER_FAILED";

export const fetchTypeContainerInit = (
  query: ListQuery,
  callbacks?: ReduxCallbacks
) => ({
  type: FETCH_TYPECONTAINER_INIT,
  payload: { query, callbacks },
});

// FETCH

export const fetchTypeContainerSuccess = (response: any) => ({
  type: FETCH_TYPECONTAINER_SUCCESS,
  payload: response,
});

export const fetchTypeContainerFailed = (error: any) => ({
  type: FETCH_TYPECONTAINER_FAILED,
  payload: error,
});

export const fetchTypeContainerCancelled = () => ({
  type: FETCH_TYPECONTAINER_CANCELLED,
});

// ADD

export const addTypeContainerInit = (
  content: TypeContainer,
  callbacks?: ReduxCallbacks
) => ({
  type: ADD_TYPECONTAINER_INIT,
  payload: { ...content, callbacks },
});

export const addTypeContainerSuccess = () => ({
  type: ADD_TYPECONTAINER_SUCCESS,
});

export const addTypeContainerFailed = (error: any) => ({
  type: ADD_TYPECONTAINER_FAILED,
  payload: error,
});

// EDIT

export const editTypeContainerInit = (
  content: TypeContainer,
  callbacks?: ReduxCallbacks
) => ({
  type: EDIT_TYPECONTAINER_INIT,
  payload: { ...content, callbacks },
});

export const editTypeContainerSuccess = () => ({
  type: EDIT_TYPECONTAINER_SUCCESS,
});

export const editTypeContainerFailed = (error: any) => ({
  type: EDIT_TYPECONTAINER_FAILED,
  payload: error,
});

// DELETE

export const deleteTypeContainerInit = (
  content: TypeContainer,
  callbacks?: ReduxCallbacks
) => {
  return {
    type: DELETE_TYPECONTAINER_INIT,
    payload: { ...content, callbacks },
  };
};

export const deleteTypeContainerSuccess = () => ({
  type: DELETE_TYPECONTAINER_SUCCESS,
});

export const deleteTypeContainerFailed = (error: any) => ({
  type: DELETE_TYPECONTAINER_FAILED,
  payload: error,
});
