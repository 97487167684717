import { ActionsObservable, ofType } from "redux-observable";
import { Action } from "../../../models/redux/action";
import {
  ADD_CURRENCY,
  DELETE_CURRENCY,
  FETCH_LIST_CURRENCY,
  fetchListCurrencySuccess,
  fetchListCurrencyFailed,
  FETCH_LIST_CURRENCY_CANCELLED,
  addCurrencySuccess,
  addCurrencyFailed,
  ADD_CURRENCY_CANCELLED,
  deleteCurrencySuccess,
  deleteCurrencyFailed,
  DELETE_CURRENCY_CANCELLED,
  EDIT_CURRENCY,
  fetchListCurrency,
  editCurrencyFailed,
} from "./actions";
import { mergeMap, map, catchError, takeUntil } from "rxjs/operators";
import { of } from "rxjs";
import { onEpicSuccess, onEpicFailed } from "../../../helpers/reduxEpic";
import { CANCEL_ALL_REQUEST } from "../../default";
import {
  getAllCurrencys,
  addCurrency,
  deleteCurrency,
} from "../../../api/currency";

export const fetchCurrencysEpic = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    ofType(FETCH_LIST_CURRENCY),
    mergeMap((action) =>
      getAllCurrencys(action.payload.query).pipe(
        map((response) =>
          onEpicSuccess(action, response, fetchListCurrencySuccess(response))
        ),
        catchError((error) =>
          of(onEpicFailed(action, error, fetchListCurrencyFailed(error)))
        ),
        takeUntil(
          action$.pipe(
            ofType(FETCH_LIST_CURRENCY_CANCELLED, CANCEL_ALL_REQUEST)
          )
        )
      )
    )
  );

export const addCurrencyEpic = (
  action$: ActionsObservable<Action>,
  state$: any
) => {
  return action$.pipe(
    ofType(ADD_CURRENCY),
    mergeMap((action) =>
      addCurrency(action.payload).pipe(
        map((response) => {
          return onEpicSuccess(
            action,
            response,
            fetchListCurrency({
              page: state$.value.currency.query.page,
              per_page: state$.value.currency.query.per_page,
            })
          );
        }),
        catchError((error) =>
          of(onEpicFailed(action, error, addCurrencyFailed(error)))
        ),
        takeUntil(action$.pipe(ofType(CANCEL_ALL_REQUEST)))
      )
    )
  );
};

export const editCurrencyEpic = (
  action$: ActionsObservable<Action>,
  state$: any
) => {
  return action$.pipe(
    ofType(EDIT_CURRENCY),
    mergeMap((action) =>
      addCurrency(action.payload).pipe(
        map((response) => {
          return onEpicSuccess(
            action,
            response,
            fetchListCurrency({
              page: state$.value.currency.query.page,
              per_page: state$.value.currency.query.per_page,
            })
          );
        }),
        catchError((error) =>
          of(onEpicFailed(action, error, editCurrencyFailed(error)))
        ),
        takeUntil(action$.pipe(ofType(CANCEL_ALL_REQUEST)))
      )
    )
  );
};

export const deleteCurrencyEpic = (
  action$: ActionsObservable<Action>,
  state$: any
) => {
  return action$.pipe(
    ofType(DELETE_CURRENCY),
    mergeMap((action) =>
      deleteCurrency(action.payload).pipe(
        map((response) => {
          return onEpicSuccess(
            action,
            response,
            fetchListCurrency({
              page: state$.value.currency.query.page,
              per_page: state$.value.currency.query.per_page,
            })
          );
        }),
        catchError((error) =>
          of(onEpicFailed(action, error, deleteCurrencyFailed(error)))
        ),
        takeUntil(action$.pipe(ofType(CANCEL_ALL_REQUEST)))
      )
    )
  );
};
