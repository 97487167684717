import { ListQuery } from "../../../models/redux/ListQuery";
import { ReduxCallbacks } from "../../../models/redux/Callbacks";
import { Office } from "../../../models/Office";

export const FETCH_OFFICE_INIT = "FETCH_OFFICE_INIT";
export const FETCH_OFFICE_SUCCESS = "FETCH_OFFICE_SUCCESS";
export const FETCH_OFFICE_FAILED = "FETCH_OFFICE_FAILED";
export const FETCH_OFFICE_CANCELLED = "FETCH_OFFICE_CANCELLED";

export const ADD_OFFICE_INIT = "ADD_OFFICE_INIT";
export const ADD_OFFICE_SUCCESS = "ADD_OFFICE_SUCCESS";
export const ADD_OFFICE_FAILED = "ADD_OFFICE_FAILED";

export const EDIT_OFFICE_INIT = "EDIT_OFFICE_INIT";
export const EDIT_OFFICE_SUCCESS = "EDIT_OFFICE_SUCCESS";
export const EDIT_OFFICE_FAILED = "EDIT_OFFICE_FAILED";

export const DELETE_OFFICE_INIT = "DELETE_OFFICE_INIT";
export const DELETE_OFFICE_SUCCESS = "DELETE_OFFICE_SUCCESS";
export const DELETE_OFFICE_FAILED = "DELETE_OFFICE_FAILED";

export const fetchOfficeInit = (
  query: ListQuery,
  callbacks?: ReduxCallbacks
) => ({
  type: FETCH_OFFICE_INIT,
  payload: { query, callbacks },
});

// FETCH

export const fetchOfficeSuccess = (response: any) => ({
  type: FETCH_OFFICE_SUCCESS,
  payload: response,
});

export const fetchOfficeFailed = (error: any) => ({
  type: FETCH_OFFICE_FAILED,
  payload: error,
});

export const fetchOfficeCancelled = () => ({
  type: FETCH_OFFICE_CANCELLED,
});

// ADD

export const addOfficeInit = (content: Office, callbacks?: ReduxCallbacks) => ({
  type: ADD_OFFICE_INIT,
  payload: { ...content, callbacks },
});

export const addOfficeSuccess = () => ({
  type: ADD_OFFICE_SUCCESS,
});

export const addOfficeFailed = (error: any) => ({
  type: ADD_OFFICE_FAILED,
  payload: error,
});

// EDIT

export const editOfficeInit = (
  content: Office,
  callbacks?: ReduxCallbacks
) => ({
  type: EDIT_OFFICE_INIT,
  payload: { ...content, callbacks },
});

export const editOfficeSuccess = () => ({
  type: EDIT_OFFICE_SUCCESS,
});

export const editOfficeFailed = (error: any) => ({
  type: EDIT_OFFICE_FAILED,
  payload: error,
});

// DELETE

export const deleteOfficeInit = (
  content: Office,
  callbacks?: ReduxCallbacks
) => {
  return {
    type: DELETE_OFFICE_INIT,
    payload: { ...content, callbacks },
  };
};

export const deleteOfficeSuccess = () => ({
  type: DELETE_OFFICE_SUCCESS,
});

export const deleteOffuceFailed = (error: any) => ({
  type: DELETE_OFFICE_FAILED,
  payload: error,
});
