import { ListQuery } from "../../../models/redux/ListQuery";
import { ReduxCallbacks } from "../../../models/redux/Callbacks";
import { Term } from "../../../models/Term";

export const FETCH_TERM_INIT = "FETCH_TERM_INIT";
export const FETCH_TERM_SUCCESS = "FETCH_TERM_SUCCESS";
export const FETCH_TERM_FAILED = "FETCH_TERM_FAILED";
export const FETCH_TERM_CANCELLED = "FETCH_TERM_CANCELLED";

export const ADD_TERM_INIT = "ADD_TERM_INIT";
export const ADD_TERM_SUCCESS = "ADD_TERM_SUCCESS";
export const ADD_TERM_FAILED = "ADD_TERM_FAILED";

export const EDIT_TERM_INIT = "EDIT_TERM_INIT";
export const EDIT_TERM_SUCCESS = "EDIT_TERM_SUCCESS";
export const EDIT_TERM_FAILED = "EDIT_TERM_FAILED";

export const DELETE_TERM_INIT = "DELETE_TERM_INIT";
export const DELETE_TERM_SUCCESS = "DELETE_TERM_SUCCESS";
export const DELETE_TERM_FAILED = "DELETE_TERM_FAILED";

export const fetchTermInit = (
  query: ListQuery,
  callbacks?: ReduxCallbacks
) => ({
  type: FETCH_TERM_INIT,
  payload: { query, callbacks },
});

// FETCH

export const fetchTermSuccess = (response: any) => ({
  type: FETCH_TERM_SUCCESS,
  payload: response,
});

export const fetchTermFailed = (error: any) => ({
  type: FETCH_TERM_FAILED,
  payload: error,
});

export const fetchTermCancelled = () => ({
  type: FETCH_TERM_CANCELLED,
});

// ADD

export const addTermInit = (content: Term, callbacks?: ReduxCallbacks) => ({
  type: ADD_TERM_INIT,
  payload: { ...content, callbacks },
});

export const addTermSuccess = () => ({
  type: ADD_TERM_SUCCESS,
});

export const addTermFailed = (error: any) => ({
  type: ADD_TERM_SUCCESS,
  payload: error,
});

// EDIT

export const editTermInit = (content: Term, callbacks?: ReduxCallbacks) => ({
  type: EDIT_TERM_INIT,
  payload: { ...content, callbacks },
});

export const editTermSuccess = () => ({
  type: EDIT_TERM_SUCCESS,
});

export const editTermFailed = (error: any) => ({
  type: EDIT_TERM_FAILED,
  payload: error,
});

// DELETE

export const deleteTermInit = (content: Term, callbacks?: ReduxCallbacks) => {
  return {
    type: DELETE_TERM_INIT,
    payload: { ...content, callbacks },
  };
};

export const deleteTermSuccess = () => ({
  type: DELETE_TERM_SUCCESS,
});

export const deleteTermFailed = (error: any) => ({
  type: DELETE_TERM_FAILED,
  payload: error,
});
