import React, { FunctionComponent, useEffect } from "react";
import { Header } from "./header/Header";
import { ScrollTop } from "../components/ScrollTop";
import { Aside } from "./aside";
import { HeaderMobile } from "./header-mobile";
import { LayoutInit } from "./LayoutInit";
import { usePageTitle } from "../hooks/usePageTitle";
//import {requestFirebaseNotificationPermission} from "../../firebaseInit";
import { notifyError } from "../helpers/notify";
import { registerDevice } from "../redux/modules/auth";

export const DashboardLayout: FunctionComponent = ({ children }) => {
  const { pageTitle } = usePageTitle();

  //   useEffect(() => {
  //     if (
  //       localStorage.getItem("registeredDevice") !== "1" &&
  //       localStorage.getItem("registeredDevice") !== "2"
  //     ) {
  //       requestFirebaseNotificationPermission()
  //         .then((firebaseToken: any) => {
  //           registerDevice(
  //             { token: firebaseToken },
  //             {
  //               onSuccess: () => {
  //                 console.log(firebaseToken);
  //                 localStorage.setItem("registeredDevice", "1");
  //               },
  //               onFailed: (error) => {},
  //             }
  //           );
  //         })
  //         .catch((err: any) => {
  //           notifyError("Khởi tạo thông báo thất bại");
  //           localStorage.setItem("registeredDevice", "2");
  //           return err;
  //         });
  //     }
  //   }, []);

  return (
    <>
      {/*begin::Main*/}
      <HeaderMobile />
      <div className="d-flex flex-column flex-root" id="page-container">
        {/*begin::Page*/}
        <div className="d-flex flex-row flex-column-fluid page">
          <Aside />
          {/*begin::Wrapper*/}
          <div
            className="d-flex flex-column flex-row-fluid wrapper"
            id="kt_wrapper"
          >
            <Header />
            {/*begin::Content*/}

            <h1 className="container text-primary text-uppercase mt-3 mb-n1">
              {pageTitle}
            </h1>

            <div
              id="kt_content"
              className={`content d-flex flex-column flex-column-fluid`}
            >
              {/*begin::Entry*/}
              <div className="d-flex flex-column-fluid">
                {/*begin::Container*/}
                <div className="container">{children}</div>
                {/*end::Container*/}
              </div>
              {/*end::Entry*/}
            </div>
            {/*end::Content*/}
          </div>
          {/*end::Wrapper*/}
        </div>
        {/*end::Page*/}
      </div>
      <ScrollTop />
      {/*end::Main*/}
      <LayoutInit />
    </>
  );
};
