import { ListState } from "../../../models/redux/listState";
import { ChargeType } from "../../../models/ChargeType";
import { Action } from "../../../models/redux/action";
import {
  FETCH_CHARGETYPE_INIT,
  FETCH_CHARGETYPE_SUCCESS,
  FETCH_CHARGETYPE_FAILED,
  ADD_CHARGETYPE_INIT,
  ADD_CHARGETYPE_SUCCESS,
  ADD_CHARGETYPE_FAILED,
  EDIT_CHARGETYPE_INIT,
  EDIT_CHARGETYPE_SUCCESS,
  EDIT_CHARGETYPE_FAILED,
  DELETE_CHARGETYPE_INIT,
  DELETE_CHARGETYPE_SUCCESS,
  DELETE_CHARGETYPE_FAILED,
} from "./actions";
import {
  fetchListState,
  fetchListStateSuccess,
  fetchListStateFailed,
  baseFetchListState,
  addInit,
  addFailed,
  addSuccess,
  deleteInit,
  deleteSuccess,
  deleteFailed,
} from "../../../helpers/reduxAction";

const initState: ListState<ChargeType> = baseFetchListState();

export const chargeTypeReducer = (
  state: ListState<ChargeType> = initState,
  action: Action
) => {
  switch (action.type) {
    case FETCH_CHARGETYPE_INIT:
      return fetchListState(action);
    case FETCH_CHARGETYPE_SUCCESS:
      return fetchListStateSuccess(action, state);
    case FETCH_CHARGETYPE_FAILED:
      return fetchListStateFailed(action, state);

    case ADD_CHARGETYPE_INIT:
      return addInit(action, state);
    case ADD_CHARGETYPE_SUCCESS:
      return addSuccess(action, state);
    case ADD_CHARGETYPE_FAILED:
      return addFailed(action, state);

    case DELETE_CHARGETYPE_INIT:
      return deleteInit(action, state);
    case DELETE_CHARGETYPE_SUCCESS:
      return deleteSuccess(action, state);
    case DELETE_CHARGETYPE_FAILED:
      return deleteFailed(action, state);
    default:
      return state;
  }
};
