import { CustomerShipSchedule } from "../../../models/CustomerShipSchedule";
import { Action } from "../../../models/redux/action";
import { BookingNoteRequest } from "../../../models/BookingNoteRequest";
import { ReduxCallbacks } from "../../../models/redux/Callbacks";
import { ListQuery } from "../../../models/redux/ListQuery";
import { AjaxResponse } from "rxjs/ajax";

export const SEARCH_BOOKING = "SEARCH_BOOKING";
export const BOOKINGNOTE_COMMON_INFO = "BOOKINGNOTE_COMMON_INFO";
export const UPDATE_BOOKING = "UPDATE_BOOKING";
export const UPDATE_BOOKING1 = "UPDATE_BOOKING1";
export const MY_BOOKING = "MY_BOOKING";

export const searchBooking = () => {
	return {
		type: SEARCH_BOOKING,
	};
};

export const BookingNoteCommonInfo = (
	shipSchedule: CustomerShipSchedule,
	bookingNote?: BookingNoteRequest,
	isEdit?: boolean
): Action => {
	return {
		type: BOOKINGNOTE_COMMON_INFO,
		payload: {
			shipSchedule,
			bookingNote,
			isEdit: isEdit !== undefined ? isEdit : false,
		},
	};
};

export const UPDATE_PRODUCT_INFOR_BOOKING_NOTE =
	"UPDATE_PRODUCT_INFOR_BOOKING_NOTE";
export const UPDATE_PRODUCT_INFOR_BOOKING_NOTE_SUCCESS =
	"UPDATE_PRODUCT_INFOR_BOOKING_NOTE_SUCCESS";
export const UPDATE_PRODUCT_INFOR_BOOKING_NOTE_FAILED =
	"UPDATE_PRODUCT_INFOR_BOOKING_NOTE_FAILED";
export const UPDATE_PRODUCT_INFOR_BOOKING_NOTE_CANCELLED =
	"UPDATE_PRODUCT_INFOR_BOOKING_NOTE_CANCELLED";

export const updateProductInforBookingNote = (
	data: any,
	callbacks?: ReduxCallbacks
) => {
	return {
		type: UPDATE_PRODUCT_INFOR_BOOKING_NOTE,
		payload: {
			data,
			callbacks,
		},
	};
};
export const updateProductInforBookingNoteSuccess = () => {
	return {
		type: UPDATE_PRODUCT_INFOR_BOOKING_NOTE_SUCCESS,
	};
};
export const updateProductInforBookingNoteFailed = () => {
	return {
		type: UPDATE_PRODUCT_INFOR_BOOKING_NOTE_FAILED,
	};
};

export const cancelUpdateProductInforBookingNote = () => {
	return {
		type: UPDATE_PRODUCT_INFOR_BOOKING_NOTE_CANCELLED,
	};
};

export const CREATE_BOOKING_NOTE = "CREATE_BOOKING_NOTE";
export const CREATE_BOOKING_NOTE_FAILED = "CREATE_BOOKING_NOTE_FAILED";
export const CREATE_BOOKING_NOTE_CANCELLED = "CREATE_BOOKING_NOTE_CANCELLED";

export const createBookingNote = (
	bookingNote: BookingNoteRequest,
	callbacks?: ReduxCallbacks
): Action => ({
	type: CREATE_BOOKING_NOTE,
	payload: { bookingNote, callbacks },
});

export const createBookingNoteFailed = (error: any): Action => ({
	type: CREATE_BOOKING_NOTE_FAILED,
	payload: { error },
});

export const cancelCreateBookingNote = (): Action => ({
	type: CREATE_BOOKING_NOTE_CANCELLED,
});

export const UPDATE_BOOKING_NOTE_STATUS = "UPDATE_BOOKING_NOTE_STATUS";
export const UPDATE_BOOKING_NOTE_STATUS_SUCCESS =
	"UPDATE_BOOKING_NOTE_STATUS_SUCCESS";
export const UPDATE_BOOKING_NOTE_STATUS_FAILED =
	"UPDATE_BOOKING_NOTE_STATUS_FAILED";
export const UPDATE_BOOKING_NOTE_STATUS_CANCELLED =
	"UPDATE_BOOKING_NOTE_STATUS_CANCELLED";

export const updateBookingNoteStatus = (
	{
		id,
		status,
	}: {
		id: number;
		status: 1 | 2;
	},
	callbacks?: ReduxCallbacks
): Action => ({
	type: UPDATE_BOOKING_NOTE_STATUS,
	payload: { id, status, callbacks },
});

export const updateBookingNoteStatusSuccess = (): Action => ({
	type: UPDATE_BOOKING_NOTE_STATUS_SUCCESS,
});

export const updateBookingNoteStatusFailed = (error: any): Action => ({
	type: UPDATE_BOOKING_NOTE_STATUS_FAILED,
	payload: { error },
});

export const cancelUpdateBookingNoteStatus = (): Action => ({
	type: UPDATE_BOOKING_NOTE_STATUS_CANCELLED,
});

export const updateBooking = (bookingNote: BookingNoteRequest): Action => ({
	type: UPDATE_BOOKING,
	payload: { bookingNote },
});

export const updateBookingNote = (bookingNote: BookingNoteRequest): Action => ({
	type: SEARCH_BOOKING,
	payload: { bookingNote },
});

export const updateBooking1 = () => ({
	type: UPDATE_BOOKING1,
});

export const ourBooking = () => ({
	type: MY_BOOKING,
});

export const UPDATE_NOTE_BOOKING_NOTE = "UPDATE_NOTE_BOOKING_NOTE";
export const UPDATE_NOTE_BOOKING_NOTE_CANCELLED =
	"UPDATE_NOTE_BOOKING_NOTE_CANCELLED";
export const UPDATE_NOTE_BOOKING_NOTE_FAILED =
	"UPDATE_NOTE_BOOKING_NOTE_FAILED";
export const UPDATE_NOTE_BOOKING_NOTE_SUCCESS =
	"UPDATE_NOTE_BOOKING_NOTE_SUCCESS";

export const updateNotee = (data: any, callbacks?: ReduxCallbacks) => {
	return {
		type: UPDATE_NOTE_BOOKING_NOTE,
		payload: { data, callbacks },
	};
};
export const updateNoteeFailed = (error: any, callbacks?: ReduxCallbacks) => {
	return {
		type: UPDATE_NOTE_BOOKING_NOTE_FAILED,
		payload: { error, callbacks },
	};
};
export const cancelUpdateNotee = () => {
	return {
		type: UPDATE_NOTE_BOOKING_NOTE_CANCELLED,
	};
};

export const updateNoteSuccess = () => {
	return {
		type: UPDATE_NOTE_BOOKING_NOTE_SUCCESS,
	};
};
export const updateNoteCancel = () => {
	return {
		type: UPDATE_NOTE_BOOKING_NOTE_CANCELLED,
	};
};
export const updateNoteFailed = () => {
	return {
		type: UPDATE_NOTE_BOOKING_NOTE_FAILED,
	};
};

// Get booking note no
export const GET_BOOKING_NOTE_NO = "GET_BOOKING_NOTE_NO";

export const getBookingNoteNo = (bookingNote: BookingNoteRequest): Action => ({
	type: GET_BOOKING_NOTE_NO,
	payload: { bookingNote },
});

// cancel booking note
export const CANCEL_BOOKING_NOTE = "CANCEL_BOOKING_NOTE";
export const CANCEL_BOOKING_NOTE_SUCCESS = "CANCEL_BOOKING_NOTE_SUCCESS";
export const CANCEL_BOOKING_NOTE_FAILED = "CANCEL_BOOKING_NOTE_FAILED";
export const CANCEL_BOOKING_NOTE_CANCELLED = "CANCEL_BOOKING_NOTE_CANCELLED";
export const cancelBookingNote = (
	bookingnoteId: number,
	callbacks?: ReduxCallbacks
): Action => ({
	type: CANCEL_BOOKING_NOTE,
	payload: { bookingnoteId, callbacks },
});
export const cancelBookingNoteSuccess = (): Action => ({
	type: CANCEL_BOOKING_NOTE_SUCCESS,
});
export const cancelBookingNoteFailed = (error: any): Action => ({
	type: CANCEL_BOOKING_NOTE_FAILED,
	payload: { error },
});
export const cancelBookingNoteCancelled = (): Action => ({
	type: CANCEL_BOOKING_NOTE_CANCELLED,
});

export const FETCH_ALL_BOOKING_NOTE = "FETCH_ALL_BOOKING_NOTE";
export const FETCH_ALL_BOOKING_NOTE_SUCCESS = "FETCH_ALL_BOOKING_NOTE_SUCCESS";
export const FETCH_ALL_BOOKING_NOTE_FAILED = "FETCH_ALL_BOOKING_NOTE_FAILED";
export const FETCH_ALL_BOOKING_NOTE_CANCELLED =
	"FETCH_ALL_BOOKING_NOTE_CANCELLED";

export const fetchAllBookingNote = (
	query: ListQuery,
	callbacks?: ReduxCallbacks
): Action => ({
	type: FETCH_ALL_BOOKING_NOTE,
	payload: { query, callbacks },
});

export const fetchAllBookingNoteSuccess = ({
	response,
}: AjaxResponse): Action => ({
	type: FETCH_ALL_BOOKING_NOTE_SUCCESS,
	payload: { response },
});

export const fetchAllBookingNoteFailed = (error: any): Action => ({
	type: FETCH_ALL_BOOKING_NOTE_FAILED,
	payload: error,
});

export const fetchAllBookingNoteCancelled = (): Action => ({
	type: FETCH_ALL_BOOKING_NOTE_CANCELLED,
});

export const FETCH_LIST_BOOKING_NOTE_BY_SENDER_ID =
	"FETCH_LIST_BOOKING_NOTE_BY_SENDER_ID";
export const FETCH_LIST_BOOKING_NOTE_BY_SENDER_ID_SUCCESS =
	"FETCH_LIST_BOOKING_NOTE_BY_SENDER_ID_SUCCESS";
export const FETCH_LIST_BOOKING_NOTE_BY_SENDER_ID_FAILED =
	"FETCH_LIST_BOOKING_NOTE_BY_SENDER_ID_FAILED";
export const FETCH_LIST_BOOKING_NOTE_BY_SENDER_ID_CANCELLED =
	"FETCH_LIST_BOOKING_NOTE_BY_SENDER_ID_CANCELLED";

export const getAllBookingNoteBySenderId = (
	query: ListQuery,
	callbacks?: ReduxCallbacks
): Action => ({
	type: FETCH_LIST_BOOKING_NOTE_BY_SENDER_ID,
	payload: { query, callbacks },
});

export const getAllBookingNoteBySenderIdSuccess = ({
	response,
}: AjaxResponse): Action => ({
	type: FETCH_LIST_BOOKING_NOTE_BY_SENDER_ID_SUCCESS,
	payload: { response },
});
export const getAllBookingNoteBySenderIdFailed = (): Action => ({
	type: FETCH_LIST_BOOKING_NOTE_BY_SENDER_ID_FAILED,
});
export const getAllBookingNoteBySenderIdCancelled = (): Action => ({
	type: FETCH_LIST_BOOKING_NOTE_BY_SENDER_ID_CANCELLED,
});
