import { Action } from "../../../models/redux/action";
import { ReduxCallbacks } from "../../../models/redux/Callbacks";
import { PortOfCalling } from "../../../models/PortOfCalling";
import { ListQuery } from "../../../models/redux/ListQuery";
import { AjaxResponse } from "rxjs/ajax";

export const FETCH_LIST_POST_OF_CALLING = "FETCH_LIST_POST_OF_CALLING";
export const FETCH_LIST_POST_OF_CALLING_SUCCESS =
	"FETCH_LIST_POST_OF_CALLING_SUCCESS";
export const FETCH_LIST_POST_OF_CALLING_FAILED =
	"FETCH_LIST_POST_OF_CALLING_FAILED";
export const FETCH_LIST_POST_OF_CALLING_CANCELLED =
	"FETCH_LIST_POST_OF_CALLING_CANCELLED";

export const fetchListPortOfCalling = (
	query: ListQuery,
	callbacks?: ReduxCallbacks
): Action => ({
	type: FETCH_LIST_POST_OF_CALLING,
	payload: { query, callbacks },
});

export const fetchListPortOfCallingSuccess = ({
	response,
}: AjaxResponse): Action => ({
	type: FETCH_LIST_POST_OF_CALLING_SUCCESS,
	payload: { response },
});

export const fetchListPortOfCallingFailed = (error: any): Action => ({
	type: FETCH_LIST_POST_OF_CALLING_FAILED,
	payload: error,
});

export const fetchListPortOfCallingCancelled = (): Action => ({
	type: FETCH_LIST_POST_OF_CALLING_CANCELLED,
});

// ADD POST_OF_CALLING
export const ADD_POST_OF_CALLING = "ADD_POST_OF_CALLING";
export const ADD_POST_OF_CALLING_SUCCESS = "ADD_POST_OF_CALLING_SUCCESS";
export const ADD_POST_OF_CALLING_FAILED = "ADD_POST_OF_CALLING_FAILED";
export const ADD_POST_OF_CALLING_CANCELLED = "ADD_POST_OF_CALLING_CANCELLED";

export const addPortOfCalling = (
	portOfCalling: PortOfCalling,
	callbacks?: ReduxCallbacks
): Action => ({
	type: ADD_POST_OF_CALLING,
	payload: { portOfCalling, callbacks },
});

export const addPortOfCallingSuccess = (): Action => ({
	type: ADD_POST_OF_CALLING_SUCCESS,
});

export const addPortOfCallingFailed = (): Action => ({
	type: ADD_POST_OF_CALLING_FAILED,
});

export const addPortOfCallingCancelled = (): Action => ({
	type: ADD_POST_OF_CALLING_CANCELLED,
});

// DELETE POST_OF_CALLING
export const DELETE_POST_OF_CALLING = "DELETE_POST_OF_CALLING";
export const DELETE_POST_OF_CALLING_SUCCESS = "DELETE_POST_OF_CALLING_SUCCESS";
export const DELETE_POST_OF_CALLING_FAILED = "DELETE_POST_OF_CALLING_FAILED";
export const DELETE_POST_OF_CALLING_CANCELLED =
	"DELETE_POST_OF_CALLING_CANCELLED";

export const deletePortOfCalling = (
	portOfCallingId: number,
	callbacks?: ReduxCallbacks
): Action => ({
	type: DELETE_POST_OF_CALLING,
	payload: { portOfCallingId, callbacks },
});

export const deletePortOfCallingSuccess = (): Action => ({
	type: DELETE_POST_OF_CALLING_SUCCESS,
});

export const deletePortOfCallingFailed = (): Action => ({
	type: DELETE_POST_OF_CALLING_FAILED,
});

export const deletePortOfCallingCancelled = (): Action => ({
	type: DELETE_POST_OF_CALLING_CANCELLED,
});
