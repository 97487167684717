import { Api } from "../api";
import { ListQuery } from "../../models/redux/ListQuery";
import { CreateOrEditBooking } from "../../models/createOrEditBooking";
import { CreateOrEditShipping } from "../../models/createOrEditShipping";
import { CreateOrEditNotifyAddress } from "../../models/createOrEditNotifyAddress";
import { CreateOrEditContainerModel } from "../../models/createOrEditContainer";
import { CreateOrEditCostModel } from "../../models/createOrEditCost";
import _ from "lodash";

// General information
export const getAllBookingList = (query: ListQuery) => {
  if (_.isEmpty(query.filter)) {
    return Api.get(
      `/booking/getAll?page=${query.page}&size=${query.per_page}&status=1`,
      {},
      {},
      true
    );
  }
  return Api.get("/booking/getAll", {
    page: query.page,
    size: query.per_page,
    bookingDateFrom: query.filter?.bookingDateFrom,
    bookingDateTo: query.filter?.bookingDateTo,
    bookingNo: query.filter?.bookingNo,
    receiveId: query.filter?.receiveId,
    senderId: query.filter?.senderId,
    status: query.filter?.status,
  });
};

export const getAllUserByType = (query: ListQuery) => {
  return Api.get("/user/getAllUserByType", {
    page: query.page,
    size: query.per_page,
    type: "customer",
  });
};

export const createBookingNotify = (data: CreateOrEditBooking) => {
  return Api.post("/booking/createOrEdit", { ...data });
};

export const getOneBooking = (id: number) =>
  Api.get("/booking/createOrEdit", { id });

export const getBookingNotifyAddress = (query: ListQuery) => {
  return Api.get("/bookingNotifyAddress/getAll", {
    page: query.page,
    size: query.per_page,
    bookingId: query.filter?.bookingId,
  });
};

export const getAllVessel = (query: ListQuery) =>
  Api.get("/vessellist/getAll", {
    page: query.page,
    size: query.per_page,
    name: query.filter?.name || "",
  });

export const createOrEditNotifyAddress = (data: CreateOrEditNotifyAddress) =>
  Api.post("/bookingNotifyAddress/addNotifyAddress", { ...data });

export const deleteRowNotifyThirdAddress = (addressId: number) =>
  Api.delete("/bookingNotifyAddress/delete", { addressId });

// Shipping
export const getBookingShippingTable = (query: ListQuery) => {
  return Api.get("/bookingShipping/getAll", {
    page: query.page,
    size: query.per_page,
    bookingId: query.filter?.bookingId,
  });
};

export const deleteRowShippingTable = (addressId: number) =>
  Api.get("/bookingShipping/delete", { addressId });

export const createBookingShipping = (data: CreateOrEditShipping) => {
  return Api.post("/bookingShipping/createOrEdit", { ...data });
};

// Container
export const getBookingContainerTable = (query: ListQuery) => {
  return Api.get("/bookingContainer/getAll", {
    page: query.page,
    size: query.per_page,
    bookingId: query.filter?.bookingId,
  });
};

export const deleteRowContainerTable = (bookingcontainerId: number) => {
  return Api.delete("/bookingContainer/delete", { bookingcontainerId });
};

export const createOrEditContainer = (data: CreateOrEditContainerModel) => {
  return Api.post("/bookingContainer/createOrEdit", { ...data });
};

export const getListContainerNeedUpdate = (bookingId: number) => {
  return Api.get("/bookingContainer/getBookingContainerForUpdateContainerNo", {
    bookingId,
  });
};

export const updateBookingContainerNo = (
  data: Array<{
    shippingId: number;
    typeContainerId: number;
    containerNo: number;
  }>
) => {
  return Api.post("/bookingContainer/updateBookingContainerNo", data);
};

// Charges
export const getBookingChargesTable = (query: ListQuery) => {
  return Api.get("/bookingCharge/getAll", {
    page: query.page,
    size: query.per_page,
    bookingId: query.filter?.bookingId,
  });
};

export const deleteRowChargesTable = (chargeId: number) => {
  return Api.delete("/bookingCharge/delete", { chargeId });
};

export const createChargeFromQuotation = (
  quotationId: number,
  bookingId: number
) => {
  return Api.post("/bookingCharge/createChargeFromQuotation", {
    quotationId,
    bookingId,
  });
};

export const createOrEditCharges = (data: any) => {
  return Api.post("/bookingCharge/createOrEdit", { ...data });
};

// Cost
export const getBookingCostTable = (query: ListQuery) => {
  return Api.get("/bookingcost/getAll", {
    page: query.page,
    size: query.per_page,
    bookingId: query.filter?.bookingId,
  });
};

export const deleteRowCostTable = (bookingCostId: number) =>
  Api.get("/bookingcost/delete", { bookingCostId });

export const createOrEditCost = (data: CreateOrEditCostModel) => {
  return Api.post("/bookingcost/createOrEdit", {
    bookingId: data.bookingId,
    feeAtPortId: data.feeAtPortId,
    departmentId: data.departmentId,
    typeOfContainerId: data.typeOfContainerId,
    commodityId: data.commodityId,
    price: data.price,
    currencyId: data.currencyId,
    chargeListId: data.chargeListId,
    quantity: data.quantity,
  });
};
