import { Api } from "../api";
import { Terminal } from "../../models/Terminal";

export const getAllTerminal = (
  name: string = "",
  page: number,
  size: number,
  portId?: number,
  status?: boolean
) => {
  return Api.get("/terminallist/getAll", { name, page, size, portId, status });
};
export const getAllTerminal1 = (
  name: string = "",
  page: number,
  size: number,
  status: string = "",
  portId: number
) => {
  return Api.get("/terminallist/getAll", { name, page, size, status, portId });
};
export const addTerminal = (data: Terminal) => {
  return Api.post("/terminallist/createOrEdit", { ...data });
};

export const getOneTerminal = (id: number) => {
  return Api.get("/terminallist/createOrEdit", { id });
};

export const deleteOneTerminal = (data: any) => {
  return Api.get("/terminallist/delete", { ...data });
};
