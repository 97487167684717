import { Action } from "../../../models/redux/action";
import { mergeMap, map, catchError, takeUntil } from "rxjs/operators";
import {
  FETCH_CHARGEGROUP_INIT,
  fetchChargeGroupSuccess,
  fetchChargeGroupFailed,
  FETCH_CHARGEGROUP_CANCELLED,
  ADD_CHARGEGROUP_INIT,
  addChargeGroupFailed,
  EDIT_CHARGEGROUP_INIT,
  editChargeGroupFailed,
  DELETE_CHARGEGROUP_INIT,
  fetchChargeGroupInit,
} from "./actions";
import { ActionsObservable, ofType } from "redux-observable";
import { of } from "rxjs";
import { CANCEL_ALL_REQUEST } from "../../default";
import { onEpicSuccess, onEpicFailed } from "../../../helpers/reduxEpic";
import {
  getAllChargeGroup,
  addChargeGroup,
  deleteOneChargeGroup,
} from "../../../api/chargeGroup";

export const fetchListChargeGroupEpic = (
  action$: ActionsObservable<Action>
) => {
  return action$.pipe(
    ofType(FETCH_CHARGEGROUP_INIT),
    mergeMap((action) =>
      getAllChargeGroup(action.payload.query).pipe(
        map((response) =>
          onEpicSuccess(action, response, fetchChargeGroupSuccess(response))
        ),
        catchError((error) =>
          of(onEpicFailed(action, error, fetchChargeGroupFailed(error)))
        ),
        takeUntil(
          action$.pipe(ofType(FETCH_CHARGEGROUP_CANCELLED, CANCEL_ALL_REQUEST))
        )
      )
    )
  );
};

export const addChargeGroupEpic = (
  action$: ActionsObservable<Action>,
  state$: any
) => {
  return action$.pipe(
    ofType(ADD_CHARGEGROUP_INIT),
    mergeMap((action) =>
      addChargeGroup(action.payload).pipe(
        map((response) => {
          return onEpicSuccess(
            action,
            response,
            fetchChargeGroupInit({
              page: state$.value.chargegroup.query.page,
              per_page: state$.value.chargegroup.query.per_page,
            })
          );
        }),
        catchError((error) =>
          of(onEpicFailed(action, error, addChargeGroupFailed(error)))
        ),
        takeUntil(action$.pipe(ofType(CANCEL_ALL_REQUEST)))
      )
    )
  );
};

export const editChargeGroupEpic = (
  action$: ActionsObservable<Action>,
  state$: any
) => {
  return action$.pipe(
    ofType(EDIT_CHARGEGROUP_INIT),
    mergeMap((action) =>
      addChargeGroup(action.payload).pipe(
        map((response) => {
          console.log("bbb", response);
          console.log(state$);
          return onEpicSuccess(
            action,
            response,
            fetchChargeGroupInit({
              page: state$.value.chargegroup.query.page,
              per_page: state$.value.chargegroup.query.per_page,
            })
          );
        }),
        catchError((error) =>
          of(onEpicFailed(action, error, editChargeGroupFailed(error)))
        ),
        takeUntil(action$.pipe(ofType(CANCEL_ALL_REQUEST)))
      )
    )
  );
};

export const deleteChargeGroupEpic = (
  action$: ActionsObservable<Action>,
  state$: any
) => {
  return action$.pipe(
    ofType(DELETE_CHARGEGROUP_INIT),
    mergeMap((action) =>
      deleteOneChargeGroup(action.payload).pipe(
        map((response) => {
          return onEpicSuccess(
            action,
            response,
            fetchChargeGroupInit({
              page: state$.value.chargegroup.query.page,
              per_page: state$.value.chargegroup.query.per_page,
            })
          );
        }),
        catchError((error) =>
          of(onEpicFailed(action, error, editChargeGroupFailed(error)))
        ),
        takeUntil(action$.pipe(ofType(CANCEL_ALL_REQUEST)))
      )
    )
  );
};
