import { Api } from "../api";
import { Currency } from "../../models/Currency";
import { ListQuery } from "../../models/redux/ListQuery";

export const getAllCurrencys = (query: ListQuery) => {
  return Api.get("/currencylist/getAll", {
    name: query?.filter?.name || "",
    page: query.page,
    size: query.per_page,
    status: query.filter?.status || "",
  });
};

export const addCurrency = (data: Currency) => {
  return Api.post("/currencylist/createOrEdit", { ...data });
};

export const getOneCurrency = (id: number) => {
  return Api.get("/currencylist/createOrEdit", { id });
};

export const deleteCurrency = (data: any) => {
  return Api.get("/currencylist/delete", { ...data });
};
