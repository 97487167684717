import { ActionsObservable, ofType } from "redux-observable";
import { Action } from "../../../models/redux/action";
import { mergeMap, map, catchError, takeUntil } from "rxjs/operators";
import { of } from "rxjs";
import { onEpicSuccess, onEpicFailed } from "../../../helpers/reduxEpic";
import { CANCEL_ALL_REQUEST } from "../../default";
import {
	FETCH_LIST_ADDRESS,
	fetchListAddressSuccess,
	fetchListAddressFailed,
	FETCH_LIST_ADDRESS_CANCELLED,
	addAddressSuccess,
	addAddressFailed,
	ADD_ADDRESS_CANCELLED,
	ADD_ADDRESS,
	DELETE_ADDRESS,
	deleteAddressSuccess,
	deleteAddressFailed,
	DELETE_ADDRESS_CANCELLED,
} from "./actions";
import {
	getAllAddress,
	createOrEditAddress,
	deleteAddress,
} from "../../../api/address";

export const fetchAddressesEpic = (action$: ActionsObservable<Action>) =>
	action$.pipe(
		ofType(FETCH_LIST_ADDRESS),
		mergeMap((action) =>
			getAllAddress(action.payload.query).pipe(
				map((response) =>
					onEpicSuccess(action, response, fetchListAddressSuccess(response))
				),
				catchError((error) =>
					of(onEpicFailed(action, error, fetchListAddressFailed(error)))
				),
				takeUntil(
					action$.pipe(ofType(FETCH_LIST_ADDRESS_CANCELLED, CANCEL_ALL_REQUEST))
				)
			)
		)
	);

export const addAddressEpic = (action$: ActionsObservable<Action>) =>
	action$.pipe(
		ofType(ADD_ADDRESS),
		mergeMap((action) =>
			createOrEditAddress(action.payload.address).pipe(
				map((response) => onEpicSuccess(action, response, addAddressSuccess())),
				catchError((error) =>
					of(onEpicFailed(action, error, addAddressFailed()))
				),
				takeUntil(
					action$.pipe(ofType(ADD_ADDRESS_CANCELLED, CANCEL_ALL_REQUEST))
				)
			)
		)
	);

export const deleteAddressEpic = (action$: ActionsObservable<Action>) =>
	action$.pipe(
		ofType(DELETE_ADDRESS),
		mergeMap((action) =>
			deleteAddress(action.payload.addressId).pipe(
				map((response) =>
					onEpicSuccess(action, response, deleteAddressSuccess())
				),
				catchError((error) =>
					of(onEpicFailed(action, error, deleteAddressFailed()))
				),
				takeUntil(
					action$.pipe(ofType(DELETE_ADDRESS_CANCELLED, CANCEL_ALL_REQUEST))
				)
			)
		)
	);
