import React, {FunctionComponent} from "react";
import {Form, Field, Formik} from "formik";
import {Input} from "../../components/Form";
import * as Yup from "yup";
import {toAbsoluteUrl} from "../../helpers";
import {Button} from "react-bootstrap";
import {login, registerDevice} from "../../redux/modules/auth";
import {LoginUser, RegisterDevice} from "../../models/LoginUser";
import {ReduxCallbacks} from "../../models/redux/Callbacks";
import {connect} from "react-redux";
import {enableLoading, disableLoading} from "../../redux/modules/loading";
import {DefaultRoute} from "../../constants";
import {notifySuccess, notifyError} from "../../helpers/notify";
import styled from "styled-components";
import {getAllUnitByUser} from "../../api/unit";
import {map} from "rxjs/operators";

interface Props {
    login: (user: LoginUser, callbacks?: ReduxCallbacks) => void;
    registerDevice: (input: RegisterDevice, callbacks? : ReduxCallbacks) => void;
    disableLoading: () => void;
    enableLoading: (opacity?: number) => void;
}

const formLogin = Yup.object().shape({
    usernameOrEmail: Yup.string()
        .min(3, "Ít nhất có 3 ký tự")
        .max(50, "Nhiều nhất có 50 ký tự")
        .required("Bắt buộc"),
    password: Yup.string()
        .min(3, "Ít nhất có 3 ký tự")
        .max(50, "Nhiều nhất có 50 ký tự")
        .required("Bắt buộc"),
});

const mapDispatchToProps = {
    login,
    registerDevice,
    enableLoading,
    disableLoading,
};

const Login: FunctionComponent<Props> = ({
                                             login,
                                             enableLoading,
                                             disableLoading,
                                         }) => {
    const handleSubmit = (user: LoginUser) => {
        enableLoading();
        login(user, {
            onSuccess: () => {
                disableLoading();
                notifySuccess("Đăng nhập thành công!");
                window.location.href = DefaultRoute;
            },
            onFailed: (error) => {
                notifyError("Đăng nhập thất bại! Kiểm tra lại thông tin đăng nhập.");
                disableLoading();
            },
        });
    };

    return (
        <Container>
            <div className="text-center">
                <Logo src={toAbsoluteUrl("/login_logo.png")}/>
            </div>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    usernameOrEmail: "",
                    password: "",
                    unitId: 0,
                    departmentId: 0,
                    unit: "",
                    department: "",
                }}
                validationSchema={formLogin}
                onSubmit={handleSubmit}
            >
                {({handleSubmit}) => (
                    <>
                        <Form className="form form-label-right" onSubmit={handleSubmit}>
                            <div className="row justify-content-center">
                                <div className="col-12 col-sm-10 col-md-8">
                                    <div className="form-group">
                                        <Field
                                            name="usernameOrEmail"
                                            component={Input}
                                            hasLabel={false}
                                            size={2}
                                            prependIcon="user"
                                            placeholder="Tài khoản"
                                            uppercase
                                        />
                                    </div>
                                    <div className="form-group">
                                        <Field
                                            name="password"
                                            component={Input}
                                            type="password"
                                            size={2}
                                            hasLabel={false}
                                            prependIcon="lock"
                                            placeholder="Mật khẩu"
                                        />
                                    </div>
                                    <div className="form-group text-center login-button">
                                        <Button type="submit" block variant="primary">
                                            Đăng nhập
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </>
                )}
            </Formik>
        </Container>
    );
};

export default connect(null, mapDispatchToProps)(Login);

const Container = styled.div``;

const Logo = styled.img`
  max-width: 150px;
  padding-bottom: 20px;
`;
