import { ActionsObservable, ofType } from "redux-observable";
import { Action } from "../../../models/redux/action";
import {
  ADD_COMMODITY,
  DELETE_COMMODITY,
  FETCH_LIST_COMMODITY,
  fetchListCommodity,
  fetchListCommoditySuccess,
  fetchListCommodityFailed,
  FETCH_LIST_COMMODITY_CANCELLED,
  addCommoditySuccess,
  addCommodityFailed,
  ADD_COMMODITY_CANCELLED,
  EDIT_COMMODITY,
  deleteCommoditySuccess,
  deleteCommodityFailed,
  DELETE_COMMODITY_CANCELLED,
  editCommoditySuccess,
  editCommodityFailed,
} from "./actions";
import { mergeMap, map, catchError, takeUntil } from "rxjs/operators";
import { of } from "rxjs";
import { onEpicSuccess, onEpicFailed } from "../../../helpers/reduxEpic";
import { CANCEL_ALL_REQUEST } from "../../default";
import {
  getAllCommoditys,
  createOrEditCommodity,
  deleteCommodity,
} from "../../../api/commodity";

export const fetchCommoditysEpic = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    ofType(FETCH_LIST_COMMODITY),
    mergeMap((action) =>
      getAllCommoditys(action.payload.query).pipe(
        map((response) =>
          onEpicSuccess(action, response, fetchListCommoditySuccess(response))
        ),
        catchError((error) =>
          of(onEpicFailed(action, error, fetchListCommodityFailed(error)))
        ),
        takeUntil(
          action$.pipe(
            ofType(FETCH_LIST_COMMODITY_CANCELLED, CANCEL_ALL_REQUEST)
          )
        )
      )
    )
  );

export const addCommodityEpic = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    ofType(ADD_COMMODITY),
    mergeMap((action) =>
      createOrEditCommodity(action.payload.commodity).pipe(
        map((response) =>
          onEpicSuccess(action, response, addCommoditySuccess())
        ),
        catchError((error) =>
          of(onEpicFailed(action, error, addCommodityFailed()))
        ),
        takeUntil(
          action$.pipe(ofType(ADD_COMMODITY_CANCELLED, CANCEL_ALL_REQUEST))
        )
      )
    )
  );
export const editCommodityEpic = (
  action$: ActionsObservable<Action>,
  state$: any
) => {
  return action$.pipe(
    ofType(EDIT_COMMODITY),
    mergeMap((action) =>
      createOrEditCommodity(action.payload).pipe(
        map((response) => {
          return onEpicSuccess(
            action,
            response,
            fetchListCommodity({
              page: state$.value.commodity.query.page,
              per_page: state$.value.commodity.query.per_page,
            })
          );
        }),
        catchError((error) =>
          of(onEpicFailed(action, error, editCommodityFailed(error)))
        ),
        takeUntil(action$.pipe(ofType(CANCEL_ALL_REQUEST)))
      )
    )
  );
};

export const deleteCommodityEpic = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    ofType(DELETE_COMMODITY),
    mergeMap((action) =>
      deleteCommodity(action.payload).pipe(
        map((response) =>
          onEpicSuccess(action, response, deleteCommoditySuccess())
        ),
        catchError((error) =>
          of(onEpicFailed(action, error, deleteCommodityFailed()))
        ),
        takeUntil(
          action$.pipe(ofType(DELETE_COMMODITY_CANCELLED, CANCEL_ALL_REQUEST))
        )
      )
    )
  );
