import { ListState } from "../../../models/redux/listState";
import { ShipSchedule } from "../../../models/ShipSchedule";
import { Action } from "../../../models/redux/action";
import {
	FETCH_SHIP_SCHEDULE_INIT,
	FETCH_SHIP_SCHEDULE_SUCCESS,
	FETCH_SHIP_SCHEDULE_FAILED,
	ADD_SHIP_SCHEDULE_INIT,
	ADD_SHIP_SCHEDULE_SUCCESS,
	ADD_SHIP_SCHEDULE_FAILED,
	DELETE_SHIP_SCHEDULE_INIT,
	DELETE_SHIP_SCHEDULE_SUCCESS,
	DELETE_SHIP_SCHEDULE_FAILED,
	FETCH_SHIP_SCHEDULE_CUSTOMER_INIT,
	FETCH_SHIP_SCHEDULE_CUSTOMER_SUCCESS,
	FETCH_SHIP_SCHEDULE_SYSTEM_INIT,
	FETCH_SHIP_SCHEDULE_SYSTEM_SUCCESS,
	FETCH_SHIP_SCHEDULE_SYSTEM_FAILED,
	FETCH_SHIP_SCHEDULE_CUSTOMER_FAILED,
	RESET_LIST_SHIP_SCHEDULE_CUSTOMER,
} from "./actions";
import {
	fetchListState,
	fetchListStateSuccess,
	fetchListStateFailed,
	baseFetchListState,
	addShipScheduleInit,
	addShipScheduleFailed,
	addShipScheduleSuccess,
	deleteShipScheduleInit,
	deleteShipScheduleSuccess,
	deleteShipScheduleFailed,
} from "../../../helpers/reduxAction";

const initState: ListState<ShipSchedule> = baseFetchListState();
const initSystemState: ListState<ShipSchedule> = baseFetchListState();
const initCustomerState: ListState<ShipSchedule> = baseFetchListState();

export const shipScheduleReducer = (
	state: ListState<ShipSchedule> = initState,
	action: Action
) => {
	switch (action.type) {
		case FETCH_SHIP_SCHEDULE_INIT:
			return fetchListState(action);
		case FETCH_SHIP_SCHEDULE_SUCCESS:
			return fetchListStateSuccess(action, state);
		case FETCH_SHIP_SCHEDULE_FAILED:
			return fetchListStateFailed(action, state);

		case ADD_SHIP_SCHEDULE_INIT:
			return addShipScheduleInit(action, state);
		case ADD_SHIP_SCHEDULE_SUCCESS:
			return addShipScheduleSuccess(action, state);
		case ADD_SHIP_SCHEDULE_FAILED:
			return addShipScheduleFailed(action, state);

		case DELETE_SHIP_SCHEDULE_INIT:
			return deleteShipScheduleInit(action, state);
		case DELETE_SHIP_SCHEDULE_SUCCESS:
			return deleteShipScheduleSuccess(action, state);
		case DELETE_SHIP_SCHEDULE_FAILED:
			return deleteShipScheduleFailed(action, state);
		default:
			return state;
	}
};
export const systemShipScheduleReducer = (
	state: ListState<ShipSchedule> = initSystemState,
	action: Action
) => {
	switch (action.type) {
		case FETCH_SHIP_SCHEDULE_SYSTEM_INIT:
			return fetchListState(action);
		case FETCH_SHIP_SCHEDULE_SYSTEM_SUCCESS:
			return fetchListStateSuccess(action, state);
		case FETCH_SHIP_SCHEDULE_SYSTEM_FAILED:
			return fetchListStateFailed(action, state);
		default:
			return state;
	}
};

export const customerShipScheduleReducer = (
	state: ListState<ShipSchedule> = initCustomerState,
	action: Action
) => {
	switch (action.type) {
		case RESET_LIST_SHIP_SCHEDULE_CUSTOMER:
			return baseFetchListState<ListState<ShipSchedule>>();
		case FETCH_SHIP_SCHEDULE_CUSTOMER_INIT:
			return fetchListState(action);
		case FETCH_SHIP_SCHEDULE_CUSTOMER_SUCCESS:
			return fetchListStateSuccess(action, state);
		case FETCH_SHIP_SCHEDULE_CUSTOMER_FAILED:
			return fetchListStateFailed(action, state);
		default:
			return state;
	}
};
