import { Api } from "../api";
import { deleteCharge } from "../../models/deleteCharge";
import { Charge } from "../../models/Charge";
import { ListQuery } from "../../models/redux/ListQuery";

export const getAllCharge = (query: ListQuery) => {
  return Api.get("/chargelist/getAll", {
    page: query.page,
    size: query.per_page,
    name: query.filter?.name || "",
    status: query.filter?.status || "",
  });
};

export const addCharge = (data: Charge) => {
  return Api.post("/chargelist/createOrEdit", { ...data });
};

export const getOneCharge = (id: number) => {
  return Api.get("/chargelist/createOrEdit", { id });
};

export const deleteOneCharge = (data: deleteCharge) => {
  return Api.get("/chargelist/delete", { ...data });
};
