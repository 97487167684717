import { ListQuery } from "../../models/redux/ListQuery";
import { Api } from "../api";
import { PortOfCalling } from "../../models/PortOfCalling";

export const getAllPortOfCallings = (query: ListQuery) =>
	Api.get("/portOfCalling/getAll", {
		page: query.page,
		size: query.per_page,
		trainScheduleId: query.filter?.trainScheduleId || 0,
	});

export const createOrEditPortOfCalling = (portOfCalling: PortOfCalling) =>
	Api.post("/portOfCalling/createOrEdit", portOfCalling);

export const getOnePortOfCalling = (id: number) =>
	Api.get("/portOfCalling/createOrEdit", { id });

export const deletePortOfCalling = (portOfCallingId: number) =>
	Api.get("/portOfCalling/delete", { portOfCallingId });
