import { ListQuery } from "../../../models/redux/ListQuery";
import { Action } from "../../../models/redux/action";
import { ReduxCallbacks } from "../../../models/redux/Callbacks";
import { AjaxResponse } from "rxjs/ajax";
import { CreateRole } from "../../../models/CreateRole";

export const FETCH_ROLE = "FETCH_ROLE";
export const FETCH_ROLE_SUCCESS = "FETCH_ROLE_SUCCESS";
export const FETCH_ROLE_FAILED = "FETCH_ROLE_FAILED";
export const FETCH_ROLE_CANCELLED = "FETCH_ROLE_CANCELLED";

export const fetchRole = (
	query: ListQuery,
	callbacks?: ReduxCallbacks
): Action => ({
	type: FETCH_ROLE,
	payload: { query, callbacks },
});

export const fetchRoleSuccess = (response: AjaxResponse): Action => ({
	type: FETCH_ROLE_SUCCESS,
	payload: response,
});

export const fetchRoleFailed = (error: any): Action => ({
	type: FETCH_ROLE_FAILED,
	payload: { error },
});

export const fetchRoleCancelled = (): Action => ({
	type: FETCH_ROLE_CANCELLED,
});

// ADD ROLE
export const ADD_ROLE = "ADD_ROLE";
export const ADD_ROLE_SUCCESS = "ADD_ROLE_SUCCESS";
export const ADD_ROLE_FAILED = "ADD_ROLE_FAILED";
export const ADD_ROLE_CANCELLED = "ADD_ROLE_CANCELLED";

export const addRole = (
	role: CreateRole,
	callbacks?: ReduxCallbacks
): Action => ({
	type: ADD_ROLE,
	payload: { role, callbacks },
});

export const addRoleSuccess = (response: AjaxResponse): Action => ({
	type: ADD_ROLE_SUCCESS,
	payload: response,
});

export const addRoleFailed = (error: any): Action => ({
	type: ADD_ROLE_FAILED,
	payload: { error },
});

export const addRoleCancelled = (): Action => ({
	type: ADD_ROLE_CANCELLED,
});

// DELETE ROLE
export const DELETE_ROLE = "DELETE_ROLE";
export const DELETE_ROLE_SUCCESS = "DELETE_ROLE_SUCCESS";
export const DELETE_ROLE_FAILED = "DELETE_ROLE_FAILED";
export const DELETE_ROLE_CANCELLED = "DELETE_ROLE_CANCELLED";

export const deleteRole = (
	roleId: number,
	callbacks?: ReduxCallbacks
): Action => ({
	type: DELETE_ROLE,
	payload: { roleId, callbacks },
});

export const deleteRoleSuccess = (response: AjaxResponse): Action => ({
	type: DELETE_ROLE_SUCCESS,
	payload: response,
});

export const deleteRoleFailed = (error: any): Action => ({
	type: DELETE_ROLE_FAILED,
	payload: { error },
});

export const deleteRoleCancelled = (): Action => ({
	type: DELETE_ROLE_CANCELLED,
});
