import React, { useEffect, FunctionComponent, useState } from "react";
import { connect } from "react-redux";
import { Card, CardBody, CardHeader } from "../../components/Card/Card";
import { Form, Formik, Field } from "formik";
import { Select } from "../../components/Form";
import * as Yup from "yup";
import { setCurrentUnit } from "../../redux/modules/current-unit";
import { ReduxCallbacks } from "../../models/redux/Callbacks";
import { Button } from "react-bootstrap";
import { CurrentUnit } from "../../models/CurrentUnit";
import { Unit } from "../../models/Unit";
import { Office } from "../../models/Office";
import { getAllUnit, getAllUnitByUser } from "../../api/unit";
import { map } from "rxjs/operators";
import { Api } from "../../api";

interface Props {
	setCurrentUnit: (unit: CurrentUnit, callbacks?: ReduxCallbacks) => void;
	className?: string;
	onHide?: () => void;
	onSuccess?: () => void;
	currentUnit?: CurrentUnit;
}

const formChooseUnit = Yup.object().shape({
	unitId: Yup.number().min(1, "Chưa chọn đơn vị").required("Bắt buộc"),
	departmentId: Yup.number().min(1, "Chưa chọn văn phòng").required("Bắt buộc"),
});

const mapDispatchToProps = {
	setCurrentUnit,
};

const ChooseCurrentUnit: FunctionComponent<Props> = ({
	setCurrentUnit,
	className,
	onHide,
	onSuccess,
	currentUnit,
}) => {
	const [listUnit, setListUnit] = useState<Unit[]>([]);
	const [listDepartment, setListDepartment] = useState<Office[]>([]);
	useEffect(() => {
		getAllUnitByUser()
			.pipe(map(({ response }) => response))
			.subscribe((response) => {
				setListUnit(response);
				if (currentUnit) {
					fetchDepartment(currentUnit.unitId);
				}
			});
	}, []);

	const handleUnitChange = (event: any) => {
		fetchDepartment(event.target.value);
	};

	const fetchDepartment = (unitId: number | string) => {
		Api.get("/auth/getAllUnitDepartmentRoleByUser", {
			unitId,
		})
			.pipe(map(({ response }) => response))
			.subscribe((response) => {
				setListDepartment(response);
			});
	};

	const handleSubmit = (value: CurrentUnit) => {
		const unit = listUnit.find(
			(unit) => parseInt(value.unitId.toString()) === unit.id
		);
		const department = listDepartment.find(
			(department) => parseInt(value.departmentId.toString()) === department.id
		);

		setCurrentUnit(
			{
				unitId: value.unitId,
				unitName: unit?.name,
				departmentId: value.departmentId,
				departmentName: department?.name,
			},
			{
				onSuccess: () => {
					if (onSuccess) {
						onSuccess();
					}
				},
			}
		);
	};

	return (
		<Card className={className} style={{ minWidth: "500px" }}>
			<CardHeader title="Chọn đơn vị cơ sở"></CardHeader>
			<CardBody>
				<Formik
					enableReinitialize={true}
					initialValues={
						currentUnit || {
							unitId: 0,
							departmentId: 0,
							departmentName: "",
							unitName: "",
						}
					}
					validationSchema={formChooseUnit}
					onSubmit={handleSubmit}
				>
					{({ handleSubmit, resetForm }) => (
						<>
							<Form className="form form-label-right" onSubmit={handleSubmit}>
								<div className="form-group">
									<Field
										component={Select}
										name="unitId"
										onChange={handleUnitChange}
										label="Chọn đơn vị"
									>
										<option value={0} disabled>
											Chọn đơn vị
										</option>
										{listUnit.map((unit) => (
											<option value={unit.id} key={unit.id}>
												{unit.name}
											</option>
										))}
									</Field>
								</div>
								<Field
									component={Select}
									name="departmentId"
									label="Chọn văn phòng"
								>
									<option value={0} disabled>
										Chọn văn phòng
									</option>
									{listDepartment.map((department) => {
										return (
											<option value={department.id} key={department.id}>
												{department.name}
											</option>
										);
									})}
								</Field>
								<div className="form-group mt-10 text-right">
									<Button
										type="button"
										onClick={onHide}
										variant="outline-danger"
										className="mr-2"
									>
										Hủy
									</Button>
									<Button type="submit" variant="outline-primary">
										Chọn
									</Button>
								</div>
							</Form>
						</>
					)}
				</Formik>
			</CardBody>
		</Card>
	);
};

export default connect(null, mapDispatchToProps)(ChooseCurrentUnit);
