import { Action } from "../models/redux/action";
import { getNotification } from "./notification";
import { notifyError } from "./notify";

export const onEpicSuccess = (action: Action, data: any, next: any) => {
	if (action.payload.callbacks) {
		if (action.payload.callbacks.onSuccess) {
			action.payload.callbacks.onSuccess(data);
		}
	}

	return next;
};

export const onEpicFailed = (action: Action, data: any, next: any) => {
	if (data.response && data.response.message) {
		console.log(getNotification(data.response.message));
		notifyError(getNotification(data.response.message));
	}

	if (action.payload.callbacks) {
		if (action.payload.callbacks.onFailed) {
			action.payload.callbacks.onFailed(data);
		}
	}
	return next;
};
