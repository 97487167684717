import { Api } from "../api";
import { Merchant } from "../../models/Merchant";
import { ListQuery } from "../../models/redux/ListQuery";

export const getAllMerchants = (query: ListQuery) =>
	Api.get("/merchantlist/getAll", {
		page: query.page,
		size: query.per_page,
		name: query?.filter?.name || "",
		type: query?.filter?.type || 0,
		status:
			query?.filter?.status !== null && query?.filter?.status !== undefined
				? query?.filter?.status
				: undefined,
	});

export const getMerchantListForCreatingAccount = (query: ListQuery) =>
	Api.get("/user/getMerchantListForCreatingAccount", {
		page: query.page,
		size: query.per_page,
		name: query?.filter?.name || "",
	});

export const addMerchant = (data: Merchant) => {
	return Api.post("/merchantlist/createOrEdit", { ...data });
};

export const getOneMerchant = (id: number) =>
	Api.get("/merchantlist/createOrEdit", { id });

export const deleteMerchant = (merchantlistId: number) =>
	Api.get("/merchantlist/delete", { merchantlistId });
