import { Api } from "../api";
import { Currency } from "../../models/Currency";
import { ListQuery } from "../../models/redux/ListQuery";

export const getAllConfigApprover = (query: ListQuery) => {
  return Api.get("/feeapprover/getAll", {
    name: query?.filter?.name || "",
    page: query.page,
    size: query.per_page,
  });
};

export const addConfigApprover = (data: Currency) => {
  return Api.post("/feeapprover/createOrEdit", { ...data });
};

export const getOneConfigApprover = (id: number) => {
  return Api.get("/feeapprover/createOrEdit", { id });
};

export const deleteConfigApprover = (data: any) => {
  return Api.get("/feeapprover/delete", { ...data });
};
