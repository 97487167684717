import { ListQuery } from "../../../models/redux/ListQuery";
import { ReduxCallbacks } from "../../../models/redux/Callbacks";
import { ChargeType } from "../../../models/ChargeType";

export const FETCH_CHARGETYPE_INIT = "FETCH_CHARGETYPE_INIT";
export const FETCH_CHARGETYPE_SUCCESS = "FETCH_CHARGETYPE_SUCCESS";
export const FETCH_CHARGETYPE_FAILED = "FETCH_CHARGETYPE_FAILED";
export const FETCH_CHARGETYPE_CANCELLED = "FETCH_CHARGETYPE_CANCELLED";

export const ADD_CHARGETYPE_INIT = "ADD_CHARGETYPE_INIT";
export const ADD_CHARGETYPE_SUCCESS = "ADD_CHARGETYPE_SUCCESS";
export const ADD_CHARGETYPE_FAILED = "ADD_CHARGETYPE_FAILED";

export const EDIT_CHARGETYPE_INIT = "EDIT_CHARGETYPE_INIT";
export const EDIT_CHARGETYPE_SUCCESS = "EDIT_CHARGETYPE_SUCCESS";
export const EDIT_CHARGETYPE_FAILED = "EDIT_CHARGETYPE_FAILED";

export const DELETE_CHARGETYPE_INIT = "DELETE_CHARGETYPE_INIT";
export const DELETE_CHARGETYPE_SUCCESS = "DELETE_CHARGETYPE_SUCCESS";
export const DELETE_CHARGETYPE_FAILED = "DELETE_CHARGETYPE_FAILED";

export const fetchChargeTypeInit = (
  query: ListQuery,
  callbacks?: ReduxCallbacks
) => ({
  type: FETCH_CHARGETYPE_INIT,
  payload: { query, callbacks },
});

// FETCH

export const fetchChargeTypeSuccess = (response: any) => ({
  type: FETCH_CHARGETYPE_SUCCESS,
  payload: response,
});

export const fetchChargeTypeFailed = (error: any) => ({
  type: FETCH_CHARGETYPE_FAILED,
  payload: error,
});

export const fetchChargeTypeCancelled = () => ({
  type: FETCH_CHARGETYPE_CANCELLED,
});

// ADD

export const addChargeTypeInit = (
  content: ChargeType,
  callbacks?: ReduxCallbacks
) => ({
  type: ADD_CHARGETYPE_INIT,
  payload: { ...content, callbacks },
});

export const addChargeTypeSuccess = () => ({
  type: ADD_CHARGETYPE_SUCCESS,
});

export const addChargeTypeFailed = (error: any) => ({
  type: ADD_CHARGETYPE_SUCCESS,
  payload: error,
});

// EDIT

export const editChargeTypeInit = (
  content: ChargeType,
  callbacks?: ReduxCallbacks
) => ({
  type: EDIT_CHARGETYPE_INIT,
  payload: { ...content, callbacks },
});

export const editChargeTypeSuccess = () => ({
  type: EDIT_CHARGETYPE_SUCCESS,
});

export const editChargeTypeFailed = (error: any) => ({
  type: EDIT_CHARGETYPE_FAILED,
  payload: error,
});

// DELETE

export const deleteChargeTypeInit = (
  content: ChargeType,
  callbacks?: ReduxCallbacks
) => {
  return {
    type: DELETE_CHARGETYPE_INIT,
    payload: { ...content, callbacks },
  };
};

export const deleteChargeTypeSuccess = () => ({
  type: DELETE_CHARGETYPE_SUCCESS,
});

export const deleteChargeTypeFailed = (error: any) => ({
  type: DELETE_CHARGETYPE_FAILED,
  payload: error,
});
