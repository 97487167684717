import React from "react";
import { Provider } from "react-redux";
import store, { persistor } from "./redux";
import { Routes } from "./routes";
import { MaterialThemeProvider } from "../_metronic/layout";
import LoadingOverlay from "./components/LoadingOverlay";
import { SplashScreen } from "./components/SplashScreen";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from "react-toastify";
import env from "./helpers/env";
import { PageTitleProvider } from "./contexts/PageTitle";
import {NotificationsProvider} from "./contexts/NotificationsContext";

const { PUBLIC_URL } = process.env;
const { DEBUG } = env;

if (DEBUG === false) {
	console.log = function () {};
	console.warn = function () {};
	console.error = function () {};
	console.debug = function () {};
	console.trace = function () {};
}
const USING_REDUX_KEY = 'USING_REDUX_KEY';

const App = () => {
	return (
		<Provider store={store}>
				<PersistGate persistor={persistor} loading={<SplashScreen />}>
					<React.Suspense fallback={<SplashScreen />}>
						<MaterialThemeProvider>
							<NotificationsProvider>
								<PageTitleProvider>
									<Routes basename={PUBLIC_URL} />
									<LoadingOverlay />
									<ToastContainer />
								</PageTitleProvider>
							</NotificationsProvider>
						</MaterialThemeProvider>
					</React.Suspense>
				</PersistGate>
		</Provider>
	);
};

export default App;
