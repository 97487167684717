import { ActionsObservable, ofType } from "redux-observable";
import { map, mergeMap, catchError, takeUntil } from "rxjs/operators";

import {
	createOrUpdateBookingNote,
	getAllBookingNoteOfMe,
	updateProductBookingNote,
	updateNote,
	fetchBookingNoteNo,
	updateBookingNoteStatus,
	deleteBookingNote,
	getAllBookingNote,
	getAllBookingNoteBySender,
} from "../../../api/bookingNote";
import {
	CREATE_BOOKING_NOTE,
	updateBooking,
	CREATE_BOOKING_NOTE_CANCELLED,
	UPDATE_PRODUCT_INFOR_BOOKING_NOTE,
	UPDATE_PRODUCT_INFOR_BOOKING_NOTE_CANCELLED,
	updateProductInforBookingNoteSuccess,
	UPDATE_NOTE_BOOKING_NOTE,
	UPDATE_NOTE_BOOKING_NOTE_CANCELLED,
	updateNoteSuccess,
	createBookingNoteFailed,
	updateProductInforBookingNoteFailed,
	updateNoteeFailed,
	GET_BOOKING_NOTE_NO,
	getBookingNoteNo,
	CANCEL_BOOKING_NOTE,
	FETCH_ALL_BOOKING_NOTE,
	FETCH_ALL_BOOKING_NOTE_CANCELLED,
	fetchAllBookingNoteSuccess,
	fetchAllBookingNoteFailed,
	FETCH_LIST_BOOKING_NOTE_BY_SENDER_ID,
	getAllBookingNoteBySenderIdSuccess,
	getAllBookingNoteBySenderIdFailed,
	FETCH_LIST_BOOKING_NOTE_BY_SENDER_ID_CANCELLED,
	cancelBookingNoteSuccess,
	cancelBookingNoteFailed,
	CANCEL_BOOKING_NOTE_CANCELLED,
	UPDATE_BOOKING_NOTE_STATUS,
	UPDATE_BOOKING_NOTE_STATUS_CANCELLED,
	updateBookingNoteStatusSuccess,
	updateBookingNoteStatusFailed,
} from "./actions";
import { onEpicSuccess, onEpicFailed } from "../../../helpers/reduxEpic";
import { of } from "rxjs";
import { CANCEL_ALL_REQUEST } from "../../default";
import { Action } from "../../../models/redux/action";
import { disableUpdateBookingNote } from "../../../helpers/booking_note";

export const createBookingNoteEpic = (action$: ActionsObservable<any>) =>
	action$.pipe(
		ofType(CREATE_BOOKING_NOTE),
		mergeMap((action) => {
			if (disableUpdateBookingNote(action.payload.bookingNote.status)) {
				return of(
					onEpicSuccess(
						action,
						null,
						getBookingNoteNo({
							...action.payload.bookingNote,
						})
					)
				);
			}

			return createOrUpdateBookingNote(action.payload.bookingNote).pipe(
				map((response) =>
					onEpicSuccess(
						action,
						response,
						getBookingNoteNo({
							...action.payload.bookingNote,
							status:
								action.payload.bookingNote.status !== undefined
									? action.payload.bookingNote.status
									: 0,
							id: parseInt(response.response.message),
						})
					)
				),
				catchError((error) =>
					of(onEpicFailed(action, error, createBookingNoteFailed(error)))
				),
				takeUntil(
					action$.pipe(
						ofType(CREATE_BOOKING_NOTE_CANCELLED, CANCEL_ALL_REQUEST)
					)
				)
			);
		})
	);

export const updateBookingNoteEpic = (action$: ActionsObservable<any>) =>
	action$.pipe(
		ofType(UPDATE_BOOKING_NOTE_STATUS),
		mergeMap((action) => {
			return updateBookingNoteStatus(
				action.payload.id,
				action.payload.status
			).pipe(
				map((response) =>
					onEpicSuccess(action, response, updateBookingNoteStatusSuccess())
				),
				catchError((error) =>
					of(onEpicFailed(action, error, updateBookingNoteStatusFailed(error)))
				),
				takeUntil(
					action$.pipe(
						ofType(UPDATE_BOOKING_NOTE_STATUS_CANCELLED, CANCEL_ALL_REQUEST)
					)
				)
			);
		})
	);

export const updateInforProductEpic = (action$: ActionsObservable<any>) =>
	action$.pipe(
		ofType(UPDATE_PRODUCT_INFOR_BOOKING_NOTE),
		mergeMap((action) =>
			updateProductBookingNote(action.payload.data).pipe(
				map((response) => {
					return onEpicSuccess(
						action,
						response,
						updateProductInforBookingNoteSuccess()
					);
				}),
				catchError((error) =>
					of(onEpicFailed(action, error, updateProductInforBookingNoteFailed()))
				),
				takeUntil(
					action$.pipe(
						ofType(
							UPDATE_PRODUCT_INFOR_BOOKING_NOTE_CANCELLED,
							CANCEL_ALL_REQUEST
						)
					)
				)
			)
		)
	);

export const updateNoteEpic = (action$: ActionsObservable<any>) =>
	action$.pipe(
		ofType(UPDATE_NOTE_BOOKING_NOTE),
		mergeMap((action) =>
			updateNote(action.payload.data).pipe(
				map((response) => {
					return onEpicSuccess(action, response, updateNoteSuccess());
				}),
				catchError((error) =>
					of(onEpicFailed(action, error, updateNoteeFailed(error)))
				),
				takeUntil(
					action$.pipe(
						ofType(UPDATE_NOTE_BOOKING_NOTE_CANCELLED, CANCEL_ALL_REQUEST)
					)
				)
			)
		)
	);

export const getBookingNoteNoEpic = (action$: ActionsObservable<Action>) =>
	action$.pipe(
		ofType(GET_BOOKING_NOTE_NO),
		mergeMap((action) =>
			fetchBookingNoteNo(action.payload.bookingNote.id).pipe(
				map(({ response }) => {
					console.log(response);
					return updateBooking({
						...action.payload.bookingNote,
						bookingNo: response,
					});
				})
			)
		)
	);

export const cancelBookingNoteEpic = (action$: ActionsObservable<Action>) =>
	action$.pipe(
		ofType(CANCEL_BOOKING_NOTE),
		mergeMap((action) =>
			deleteBookingNote(action.payload.bookingnoteId).pipe(
				map((response) =>
					onEpicSuccess(action, response, cancelBookingNoteSuccess())
				),
				catchError((error) =>
					of(onEpicFailed(action, error, cancelBookingNoteFailed(error)))
				),
				takeUntil(
					action$.pipe(
						ofType(CANCEL_BOOKING_NOTE_CANCELLED, CANCEL_ALL_REQUEST)
					)
				)
			)
		)
	);

export const fetchAllBookingNoteEpic = (action$: ActionsObservable<Action>) =>
	action$.pipe(
		ofType(FETCH_ALL_BOOKING_NOTE),
		mergeMap((action) =>
			getAllBookingNote(action.payload.query).pipe(
				map((response) =>
					onEpicSuccess(action, response, fetchAllBookingNoteSuccess(response))
				),
				catchError((error) =>
					of(onEpicFailed(action, error, fetchAllBookingNoteFailed(error)))
				),
				takeUntil(
					action$.pipe(
						ofType(FETCH_ALL_BOOKING_NOTE_CANCELLED, CANCEL_ALL_REQUEST)
					)
				)
			)
		)
	);

export const fetchAllBookingNoteBySenderIdEpic = (
	action$: ActionsObservable<Action>
) =>
	action$.pipe(
		ofType(FETCH_LIST_BOOKING_NOTE_BY_SENDER_ID),
		mergeMap((action) =>
			getAllBookingNoteBySender(action.payload.query).pipe(
				map((response) =>
					onEpicSuccess(
						action,
						response,
						getAllBookingNoteBySenderIdSuccess(response)
					)
				),
				catchError((error) =>
					of(onEpicFailed(action, error, getAllBookingNoteBySenderIdFailed()))
				),
				takeUntil(
					action$.pipe(
						ofType(
							FETCH_LIST_BOOKING_NOTE_BY_SENDER_ID_CANCELLED,
							CANCEL_ALL_REQUEST
						)
					)
				)
			)
		)
	);
