import { ListQuery } from "../../../models/redux/ListQuery";
import { ReduxCallbacks } from "../../../models/redux/Callbacks";
import { Action } from "../../../models/redux/action";
import { CreateOrEditCostModel } from "../../../models/createOrEditCost";
import { CreateOrEditContainerModel } from "../../../models/createOrEditContainer";
import { AjaxResponse } from "rxjs/ajax";

// BOOKING LIST
export const FETCH_BOOKING_LIST = "FETCH_BOOKING_LIST";
export const FETCH_BOOKING_LIST_SUCCESS = "FETCH_BOOKING_LIST_SUCCESS";
export const FETCH_BOOKING_LIST_FAILED = "FETCH_BOOKING_LIST_FAILED";
export const FETCH_BOOKING_LIST_CANCELLED = "FETCH_BOOKING_LIST_CANCELLED";

export const GET_ALL_USER_BY_TYPE = "GET_ALL_USER_BY_TYPE";
export const GET_ALL_USER_BY_TYPE_SUCCESS = "GET_ALL_USER_BY_TYPE_SUCCESS";
export const GET_ALL_USER_BY_TYPE_FAILED = "GET_ALL_USER_BY_TYPE_FAILED";
export const GET_ALL_USER_BY_TYPE_CANCELLED = "GET_ALL_USER_BY_TYPE_CANCELLED";

// GENERAL INFORMATION
export const GET_BOOKING_NOTIFY_ADDRESS = "GET_BOOKING_NOTIFY_ADDRESS";
export const GET_BOOKING_NOTIFY_ADDRESS_SUCCESS =
  "GET_BOOKING_NOTIFY_ADDRESS_SUCCESS";
export const GET_BOOKING_NOTIFY_ADDRESS_FAILED =
  "GET_BOOKING_NOTIFY_ADDRESS_FAILED";
export const GET_BOOKING_NOTIFY_ADDRESS_CANCELLED =
  "GET_BOOKING_NOTIFY_ADDRESS_CANCELLED";

export const GET_VESSEL_REQUEST = "GET_VESSEL_REQUEST";
export const GET_VESSEL_REQUEST_SUCCESS = "GET_VESSEL_REQUEST_SUCCESS";
export const GET_VESSEL_REQUEST_FAILED = "GET_VESSEL_REQUEST_FAILED";
export const GET_VESSEL_REQUEST_CANCELLED = "GET_VESSEL_REQUEST_CANCELLED";

export const DELETE_ROW_NOTIFY_REQUEST = "DELETE_ROW_NOTIFY_REQUEST";
export const DELETE_ROW_NOTIFY_SUCCESS = "DELETE_ROW_NOTIFY_SUCCESS";
export const DELETE_ROW_NOTIFY_FAILED = "DELETE_ROW_NOTIFY_FAILED";
export const DELETE_ROW_NOTIFY_CANCELLED = "DELETE_ROW_NOTIFY_CANCELLED";

// SHIPPING
export const BOOKING_SHIPPING_TABLE = "BOOKING_SHIPPING_TABLE";
export const BOOKING_SHIPPING_TABLE_SUCCESS = "BOOKING_SHIPPING_TABLE_SUCCESS";
export const BOOKING_SHIPPING_TABLE_FAILED = "BOOKING_SHIPPING_TABLE_FAILED";
export const BOOKING_SHIPPING_TABLE_CANCELLED =
  "BOOKING_SHIPPING_TABLE_CANCELLED";

export const DELETE_ROW_SHIPPING = "DELETE_ROW_SHIPPING";
export const DELETE_ROW_SHIPPING_SUCCESS = "DELETE_ROW_SHIPPING_SUCCESS";
export const DELETE_ROW_SHIPPING_FAILED = "DELETE_ROW_SHIPPING_FAILED";
export const DELETE_ROW_SHIPPING_CANCELLED = "DELETE_ROW_SHIPPING_CANCELLED";

export const BOOKING_CONTAINER_TABLE = "BOOKING_CONTAINER_TABLE";
export const BOOKING_CONTAINER_TABLE_SUCCESS =
  "BOOKING_CONTAINER_TABLE_SUCCESS";
export const BOOKING_CONTAINER_TABLE_FAILED = "BOOKING_CONTAINER_TABLE_FAILED";
export const BOOKING_CONTAINER_TABLE_CANCELLED =
  "BOOKING_CONTAINER_TABLE_CANCELLED";

export const DELETE_ROW_CONTAINER = "DELETE_ROW_CONTAINER";
export const DELETE_ROW_CONTAINER_SUCCESS = "DELETE_ROW_CONTAINER_SUCCESS";
export const DELETE_ROW_CONTAINER_FAILED = "DELETE_ROW_CONTAINER_FAILED";
export const DELETE_ROW_CONTAINER_CANCELLED = "DELETE_ROW_CONTAINER_CANCELLED";

// CHARGES
export const BOOKING_CHARGES_TABLE = "BOOKING_CHARGES_TABLE";
export const BOOKING_CHARGES_TABLE_SUCCESS = "BOOKING_CHARGES_TABLE_SUCCESS";
export const BOOKING_CHARGES_TABLE_FAILED = "BOOKING_CHARGES_TABLE_FAILED";
export const BOOKING_CHARGES_TABLE_CANCELLED =
  "BOOKING_CHARGES_TABLE_CANCELLED";

export const DELETE_ROW_CHARGES = "DELETE_ROW_CHARGES";
export const DELETE_ROW_CHARGES_SUCCESS = "DELETE_ROW_CHARGES_SUCCESS";
export const DELETE_ROW_CHARGES_FAILED = "DELETE_ROW_CHARGES_FAILED";
export const DELETE_ROW_CHARGES_CANCELLED = "DELETE_ROW_CHARGES_CANCELLED";

export const BOOKING_COST_TABLE = "BOOKING_COST_TABLE";
export const BOOKING_COST_TABLE_SUCCESS = "BOOKING_COST_TABLE_SUCCESS";
export const BOOKING_COST_TABLE_FAILED = "BOOKING_COST_TABLE_FAILED";
export const BOOKING_COST_TABLE_CANCELLED = "BOOKING_COST_TABLE_CANCELLED";

export const DELETE_ROW_COST = "DELETE_ROW_COST";
export const DELETE_ROW_COST_SUCCESS = "DELETE_ROW_COST_SUCCESS";
export const DELETE_ROW_COST_FAILED = "DELETE_ROW_COST_FAILED";
export const DELETE_ROW_COST_CANCELLED = "DELETE_ROW_COST_CANCELLED";

// GET BOOKING LIST
export const fetchBookingList = (query: ListQuery) => ({
  type: FETCH_BOOKING_LIST,
  payload: { query },
});

export const fetchBookingListSuccess = (response: any) => ({
  type: FETCH_BOOKING_LIST_SUCCESS,
  payload: response,
});

export const fetchBookingListFailed = (error: any) => ({
  type: FETCH_BOOKING_LIST_FAILED,
  payload: error,
});

export const fetchBookingListCancelled = () => ({
  type: FETCH_BOOKING_LIST_CANCELLED,
});

// GET USER BY TYPE
export const getAllUserByType = (
  query: ListQuery,
  callbacks?: ReduxCallbacks
) => ({
  type: GET_ALL_USER_BY_TYPE,
  payload: { query, callbacks },
});

export const getAllUserByTypeSuccess = (response: any) => ({
  type: GET_ALL_USER_BY_TYPE_SUCCESS,
  payload: response,
});

export const getAllUserByTypeFailed = (error: any) => ({
  type: GET_ALL_USER_BY_TYPE_FAILED,
  payload: error,
});

export const getAllUserByTypeCancelled = () => ({
  type: GET_ALL_USER_BY_TYPE_CANCELLED,
});

// GET BOOKING NOTIFY ADDRESS
export const getBookingNotifyAddress = (
  query: ListQuery,
  callbacks?: ReduxCallbacks
) => ({
  type: GET_BOOKING_NOTIFY_ADDRESS,
  payload: { query, callbacks },
});

export const getBookingNotifyAddressSuccess = (response: any) => ({
  type: GET_BOOKING_NOTIFY_ADDRESS_SUCCESS,
  payload: response,
});

export const getBookingNotifyAddressFailed = (error: any) => ({
  type: GET_BOOKING_NOTIFY_ADDRESS_FAILED,
  payload: error,
});

export const getBookingNotifyAddressCancelled = () => ({
  type: GET_BOOKING_NOTIFY_ADDRESS_CANCELLED,
});

// GET VESSEL REQUEST
export const getVesselRequest = (
  query: ListQuery,
  callbacks?: ReduxCallbacks
): Action => ({
  type: GET_VESSEL_REQUEST,
  payload: { query, callbacks },
});

export const getVesselRequestSuccess = ({
  response,
}: AjaxResponse): Action => ({
  type: GET_VESSEL_REQUEST_SUCCESS,
  payload: { response },
});

export const getVesselRequestFailed = (error: any): Action => ({
  type: GET_VESSEL_REQUEST_FAILED,
  payload: error,
});

export const getVesselRequestCancelled = (): Action => ({
  type: GET_VESSEL_REQUEST_CANCELLED,
});

// DELETE AN ROW NOTIFY ADDRESS ITEM
export const deleteRowNotifyRequest = (
  addressId: number,
  callbacks?: ReduxCallbacks
) => {
  return {
    type: DELETE_ROW_NOTIFY_REQUEST,
    payload: { addressId, callbacks },
  };
};

export const deleteRowNotifySuccess = () => ({
  type: DELETE_ROW_NOTIFY_SUCCESS,
});

export const deleteRowNotifyFailed = (error: any) => ({
  type: DELETE_ROW_NOTIFY_FAILED,
  payload: error,
});

export const deleteAddressCancelled = (): Action => ({
  type: DELETE_ROW_NOTIFY_CANCELLED,
});

// GET BOOKING SHIPPING
export const getBookingShipping = (
  query: ListQuery,
  callbacks?: ReduxCallbacks
) => ({
  type: BOOKING_SHIPPING_TABLE,
  payload: { query, callbacks },
});

export const getBookingShippingSuccess = (response: any) => ({
  type: BOOKING_SHIPPING_TABLE_SUCCESS,
  payload: response,
});

export const getBookingShippingFailed = (error: any) => ({
  type: BOOKING_SHIPPING_TABLE_FAILED,
  payload: error,
});

export const getBookingShippingCancelled = () => ({
  type: BOOKING_SHIPPING_TABLE_CANCELLED,
});

// DELETE AN ROW NOTIFY ADDRESS ITEM
export const deleteRowShipping = (
  addressId: number,
  callbacks?: ReduxCallbacks
) => {
  return {
    type: DELETE_ROW_SHIPPING,
    payload: { addressId, callbacks },
  };
};

export const deleteRowShippingSuccess = () => ({
  type: DELETE_ROW_SHIPPING_SUCCESS,
});

export const deleteRowShippingFailed = (error: any) => ({
  type: DELETE_ROW_SHIPPING_FAILED,
  payload: error,
});

export const deleteRowShippingCancelled = (): Action => ({
  type: DELETE_ROW_SHIPPING_CANCELLED,
});

// GET BOOKING COST
export const getBookingCost = (
  query: ListQuery,
  callbacks?: ReduxCallbacks
) => ({
  type: BOOKING_COST_TABLE,
  payload: { query, callbacks },
});

export const getBookingCostSuccess = (response: any) => ({
  type: BOOKING_COST_TABLE_SUCCESS,
  payload: response,
});

export const getBookingCostFailed = (error: any) => ({
  type: BOOKING_COST_TABLE_FAILED,
  payload: error,
});

export const getBookingCostCancelled = () => ({
  type: BOOKING_COST_TABLE_CANCELLED,
});

// DELETE AN ROW COST
export const deleteRowCost = (
  addressId: number,
  callbacks?: ReduxCallbacks
) => {
  return {
    type: DELETE_ROW_COST,
    payload: { addressId, callbacks },
  };
};

export const deleteRowCostSuccess = () => ({
  type: DELETE_ROW_COST_SUCCESS,
});

export const deleteRowCostFailed = (error: any) => ({
  type: DELETE_ROW_COST_FAILED,
  payload: error,
});

export const deleteRowCostCancelled = (): Action => ({
  type: DELETE_ROW_COST_CANCELLED,
});

// GET BOOKING CONTAINER
export const getBookingContainer = (
  query: ListQuery,
  callbacks?: ReduxCallbacks
) => ({
  type: BOOKING_CONTAINER_TABLE,
  payload: { query, callbacks },
});

export const getBookingContainerSuccess = (response: any) => ({
  type: BOOKING_CONTAINER_TABLE_SUCCESS,
  payload: response,
});

export const getBookingContainerFailed = (error: any) => ({
  type: BOOKING_CONTAINER_TABLE_FAILED,
  payload: error,
});

export const getBookingContainerCancelled = () => ({
  type: BOOKING_CONTAINER_TABLE_CANCELLED,
});

// DELETE AN ROW CONTAINER
export const deleteRowContainer = (Id: number, callbacks?: ReduxCallbacks) => {
  return {
    type: DELETE_ROW_CONTAINER,
    payload: { Id, callbacks },
  };
};

export const deleteRowContainerSuccess = () => ({
  type: DELETE_ROW_CONTAINER_SUCCESS,
});

export const deleteRowContainerFailed = (error: any) => ({
  type: DELETE_ROW_CONTAINER_FAILED,
  payload: error,
});

export const deleteRowContainerCancelled = (): Action => ({
  type: DELETE_ROW_CONTAINER_CANCELLED,
});

// GET BOOKING CHARGES
export const getBookingCharges = (
  query: ListQuery,
  callbacks?: ReduxCallbacks
) => ({
  type: BOOKING_CHARGES_TABLE,
  payload: { query, callbacks },
});

export const getBookingChargesSuccess = (response: any) => ({
  type: BOOKING_CHARGES_TABLE_SUCCESS,
  payload: response,
});

export const getBookingChargesFailed = (error: any) => ({
  type: BOOKING_CHARGES_TABLE_FAILED,
  payload: error,
});

export const getBookingChargesCancelled = () => ({
  type: BOOKING_CHARGES_TABLE_CANCELLED,
});

// DELETE AN ROW CHARGES
export const deleteRowCharges = (
  chargeId: number,
  callbacks?: ReduxCallbacks
) => {
  return {
    type: DELETE_ROW_CHARGES,
    payload: { chargeId, callbacks },
  };
};

export const deleteRowChargesSuccess = () => ({
  type: DELETE_ROW_CHARGES_SUCCESS,
});

export const deleteRowChargesFailed = (error: any) => ({
  type: DELETE_ROW_CHARGES_FAILED,
  payload: error,
});

export const deleteRowChargesCancelled = (): Action => ({
  type: DELETE_ROW_CHARGES_CANCELLED,
});
