import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { logout } from "../../redux/modules/auth";
import { User } from "../../models/User";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { Dropdown, Modal } from "react-bootstrap";
import { ReduxCallbacks } from "../../models/redux/Callbacks";
import { CurrentUnit } from "../../models/CurrentUnit";
import ChooseCurrentUnit from "../../components/ChooseCurrentUnit";
import { Auth } from "../../services/auth";
import { UserNotificationsDropdown } from "../../../_metronic/layout/components/extras/dropdowns/UserNotificationsDropdown";
import { ChangePasswordModal } from "../../components/ChangePasswordModal";

interface Props {
	user: User;
	logout: (callbacks?: ReduxCallbacks) => void;
	currentUnit: CurrentUnit | null;
}

const DropdownTopbarItemToggler = React.forwardRef((props: any, ref: any) => {
	return (
		<div
			ref={ref}
			className="topbar-item"
			onClick={(e) => {
				e.preventDefault();
				props.onClick(e);
			}}
		>
			{props.children}
		</div>
	);
});

DropdownTopbarItemToggler.displayName = "DropdownTopbarItemToggler";

const maptStateToProps = (state: any) => {
	return {
		user: state.auth.user,
		currentUnit: state.currentUnit,
	};
};

const mapDispatchToProps = {
	logout,
};

export const Topbar = connect(
	maptStateToProps,
	mapDispatchToProps
)(({ logout, user, currentUnit }: Props) => {
	const [
		chooseCurrentUnitModalVisible,
		setChooseCurrentUnitModalVisible,
	] = useState(false);
	const [
		changePasswordModalVisible,
		setChangePasswordModalVisible,
	] = useState(false);

	useEffect(() => {
		if (!user) {
			console.log(user);

			window.location.reload();
		}
	}, [user]);

	const handleLogout = (event: any) => {
		event.preventDefault();
		logout();
	};

	const openChooseCurrentUnitModal = (event: any) => {
		event.preventDefault();
		setChooseCurrentUnitModalVisible(true);
	};
	
	const closeChooseCurrentUnitModal = () => {
		setChooseCurrentUnitModalVisible(false);
	};

	const openChangePasswordModal = (event: any) => {
		event.preventDefault();
		setChangePasswordModalVisible(true);
	};

	const closeChangePasswordModal = () => {
		setChangePasswordModalVisible(false);
	};

	return (
		<div className="topbar">
			<UserNotificationsDropdown></UserNotificationsDropdown>
			<div className="topbar-item">
				<div className="btn btn-icon w-auto d-flex flex-column justify-content-center align-items-end text-right btn-lg px-2 p-0 mt-n1">
					<div>
						<span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
							Hi,
						</span>
						<span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline">
							{user?.name}
						</span>
					</div>
					{Auth.isSystemUser() && (
						<div>
							<span className="font-size-sm">{currentUnit?.unitName}</span>
							<span className="d-inline-block mx-1">-</span>
							<span className="font-size-sm">
								{currentUnit?.departmentName}
							</span>
						</div>
					)}
				</div>
			</div>
			<Dropdown drop="down" alignRight>
				<Dropdown.Toggle
					as={DropdownTopbarItemToggler}
					id="dropdown-toggle-my-cart"
				>
					<div
						className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
						id="kt_quick_user_toggle"
					>
						<>
							<span className="symbol symbol-circle symbol-35 symbol-light-success">
								<span className="symbol-label font-size-h5 font-weight-bold">
									{user?.name ? user.name[0] : null}
								</span>
							</span>
						</>
					</div>
				</Dropdown.Toggle>
				<Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround">
					<ul className="navi py-4">
						{Auth.isSystemUser() && (
							<li className="navi-item">
								<a
									href="/#"
									onClick={openChooseCurrentUnitModal}
									className="navi-link"
								>
									<span className="navi-text">Đổi đơn vị</span>
								</a>
							</li>
						)}
						<li className="navi-item">
							<a href="/#" onClick={openChangePasswordModal} className="navi-link">
								<span className="navi-text">Đổi mật khẩu</span>
							</a>
						</li>
						<li className="navi-item">
							<a href="/#" onClick={handleLogout} className="navi-link">
								<span className="navi-text">Đăng xuất</span>
							</a>
						</li>
					</ul>
				</Dropdown.Menu>
			</Dropdown>
			<Modal
				show={chooseCurrentUnitModalVisible}
				onHide={closeChooseCurrentUnitModal}
			>
				<ChooseCurrentUnit
					className="mb-0"
					currentUnit={currentUnit || undefined}
					onSuccess={closeChooseCurrentUnitModal}
					onHide={closeChooseCurrentUnitModal}
				/>
			</Modal>
			<ChangePasswordModal visible={changePasswordModalVisible} onHide={closeChangePasswordModal} />
		</div>
	);
});
