/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { RoutePaths } from "../../../constants";
import ItemMenu from "./ItemMenu";
import SVG from "react-inlinesvg";
import { MenuSection } from "./MenuSection";
import { FaIcon } from "../../../components/FaIcon";
import { toAbsoluteUrl } from "../../../helpers";

export function UserMenuList() {
	return (
		<>
			{/* begin::Menu Nav */}
			<ul className={`menu-nav pt-0`}>
				{/* <ParentMenu
					icon={
						<span className="menu-icon">
							<FaIcon icon="anchor" />
						</span>
					}
					name="Khách hàng"
					to={RoutePaths.Ship.Index}
					permission={Permissions.Pages.ShipSchedule.Main}
					showWithUser
				></ParentMenu> */}

				<MenuSection title="Khách hàng" />

				<ItemMenu
					to={RoutePaths.Ship.Schedule}
					icon={
						<span className="svg-icon menu-icon">
							<SVG src={toAbsoluteUrl("/media/svg/ship.svg")} />
						</span>
					}
					name="Lịch tàu"
					showWithUser
				/>
				{/*end::2 Level*/}

				{/*begin::2 Level*/}
				<ItemMenu
					to={RoutePaths.Action.MyBookingNote}
					icon={
						<span className="menu-icon">
							<FaIcon icon="book" />
						</span>
					}
					name="Booking Note"
				/>
				<ItemMenu
					to={RoutePaths.Action.CreateBookingNote}
					icon={
						<span className="menu-icon">
							<FaIcon icon="book" />
						</span>
					}
					name="Tạo Booking Note"
				/>

				{/*end::1 Level*/}
			</ul>
			{/* end::Menu Nav */}
		</>
	);
}
