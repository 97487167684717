import { ListQuery } from "../../models/redux/ListQuery";
import { Api } from "../api";
import { Commodity } from "../../models/Commodity";

export const getAllCommoditys = (query: ListQuery) =>
  Api.get("/commoditylist/getAll", {
    page: query.page,
    size: query.per_page,
    name: query.filter?.name || "",
    status: query.filter?.status || "",
  });

export const createOrEditCommodity = (commodity: Commodity) =>
  Api.post("/commoditylist/createOrEdit", commodity);

export const getOneCommodity = (id: number) =>
  Api.get("/commoditylist/createOrEdit", { id });

export const deleteCommodity = (data: any) =>
  Api.get("/commoditylist/delete", { ...data });
