import { Field, Form, Formik } from "formik";
import React, { useCallback } from "react";
import { Button, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { ChangePasswordRequest } from "../../models/ChangePasswordRequest";
import { ReduxCallbacks } from "../../models/redux/Callbacks";
import { changePassword } from "../../redux/modules/account/actions";
import { Input } from "../Form";
import * as yup from "yup";
import { ComponentWithLoading } from "../../models/ComponentWithLoading";
import { disableLoading, enableLoading } from "../../redux/modules/loading";
import { notifyError, notifySuccess } from "../../helpers/notify";

interface ChangePasswordModalProps extends ComponentWithLoading {
	visible: boolean;
	onHide?: () => void;
	changePassword: (
		payload: ChangePasswordRequest,
		callbacks?: ReduxCallbacks
	) => void;
}

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = {
	changePassword: changePassword,
	disableLoading: disableLoading,
	enableLoading: enableLoading,
};

const validationSchema = yup.object().shape({
	currentPassword: yup.string().required("Bắt buộc"),
	newPassword: yup.string().required("Bắt buộc"),
	confirmPassword: yup.string().required("Bắt buộc").oneOf([yup.ref('newPassword'), '`'], 'Mật khẩu không khớp'),
});

const ChangePasswordModalBased = ({
	changePassword,
	onHide,
	visible,
}: ChangePasswordModalProps) => {
	const handleSubmit = useCallback((values: ChangePasswordRequest) => {
		enableLoading();		
		changePassword(values, {
			onSuccess: () => {
				disableLoading();
				notifySuccess("Đổi mật khẩu thành công!");
				onHide && onHide();
			},
			onFailed: () => {
				disableLoading();
				notifyError("Đổi mật khẩu thất bại, vui lòng thử lại sau!");
			},
		});
	}, []);

	return (
		<Modal show={visible} onHide={onHide}>
			<Modal.Header>
				<Modal.Title>Đổi mật khẩu</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Formik<ChangePasswordRequest>
					enableReinitialize={true}
					initialValues={{
						currentPassword: "",
						newPassword: "",
						confirmPassword: "",
					}}
					validationSchema={validationSchema}
					onSubmit={handleSubmit}
				>
					{({ handleSubmit, resetForm }) => (
						<>
							<Form className="form form-label-right" onSubmit={handleSubmit}>
								<div className="form-group">
									<Field
										component={Input}
										name="currentPassword"
										type="password"
										label="Mật khẩu hiện tại"
									/>
								</div>
								<div className="form-group">
									<Field
										component={Input}
										name="newPassword"
										type="password"
										label="Mật khẩu mới"
									/>
								</div>
								<div className="form-group">
									<Field
										component={Input}
										name="confirmPassword"
										type="password"
										label="Xác nhận mật khẩu"
									/>
								</div>
								<div className="form-group mt-10 text-right">
									<Button
										type="button"
										onClick={onHide}
										variant="outline-danger"
										className="mr-2"
									>
										Hủy
									</Button>
									<Button type="submit" variant="outline-primary">
										Đổi mật khẩu
									</Button>
								</div>
							</Form>
						</>
					)}
				</Formik>
			</Modal.Body>
		</Modal>
	);
};
export const ChangePasswordModal = connect(
	mapStateToProps,
	mapDispatchToProps
)(ChangePasswordModalBased);
